import React from 'react';
import {TableBody, TableCell, TableRow } from '@material-ui/core';

const HeatmapTable = ({ data, columns }) => {
    const colorRange = ['#FFEEEE', 'rgb(239 159 159)', 'rgb(237, 145, 145)', 'rgb(221 51 51)']; 

  const valueRanges = {};
  columns.forEach(column => {
    const values = data.map(row => row[column.property]);
    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);
    valueRanges[column.property] = { minValue, maxValue };
  });

  
  const getColorIndex = (value, columnProperty) => {
    const { minValue, maxValue } = valueRanges[columnProperty];
    const range = maxValue - minValue;
    const valueRatio = (value - minValue) / range;
    return (Math.floor(valueRatio * (colorRange.length - 1)));
  };

  const formatNumber = (num) => {
    if (typeof num === 'number') {
      const roundedNumber = parseFloat(num.toFixed(2));
      return(roundedNumber.toLocaleString('en-US'));
    }
    else {
      return num;
    }
  }

  return (
        <TableBody id="#pdfContentBalance">
          {data.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {columns.map((column, colIndex) => (
                <TableCell key={colIndex} style={{padding: '10px', backgroundColor: colorRange[getColorIndex(row[column.property], column.property)] }}>
                  {formatNumber(row[column.property])}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>

        
  );
};

export default HeatmapTable;
