import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import {
  Checkbox,
  FormControl,
  MenuItem,
  Modal,
  Select,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import { Grid, Paper } from '@material-ui/core'
import datajson from '../template.json'
import { Close } from '@mui/icons-material'
import HeatmapTable from './heatMapTable'
import InputLabel from '@mui/material/InputLabel'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: 'calc(100vh - 65.20px)',
  width: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
};

const tableContentStyle1 = {
  fontSize: '11.3',
  lineHeight: '1.3',
  fontWeight: 'bold',
  color: 'white',
  border: 'white',
  zIndex: 0,
  background: 'transparent linear-gradient(180deg, #0C6276 0%, #00A9A6 100%) 0% 0% no-repeat padding-box'
}

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

function AnalyticsBalancedScorecard(props) {
  let content = [];



  const [checkedScope1, setCheckedScope1] = React.useState(true);
  const [checkedScope2, setCheckedScope2] = React.useState(true);
  const [checkedScope3, setCheckedScope3] = React.useState(true);

  const [rootNodeSelected, setRootNodeSelected] = React.useState("");
  const [rootDetails, setRootDetails] = React.useState([]);


  const handleRootNodeSelected = (value) => {
    setRootNodeSelected(value.target.value);
  }

  const handleScope1Change = () => {
    setCheckedScope1(!checkedScope1);
  }

  const handleScope2Change = () => {
    setCheckedScope2(!checkedScope2);
  }

  const handleScope3Change = () => {
    setCheckedScope3(!checkedScope3);
  }

  const getNodeMaterialDetails = (nodeID) => {
    let ScopeDetails = props.getChildNodes(nodeID)
    let ScopeNodeId = [];
    ScopeDetails.forEach(node => {
      ScopeNodeId.push(node.id);
    });
    ScopeNodeId.forEach(scopeNode => {
      let materialDetails = props.getChildNodes(scopeNode);
      materialDetails.forEach(material => {
        let linkInfo = props.getLink(scopeNode, material.id);
        let contentTemp = {
          "Scope": linkInfo.source.uid.split("-")[0],
          "Material": material.uid,
          "eci_cost": linkInfo.target.eci_cost * linkInfo.quantity,
          "adp_elements": linkInfo.target.adp_elements * linkInfo.quantity,
          "adp_fossil": linkInfo.target.adp_fossil * linkInfo.quantity,
          "ap": linkInfo.target.ap * linkInfo.quantity,
          "ep": linkInfo.target.ep * linkInfo.quantity,
          "faetp": linkInfo.target.faetp * linkInfo.quantity,
          "gwp_100": linkInfo.target.gwp_100 * linkInfo.quantity,
          "gwp_100_ebc": linkInfo.target.gwp_100_ebc * linkInfo.quantity,
          "htp": linkInfo.target.htp * linkInfo.quantity,
          "maetp": linkInfo.target.maetp * linkInfo.quantity,
          "odp": linkInfo.target.odp * linkInfo.quantity,
          "pocp": linkInfo.target.pocp * linkInfo.quantity,
          "tetp": linkInfo.target.tetp * linkInfo.quantity,
        };
        if (checkedScope1 && contentTemp.Scope === "Scope 1") {
          content.push(contentTemp);
        }
        if (checkedScope2 && contentTemp.Scope === "Scope 2") {
          content.push(contentTemp);
        }
        if (checkedScope3 && contentTemp.Scope === "Scope 3") {
          content.push(contentTemp);
        }
      });
    });
  }

  getNodeMaterialDetails(rootNodeSelected);
  const columns = [
    { label: "Scope", property: "Scope" },
    { label: "Material", property: "Material" },
    { label: "eci_cost", property: "eci_cost" },
    { label: "adp_elements", property: "adp_elements" },
    { label: "adp_fossil", property: "adp_fossil" },
    { label: "ap", property: "ap" },
    { label: "ep", property: "ep" },
    { label: "faetp", property: "faetp" },
    { label: "gwp_100", property: "gwp_100" },
    { label: "gwp_100_ebc", property: "gwp_100_ebc" },
    { label: "htp", property: "htp" },
    { label: "maetp", property: "maetp" },
    { label: "odp", property: "odp" },
    { label: "pocp", property: "pocp" },
    { label: "tetp", property: "tetp" },
  ];
  const filteredBalance12 = content.filter(entry => entry.Scope !== "Scope 3");
  const filteredBalance3 = content.filter(entry => entry.Scope == "Scope 3");
  const filteredBalance121 = filteredBalance12.concat(filteredBalance3);


  const exportPDF_Balance = () => {
    if (rootNodeSelected != "")
    {
    const doc = new jsPDF('p', 'pt', 'a3')
    const table = document.getElementById('Table_Id_Balance')

    const title = 'Neom Tunnel Balance Scorecard';
    const titleX = doc.internal.pageSize.getWidth() / 2;
    const titleY = 25;

    const margins = {
      top: 50,
      bottom: 10,
      left: 20,
      width: 800
    }


    doc.setFontSize(15);
    doc.setTextColor(8, 116, 130);
    doc.text(title, titleX, titleY, { align: 'center' });


    html2canvas(table).then(canvas => {

      doc.addImage(require('./../../assets/sustain360_dark.png'), 'PNG', margins.left, 11, 80, 12);
      doc.addImage(require('./../../assets/guardtime.png'), 'PNG', doc.internal.pageSize.width - 90, 10, 70, 20);

      const imgData = canvas.toDataURL('image/png')

      doc.addImage(imgData, 'PNG', margins.left, margins.top, margins.width, 0)

      doc.save('BalanceScorecard.pdf')

    })
  }
  }

  const getRootNodeDetails = async () => {
    let rootNDetails = await props.getRootNodesUid();
    setRootDetails(rootNDetails);
  }

  React.useEffect(() => {

    getRootNodeDetails()
  }, []);

  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div style={{ maxHeight: 'calc(100vh - 12.20px)' }}>

          <Grid container>
            <Grid item xs={9}>
              <Typography id="modal-modal-title" component="h1" style={{ color: '#2D323A', marginBottom: '10px', fontSize: '17' }}>
                <b>Balanced Scorecard</b>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end'
                }}
              >


                <img
                  src={`${process.env.REACT_APP_BASE_URL}${datajson.images.MainCanvas.Menubar.upload}`}
                  alt='settings'
                  style={{
                    width: '25px',
                    height: '25px',
                  }}
                />
                <Typography
                  style={{
                    marginLeft: '5px',
                    fontSize: '15px',
                    marginRight: '12px',
                    cursor: rootNodeSelected ? 'pointer' : 'not-allowed',
                  }}
                  onClick={exportPDF_Balance}
                >
                  {' '}
                  Export
                </Typography>
                <Close
                  style={{
                    color: '#029E9E',
                    fontSize: 25,
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    props.handleBalancedModalClose()
                  }}
                />
              </div>
            </Grid>
          </Grid>

          <Divider
            style={{ width: '100%', color: 'darkgray', marginBottom: '0.5rem' }}
          />
          <Grid container spacing={2}>
            <Grid item>
              <Typography
                id='modal-modal-title'
                component='h1'
                style={{ color: '#0C6276', fontSize: '14' }}
              >
                <b>Options</b>
              </Typography>

              <div style={{ display: 'flex', alignItems: 'center', marginTop: '2px' }}>
                <FormControl sx={{ width: '100%' }} size='small'>
                  {rootNodeSelected ? (
                    ''
                  ) : (
                    <InputLabel
                      id='demo-select-small-label'
                      shrink={false}
                      focused={false}
                    >
                      Select an Option
                    </InputLabel>
                  )}
                  <Select
                    labelId='demo-select-small-label'
                    id='demo-select-small'
                    value={rootNodeSelected}
                    onChange={handleRootNodeSelected}
                    style={{
                      height: '40px',
                      boxShadow: '0 .125rem .25rem rgba(0,0,0,.075)',
                      minWidth: '250px',
                      textAlign: 'center'
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: '200px',
                          width: '250px',
                        },
                      },
                    }}
                  >
                    {rootDetails.map((item, index) => {
                      return (
                        <MenuItem key={item.id} value={item.id} style={{ fontSize: '14px', paddingLeft: '15px' }}>
                          {item.uid}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </Grid>

            <Grid item>
              <Typography
                id='modal-modal-title'
                component='h1'
                style={{ color: '#0C6276', fontSize: '14' }}
              >
                <b>Scopes</b>
              </Typography>
              <Grid item container direction='row' alignItems='center'>

              <Paper style={{ width: 125, height: 40, marginRight: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
  <Checkbox
    {...label}
    checked={checkedScope1}
    onChange={handleScope1Change}
    sx={{
      color: '#029E9E',
      '&.Mui-checked': {
        color: '#029E9E'
      }
    }}
  />
  <Typography style={{ marginTop: 5, fontSize: 17 }}>
    Scope 1
  </Typography>
</Paper>

<Paper style={{ width: 125, height: 40, marginRight: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
  <Checkbox
    {...label}
    checked={checkedScope2}
    onChange={handleScope2Change}
    sx={{
      color: '#029E9E',
      '&.Mui-checked': {
        color: '#029E9E'
      }
    }}
  />
  <Typography style={{ marginTop: 5, fontSize: 17 }}>
    Scope 2
  </Typography>
</Paper>

<Paper style={{ width: 125, height: 40, marginRight: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
  <Checkbox
    {...label}
    checked={checkedScope3}
    onChange={handleScope3Change}
    sx={{
      color: '#029E9E',
      '&.Mui-checked': {
        color: '#029E9E'
      }
    }}
  />
  <Typography style={{ marginTop: 5, fontSize: 17 }}>
    Scope 3
  </Typography>
</Paper>
              </Grid>
            </Grid>
          </Grid>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '10'
            }}
          ></div>

          <Divider
            style={{ width: '100%', color: '#707070', marginBottom: '0.5rem' }}
          />
        </div>

        <div>

          <TableContainer component={Paper} style={{ height: 'calc(100vh - 200.20px)', overflow: 'auto' }}>
            <Table sx={{ width: '50%' }} aria-label="simple table" id='Table_Id_Balance'>
              <TableHead style={{ background: 'transparent linear-gradient(180deg, #0C6276 0%, #00A9A6 100%) 0% 0% no-repeat padding-box' }} >
                <TableRow>
                  <TableCell component="th" scope="row" style={{ ...tableContentStyle1 }}>Scope</TableCell>
                  <TableCell align="center" style={{ ...tableContentStyle1 }}>Material/ Energy/ process</TableCell>
                  <TableCell align="center" style={{ ...tableContentStyle1 }}>ECI Cost (USD)</TableCell>
                  <TableCell align="center" style={{ ...tableContentStyle1 }}>Abiotic Depletion (ADP Elements) [kg Sb eq.]</TableCell>
                  <TableCell align="center" style={{ ...tableContentStyle1 }}>Abiotic Depletion (ADP Fossils) [MJ]</TableCell>
                  <TableCell align="center" style={{ ...tableContentStyle1 }}>Acidification Potential (AP) [kg SO2 eq.]</TableCell>
                  <TableCell align="center" style={{ ...tableContentStyle1 }}>Eutrophication Potential (EP) [kg Phosphet eq.]</TableCell>
                  <TableCell align="center" style={{ ...tableContentStyle1 }}>Freshwater Aquatic Ecotoxicity Pot. (FAETP inf.) [kg DCB eq.]</TableCell>
                  <TableCell align="center" style={{ ...tableContentStyle1 }}>Global Warming Potential (GWP 100 years) [kg CO2 eq.]</TableCell>
                  <TableCell align="center" style={{ ...tableContentStyle1 }}>Global Warming Potential (GWP 100 years), excl biogenic carbon [kg CO2 eq.]</TableCell>
                  <TableCell align="center" style={{ ...tableContentStyle1 }}>Human Toxicity Potential (HTP inf.) [kg DCB eq.]</TableCell>
                  <TableCell align="center" style={{ ...tableContentStyle1 }}>Marine Aquatic Ecotoxicity Pot (MAETP inf) [kg DCB eq.]</TableCell>
                  <TableCell align="center" style={{ ...tableContentStyle1 }}>Ozone Layer Depletion Potential (ODP steaady state) [kg R11 eq.]</TableCell>
                  <TableCell align="center" style={{ ...tableContentStyle1 }}>Photochem Ozone creation potential (POCP) [kg Ethene eq.]</TableCell>
                  <TableCell align="center" style={{ ...tableContentStyle1 }}>Terrestic Ecotoxoicity Potential (TETP inf.) [kg DCB eq.]</TableCell>
                </TableRow>
              </TableHead>
              <HeatmapTable data={filteredBalance121} columns={columns} />
            </Table>
          </TableContainer>
        </div>
      </Box>
    </Modal>
  )
}

export default AnalyticsBalancedScorecard