import { Grid, Typography } from '@mui/material';
import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const formatNumber = (num) => {
    if (typeof num === 'number') {
        const roundedNumber = parseFloat(num.toFixed(2));
        return (roundedNumber.toLocaleString('en-US'));
    }
    else {
        return num;
    }
}

const tableHeadStyle = {
    fontSize: '10',
    lineHeight: '1.3',
    // fontWeight: 'bold',
    color: 'white',
    padding: '10px'
}
const tableContentStyle = {
    fontSize: '10',
    lineHeight: '1.3',
    padding: '10px'
    // fontWeight: 'bold',
}

function SupplyChainStatsComponent(props) {
    const senderToport = props?.senderToport
    const recieverToport = props?.recieverToport
    const distance = props?.distance
    const emission = props?.emission
    const project_id = sessionStorage.getItem('project_id')
    const transportPort = props?.transportPort
    return (
        <>
            {transportPort === 'truck_ship_truck' ? (
                <Grid container spacing={1}>
                    <Grid item xs={12} >
                        <TableContainer component={Paper}>
                            <Table sx={{ width: '100%' }} aria-label="simple table">
                                <TableHead style={{ backgroundColor: '#029E9E' }} >
                                    <TableRow>
                                        <TableCell component="th" scope="row" style={tableHeadStyle}>Locations</TableCell>
                                        <TableCell align="left" style={tableHeadStyle}>Distance</TableCell>
                                        <TableCell align="left" style={tableHeadStyle}>Global Warming Potential</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" style={tableContentStyle}>Sender-To-Port</TableCell>
                                        <TableCell align="left" style={tableContentStyle}>{formatNumber(senderToport?.distance)} Km</TableCell>
                                        <TableCell align="left" style={tableContentStyle}>{senderToport?.emission} Kg CO2/Km</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" style={tableContentStyle}>Port-To-Port</TableCell>
                                        <TableCell align="left" style={tableContentStyle}>{formatNumber(distance)} Km</TableCell>
                                        <TableCell align="left" style={tableContentStyle}>{emission} Kg CO2/Km</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" style={tableContentStyle}>Port-To-Receiver</TableCell>
                                        <TableCell align="left" style={tableContentStyle}>{formatNumber(recieverToport?.distance)} Km</TableCell>
                                        <TableCell align="left" style={tableContentStyle}>{recieverToport?.emission} Kg CO2/Km</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" style={tableContentStyle}>Total</TableCell>
                                        <TableCell align="left" style={tableContentStyle}>{formatNumber(recieverToport?.distance + senderToport?.distance + distance)} Km</TableCell>
                                        <TableCell align="left" style={tableContentStyle}>{emission + recieverToport?.emission + senderToport?.emission} Kg CO2/Km</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            ) : transportPort === 'truck' ? (
                <Grid container spacing={1}>
                    <Grid item xs={12} >
                        <TableContainer component={Paper}>
                            <Table sx={{ width: '100%' }} aria-label="simple table">
                                <TableHead style={{ backgroundColor: '#029E9E' }} >
                                    <TableRow>
                                        <TableCell component="th" scope="row" style={tableHeadStyle}>Locations</TableCell>
                                        <TableCell align="left" style={tableHeadStyle}>Distance</TableCell>
                                        <TableCell align="left" style={tableHeadStyle}>Global Warming Potential</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" style={tableContentStyle}>Sender-To-Destination</TableCell>
                                        <TableCell align="left" style={tableContentStyle}>{formatNumber(senderToport?.distance)} Km</TableCell>
                                        <TableCell align="left" style={tableContentStyle}>{senderToport?.emission} Kg CO2/Km</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            ) : project_id === '1' ? (
                <Grid container spacing={1}>
                    <Grid item xs={12} >
                        <TableContainer component={Paper}>
                            <Table sx={{ width: '100%' }} aria-label="simple table">
                                <TableHead style={{ backgroundColor: '#029E9E' }} >
                                    <TableRow>
                                        <TableCell component="th" scope="row" style={tableHeadStyle}>Locations</TableCell>
                                        <TableCell align="left" style={tableHeadStyle}>Distance</TableCell>
                                        <TableCell align="left" style={tableHeadStyle}>Global Warming Potential</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" style={tableContentStyle}>Sender-To-Port</TableCell>
                                        <TableCell align="left" style={tableContentStyle}>{formatNumber(senderToport?.distance)} Km</TableCell>
                                        <TableCell align="left" style={tableContentStyle}>{formatNumber(senderToport?.emission)} Kg CO2/Km</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" style={tableContentStyle}>Port-To-Port</TableCell>
                                        <TableCell align="left" style={tableContentStyle}>{formatNumber(distance)} Km</TableCell>
                                        <TableCell align="left" style={tableContentStyle}>{formatNumber(emission)} Kg CO2/Km</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" style={tableContentStyle}>Port-To-Receiver</TableCell>
                                        <TableCell align="left" style={tableContentStyle}>{formatNumber(recieverToport?.distance)} Km</TableCell>
                                        <TableCell align="left" style={tableContentStyle}>{formatNumber(recieverToport?.emission)} Kg CO2/Km</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row" style={tableContentStyle}>Total</TableCell>
                                        <TableCell align="left" style={tableContentStyle}>{formatNumber(recieverToport?.distance + senderToport?.distance + distance)} Km</TableCell>
                                        <TableCell align="left" style={tableContentStyle}>{formatNumber(emission + recieverToport?.emission + senderToport?.emission)} Kg CO2/Km</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            ) : (
                <></>
            )}



        </>
    )
}

export default SupplyChainStatsComponent
