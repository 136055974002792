module.exports ={
    "nodes": [
        {
            "id": "node-56",
            "labels": "Option",
            "maetp": 21176767862.3488,
            "htp": 49378954.72813118,
            "adp_elements": 515.43268671408,
            "gwp_100_ebc": 638406907.98128,
            "ep": 317523.88097539195,
            "faetp": 1733934.7129654402,
            "ap": 1719590.4106412798,
            "uid": "Baseline (Concrete Design)",
            "size": 600,
            "rootNodeLabel" : true,
            "fx" : ((window.innerWidth*3)/4)*(1/5),
            "fy" : window.innerHeight/2,
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/Baseline.png`,
            "eci_cost": 49981047.54630458,
            "pocp": -159688.4081056961,
            "name": "Baseline (Concrete Design)",
            "odp": 0.03158179194983444,
            "gwp_100": 668269951.82128,
            "tetp": 2098254.70051616,
            "adp_fossil": 5335926349.345921
        },
        {
            "id": "node-60",
            "labels": "Scope",
            "maetp": 1418553760,
            "htp": 1762076.16,
            "adp_elements": 3.3593248000000004,
            "gwp_100_ebc": 23096842.6,
            "ep": 12154.5697,
            "faetp": 195949.77999999997,
            "ap": 89203.92000000001,
            "uid": "Scope 1-Baseline (Concrete Design)",
            "labelPosition": "center",
            "size": 600,
            "svg": "https://cdn-icons-png.flaticon.com/512/594/594580.png",
            "eci_cost": 1912593.7698175733,
            "pocp": 7567.9786,
            "name": "Scope 1",
            "odp": 0.00007392734205999999,
            "gwp_100": 21460254.4,
            "tetp": 90544.35,
            "adp_fossil": 603392790
        },
        {
            "id": "node-61",
            "labels": "Scope",
            "maetp": 13043236326.348799,
            "htp": 43187978.35213119,
            "adp_elements": 507.06446243408,
            "gwp_100_ebc": 527889283.62127995,
            "ep": 284835.116555392,
            "faetp": 1434386.4249654403,
            "ap": 1404631.8186412798,
            "uid": "Scope 3-Baseline (Concrete Design)",
            "labelPosition": "center",
            "size": 600,
            "svg": "https://cdn-icons-png.flaticon.com/512/594/594580.png",
            "eci_cost": 41441448.27363595,
            "pocp": -179446.28806569608,
            "name": "Scope 3",
            "odp": 0.031224194692414435,
            "gwp_100": 559527711.98128,
            "tetp": 1932244.05051616,
            "adp_fossil": 3785414555.34592
        },
        {
            "id": "node-62",
            "labels": "Scope",
            "maetp": 6714977776,
            "htp": 4428900.216,
            "adp_elements": 5.008899479999999,
            "gwp_100_ebc": 87420781.76,
            "ep": 20534.19472,
            "faetp": 103598.508,
            "ap": 225754.672,
            "uid": "Scope 2-Baseline (Concrete Design)",
            "labelPosition": "center",
            "size": 600,
            "svg": "https://cdn-icons-png.flaticon.com/512/594/594580.png",
            "eci_cost": 6627005.502851058,
            "pocp": 12189.90136,
            "name": "Scope 2",
            "odp": 0.00028366991536,
            "gwp_100": 87281985.44,
            "tetp": 75466.29999999999,
            "adp_fossil": 947119004
        },
        {
            "id": "node-57",
            "labels": "Option",
            "maetp": 11459917903.68,
            "htp": 15719187.2777472,
            "adp_elements": 903.784752596,
            "gwp_100_ebc": 399692127.84,
            "ep": 228912.00572,
            "faetp": 1648691.59568,
            "ap": 1290861.5836,
            "uid": "Arch Option 1a (GFRP)",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/option-1a.png`,
            "size": 600,
            rootNodeLabel : true,
            fx : ((window.innerWidth*3)/4)*(2/5),
            fy : window.innerHeight/2,
            "eci_cost": 32344949.693842284,
            "pocp": -120129.92672000002,
            "name": "Arch Option 1a (GFRP)",
            "odp": 0.04648719830336761,
            "gwp_100": 445354890.64,
            "tetp": 1203246.0368000001,
            "adp_fossil": 5338778508.799999
        },
        {
            "id": "node-63",
            "labels": "Scope",
            "maetp": 1513551440,
            "htp": 1874489.88,
            "adp_elements": 3.5396584800000004,
            "gwp_100_ebc": 23948298.8,
            "ep": 12342.053,
            "faetp": 206299.32799999998,
            "ap": 92973.564,
            "uid": "Scope 1-Arch Option 1a (GFRP)",
            "labelPosition": "center",
            "size": 600,
            "svg": "https://cdn-icons-png.flaticon.com/512/594/594580.png",
            "eci_cost": 1991082.4538762355,
            "pocp": 7836.0416000000005,
            "name": "Scope 1",
            "odp": 0.00007961390728759999,
            "gwp_100": 22226399.2,
            "tetp": 95569.576,
            "adp_fossil": 637795940
        },
        {
            "id": "node-64",
            "labels": "Scope",
            "maetp": 2714851935.680001,
            "htp": 9075112.5497472,
            "adp_elements": 894.8508946759999,
            "gwp_100_ebc": 281598371.76,
            "ep": 194456.20455999998,
            "faetp": 1330824.64368,
            "ap": 954767.6035999999,
            "uid": "Scope 3-Arch Option 1a (GFRP)",
            "labelPosition": "center",
            "size": 600,
            "svg": "https://cdn-icons-png.flaticon.com/512/594/594580.png",
            "eci_cost": 23217092.083049525,
            "pocp": -141093.55440000002,
            "name": "Scope 3",
            "odp": 0.04610209371800001,
            "gwp_100": 329132507.12,
            "tetp": 1026405.0608000001,
            "adp_fossil": 3681008256.7999997
        },
        {
            "id": "node-65",
            "labels": "Scope",
            "maetp": 7231514528,
            "htp": 4769584.848,
            "adp_elements": 5.39419944,
            "gwp_100_ebc": 94145457.28,
            "ep": 22113.74816,
            "faetp": 111567.624,
            "ap": 243120.416,
            "uid": "Scope 2-Arch Option 1a (GFRP)",
            "labelPosition": "center",
            "size": 600,
            "svg": "https://cdn-icons-png.flaticon.com/512/594/594580.png",
            "eci_cost": 7136775.156916523,
            "pocp": 13127.58608,
            "name": "Scope 2",
            "odp": 0.00030549067808,
            "gwp_100": 93995984.32,
            "tetp": 81271.4,
            "adp_fossil": 1019974312
        },
        {
            "id": "node-58",
            "labels": "Option",
            "maetp": 13418675658,
            "htp": 17706808.08004,
            "adp_elements": 1316.7492298199998,
            "gwp_100_ebc": 437580461.12,
            "ep": 233214.81464,
            "faetp": 1889961.907,
            "ap": 1385259.4619999998,
            "uid": "Arch Option 1b (GFRP)",
            "size": 600,
            rootNodeLabel : true,
            fx : ((window.innerWidth*3)/4)*(3/5),
            fy : window.innerHeight/2,
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/option-1b.png`,
            "eci_cost": 35503707.496049605,
            "pocp": -93910.15448,
            "name": "Arch Option 1b (GFRP)",
            "odp": 0.0557717443503812,
            "gwp_100": 490122117.28,
            "tetp": 1307167.484,
            "adp_fossil": 6160950428
        },
        {
            "id": "node-66",
            "labels": "Scope",
            "maetp": 1715128240,
            "htp": 2118286.92,
            "adp_elements": 3.96435656,
            "gwp_100_ebc": 26409995.2,
            "ep": 13330.0372,
            "faetp": 230872.21600000001,
            "ap": 103048.788,
            "uid": "Scope 1-Arch Option 1b (GFRP)",
            "labelPosition": "center",
            "size": 600,
            "svg": "https://cdn-icons-png.flaticon.com/512/594/594580.png",
            "eci_cost": 2204347.072985692,
            "pocp": 8629.754799999999,
            "name": "Scope 1",
            "odp": 0.00009098716354119999,
            "gwp_100": 24484192.8,
            "tetp": 107210.812,
            "adp_fossil": 716453120
        },
        {
            "id": "node-67",
            "labels": "Scope",
            "maetp": 3438959386,
            "htp": 10137567.04804,
            "adp_elements": 1306.6200738999999,
            "gwp_100_ebc": 303575657.6,
            "ep": 194611.9224,
            "faetp": 1531583.835,
            "ap": 1004358.77,
            "uid": "Scope 3-Arch Option 1b (GFRP)",
            "labelPosition": "center",
            "size": 600,
            "svg": "https://cdn-icons-png.flaticon.com/512/594/594580.png",
            "eci_cost": 25143045.958016455,
            "pocp": -117542.8648,
            "name": "Scope 3",
            "odp": 0.05533162498332,
            "gwp_100": 358213942.4,
            "tetp": 1107075.072,
            "adp_fossil": 4278812380
        },
        {
            "id": "node-68",
            "labels": "Scope",
            "maetp": 8264588032,
            "htp": 5450954.112,
            "adp_elements": 6.16479936,
            "gwp_100_ebc": 107594808.32,
            "ep": 25272.85504,
            "faetp": 127505.856,
            "ap": 277851.904,
            "uid": "Scope 2-Arch Option 1b (GFRP)",
            "labelPosition": "center",
            "size": 600,
            "svg": "https://cdn-icons-png.flaticon.com/512/594/594580.png",
            "eci_cost": 8156314.465047456,
            "pocp": 15002.955520000001,
            "name": "Scope 2",
            "odp": 0.00034913220352,
            "gwp_100": 107423982.08,
            "tetp": 92881.6,
            "adp_fossil": 1165684928
        },
        {
            "id": "node-59",
            "labels": "Option",
            "maetp": 10248869471.20484,
            "htp": 14238213.765103338,
            "adp_elements": 894.5778772797743,
            "gwp_100_ebc": 365238592.9316793,
            "ep": 202031.99596918168,
            "faetp": 1503043.3975090645,
            "ap": 1167613.1666234334,
            "uid": "Arch Option 1c (GFRP)",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/option-1c.png`,
            "size": 600,
            rootNodeLabel : true,
            fx : ((window.innerWidth*3)/4)*(4/5),
            fy : window.innerHeight/2,
            "eci_cost": 29677473.845540125,
            "pocp": -88124.80604899039,
            "name": "Arch Option 1c (GFRP)",
            "odp": 0.046378982842909665,
            "gwp_100": 411364207.54260445,
            "tetp": 1118535.4075349935,
            "adp_fossil": 4907925863.17767
        },
        {
            "id": "node-69",
            "labels": "Scope",
            "maetp": 1402041160,
            "htp": 1735029.66,
            "adp_elements": 3.2680310000000006,
            "gwp_100_ebc": 22014982.6,
            "ep": 11280.5407,
            "faetp": 190426.59999999998,
            "ap": 85588.23000000001,
            "uid": "Scope 1-Arch Option 1c (GFRP)",
            "labelPosition": "center",
            "size": 600,
            "svg": "https://cdn-icons-png.flaticon.com/512/594/594580.png",
            "eci_cost": 1832340.462738948,
            "pocp": 7200.7156,
            "name": "Scope 1",
            "odp": 0.000073927162699,
            "gwp_100": 20425844.4,
            "tetp": 88276.24,
            "adp_fossil": 589347590
        },
        {
            "id": "node-70",
            "labels": "Scope",
            "maetp": 2131850535.2048392,
            "htp": 8074283.889103338,
            "adp_elements": 886.3009467997744,
            "gwp_100_ebc": 255802828.57167932,
            "ep": 170217.26054918166,
            "faetp": 1209018.2895090648,
            "ap": 856270.2646234334,
            "labelPosition": "center",
            "size": 600,
            "uid": "Scope 3-Arch Option 1c (GFRP)",
            "svg": "https://cdn-icons-png.flaticon.com/512/594/594580.png",
            "eci_cost": 21218127.879950117,
            "pocp": -107515.42300899039,
            "name": "Scope 3",
            "odp": 0.04602138576485066,
            "gwp_100": 303656377.7026045,
            "tetp": 954792.8675349934,
            "adp_fossil": 3371459269.177669
        },
        {
            "id": "node-71",
            "labels": "Scope",
            "maetp": 6714977776,
            "htp": 4428900.216,
            "adp_elements": 5.008899479999999,
            "gwp_100_ebc": 87420781.76,
            "ep": 20534.19472,
            "faetp": 103598.508,
            "ap": 225754.672,
            "uid": "Scope 2-Arch Option 1c (GFRP)",
            "labelPosition": "center",
            "size": 600,
            "svg": "https://cdn-icons-png.flaticon.com/512/594/594580.png",
            "eci_cost": 6627005.502851058,
            "pocp": 12189.90136,
            "name": "Scope 2",
            "odp": 0.00028366991536,
            "gwp_100": 87281985.44,
            "tetp": 75466.29999999999,
            "adp_fossil": 947119004
        },
        {
            "id": "node-72",
            "labels": "Process",
            "maetp": 22.62,
            "htp": 0.03705,
            "adp_elements": 1.2506e-7,
            "gwp_100_ebc": 1.482,
            "ep": 0.0011973,
            "faetp": 0.007566,
            "ap": 0.004953,
            "functional_unit": "m3",
            "uid": "Excavation",
            "eci_cost": 0.10993603709400739,
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/excavation.png`,
            "pocp": 0.0005031,
            "name": "Excavation",
            "odp": 2.457e-16,
            "gwp_100": 1.417,
            "tetp": 0.003107,
            "adp_fossil": 19.24
        },
        {
            "id": "node-73",
            "labels": "Process",
            "maetp": 0.00151,
            "htp": 0.00000193,
            "adp_elements": 9.85e-12,
            "gwp_100_ebc": 0.0000851,
            "ep": 9.06e-8,
            "faetp": 4.52e-7,
            "ap": 3.56e-7,
            "functional_unit": "kgkm",
            "uid": "Excavated Soil Transportation",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/excavatedSoilTransportation.png`,
            "eci_cost": 0.000006604965249703,
            "pocp": -1.34e-7,
            "name": "Excavated Soil Transportation",
            "odp": 1.01e-17,
            "gwp_100": 0.0000839,
            "tetp": 2.08e-7,
            "adp_fossil": 0.00114
        },
        {
            "id": "node-92",
            "labels": "Material",
            "maetp": 4.908,
            "htp": 0.01992,
            "adp_elements": 2.412e-7,
            "gwp_100_ebc": 0.1488,
            "ep": 0.00003312,
            "faetp": 0.0001884,
            "ap": 0.0002148,
            "functional_unit": "kg",
            "uid": "Base Concrete",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/base_concrete.png`,
            "eci_cost": 0.010993315270728,
            "pocp": 0.00002244,
            "name": "Base Concrete",
            "odp": 1.692e-13,
            "gwp_100": 0.1488,
            "tetp": 0.0003516,
            "adp_fossil": 0.5292
        },
        {
            "id": "node-74",
            "labels": "Process",
            "maetp": 0.002265,
            "htp": 0.000002895,
            "adp_elements": 1.4775e-11,
            "gwp_100_ebc": 0.00012765,
            "ep": 1.359e-7,
            "faetp": 6.78e-7,
            "ap": 5.34e-7,
            "functional_unit": "kgkm",
            "uid": "Concrete Transportation",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/concrete_transportation.png`,
            "eci_cost": 0.0000099074483286,
            "pocp": -2.01e-7,
            "name": "Concrete Transportation",
            "odp": 1.515e-14,
            "gwp_100": 0.00012585,
            "tetp": 3.12e-7,
            "adp_fossil": 0.00171
        },
        {
            "id": "node-75",
            "labels": "Material",
            "maetp": 0.173,
            "htp": 0.000115,
            "adp_elements": 6.51e-10,
            "gwp_100_ebc": 0.00231,
            "ep": 0.00000114,
            "faetp": 0.00000922,
            "ap": 0.00000584,
            "functional_unit": "kg",
            "uid": "Gravel",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/gravel.png`,
            "eci_cost": 0.00017803318515300002,
            "pocp": -6.18e-7,
            "name": "Gravel",
            "odp": 2.17e-14,
            "gwp_100": 0.00229,
            "tetp": 0.0000175,
            "adp_fossil": 0.0282
        },
        {
            "id": "node-76",
            "labels": "Process",
            "maetp": 0.00151,
            "htp": 0.00000193,
            "adp_elements": 9.85e-12,
            "gwp_100_ebc": 0.0000851,
            "ep": 9.06e-8,
            "faetp": 4.52e-7,
            "ap": 3.56e-7,
            "functional_unit": "kgkm",
            "uid": "Gravel Transportation",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/gravel_transpoortation.png`,
            "eci_cost": 0.000006604965249703,
            "pocp": -1.34e-7,
            "name": "Gravel Transportation",
            "odp": 1.01e-17,
            "gwp_100": 0.0000839,
            "tetp": 2.08e-7,
            "adp_fossil": 0.00114
        },
        {
            "id": "node-81",
            "labels": "Material",
            "maetp": 0.009542,
            "htp": 1.4268e-7,
            "adp_elements": 0.00000243714,
            "gwp_100_ebc": 20.707,
            "ep": 0.000158497,
            "faetp": 0.000158497,
            "ap": 0.03031,
            "functional_unit": "m2",
            "uid": "Fire Liner",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/fireLiner.png`,
            "eci_cost": 1.2429910404846005,
            "pocp": 0.02923,
            "name": "Fire Liner",
            "odp": 8.6510400178e-9,
            "gwp_100": 20.707,
            "tetp": 0.10209,
            "adp_fossil": 264.58
        },
        {
            "id": "node-82",
            "labels": "Process",
            "maetp": 0.00118,
            "htp": 0.00000151,
            "adp_elements": 7.71e-12,
            "gwp_100_ebc": 0.0000666,
            "ep": 7.03e-8,
            "faetp": 3.53e-7,
            "ap": 2.76e-7,
            "functional_unit": "kgkm",
            "uid": "Fire Liner Transportation",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/fire_liner_transportation.png`,
            "eci_cost": 0.000005156766468039999,
            "pocp": -1.04e-7,
            "name": "Fire Liner Transportation",
            "odp": 7.87e-16,
            "gwp_100": 0.0000657,
            "tetp": 1.63e-7,
            "adp_fossil": 0.000893
        },
        {
            "id": "node-83",
            "labels": "Process",
            "maetp": 0.00151,
            "htp": 0.00000193,
            "adp_elements": 9.85e-12,
            "gwp_100_ebc": 0.0000851,
            "ep": 9.06e-8,
            "faetp": 4.52e-7,
            "ap": 3.56e-7,
            "functional_unit": "kgkm",
            "uid": "Backfill Soil Transportation",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/Backfill_Soil_Transportation.png`,
            "eci_cost": 0.000006604965249703,
            "pocp": -1.34e-7,
            "name": "Backfill Soil Transportation",
            "odp": 1.01e-17,
            "gwp_100": 0.0000839,
            "tetp": 2.08e-7,
            "adp_fossil": 0.00114
        },
        {
            "id": "node-84",
            "labels": "Process",
            "maetp": 6833,
            "htp": 8.5628,
            "adp_elements": 0.0000015684,
            "gwp_100_ebc": 104.7725,
            "ep": 0.03984525,
            "faetp": 0.17553,
            "ap": 0.44016,
            "functional_unit": "m2",
            "uid": "Average site impacts",
            "svg": "https://cdn-icons-png.flaticon.com/512/3382/3382562.png",
            "eci_cost": 8.96037459867331,
            "pocp": 0.0234445,
            "name": "Average site impacts",
            "odp": 5.5320196e-10,
            "gwp_100": 104.76,
            "tetp": 0.145475,
            "adp_fossil": 1146.075
        },
        {
            "id": "node-85",
            "labels": "Energy",
            "maetp": 2762.6752,
            "htp": 3.4524632,
            "adp_elements": 0.000005376996,
            "gwp_100_ebc": 40.367552,
            "ep": 0.016055344,
            "faetp": 0.1734116,
            "ap": 0.1670744,
            "functional_unit": "m2",
            "uid": "Average energy and fuel use in construction site",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/fuel_use_in_construction site.png`,
            "eci_cost": 3.4504547363745965,
            "pocp": 0.010348472,
            "name": "Average energy and fuel use in construction site",
            "odp": 1.99776272e-10,
            "gwp_100": 39.299888,
            "tetp": 0.09461,
            "adp_fossil": 702.2308
        },
        {
            "id": "node-86",
            "labels": "Energy",
            "maetp": 56.3,
            "htp": 0.0712,
            "adp_elements": 7.71e-8,
            "gwp_100_ebc": 0.87,
            "ep": 0.00033,
            "faetp": 0.00145,
            "ap": 0.00365,
            "functional_unit": "kWh",
            "uid": "Energy use on the site (Electricity Saudi)",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/Energy_useon_the_site_(Electricity Saudi).png`,
            "eci_cost": 0.0743399468024,
            "pocp": 0.000194,
            "name": "Energy use on the site (Electricity Saudi)",
            "odp": 4.61e-12,
            "gwp_100": 0.87,
            "tetp": 0.00113,
            "adp_fossil": 9.51
        },
        {
            "id": "node-125",
            "labels": "Energy",
            "maetp": 19.5,
            "htp": 0,
            "adp_elements": 0,
            "gwp_100_ebc": 0.1,
            "ep": 0,
            "faetp": 0,
            "ap": 0,
            "functional_unit": "kWh",
            "uid": "Alternative Renewable electricity (Solar)",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/solarElectricity.png`,
            "eci_cost": 0.007003926817000001,
            "pocp": 0,
            "name": "Alternative Renewable electricity (Solar)",
            "odp": 0,
            "gwp_100": 0.1,
            "tetp": 0,
            "adp_fossil": 0.7
        },
        {
            "id": "node-126",
            "labels": "Energy",
            "maetp": 37.9,
            "htp": 0,
            "adp_elements": 0,
            "gwp_100_ebc": 0.5,
            "ep": 0,
            "faetp": 0,
            "ap": 0,
            "functional_unit": "kWh",
            "uid": "Alternative Electricity (Saudi Grid +Solar)",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/Electricity.png`,
            "eci_cost": 0.029182895381000003,
            "pocp": 0,
            "name": "Alternative Electricity (Saudi Grid +Solar)",
            "odp": 0,
            "gwp_100": 0.5,
            "tetp": 0,
            "adp_fossil": 5.1
        },
        {
            "id": "node-87",
            "labels": "Material",
            "maetp": 65.726,
            "htp": 0.075166,
            "adp_elements": 3.9648e-7,
            "gwp_100_ebc": 0.56876,
            "ep": 0.00035872,
            "faetp": 0.021358,
            "ap": 0.001947,
            "functional_unit": "l",
            "uid": "Diesel",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/diesel.png`,
            "eci_cost": 0.048814812282960786,
            "pocp": 0.00038586,
            "name": "Diesel",
            "odp": 2.9736e-13,
            "gwp_100": 0.36344,
            "tetp": 0.00885,
            "adp_fossil": 56.404
        },
        {
            "id": "node-88",
            "labels": "Material",
            "maetp": 3.08,
            "htp": 0.000752,
            "adp_elements": 5.28e-9,
            "gwp_100_ebc": 0.0149,
            "ep": 0.00000981,
            "faetp": 0.0000612,
            "ap": 0.0000864,
            "functional_unit": "kg",
            "uid": "Waste",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/waste.png`,
            "eci_cost": 0.0015832893152523523,
            "pocp": 0.00000658,
            "name": "Waste",
            "odp": 7.84e-17,
            "gwp_100": 0.0144,
            "tetp": 0.000395,
            "adp_fossil": 0.195
        },
        {
            "id": "node-89",
            "labels": "Material",
            "maetp": 0.019635,
            "htp": 0.0000217,
            "adp_elements": 1.295e-10,
            "gwp_100_ebc": 0.00028385,
            "ep": 3.57e-7,
            "faetp": 0.00000462,
            "ap": 4.095e-7,
            "functional_unit": "kg",
            "uid": "Maintenance Water",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/ManitenanceWater.png`,
            "eci_cost": 0.000024895136553350002,
            "pocp": 3.78e-8,
            "name": "Maintenance Water",
            "odp": 1.47e-15,
            "gwp_100": 0.00030765,
            "tetp": 0.00000413,
            "adp_fossil": 0.003675
        },
        {
            "id": "node-90",
            "labels": "Material",
            "maetp": 0.009542,
            "htp": 1.4268e-7,
            "adp_elements": 0.00000243714,
            "gwp_100_ebc": 20.707,
            "ep": 0.000158497,
            "faetp": 0.000158497,
            "ap": 0.03031,
            "functional_unit": "m2",
            "uid": "Fire Liner replacement",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/FireLineReplacement.png`,
            "eci_cost": 1.2429910404846005,
            "pocp": 0.02923,
            "name": "Fire Liner replacement",
            "odp": 8.6510400178e-9,
            "gwp_100": 20.707,
            "tetp": 0.10209,
            "adp_fossil": 264.58
        },
        {
            "id": "node-91",
            "labels": "Process",
            "maetp": 0.00118,
            "htp": 0.00000151,
            "adp_elements": 7.71e-12,
            "gwp_100_ebc": 0.0000666,
            "ep": 7.03e-8,
            "faetp": 3.53e-7,
            "ap": 2.76e-7,
            "functional_unit": "kgkm",
            "uid": "Fire Liner Transportation replacement",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/Fire_LinerTrans_replacement.png`,
            "eci_cost": 0.000005156766468039999,
            "pocp": -1.04e-7,
            "name": "Fire Liner Transportation replacement",
            "odp": 7.87e-16,
            "gwp_100": 0.0000657,
            "tetp": 1.63e-7,
            "adp_fossil": 0.000893
        },
        {
            "id": "node-111",
            "labels": "Material",
            "maetp": 4.908,
            "htp": 0.01992,
            "adp_elements": 2.412e-7,
            "gwp_100_ebc": 0.1488,
            "ep": 0.00003312,
            "faetp": 0.0001884,
            "ap": 0.0002148,
            "functional_unit": "kg",
            "uid": "Base Concrete (EoL)",
            "svg": "https://cdn-icons-png.flaticon.com/512/1207/1207222.png",
            "eci_cost": 0.010993315270728,
            "pocp": 0.00002244,
            "name": "Base Concrete (EoL)",
            "odp": 1.692e-13,
            "gwp_100": 0.1488,
            "tetp": 0.0003516,
            "adp_fossil": 0.5292
        },
        {
            "id": "node-110",
            "labels": "Process",
            "maetp": 0.002265,
            "htp": 0.000002895,
            "adp_elements": 1.4775e-11,
            "gwp_100_ebc": 0.00012765,
            "ep": 1.359e-7,
            "faetp": 6.78e-7,
            "ap": 5.34e-7,
            "functional_unit": "kgkm",
            "uid": "Concrete Transportation (EoL)",
            "svg": "https://cdn-icons-png.flaticon.com/512/4321/4321802.png",
            "eci_cost": 0.0000099074483286,
            "pocp": -2.01e-7,
            "name": "Concrete Transportation (EoL)",
            "odp": 1.515e-14,
            "gwp_100": 0.00012585,
            "tetp": 3.12e-7,
            "adp_fossil": 0.00171
        },
        {
            "id": "node-93",
            "labels": "Process",
            "maetp": 3.08,
            "htp": 0.000752,
            "adp_elements": 5.28e-9,
            "gwp_100_ebc": 0.0149,
            "ep": 0.00000981,
            "faetp": 0.0000612,
            "ap": 0.0000864,
            "functional_unit": "kg",
            "uid": "Construction concrete waste",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/constructionConcreteWaste.png`,
            "eci_cost": 0.0015832893152523523,
            "pocp": 0.00000658,
            "name": "Construction concrete waste",
            "odp": 7.84e-17,
            "gwp_100": 0.0144,
            "tetp": 0.000395,
            "adp_fossil": 0.195
        },
        {
            "id": "node-95",
            "labels": "Process",
            "maetp": 2.23,
            "htp": 0.00396,
            "adp_elements": 9.2e-8,
            "gwp_100_ebc": 0.485,
            "ep": 0.000102,
            "faetp": 0.000105,
            "ap": 0.000522,
            "functional_unit": "kg",
            "uid": "Waste disposal of Fire Liner",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/wasteDisposalOfFireLiner.png`,
            "eci_cost": 0.0672157610164451,
            "pocp": 0.0000305,
            "name": "Waste disposal of Fire Liner",
            "odp": 8.36e-16,
            "gwp_100": 1.27,
            "tetp": 0.000356,
            "adp_fossil": 0.582
        },
        {
            "id": "node-97",
            "labels": "Process",
            "maetp": -82.1,
            "htp": -0.0399,
            "adp_elements": -2.62e-7,
            "gwp_100_ebc": -1.38,
            "ep": -0.000221,
            "faetp": -0.00167,
            "ap": -0.00161,
            "functional_unit": "kg",
            "uid": "Energy recovery from incineration of Liner",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/Energy_from_incineration_Liner.png`,
            "eci_cost": -0.09119250045864198,
            "pocp": -0.000147,
            "name": "Energy recovery from incineration of Liner",
            "odp": -2.14e-14,
            "gwp_100": -1.38,
            "tetp": -0.00155,
            "adp_fossil": -19.8
        },
        {
            "id": "node-77",
            "labels": "Material",
            "maetp": 165.12,
            "htp": 0.16383,
            "adp_elements": 0.000044247,
            "gwp_100_ebc": 4.0119,
            "ep": 0.00091461,
            "faetp": 0.026832,
            "ap": 0.0095331,
            "functional_unit": "kg",
            "uid": "GFRP Panels",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/GFRP_Panel.png`,
            "eci_cost": 0.2907188764419,
            "pocp": 0.0015867,
            "name": "GFRP Panels",
            "odp": 1.02297e-9,
            "gwp_100": 4.0248,
            "tetp": 0.0119583,
            "adp_fossil": 92.88
        },
        {
            "id": "node-78",
            "labels": "Process",
            "maetp": 0.00118,
            "htp": 0.00000151,
            "adp_elements": 7.71e-12,
            "gwp_100_ebc": 0.0000666,
            "ep": 7.03e-8,
            "faetp": 3.53e-7,
            "ap": 2.76e-7,
            "functional_unit": "kgkm",
            "uid": "GFRP Panel Transportation",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/GFRPPanelTransportation.png`,
            "eci_cost": 0.000005156766468039999,
            "pocp": -1.04e-7,
            "name": "GFRP Panel Transportation",
            "odp": 7.87e-16,
            "gwp_100": 0.0000657,
            "tetp": 1.63e-7,
            "adp_fossil": 0.000893
        },
        {
            "id": "node-94",
            "labels": "Process",
            "maetp": 2.23,
            "htp": 0.00396,
            "adp_elements": 9.2e-8,
            "gwp_100_ebc": 0.485,
            "ep": 0.000102,
            "faetp": 0.000105,
            "ap": 0.000522,
            "functional_unit": "kg",
            "uid": "Waste disposal of GFRP",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/wasteDsiposalofGfrp.png`,
            "eci_cost": 0.0672157610164451,
            "pocp": 0.0000305,
            "name": "Waste disposal of GFRP",
            "odp": 8.36e-16,
            "gwp_100": 1.27,
            "tetp": 0.000356,
            "adp_fossil": 0.582
        },
        {
            "id": "node-96",
            "labels": "Process",
            "maetp": -82.1,
            "htp": -0.0399,
            "adp_elements": -2.62e-7,
            "gwp_100_ebc": -1.38,
            "ep": -0.000221,
            "faetp": -0.00167,
            "ap": -0.00161,
            "functional_unit": "kg",
            "uid": "Energy recovery from incineration of GFRP",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/EnergyRec_fromInc.png`,
            "eci_cost": -0.09119250045864198,
            "pocp": -0.000147,
            "name": "Energy recovery from incineration of GFRP",
            "odp": -2.14e-14,
            "gwp_100": -1.38,
            "tetp": -0.00155,
            "adp_fossil": -19.8
        },
        {
            "id": "node-79",
            "labels": "Material",
            "maetp": 165.12,
            "htp": 0.16383,
            "adp_elements": 0.000044247,
            "gwp_100_ebc": 4.0119,
            "ep": 0.00091461,
            "faetp": 0.026832,
            "ap": 0.0095331,
            "functional_unit": "kg",
            "uid": "GFRP Tension Bars",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/GFRPTensionBars.png`,
            "eci_cost": 0.2907188764419,
            "pocp": 0.0015867,
            "name": "GFRP Tension Bars",
            "odp": 1.02297e-9,
            "gwp_100": 4.0248,
            "tetp": 0.0119583,
            "adp_fossil": 92.88
        },
        {
            "id": "node-80",
            "labels": "Process",
            "maetp": 0.00151,
            "htp": 0.00000193,
            "adp_elements": 9.85e-12,
            "gwp_100_ebc": 0.0000851,
            "ep": 9.06e-8,
            "faetp": 4.52e-7,
            "ap": 3.56e-7,
            "functional_unit": "kgkm",
            "uid": "GFRP Tension Bars Transport",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/GFRPTensionBarsTransport.png`,
            "eci_cost": 0.000006604965249703,
            "pocp": -1.34e-7,
            "name": "GFRP Tension Bars Transport",
            "odp": 1.01e-17,
            "gwp_100": 0.0000839,
            "tetp": 2.08e-7,
            "adp_fossil": 0.00114
        },
        {
            "id": "node-98",
            "labels": "Material",
            "maetp": 53,
            "htp": 0.0313749585,
            "adp_elements": 0.000060485058,
            "gwp_100_ebc": 1.1191068,
            "ep": 0.000417666645,
            "faetp": 0.0016653375,
            "ap": 0.007593939,
            "functional_unit": "kg",
            "uid": "Glass fiber ",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/glass__fiber.png`,
            "eci_cost": 0.10046352948230278,
            "pocp": 0.000401679405,
            "name": "Glass fiber ",
            "odp": 2.12497065e-14,
            "gwp_100": 1.1191068,
            "tetp": 0.00309752775,
            "adp_fossil": 15.587559
        },
        {
            "id": "node-99",
            "labels": "Material",
            "maetp": 81,
            "htp": 0.084076062,
            "adp_elements": 0.00000312823546,
            "gwp_100_ebc": 2.1965818,
            "ep": 0.000418865426,
            "faetp": 0.0149973516,
            "ap": 0.00244653766,
            "functional_unit": "kg",
            "uid": "UP Resin",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/up_resin.png`,
            "eci_cost": 0.14662751504361518,
            "pocp": 0.00093922808,
            "name": "UP Resin",
            "odp": 1.69667008e-14,
            "gwp_100": 2.20415622,
            "tetp": 0.0062488965,
            "adp_fossil": 58.323034
        },
        {
            "id": "node-100",
            "labels": "Material",
            "maetp": 0.6,
            "htp": 0,
            "adp_elements": 0,
            "gwp_100_ebc": 0,
            "ep": 0,
            "faetp": 0,
            "ap": 0,
            "functional_unit": "kg",
            "uid": "UPR Based Gelcoat",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/upr_based_gelcoat.png`,
            "eci_cost": 0.000098519155,
            "pocp": 0,
            "name": "UPR Based Gelcoat",
            "odp": 0,
            "gwp_100": 0,
            "tetp": 0,
            "adp_fossil": 0.5
        },
        {
            "id": "node-101",
            "labels": "Material",
            "maetp": 11.5,
            "htp": 0.0097071,
            "adp_elements": 2.358e-7,
            "gwp_100_ebc": 0.21222,
            "ep": 0.000048732,
            "faetp": 0.00115935,
            "ap": 0.00061308,
            "functional_unit": "kg",
            "uid": "Catalyst",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/catalyst.png`,
            "eci_cost": 0.0161096802812672,
            "pocp": 0.000047422,
            "name": "Catalyst",
            "odp": 1.8733e-15,
            "gwp_100": 0.21353,
            "tetp": 0.00057902,
            "adp_fossil": 4.5981
        },
        {
            "id": "node-102",
            "labels": "Material",
            "maetp": 0.530080901856764,
            "htp": 0.00348696286472149,
            "adp_elements": 2.45494694960212e-8,
            "gwp_100_ebc": 0.041906100795756,
            "ep": 0.00000713029177718833,
            "faetp": 0.0000392478779840849,
            "ap": 0.0000939759946949602,
            "functional_unit": "kg",
            "uid": "Carbon Steel",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/steel.png`,
            "eci_cost": 0.0029746767528615907,
            "pocp": 0.0000175129973474801,
            "name": "Carbon Steel",
            "odp": 3.90915119363395e-18,
            "gwp_100": 0.041906100795756,
            "tetp": 0.0000119463660477454,
            "adp_fossil": 0.461279840848806
        },
        {
            "id": "node-103",
            "labels": "Material",
            "maetp": 0.00524694960212202,
            "htp": 0.00000602009283819629,
            "adp_elements": 3.09257294429708e-11,
            "gwp_100_ebc": 0.000132911140583554,
            "ep": 2.44104774535809e-8,
            "faetp": 0.00000130305039787798,
            "ap": 1.82427055702918e-7,
            "functional_unit": "kg",
            "uid": "Acetone",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/acetone.png`,
            "eci_cost": 0.000009168814775597966,
            "pocp": 4.00470822281167e-8,
            "name": "Acetone",
            "odp": 3.04045092838196e-19,
            "gwp_100": 0.000132911140583554,
            "tetp": 3.57035809018568e-7,
            "adp_fossil": 0.0047604774535809
        },
        {
            "id": "node-104",
            "labels": "Process",
            "maetp": 1.15120297487088,
            "htp": 0.00394140679532064,
            "adp_elements": 2.54630488509576e-9,
            "gwp_100_ebc": 0.080974446537528,
            "ep": 0.000267313233147984,
            "faetp": 0.000350238871168344,
            "ap": 0.0023902095664692,
            "functional_unit": "kgkm",
            "uid": "Ship transport",
            "svg": "https://cdn-icons-png.flaticon.com/512/607/607995.png",
            "eci_cost": 0.016836036676577584,
            "pocp": 0.000129754233608328,
            "name": "Ship transport",
            "odp": 1.1219351026284e-17,
            "gwp_100": 0.080974446537528,
            "tetp": 0.0000688770593439696,
            "adp_fossil": 0.99510765624432
        },
        {
            "id": "node-105",
            "labels": "Process",
            "maetp": 2.56323166253316,
            "htp": 0.00329558356611406,
            "adp_elements": 1.44962597385278e-8,
            "gwp_100_ebc": 0.143239416435676,
            "ep": 0.000149701344996684,
            "faetp": 0.00077973937969496,
            "ap": 0.000592343451425729,
            "functional_unit": "kgkm",
            "uid": "Truck transport",
            "svg": "https://cdn-icons-png.flaticon.com/512/609/609361.png",
            "eci_cost": 0.01114357347193895,
            "pocp": -0.000224013523448276,
            "name": "Truck transport",
            "odp": 3.83407761286472e-17,
            "gwp_100": 0.142593223579576,
            "tetp": 0.000320942451863395,
            "adp_fossil": 1.94288652067639
        },
        {
            "id": "node-106",
            "labels": "Energy",
            "maetp": 0.179025920424403,
            "htp": 0.000250162047745358,
            "adp_elements": 2.33860018567639e-10,
            "gwp_100_ebc": 0.00272095687002653,
            "ep": 0.00000100479779840849,
            "faetp": 0.00000503880901856764,
            "ap": 0.0000110853798408488,
            "functional_unit": "kWh",
            "uid": "Processing (Vaccum Infusion)",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/infusion.png`,
            "eci_cost": 0.00023380314241276332,
            "pocp": 5.92801061007958e-7,
            "name": "Processing (Vaccum Infusion)",
            "odp": 2.16668787798408e-17,
            "gwp_100": 0.00272095687002653,
            "tetp": 0.00000403104721485411,
            "adp_fossil": 0.0308256551724138
        },
        {
            "id": "node-107",
            "labels": "Process",
            "maetp": 0.112879212734347,
            "htp": 0.0000418770858958664,
            "adp_elements": 3.24311266712348e-10,
            "gwp_100_ebc": 0.000961913514374976,
            "ep": 2.2198004177884e-7,
            "faetp": 0.0000021095975601677,
            "ap": 0.0000018891918449263,
            "functional_unit": "Nm3",
            "uid": "Compressed air",
            "svg": "https://cdn-icons-png.flaticon.com/512/6357/6357789.png",
            "eci_cost": 0.00007394200120427965,
            "pocp": 1.36651543449669e-7,
            "name": "Compressed air",
            "odp": 3.10142327875401e-17,
            "gwp_100": 0.000961913514374976,
            "tetp": 0.00000112406914773115,
            "adp_fossil": 0.0107841367814543
        },
        {
            "id": "node-108",
            "labels": "Process",
            "maetp": 0,
            "htp": 0,
            "adp_elements": 0,
            "gwp_100_ebc": 0.0150343059239611,
            "ep": 0,
            "faetp": 0,
            "ap": 0,
            "functional_unit": "hour",
            "uid": "Crane operation",
            "svg": "https://cdn-icons-png.flaticon.com/512/1833/1833115.png",
            "eci_cost": 0.000751715296198055,
            "pocp": 0,
            "name": "Crane operation",
            "odp": 0,
            "gwp_100": 0.0150343059239611,
            "tetp": 0,
            "adp_fossil": 0
        },
        {
            "id": "node-109",
            "labels": "Process",
            "maetp": 0.0000653587507348893,
            "htp": 5.99598848883166e-8,
            "adp_elements": 2.55730413614063e-11,
            "gwp_100_ebc": 0.00000159554033006017,
            "ep": 5.38360025645689e-10,
            "faetp": 9.95832057128276e-9,
            "ap": 5.59625432102044e-9,
            "functional_unit": "number",
            "uid": "Mould",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/mould.png`,
            "eci_cost": 1.2354379541856052e-7,
            "pocp": 5.50357923578601e-10,
            "name": "Mould",
            "odp": 3.75242648441776e-16,
            "gwp_100": 0.00000159997858383096,
            "tetp": 4.74306793186739e-9,
            "adp_fossil": 0.00003497210043389
        },
        {
            "id": "node-127",
            "labels": "Chemical",
            "uid": "Cobalt Octate",
            "svg":  `${process.env.REACT_APP_BASE_URL}/assets/cobalt octate.png`,
            "smiles": "OC(=O)[C@@H](CCCC)CC",
            "name": "Cobalt Octate",
            "carbon_atoms_count": 16,
            "carbon_mass_percentage_in_molecule": "0.6",
            "chemical_formula": "C₈H₁₅CoO₂"
        },
        {
            "id": "node-128",
            "labels": "Chemical",
            "uid": "Antimony trioxide",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/antimony trioxide.png`,
            "smiles": "[Sb+3].[Sb+3].[O-2].[O-2].[O-2]",
            "name": "Antimony trioxide",
            "carbon_atoms_count": 0,
            "carbon_mass_percentage_in_molecule": "0",
            "chemical_formula": "O₃Sb₂"
        },
        {
            "id": "node-129",
            "labels": "Chemical",
            "uid": "Styrene",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/styrene.png`,
            "smiles": "c1(ccccc1)C=C",
            "name": "Styrene",
            "carbon_atoms_count": 8,
            "carbon_mass_percentage_in_molecule": "0.9",
            "chemical_formula": "C₈H₈"
        },
        {
            "id": "node-130",
            "labels": "Chemical",
            "uid": "Maleic anhydride",
            "svg": "https://cdn-icons-png.flaticon.com/512/710/710481.png",
            "smiles": "O1C(=O)C=CC1=O",
            "name": "Maleic anhydride",
            "carbon_atoms_count": 4,
            "carbon_mass_percentage_in_molecule": "0.5",
            "chemical_formula": "C₄H₂O₃"
        },
        {
            "id": "node-131",
            "labels": "Chemical",
            "uid": "Neopentyl glycol",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/glycol.png`,
            "smiles": "BrCC(CO)(CO)CBr",
            "name": "Neopentyl glycol",
            "carbon_atoms_count": 5,
            "carbon_mass_percentage_in_molecule": "0.2",
            "chemical_formula": "C₅H₁₀Br₂O₂"
        },
        {
            "id": "node-132",
            "labels": "Chemical",
            "uid": "Cobalt(II) 2-ethylhexanoate",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/ethyloxanate.png`,
            "smiles": "[Co+2].[O-]C(=O)C(CC)CCCC.[O-]C(=O)C(CC)CCCC",
            "name": "Cobalt(II) 2-ethylhexanoate",
            "carbon_mass_percentage_in_molecule": "0.6",
            "carbon_atoms_count": 16,
            "chemical_formula": "\tC₁₆H₃₂CoO₄"
        },
        {
            "id": "node-133",
            "labels": "Chemical",
            "uid": "Fumed Silicia",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/Fumed silica.png`,
            "smiles": "[Si](=O)=O",
            "name": "Fumed Silicia",
            "carbon_atoms_count": 0,
            "carbon_mass_percentage_in_molecule": "0",
            "chemical_formula": "SiO₂"
        },
        {
            "id": "node-134",
            "labels": "Chemical",
            "uid": "Water",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/H20.png`,
            "smiles": "O",
            "name": "Water",
            "carbon_atoms_count": 0,
            "carbon_mass_percentage_in_molecule": "0",
            "chemical_formula": "H₂O"
        },
        {
            "id": "node-135",
            "labels": "Chemical",
            "uid": "Methyl Ethyl Ketone",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/Metylethyl ketone.png`,
            "smiles": "O=C(CC)C",
            "name": "Methyl Ethyl Ketone",
            "carbon_atoms_count": 4,
            "carbon_mass_percentage_in_molecule": "66.6",
            "chemical_formula": "C₄H₈O"
        },
        {
            "id": "node-136",
            "labels": "Chemical",
            "uid": "Ketone Peroxide",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/ketone Peroxide.png`,
            "smiles": "O(O[C@@](OO)(CC)C)[C@@](OO)(CC)C",
            "name": "Ketone Peroxide",
            "carbon_atoms_count": 8,
            "carbon_mass_percentage_in_molecule": "45.7",
            "chemical_formula": "C₈H₁₈O₆"
        },
        {
            "id": "node-137",
            "labels": "Chemical",
            "uid": "Dimethyl Phthalate",
            "svg": "https://cdn-icons-png.flaticon.com/512/2106/2106370.png",
            "smiles": "O(C(=O)c1c(cccc1)C(=O)OC)C",
            "name": "Dimethyl Phthalate",
            "carbon_atoms_count": 10,
            "carbon_mass_percentage_in_molecule": "61.9",
            "chemical_formula": "C₁₀H₁₀O₄"
        },
        {
            "id": "node-138",
            "labels": "Chemical",
            "uid": "Hydrogen Peroxide",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/Hydrogen Peroxide.png`,
            "smiles": "OO",
            "name": "Hydrogen Peroxide",
            "carbon_atoms_count": 0,
            "carbon_mass_percentage_in_molecule": "0",
            "chemical_formula": "H₂O₂"
        },
        {
            "id": "node-139",
            "labels": "Chemical",
            "uid": "diisobutyrate",
            "svg": `${process.env.REACT_APP_BASE_URL}/assets/diisobutyrate.png`,
            "smiles": "O([C@@H](C(COC(=O)C(C)C)(C)C)C(C)C)C(=O)C(C)C",
            "name": "diisobutyrate",
            "carbon_atoms_count": 16,
            "carbon_mass_percentage_in_molecule": "0.7",
            "chemical_formula": "C₁₆H₃₀O₄"
        }
    ],
    "links": [
        {
            "id": "edge-134",
            "source": "node-56",
            "target": "node-60",
            "erelationship": "CONTAINS",
            "uid": "Baseline (Concrete Design)-Scope 1-Baseline (Concrete Design)"
        },
        {
            "id": "edge-135",
            "source": "node-56",
            "target": "node-61",
            "erelationship": "CONTAINS",
            "uid": "Baseline (Concrete Design)-Scope 3-Baseline (Concrete Design)"
        },
        {
            "id": "edge-136",
            "source": "node-56",
            "target": "node-62",
            "erelationship": "CONTAINS",
            "uid": "Baseline (Concrete Design)-Scope 2-Baseline (Concrete Design)"
        },
        {
            "id": "edge-137",
            "source": "node-57",
            "target": "node-63",
            "erelationship": "CONTAINS",
            "uid": "Arch Option 1a (GFRP)-Scope 1-Arch Option 1a (GFRP)"
        },
        {
            "id": "edge-138",
            "source": "node-57",
            "target": "node-64",
            "erelationship": "CONTAINS",
            "uid": "Arch Option 1a (GFRP)-Scope 3-Arch Option 1a (GFRP)"
        },
        {
            "id": "edge-139",
            "source": "node-57",
            "target": "node-65",
            "erelationship": "CONTAINS",
            "uid": "Arch Option 1a (GFRP)-Scope 2-Arch Option 1a (GFRP)"
        },
        {
            "id": "edge-140",
            "source": "node-58",
            "target": "node-66",
            "erelationship": "CONTAINS",
            "uid": "Arch Option 1b (GFRP)-Scope 1-Arch Option 1b (GFRP)"
        },
        {
            "id": "edge-141",
            "source": "node-58",
            "target": "node-67",
            "erelationship": "CONTAINS",
            "uid": "Arch Option 1b (GFRP)-Scope 3-Arch Option 1b (GFRP)"
        },
        {
            "id": "edge-142",
            "source": "node-58",
            "target": "node-68",
            "erelationship": "CONTAINS",
            "uid": "Arch Option 1b (GFRP)-Scope 2-Arch Option 1b (GFRP)"
        },
        {
            "id": "edge-143",
            "source": "node-59",
            "target": "node-69",
            "erelationship": "CONTAINS",
            "uid": "Arch Option 1c (GFRP)-Scope 1-Arch Option 1c (GFRP)"
        },
        {
            "id": "edge-144",
            "source": "node-59",
            "target": "node-70",
            "erelationship": "CONTAINS",
            "uid": "Arch Option 1c (GFRP)-Scope 3-Arch Option 1c (GFRP)"
        },
        {
            "id": "edge-145",
            "source": "node-59",
            "target": "node-71",
            "erelationship": "CONTAINS",
            "uid": "Arch Option 1c (GFRP)-Scope 2-Arch Option 1c (GFRP)"
        },
        {
            "id": "edge-146",
            "source": "node-60",
            "target": "node-72",
            "erelationship": "USES",
            "maetp": 85956000,
            "end_date": "2030-12-30",
            "quantity": 3800000,
            "htp": 140790,
            "adp_elements": 0.47522800000000004,
            "_updated": "1677771353122",
            "gwp_100_ebc": 5631600,
            "ep": 4549.740000000001,
            "faetp": 28750.8,
            "ap": 18821.4,
            "functional_unit": "m3",
            "uid": "Scope 1-Baseline (Concrete Design)-Excavation",
            "eci_cost": 417756.9409572281,
            "pocp": 1911.7800000000002,
            "groupStrategy": "minimize_eci_cost",
            "odp": 9.336599999999999e-10,
            "gwp_100": 5384600,
            "tetp": 11806.6,
            "adp_fossil": 73112000,
            "start_date": "2022-04-24",
            "ghg_scope": "Scope 1",
            "group": "Tunneling Process"
        },
        {
            "id": "edge-147",
            "source": "node-61",
            "target": "node-73",
            "erelationship": "USES",
            "maetp": 1784518000,
            "end_date": "",
            "quantity": 1181800000000,
            "htp": 2280874,
            "adp_elements": 11.64073,
            "gwp_100_ebc": 100571180,
            "ep": 107071.08,
            "faetp": 534173.6,
            "ap": 420720.8,
            "functional_unit": "kgkm",
            "uid": "Scope 3-Baseline (Concrete Design)-Excavated Soil Transportation",
            "eci_cost": 7805747.932099005,
            "pocp": -158361.2,
            "odp": 0.00001193618,
            "gwp_100": 99153020.00000001,
            "tetp": 245814.40000000002,
            "adp_fossil": 1347252000,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-148",
            "source": "node-61",
            "target": "node-92",
            "erelationship": "USES",
            "maetp": 8519828893.9008,
            "end_date": "",
            "quantity": 1735906457.6,
            "htp": 34579256.635391995,
            "adp_elements": 418.70063757312,
            "gwp_100_ebc": 258302880.89087996,
            "ep": 57493.221875712,
            "faetp": 327044.77661184,
            "ap": 372872.70709248,
            "functional_unit": "kg",
            "uid": "Scope 3-Baseline (Concrete Design)-Base Concrete",
            "eci_cost": 19083366.968889426,
            "pocp": 38953.740908543994,
            "odp": 0.00029371537262592,
            "gwp_100": 258302880.89087996,
            "tetp": 610344.7104921599,
            "adp_fossil": 918641697.36192,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-149",
            "source": "node-61",
            "target": "node-74",
            "erelationship": "USES",
            "maetp": 568243200,
            "end_date": "",
            "quantity": 250880000000,
            "htp": 726297.6,
            "adp_elements": 3.706752,
            "gwp_100_ebc": 32024832.000000004,
            "ep": 34094.592,
            "faetp": 170096.64,
            "ap": 133969.91999999998,
            "functional_unit": "kgkm",
            "uid": "Scope 3-Baseline (Concrete Design)-Concrete Transportation",
            "eci_cost": 2485580.636679168,
            "pocp": -50426.880000000005,
            "odp": 0.003800832,
            "gwp_100": 31573248,
            "tetp": 78274.56,
            "adp_fossil": 429004800,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-150",
            "source": "node-61",
            "target": "node-75",
            "erelationship": "USES",
            "maetp": 17992000,
            "end_date": "",
            "quantity": 104000000,
            "htp": 11960,
            "adp_elements": 0.067704,
            "gwp_100_ebc": 240240,
            "ep": 118.56000000000002,
            "faetp": 958.88,
            "ap": 607.36,
            "functional_unit": "kg",
            "uid": "Scope 3-Baseline (Concrete Design)-Gravel",
            "eci_cost": 18515.451255912,
            "pocp": -64.27199999999999,
            "odp": 0.0000022568,
            "gwp_100": 238160,
            "tetp": 1819.9999999999998,
            "adp_fossil": 2932800,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-151",
            "source": "node-61",
            "target": "node-76",
            "erelationship": "USES",
            "maetp": 31408000,
            "end_date": "",
            "quantity": 20800000000,
            "htp": 40144,
            "adp_elements": 0.20488,
            "gwp_100_ebc": 1770080,
            "ep": 1884.48,
            "faetp": 9401.6,
            "ap": 7404.8,
            "functional_unit": "kgkm",
            "uid": "Scope 3-Baseline (Concrete Design)-Gravel Transportation",
            "eci_cost": 137383.2771938224,
            "pocp": -2787.2000000000003,
            "odp": 2.1008e-7,
            "gwp_100": 1745120.0000000002,
            "tetp": 4326.400000000001,
            "adp_fossil": 23712000,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-152",
            "source": "node-61",
            "target": "node-81",
            "erelationship": "USES",
            "maetp": 2900.768,
            "end_date": "",
            "quantity": 304000,
            "htp": 0.04337472,
            "adp_elements": 0.7408905600000001,
            "gwp_100_ebc": 6294928,
            "ep": 48.183088000000005,
            "faetp": 48.183088000000005,
            "ap": 9214.24,
            "functional_unit": "m2",
            "uid": "Scope 3-Baseline (Concrete Design)-Fire Liner",
            "eci_cost": 377869.27630731853,
            "pocp": 8885.92,
            "odp": 0.0026299161654112003,
            "gwp_100": 6294928,
            "tetp": 31035.36,
            "adp_fossil": 80432320,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-153",
            "source": "node-61",
            "target": "node-82",
            "erelationship": "USES",
            "maetp": 1054636.8,
            "end_date": "",
            "quantity": 893760000,
            "htp": 1349.5775999999998,
            "adp_elements": 0.0068908896,
            "gwp_100_ebc": 59524.416000000005,
            "ep": 62.831328,
            "faetp": 315.49728,
            "ap": 246.67775999999998,
            "functional_unit": "kgkm",
            "uid": "Scope 3-Baseline (Concrete Design)-Fire Liner Transportation",
            "eci_cost": 4608.911598475429,
            "pocp": -92.95104,
            "odp": 7.033891199999999e-7,
            "gwp_100": 58720.032,
            "tetp": 145.68287999999998,
            "adp_fossil": 798127.68,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-154",
            "source": "node-61",
            "target": "node-83",
            "erelationship": "USES",
            "maetp": 1039716540,
            "end_date": "",
            "quantity": 688554000000,
            "htp": 1328909.2200000002,
            "adp_elements": 6.7822569,
            "gwp_100_ebc": 58595945.4,
            "ep": 62382.9924,
            "faetp": 311226.408,
            "ap": 245125.22400000002,
            "functional_unit": "kgkm",
            "uid": "Scope 3-Baseline (Concrete Design)-Backfill Soil Transportation",
            "eci_cost": 4547875.242543999,
            "pocp": -92266.236,
            "odp": 0.0000069543954,
            "gwp_100": 57769680.6,
            "tetp": 143219.23200000002,
            "adp_fossil": 784951560,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-155",
            "source": "node-60",
            "target": "node-84",
            "erelationship": "USES",
            "maetp": 888290000,
            "end_date": "",
            "quantity": 130000,
            "htp": 1113164,
            "adp_elements": 0.20389200000000002,
            "gwp_100_ebc": 13620425,
            "ep": 5179.8825,
            "faetp": 22818.899999999998,
            "ap": 57220.8,
            "functional_unit": "m2",
            "uid": "Scope 1-Baseline (Concrete Design)-Average site impacts",
            "eci_cost": 1164848.6978275303,
            "pocp": 3047.785,
            "odp": 0.0000719162548,
            "gwp_100": 13618800,
            "tetp": 18911.75,
            "adp_fossil": 148989750,
            "ghg_scope": "Scope 1",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-156",
            "source": "node-62",
            "target": "node-85",
            "erelationship": "USES",
            "maetp": 359147776,
            "end_date": "",
            "quantity": 130000,
            "htp": 448820.216,
            "adp_elements": 0.6990094800000001,
            "gwp_100_ebc": 5247781.760000001,
            "ep": 2087.19472,
            "faetp": 22543.508,
            "ap": 21719.672000000002,
            "functional_unit": "m2",
            "uid": "Scope 2-Baseline (Concrete Design)-Average energy and fuel use in construction site",
            "eci_cost": 448559.11572869756,
            "pocp": 1345.30136,
            "odp": 0.00002597091536,
            "gwp_100": 5108985.44,
            "tetp": 12299.3,
            "adp_fossil": 91290004,
            "ghg_scope": "Scope 2",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-157",
            "source": "node-62",
            "target": "node-86",
            "erelationship": "USES",
            "maetp": 3147170000,
            "end_date": "",
            "quantity": 55900000,
            "htp": 3980080,
            "adp_elements": 4.309889999999999,
            "gwp_100_ebc": 48633000,
            "ep": 18447,
            "faetp": 81055,
            "ap": 204035,
            "functional_unit": "kWh",
            "uid": "Scope 2-Baseline (Concrete Design)-Energy use on the site (Electricity Saudi)",
            "eci_cost": 4155603.02625416,
            "pocp": 10844.6,
            "odp": 0.000257699,
            "gwp_100": 48633000,
            "tetp": 63166.99999999999,
            "adp_fossil": 531609000,
            "start_date": "2022-04-24",
            "ghg_scope": "Scope 2",
            "group": "Energy Mix"
        },
        {
            "id": "edge-158",
            "source": "node-62",
            "target": "node-125",
            "erelationship": "USES",
            "maetp": 1090050000,
            "end_date": "2022-06-30",
            "quantity": 55900000,
            "htp": 0,
            "adp_elements": 0,
            "_updated": "1677771353122",
            "gwp_100_ebc": 5590000,
            "ep": 0,
            "faetp": 0,
            "ap": 0,
            "functional_unit": "kWh",
            "uid": "Scope 2-Baseline (Concrete Design)-Alternative Renewable electricity (Solar)",
            "eci_cost": 391519.5090703001,
            "pocp": 0,
            "groupStrategy": "minimize_eci_cost",
            "odp": 0,
            "gwp_100": 5590000,
            "tetp": 0,
            "adp_fossil": 39130000,
            "start_date": "2022-04-24",
            "ghg_scope": "Scope 2",
            "group": "Energy Mix"
        },
        {
            "id": "edge-159",
            "source": "node-62",
            "target": "node-126",
            "erelationship": "USES",
            "maetp": 2118610000,
            "end_date": "2022-06-30",
            "quantity": 55900000,
            "htp": 0,
            "adp_elements": 0,
            "gwp_100_ebc": 27950000,
            "ep": 0,
            "faetp": 0,
            "ap": 0,
            "functional_unit": "kWh",
            "uid": "Scope 2-Baseline (Concrete Design)-Alternative Electricity (Saudi Grid +Solar)",
            "eci_cost": 1631323.8517979002,
            "pocp": 0,
            "odp": 0,
            "gwp_100": 27950000,
            "tetp": 0,
            "adp_fossil": 285090000,
            "start_date": "2022-04-24",
            "ghg_scope": "Scope 2",
            "group": "Energy Mix"
        },
        {
            "id": "edge-160",
            "source": "node-60",
            "target": "node-87",
            "erelationship": "USES",
            "maetp": 444307760,
            "end_date": "",
            "quantity": 6760000,
            "htp": 508122.16,
            "adp_elements": 2.6802048000000003,
            "gwp_100_ebc": 3844817.6,
            "ep": 2424.9472,
            "faetp": 144380.08,
            "ap": 13161.72,
            "functional_unit": "l",
            "uid": "Scope 1-Baseline (Concrete Design)-Diesel",
            "eci_cost": 329988.1310328149,
            "pocp": 2608.4136000000003,
            "odp": 0.0000020101536,
            "gwp_100": 2456854.4,
            "tetp": 59826,
            "adp_fossil": 381291040,
            "ghg_scope": "Scope 1",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-161",
            "source": "node-61",
            "target": "node-88",
            "erelationship": "USES",
            "maetp": 2002000,
            "end_date": "",
            "quantity": 650000,
            "htp": 488.79999999999995,
            "adp_elements": 0.003432,
            "gwp_100_ebc": 9685,
            "ep": 6.376499999999999,
            "faetp": 39.78,
            "ap": 56.16,
            "functional_unit": "kg",
            "uid": "Scope 3-Baseline (Concrete Design)-Waste",
            "eci_cost": 1029.138054914029,
            "pocp": 4.277,
            "odp": 5.0960000000000004e-11,
            "gwp_100": 9360,
            "tetp": 256.75,
            "adp_fossil": 126750,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-162",
            "source": "node-61",
            "target": "node-89",
            "erelationship": "USES",
            "maetp": 6008310,
            "end_date": "",
            "quantity": 306000000,
            "htp": 6640.2,
            "adp_elements": 0.039626999999999996,
            "gwp_100_ebc": 86858.09999999999,
            "ep": 109.24199999999999,
            "faetp": 1413.72,
            "ap": 125.307,
            "functional_unit": "kg",
            "uid": "Scope 3-Baseline (Concrete Design)-Maintenance Water",
            "eci_cost": 7617.911785325101,
            "pocp": 11.5668,
            "odp": 4.4982e-7,
            "gwp_100": 94140.90000000001,
            "tetp": 1263.78,
            "adp_fossil": 1124550,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-163",
            "source": "node-61",
            "target": "node-90",
            "erelationship": "USES",
            "maetp": 26106.912,
            "end_date": "",
            "quantity": 2736000,
            "htp": 0.39037248,
            "adp_elements": 6.66801504,
            "gwp_100_ebc": 56654352,
            "ep": 433.64779200000004,
            "faetp": 433.64779200000004,
            "ap": 82928.16,
            "functional_unit": "m2",
            "uid": "Scope 3-Baseline (Concrete Design)-Fire Liner replacement",
            "eci_cost": 3400823.486765867,
            "pocp": 79973.28,
            "odp": 0.0236692454887008,
            "gwp_100": 56654352,
            "tetp": 279318.24,
            "adp_fossil": 723890880,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-164",
            "source": "node-61",
            "target": "node-91",
            "erelationship": "USES",
            "maetp": 9491731.200000001,
            "end_date": "",
            "quantity": 8043840000,
            "htp": 12146.1984,
            "adp_elements": 0.0620180064,
            "gwp_100_ebc": 535719.7440000001,
            "ep": 565.481952,
            "faetp": 2839.47552,
            "ap": 2220.09984,
            "functional_unit": "kgkm",
            "uid": "Scope 3-Baseline (Concrete Design)-Fire Liner Transportation replacement",
            "eci_cost": 41480.20438627886,
            "pocp": -836.5593600000001,
            "odp": 0.00000633050208,
            "gwp_100": 528480.288,
            "tetp": 1311.14592,
            "adp_fossil": 7183149.12,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-165",
            "source": "node-61",
            "target": "node-111",
            "erelationship": "USES",
            "maetp": 1231319040,
            "end_date": "",
            "quantity": 250880000,
            "htp": 4997529.6,
            "adp_elements": 60.512256,
            "gwp_100_ebc": 37330944,
            "ep": 8309.1456,
            "faetp": 47265.792,
            "ap": 53889.024,
            "functional_unit": "kg",
            "uid": "Scope 3-Baseline (Concrete Design)-Base Concrete (EoL)",
            "eci_cost": 2758002.935120241,
            "pocp": 5629.7472,
            "odp": 0.000042448896,
            "gwp_100": 37330944,
            "tetp": 88209.408,
            "adp_fossil": 132765696,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-166",
            "source": "node-61",
            "target": "node-110",
            "erelationship": "USES",
            "maetp": 113648640,
            "end_date": "",
            "quantity": 50176000000,
            "htp": 145259.52,
            "adp_elements": 0.7413504,
            "gwp_100_ebc": 6404966.4,
            "ep": 6818.9184,
            "faetp": 34019.328,
            "ap": 26793.984,
            "functional_unit": "kgkm",
            "uid": "Scope 3-Baseline (Concrete Design)-Concrete Transportation (EoL)",
            "eci_cost": 497116.1273358336,
            "pocp": -10085.376,
            "odp": 0.0007601664,
            "gwp_100": 6314649.6,
            "tetp": 15654.912,
            "adp_fossil": 85800960,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-167",
            "source": "node-61",
            "target": "node-93",
            "erelationship": "USES",
            "maetp": 3863552000,
            "end_date": "",
            "quantity": 1254400000,
            "htp": 943308.7999999999,
            "adp_elements": 6.623232,
            "gwp_100_ebc": 18690560,
            "ep": 12305.663999999999,
            "faetp": 76769.28,
            "ap": 108380.16,
            "functional_unit": "kg",
            "uid": "Scope 3-Baseline (Concrete Design)-Construction concrete waste",
            "eci_cost": 1986078.1170525507,
            "pocp": 8253.952,
            "odp": 9.834496e-8,
            "gwp_100": 18063360,
            "tetp": 495488,
            "adp_fossil": 244608000,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-168",
            "source": "node-61",
            "target": "node-95",
            "erelationship": "USES",
            "maetp": 99654240,
            "end_date": "",
            "quantity": 44688000,
            "htp": 176964.48,
            "adp_elements": 4.111296,
            "gwp_100_ebc": 21673680,
            "ep": 4558.176,
            "faetp": 4692.24,
            "ap": 23327.136,
            "functional_unit": "kg",
            "uid": "Scope 3-Baseline (Concrete Design)-Waste disposal of Fire Liner",
            "eci_cost": 3003737.9283028985,
            "pocp": 1362.984,
            "odp": 3.7359168e-8,
            "gwp_100": 56753760,
            "tetp": 15908.928,
            "adp_fossil": 26008416,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-169",
            "source": "node-61",
            "target": "node-97",
            "erelationship": "USES",
            "maetp": -4245229913.232,
            "end_date": "",
            "quantity": 51708037.92,
            "htp": -2063150.713008,
            "adp_elements": -13.54750593504,
            "gwp_100_ebc": -71357092.32959999,
            "ep": -11427.47638032,
            "faetp": -86352.4233264,
            "ap": -83249.9410512,
            "functional_unit": "kg",
            "uid": "Scope 3-Baseline (Concrete Design)-Energy recovery from incineration of Liner",
            "eci_cost": -4715385.271735077,
            "pocp": -7601.08157424,
            "odp": -0.000001106552011488,
            "gwp_100": -71357092.32959999,
            "tetp": -80147.458776,
            "adp_fossil": -1023819150.8160001,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-170",
            "source": "node-63",
            "target": "node-72",
            "erelationship": "USES",
            "maetp": 78446160,
            "end_date": "",
            "quantity": 3468000,
            "htp": 128489.4,
            "adp_elements": 0.43370808000000005,
            "_updated": "1677771353122",
            "gwp_100_ebc": 5139576,
            "ep": 4152.236400000001,
            "faetp": 26238.888,
            "ap": 17177.004,
            "functional_unit": "m3",
            "uid": "Scope 1-Arch Option 1a (GFRP)-Excavation",
            "eci_cost": 381258.1766420176,
            "pocp": 1744.7508,
            "groupStrategy": "minimize_eci_cost",
            "odp": 8.520876e-10,
            "gwp_100": 4914156,
            "tetp": 10775.076,
            "adp_fossil": 66724319.99999999,
            "start_date": "2022-04-24",
            "ghg_scope": "Scope 1",
            "group": "Tunneling Process"
        },
        {
            "id": "edge-171",
            "source": "node-64",
            "target": "node-73",
            "erelationship": "USES",
            "maetp": 1628607480,
            "end_date": "",
            "quantity": 1078548000000,
            "htp": 2081597.6400000001,
            "adp_elements": 10.6236978,
            "gwp_100_ebc": 91784434.8,
            "ep": 97716.4488,
            "faetp": 487503.69600000005,
            "ap": 383963.088,
            "functional_unit": "kgkm",
            "uid": "Scope 3-Arch Option 1a (GFRP)-Excavated Soil Transportation",
            "eci_cost": 7123772.060136671,
            "pocp": -144525.432,
            "odp": 0.0000108933348,
            "gwp_100": 90490177.2,
            "tetp": 224337.984,
            "adp_fossil": 1229544720,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-172",
            "source": "node-64",
            "target": "node-92",
            "erelationship": "USES",
            "maetp": 1082214000,
            "end_date": "",
            "quantity": 220500000,
            "htp": 4392360,
            "adp_elements": 53.1846,
            "gwp_100_ebc": 32810399.999999996,
            "ep": 7302.96,
            "faetp": 41542.2,
            "ap": 47363.399999999994,
            "functional_unit": "kg",
            "uid": "Scope 3-Arch Option 1a (GFRP)-Base Concrete",
            "eci_cost": 2424026.017195524,
            "pocp": 4948.0199999999995,
            "odp": 0.0000373086,
            "gwp_100": 32810399.999999996,
            "tetp": 77527.8,
            "adp_fossil": 116688600,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-173",
            "source": "node-64",
            "target": "node-74",
            "erelationship": "USES",
            "maetp": 99886500,
            "end_date": "",
            "quantity": 44100000000,
            "htp": 127669.49999999999,
            "adp_elements": 0.6515774999999999,
            "gwp_100_ebc": 5629365.000000001,
            "ep": 5993.19,
            "faetp": 29899.8,
            "ap": 23549.399999999998,
            "functional_unit": "kgkm",
            "uid": "Scope 3-Arch Option 1a (GFRP)-Concrete Transportation",
            "eci_cost": 436918.47129126004,
            "pocp": -8864.1,
            "odp": 0.000668115,
            "gwp_100": 5549985,
            "tetp": 13759.199999999999,
            "adp_fossil": 75411000,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-174",
            "source": "node-64",
            "target": "node-75",
            "erelationship": "USES",
            "maetp": 11625600,
            "end_date": "",
            "quantity": 67200000,
            "htp": 7728,
            "adp_elements": 0.0437472,
            "gwp_100_ebc": 155232,
            "ep": 76.608,
            "faetp": 619.584,
            "ap": 392.448,
            "functional_unit": "kg",
            "uid": "Scope 3-Arch Option 1a (GFRP)-Gravel",
            "eci_cost": 11963.830042281601,
            "pocp": -41.529599999999995,
            "odp": 0.0000014582400000000001,
            "gwp_100": 153888,
            "tetp": 1176,
            "adp_fossil": 1895040,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-175",
            "source": "node-64",
            "target": "node-76",
            "erelationship": "USES",
            "maetp": 20294400,
            "end_date": "",
            "quantity": 13440000000,
            "htp": 25939.2,
            "adp_elements": 0.132384,
            "gwp_100_ebc": 1143744,
            "ep": 1217.664,
            "faetp": 6074.88,
            "ap": 4784.64,
            "functional_unit": "kgkm",
            "uid": "Scope 3-Arch Option 1a (GFRP)-Gravel Transportation",
            "eci_cost": 88770.73295600832,
            "pocp": -1800.96,
            "odp": 1.35744e-7,
            "gwp_100": 1127616,
            "tetp": 2795.52,
            "adp_fossil": 15321600,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-176",
            "source": "node-64",
            "target": "node-77",
            "erelationship": "USES",
            "maetp": 3077836800,
            "end_date": "",
            "quantity": 18640000,
            "htp": 3053791.2,
            "adp_elements": 824.7640799999999,
            "gwp_100_ebc": 74781816,
            "ep": 17048.330400000003,
            "faetp": 500148.48000000004,
            "ap": 177696.984,
            "functional_unit": "kg",
            "uid": "Scope 3-Arch Option 1a (GFRP)-GFRP Panels",
            "eci_cost": 5418999.856877016,
            "pocp": 29576.088,
            "odp": 0.0190681608,
            "gwp_100": 75022272,
            "tetp": 222902.712,
            "adp_fossil": 1731283200,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-177",
            "source": "node-64",
            "target": "node-78",
            "erelationship": "USES",
            "maetp": 4399040,
            "end_date": "",
            "quantity": 3728000000,
            "htp": 5629.28,
            "adp_elements": 0.028742880000000002,
            "gwp_100_ebc": 248284.80000000002,
            "ep": 262.0784,
            "faetp": 1315.984,
            "ap": 1028.9279999999999,
            "functional_unit": "kgkm",
            "uid": "Scope 3-Arch Option 1a (GFRP)-GFRP Panel Transportation",
            "eci_cost": 19224.425392853114,
            "pocp": -387.71200000000005,
            "odp": 0.0000029339359999999997,
            "gwp_100": 244929.6,
            "tetp": 607.664,
            "adp_fossil": 3329104,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-178",
            "source": "node-64",
            "target": "node-81",
            "erelationship": "USES",
            "maetp": 2900.768,
            "end_date": "",
            "quantity": 304000,
            "htp": 0.04337472,
            "adp_elements": 0.7408905600000001,
            "gwp_100_ebc": 6294928,
            "ep": 48.183088000000005,
            "faetp": 48.183088000000005,
            "ap": 9214.24,
            "functional_unit": "m2",
            "uid": "Scope 3-Arch Option 1a (GFRP)-Fire Liner",
            "eci_cost": 377869.27630731853,
            "pocp": 8885.92,
            "odp": 0.0026299161654112003,
            "gwp_100": 6294928,
            "tetp": 31035.36,
            "adp_fossil": 80432320,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-179",
            "source": "node-64",
            "target": "node-82",
            "erelationship": "USES",
            "maetp": 1054636.8,
            "end_date": "",
            "quantity": 893760000,
            "htp": 1349.5775999999998,
            "adp_elements": 0.0068908896,
            "gwp_100_ebc": 59524.416000000005,
            "ep": 62.831328,
            "faetp": 315.49728,
            "ap": 246.67775999999998,
            "functional_unit": "kgkm",
            "uid": "Scope 3-Arch Option 1a (GFRP)-Fire Liner Transportation",
            "eci_cost": 4608.911598475429,
            "pocp": -92.95104,
            "odp": 7.033891199999999e-7,
            "gwp_100": 58720.032,
            "tetp": 145.68287999999998,
            "adp_fossil": 798127.68,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-180",
            "source": "node-64",
            "target": "node-83",
            "erelationship": "USES",
            "maetp": 1149605280,
            "end_date": "",
            "quantity": 761328000000,
            "htp": 1469363.04,
            "adp_elements": 7.4990808,
            "gwp_100_ebc": 64789012.8,
            "ep": 68976.3168,
            "faetp": 344120.256,
            "ap": 271032.768,
            "functional_unit": "kgkm",
            "uid": "Scope 3-Arch Option 1a (GFRP)-Backfill Soil Transportation",
            "eci_cost": 5028544.983625886,
            "pocp": -102017.952,
            "odp": 0.0000076894128,
            "gwp_100": 63875419.2,
            "tetp": 158356.22400000002,
            "adp_fossil": 867913920,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-181",
            "source": "node-63",
            "target": "node-84",
            "erelationship": "USES",
            "maetp": 956620000,
            "end_date": "",
            "quantity": 140000,
            "htp": 1198792,
            "adp_elements": 0.21957600000000002,
            "gwp_100_ebc": 14668150,
            "ep": 5578.335,
            "faetp": 24574.199999999997,
            "ap": 61622.4,
            "functional_unit": "m2",
            "uid": "Scope 1-Arch Option 1a (GFRP)-Average site impacts",
            "eci_cost": 1254452.4438142634,
            "pocp": 3282.23,
            "odp": 0.00007744827439999999,
            "gwp_100": 14666400,
            "tetp": 20366.5,
            "adp_fossil": 160450500,
            "ghg_scope": "Scope 1",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-182",
            "source": "node-65",
            "target": "node-85",
            "erelationship": "USES",
            "maetp": 386774528,
            "end_date": "",
            "quantity": 140000,
            "htp": 483344.848,
            "adp_elements": 0.7527794400000001,
            "gwp_100_ebc": 5651457.28,
            "ep": 2247.74816,
            "faetp": 24277.624,
            "ap": 23390.416,
            "functional_unit": "m2",
            "uid": "Scope 2-Arch Option 1a (GFRP)-Average energy and fuel use in construction site",
            "eci_cost": 483063.66309244354,
            "pocp": 1448.7860799999999,
            "odp": 0.00002796867808,
            "gwp_100": 5501984.32,
            "tetp": 13245.4,
            "adp_fossil": 98312312,
            "ghg_scope": "Scope 2",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-183",
            "source": "node-65",
            "target": "node-86",
            "erelationship": "USES",
            "maetp": 3389260000,
            "end_date": "",
            "quantity": 60200000,
            "htp": 4286240,
            "adp_elements": 4.64142,
            "gwp_100_ebc": 52374000,
            "ep": 19866,
            "faetp": 87290,
            "ap": 219730,
            "functional_unit": "kWh",
            "uid": "Scope 2-Arch Option 1a (GFRP)-Energy use on the site (Electricity Saudi)",
            "eci_cost": 4475264.79750448,
            "pocp": 11678.8,
            "odp": 0.000277522,
            "gwp_100": 52374000,
            "tetp": 68026,
            "adp_fossil": 572502000,
            "start_date": "2022-04-24",
            "ghg_scope": "Scope 2",
            "group": "Energy Mix"
        },
        {
            "id": "edge-184",
            "source": "node-65",
            "target": "node-125",
            "erelationship": "USES",
            "maetp": 1173900000,
            "end_date": "2022-06-30",
            "quantity": 60200000,
            "htp": 0,
            "adp_elements": 0,
            "_updated": "1677771353122",
            "gwp_100_ebc": 6020000,
            "ep": 0,
            "faetp": 0,
            "ap": 0,
            "functional_unit": "kWh",
            "uid": "Scope 2-Arch Option 1a (GFRP)-Alternative Renewable electricity (Solar)",
            "eci_cost": 421636.3943834001,
            "pocp": 0,
            "groupStrategy": "minimize_eci_cost",
            "odp": 0,
            "gwp_100": 6020000,
            "tetp": 0,
            "adp_fossil": 42140000,
            "start_date": "2022-04-24",
            "ghg_scope": "Scope 2",
            "group": "Energy Mix"
        },
        {
            "id": "edge-185",
            "source": "node-65",
            "target": "node-126",
            "erelationship": "USES",
            "maetp": 2281580000,
            "end_date": "2022-06-30",
            "quantity": 60200000,
            "htp": 0,
            "adp_elements": 0,
            "gwp_100_ebc": 30100000,
            "ep": 0,
            "faetp": 0,
            "ap": 0,
            "functional_unit": "kWh",
            "uid": "Scope 2-Arch Option 1a (GFRP)-Alternative Electricity (Saudi Grid +Solar)",
            "eci_cost": 1756810.3019362001,
            "pocp": 0,
            "odp": 0,
            "gwp_100": 30100000,
            "tetp": 0,
            "adp_fossil": 307020000,
            "start_date": "2022-04-24",
            "ghg_scope": "Scope 2",
            "group": "Energy Mix"
        },
        {
            "id": "edge-186",
            "source": "node-63",
            "target": "node-87",
            "erelationship": "USES",
            "maetp": 478485280,
            "end_date": "",
            "quantity": 7280000,
            "htp": 547208.48,
            "adp_elements": 2.8863744000000002,
            "gwp_100_ebc": 4140572.8000000003,
            "ep": 2611.4816,
            "faetp": 155486.24,
            "ap": 14174.16,
            "functional_unit": "l",
            "uid": "Scope 1-Arch Option 1a (GFRP)-Diesel",
            "eci_cost": 355371.8334199545,
            "pocp": 2809.0608,
            "odp": 0.0000021647808000000002,
            "gwp_100": 2645843.1999999997,
            "tetp": 64428,
            "adp_fossil": 410621120,
            "ghg_scope": "Scope 1",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-187",
            "source": "node-64",
            "target": "node-88",
            "erelationship": "USES",
            "maetp": 2156000,
            "end_date": "",
            "quantity": 700000,
            "htp": 526.4,
            "adp_elements": 0.003696,
            "gwp_100_ebc": 10430,
            "ep": 6.866999999999999,
            "faetp": 42.839999999999996,
            "ap": 60.48,
            "functional_unit": "kg",
            "uid": "Scope 3-Arch Option 1a (GFRP)-Waste",
            "eci_cost": 1108.3025206766465,
            "pocp": 4.606,
            "odp": 5.488e-11,
            "gwp_100": 10080,
            "tetp": 276.5,
            "adp_fossil": 136500,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-188",
            "source": "node-64",
            "target": "node-89",
            "erelationship": "USES",
            "maetp": 6518820,
            "end_date": "",
            "quantity": 332000000,
            "htp": 7204.4,
            "adp_elements": 0.042994,
            "gwp_100_ebc": 94238.2,
            "ep": 118.52399999999999,
            "faetp": 1533.84,
            "ap": 135.954,
            "functional_unit": "kg",
            "uid": "Scope 3-Arch Option 1a (GFRP)-Maintenance Water",
            "eci_cost": 8265.1853357122,
            "pocp": 12.5496,
            "odp": 4.8804e-7,
            "gwp_100": 102139.8,
            "tetp": 1371.16,
            "adp_fossil": 1220100,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-189",
            "source": "node-64",
            "target": "node-90",
            "erelationship": "USES",
            "maetp": 26106.912,
            "end_date": "",
            "quantity": 2736000,
            "htp": 0.39037248,
            "adp_elements": 6.66801504,
            "gwp_100_ebc": 56654352,
            "ep": 433.64779200000004,
            "faetp": 433.64779200000004,
            "ap": 82928.16,
            "functional_unit": "m2",
            "uid": "Scope 3-Arch Option 1a (GFRP)-Fire Liner replacement",
            "eci_cost": 3400823.486765867,
            "pocp": 79973.28,
            "odp": 0.0236692454887008,
            "gwp_100": 56654352,
            "tetp": 279318.24,
            "adp_fossil": 723890880,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-190",
            "source": "node-64",
            "target": "node-91",
            "erelationship": "USES",
            "maetp": 9491731.200000001,
            "end_date": "",
            "quantity": 8043840000,
            "htp": 12146.1984,
            "adp_elements": 0.0620180064,
            "gwp_100_ebc": 535719.7440000001,
            "ep": 565.481952,
            "faetp": 2839.47552,
            "ap": 2220.09984,
            "functional_unit": "kgkm",
            "uid": "Scope 3-Arch Option 1a (GFRP)-Fire Liner Transportation replacement",
            "eci_cost": 41480.20438627886,
            "pocp": -836.5593600000001,
            "odp": 0.00000633050208,
            "gwp_100": 528480.288,
            "tetp": 1311.14592,
            "adp_fossil": 7183149.12,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-191",
            "source": "node-64",
            "target": "node-93",
            "erelationship": "USES",
            "maetp": 679140000,
            "end_date": "",
            "quantity": 220500000,
            "htp": 165816,
            "adp_elements": 1.16424,
            "gwp_100_ebc": 3285450,
            "ep": 2163.105,
            "faetp": 13494.599999999999,
            "ap": 19051.2,
            "functional_unit": "kg",
            "uid": "Scope 3-Arch Option 1a (GFRP)-Construction concrete waste",
            "eci_cost": 349115.2940131437,
            "pocp": 1450.8899999999999,
            "odp": 1.72872e-8,
            "gwp_100": 3175200,
            "tetp": 87097.5,
            "adp_fossil": 42997500,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-192",
            "source": "node-64",
            "target": "node-94",
            "erelationship": "USES",
            "maetp": 41567200,
            "end_date": "",
            "quantity": 18640000,
            "htp": 73814.4,
            "adp_elements": 1.71488,
            "gwp_100_ebc": 9040400,
            "ep": 1901.28,
            "faetp": 1957.2,
            "ap": 9730.08,
            "functional_unit": "kg",
            "uid": "Scope 3-Arch Option 1a (GFRP)-Waste disposal of GFRP",
            "eci_cost": 1252901.7853465367,
            "pocp": 568.52,
            "odp": 1.558304e-8,
            "gwp_100": 23672800,
            "tetp": 6635.839999999999,
            "adp_fossil": 10848480,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-193",
            "source": "node-64",
            "target": "node-95",
            "erelationship": "USES",
            "maetp": 99654240,
            "end_date": "",
            "quantity": 44688000,
            "htp": 176964.48,
            "adp_elements": 4.111296,
            "gwp_100_ebc": 21673680,
            "ep": 4558.176,
            "faetp": 4692.24,
            "ap": 23327.136,
            "functional_unit": "kg",
            "uid": "Scope 3-Arch Option 1a (GFRP)-Waste disposal of Fire Liner",
            "eci_cost": 3003737.9283028985,
            "pocp": 1362.984,
            "odp": 3.7359168e-8,
            "gwp_100": 56753760,
            "tetp": 15908.928,
            "adp_fossil": 26008416,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-194",
            "source": "node-64",
            "target": "node-96",
            "erelationship": "USES",
            "maetp": -1530344000,
            "end_date": "",
            "quantity": 18640000,
            "htp": -743736,
            "adp_elements": -4.88368,
            "gwp_100_ebc": -25723199.999999996,
            "ep": -4119.4400000000005,
            "faetp": -31128.8,
            "ap": -30010.4,
            "functional_unit": "kg",
            "uid": "Scope 3-Arch Option 1a (GFRP)-Energy recovery from incineration of GFRP",
            "eci_cost": -1699828.2085490865,
            "pocp": -2740.08,
            "odp": -3.98896e-7,
            "gwp_100": -25723199.999999996,
            "tetp": -28892,
            "adp_fossil": -369072000,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-195",
            "source": "node-64",
            "target": "node-97",
            "erelationship": "USES",
            "maetp": -3668884799.9999995,
            "end_date": "",
            "quantity": 44688000,
            "htp": -1783051.2,
            "adp_elements": -11.708256,
            "gwp_100_ebc": -61669439.99999999,
            "ep": -9876.048,
            "faetp": -74628.96,
            "ap": -71947.68000000001,
            "functional_unit": "kg",
            "uid": "Scope 3-Arch Option 1a (GFRP)-Energy recovery from incineration of Liner",
            "eci_cost": -4075210.460495793,
            "pocp": -6569.1359999999995,
            "odp": -9.563232e-7,
            "gwp_100": -61669439.99999999,
            "tetp": -69266.4,
            "adp_fossil": -884822400,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-196",
            "source": "node-66",
            "target": "node-72",
            "erelationship": "USES",
            "maetp": 75007920,
            "end_date": "",
            "quantity": 3316000,
            "htp": 122857.8,
            "adp_elements": 0.41469896,
            "_updated": "1677771353122",
            "gwp_100_ebc": 4914312,
            "ep": 3970.2468000000003,
            "faetp": 25088.856,
            "ap": 16424.148,
            "functional_unit": "m3",
            "uid": "Scope 1-Arch Option 1b (GFRP)-Excavation",
            "eci_cost": 364547.89900372847,
            "pocp": 1668.2796,
            "groupStrategy": "minimize_eci_cost",
            "odp": 8.147411999999999e-10,
            "gwp_100": 4698772,
            "tetp": 10302.812,
            "adp_fossil": 63799839.99999999,
            "start_date": "2022-04-24",
            "ghg_scope": "Scope 1",
            "group": "Tunneling Process"
        },
        {
            "id": "edge-197",
            "source": "node-67",
            "target": "node-73",
            "erelationship": "USES",
            "maetp": 1557226760,
            "end_date": "",
            "quantity": 1031276000000,
            "htp": 1990362.6800000002,
            "adp_elements": 10.1580686,
            "gwp_100_ebc": 87761587.6,
            "ep": 93433.60560000001,
            "faetp": 466136.75200000004,
            "ap": 367134.256,
            "functional_unit": "kgkm",
            "uid": "Scope 3-Arch Option 1b (GFRP)-Excavated Soil Transportation",
            "eci_cost": 6811542.142852711,
            "pocp": -138190.984,
            "odp": 0.0000104158876,
            "gwp_100": 86524056.4,
            "tetp": 214505.408,
            "adp_fossil": 1175654640,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-198",
            "source": "node-67",
            "target": "node-92",
            "erelationship": "USES",
            "maetp": 1082214000,
            "end_date": "",
            "quantity": 220500000,
            "htp": 4392360,
            "adp_elements": 53.1846,
            "gwp_100_ebc": 32810399.999999996,
            "ep": 7302.96,
            "faetp": 41542.2,
            "ap": 47363.399999999994,
            "functional_unit": "kg",
            "uid": "Scope 3-Arch Option 1b (GFRP)-Base Concrete",
            "eci_cost": 2424026.017195524,
            "pocp": 4948.0199999999995,
            "odp": 0.0000373086,
            "gwp_100": 32810399.999999996,
            "tetp": 77527.8,
            "adp_fossil": 116688600,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-199",
            "source": "node-67",
            "target": "node-74",
            "erelationship": "USES",
            "maetp": 99886500,
            "end_date": "",
            "quantity": 44100000000,
            "htp": 127669.49999999999,
            "adp_elements": 0.6515774999999999,
            "gwp_100_ebc": 5629365.000000001,
            "ep": 5993.19,
            "faetp": 29899.8,
            "ap": 23549.399999999998,
            "functional_unit": "kgkm",
            "uid": "Scope 3-Arch Option 1b (GFRP)-Concrete Transportation",
            "eci_cost": 436918.47129126004,
            "pocp": -8864.1,
            "odp": 0.000668115,
            "gwp_100": 5549985,
            "tetp": 13759.199999999999,
            "adp_fossil": 75411000,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-200",
            "source": "node-67",
            "target": "node-75",
            "erelationship": "USES",
            "maetp": 13286399.999999998,
            "end_date": "",
            "quantity": 76800000,
            "htp": 8832,
            "adp_elements": 0.0499968,
            "gwp_100_ebc": 177408,
            "ep": 87.552,
            "faetp": 708.096,
            "ap": 448.512,
            "functional_unit": "kg",
            "uid": "Scope 3-Arch Option 1b (GFRP)-Gravel",
            "eci_cost": 13672.948619750401,
            "pocp": -47.462399999999995,
            "odp": 0.00000166656,
            "gwp_100": 175872,
            "tetp": 1343.9999999999998,
            "adp_fossil": 2165760,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-201",
            "source": "node-67",
            "target": "node-76",
            "erelationship": "USES",
            "maetp": 23193600,
            "end_date": "",
            "quantity": 15360000000,
            "htp": 29644.800000000003,
            "adp_elements": 0.151296,
            "gwp_100_ebc": 1307136,
            "ep": 1391.616,
            "faetp": 6942.72,
            "ap": 5468.16,
            "functional_unit": "kgkm",
            "uid": "Scope 3-Arch Option 1b (GFRP)-Gravel Transportation",
            "eci_cost": 101452.26623543807,
            "pocp": -2058.2400000000002,
            "odp": 1.55136e-7,
            "gwp_100": 1288704,
            "tetp": 3194.88,
            "adp_fossil": 17510400,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-202",
            "source": "node-67",
            "target": "node-77",
            "erelationship": "USES",
            "maetp": 4623360000,
            "end_date": "",
            "quantity": 28000000,
            "htp": 4587240,
            "adp_elements": 1238.916,
            "gwp_100_ebc": 112333200,
            "ep": 25609.08,
            "faetp": 751296,
            "ap": 266926.8,
            "functional_unit": "kg",
            "uid": "Scope 3-Arch Option 1b (GFRP)-GFRP Panels",
            "eci_cost": 8140128.5403732,
            "pocp": 44427.6,
            "odp": 0.02864316,
            "gwp_100": 112694400,
            "tetp": 334832.4,
            "adp_fossil": 2600640000,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-203",
            "source": "node-67",
            "target": "node-78",
            "erelationship": "USES",
            "maetp": 6608000,
            "end_date": "",
            "quantity": 5600000000,
            "htp": 8456,
            "adp_elements": 0.043176,
            "gwp_100_ebc": 372960.00000000006,
            "ep": 393.68,
            "faetp": 1976.8,
            "ap": 1545.6,
            "functional_unit": "kgkm",
            "uid": "Scope 3-Arch Option 1b (GFRP)-GFRP Panel Transportation",
            "eci_cost": 28877.892221023994,
            "pocp": -582.4,
            "odp": 0.0000044072,
            "gwp_100": 367920,
            "tetp": 912.8,
            "adp_fossil": 5000800,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-204",
            "source": "node-67",
            "target": "node-81",
            "erelationship": "USES",
            "maetp": 2862.6,
            "end_date": "",
            "quantity": 300000,
            "htp": 0.042804,
            "adp_elements": 0.7311420000000001,
            "gwp_100_ebc": 6212100,
            "ep": 47.5491,
            "faetp": 47.5491,
            "ap": 9093,
            "functional_unit": "m2",
            "uid": "Scope 3-Arch Option 1b (GFRP)-Fire Liner",
            "eci_cost": 372897.3121453801,
            "pocp": 8769,
            "odp": 0.0025953120053400004,
            "gwp_100": 6212100,
            "tetp": 30627,
            "adp_fossil": 79374000,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-205",
            "source": "node-67",
            "target": "node-82",
            "erelationship": "USES",
            "maetp": 1040760,
            "end_date": "",
            "quantity": 882000000,
            "htp": 1331.82,
            "adp_elements": 0.00680022,
            "gwp_100_ebc": 58741.200000000004,
            "ep": 62.0046,
            "faetp": 311.346,
            "ap": 243.432,
            "functional_unit": "kgkm",
            "uid": "Scope 3-Arch Option 1b (GFRP)-Fire Liner Transportation",
            "eci_cost": 4548.268024811279,
            "pocp": -91.72800000000001,
            "odp": 6.941339999999999e-7,
            "gwp_100": 57947.4,
            "tetp": 143.766,
            "adp_fossil": 787626,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-206",
            "source": "node-67",
            "target": "node-83",
            "erelationship": "USES",
            "maetp": 1093252080,
            "end_date": "",
            "quantity": 724008000000,
            "htp": 1397335.4400000002,
            "adp_elements": 7.1314788,
            "gwp_100_ebc": 61613080.8,
            "ep": 65595.1248,
            "faetp": 327251.61600000004,
            "ap": 257746.848,
            "functional_unit": "kgkm",
            "uid": "Scope 3-Arch Option 1b (GFRP)-Backfill Soil Transportation",
            "eci_cost": 4782047.68050697,
            "pocp": -97017.072,
            "odp": 0.0000073124808000000005,
            "gwp_100": 60744271.2,
            "tetp": 150593.66400000002,
            "adp_fossil": 825369120,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-207",
            "source": "node-66",
            "target": "node-84",
            "erelationship": "USES",
            "maetp": 1093280000,
            "end_date": "",
            "quantity": 160000,
            "htp": 1370048,
            "adp_elements": 0.250944,
            "gwp_100_ebc": 16763599.999999998,
            "ep": 6375.24,
            "faetp": 28084.8,
            "ap": 70425.6,
            "functional_unit": "m2",
            "uid": "Scope 1-Arch Option 1b (GFRP)-Average site impacts",
            "eci_cost": 1433659.9357877297,
            "pocp": 3751.12,
            "odp": 0.0000885123136,
            "gwp_100": 16761600,
            "tetp": 23276,
            "adp_fossil": 183372000,
            "ghg_scope": "Scope 1",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-208",
            "source": "node-68",
            "target": "node-85",
            "erelationship": "USES",
            "maetp": 442028032,
            "end_date": "",
            "quantity": 160000,
            "htp": 552394.112,
            "adp_elements": 0.8603193600000001,
            "gwp_100_ebc": 6458808.32,
            "ep": 2568.85504,
            "faetp": 27745.856,
            "ap": 26731.904000000002,
            "functional_unit": "m2",
            "uid": "Scope 2-Arch Option 1b (GFRP)-Average energy and fuel use in construction site",
            "eci_cost": 552072.7578199354,
            "pocp": 1655.75552,
            "odp": 0.00003196420352,
            "gwp_100": 6287982.08,
            "tetp": 15137.6,
            "adp_fossil": 112356928,
            "ghg_scope": "Scope 2",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-209",
            "source": "node-68",
            "target": "node-86",
            "erelationship": "USES",
            "maetp": 3873440000,
            "end_date": "",
            "quantity": 68800000,
            "htp": 4898560,
            "adp_elements": 5.30448,
            "gwp_100_ebc": 59856000,
            "ep": 22704,
            "faetp": 99760,
            "ap": 251120,
            "functional_unit": "kWh",
            "uid": "Scope 2-Arch Option 1b (GFRP)-Energy use on the site (Electricity Saudi)",
            "eci_cost": 5114588.34000512,
            "pocp": 13347.2,
            "odp": 0.000317168,
            "gwp_100": 59856000,
            "tetp": 77744,
            "adp_fossil": 654288000,
            "start_date": "2022-04-24",
            "ghg_scope": "Scope 2",
            "group": "Energy Mix"
        },
        {
            "id": "edge-210",
            "source": "node-68",
            "target": "node-125",
            "erelationship": "USES",
            "maetp": 1341600000,
            "end_date": "2022-06-30",
            "quantity": 68800000,
            "htp": 0,
            "adp_elements": 0,
            "_updated": "1677771353122",
            "gwp_100_ebc": 6880000,
            "ep": 0,
            "faetp": 0,
            "ap": 0,
            "functional_unit": "kWh",
            "uid": "Scope 2-Arch Option 1b (GFRP)-Alternative Renewable electricity (Solar)",
            "eci_cost": 481870.1650096001,
            "pocp": 0,
            "groupStrategy": "minimize_eci_cost",
            "odp": 0,
            "gwp_100": 6880000,
            "tetp": 0,
            "adp_fossil": 48160000,
            "start_date": "2022-04-24",
            "ghg_scope": "Scope 2",
            "group": "Energy Mix"
        },
        {
            "id": "edge-211",
            "source": "node-68",
            "target": "node-126",
            "erelationship": "USES",
            "maetp": 2607520000,
            "end_date": "2022-06-30",
            "quantity": 68800000,
            "htp": 0,
            "adp_elements": 0,
            "gwp_100_ebc": 34400000,
            "ep": 0,
            "faetp": 0,
            "ap": 0,
            "functional_unit": "kWh",
            "uid": "Scope 2-Arch Option 1b (GFRP)-Alternative Electricity (Saudi Grid +Solar)",
            "eci_cost": 2007783.2022128003,
            "pocp": 0,
            "odp": 0,
            "gwp_100": 34400000,
            "tetp": 0,
            "adp_fossil": 350880000,
            "start_date": "2022-04-24",
            "ghg_scope": "Scope 2",
            "group": "Energy Mix"
        },
        {
            "id": "edge-212",
            "source": "node-66",
            "target": "node-87",
            "erelationship": "USES",
            "maetp": 546840320,
            "end_date": "",
            "quantity": 8320000,
            "htp": 625381.12,
            "adp_elements": 3.2987136,
            "gwp_100_ebc": 4732083.2,
            "ep": 2984.5503999999996,
            "faetp": 177698.56,
            "ap": 16199.039999999999,
            "functional_unit": "l",
            "uid": "Scope 1-Arch Option 1b (GFRP)-Diesel",
            "eci_cost": 406139.23819423374,
            "pocp": 3210.3552,
            "odp": 0.0000024740352,
            "gwp_100": 3023820.8,
            "tetp": 73632,
            "adp_fossil": 469281280,
            "ghg_scope": "Scope 1",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-213",
            "source": "node-67",
            "target": "node-88",
            "erelationship": "USES",
            "maetp": 2464000,
            "end_date": "",
            "quantity": 800000,
            "htp": 601.5999999999999,
            "adp_elements": 0.004224,
            "gwp_100_ebc": 11920,
            "ep": 7.847999999999999,
            "faetp": 48.96,
            "ap": 69.12,
            "functional_unit": "kg",
            "uid": "Scope 3-Arch Option 1b (GFRP)-Waste",
            "eci_cost": 1266.6314522018818,
            "pocp": 5.263999999999999,
            "odp": 6.272e-11,
            "gwp_100": 11520,
            "tetp": 316,
            "adp_fossil": 156000,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-214",
            "source": "node-67",
            "target": "node-89",
            "erelationship": "USES",
            "maetp": 6518820,
            "end_date": "",
            "quantity": 332000000,
            "htp": 7204.4,
            "adp_elements": 0.042994,
            "gwp_100_ebc": 94238.2,
            "ep": 118.52399999999999,
            "faetp": 1533.84,
            "ap": 135.954,
            "functional_unit": "kg",
            "uid": "Scope 3-Arch Option 1b (GFRP)-Maintenance Water",
            "eci_cost": 8265.1853357122,
            "pocp": 12.5496,
            "odp": 4.8804e-7,
            "gwp_100": 102139.8,
            "tetp": 1371.16,
            "adp_fossil": 1220100,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-215",
            "source": "node-67",
            "target": "node-90",
            "erelationship": "USES",
            "maetp": 25763.4,
            "end_date": "",
            "quantity": 2700000,
            "htp": 0.385236,
            "adp_elements": 6.580278000000001,
            "gwp_100_ebc": 55908900,
            "ep": 427.94190000000003,
            "faetp": 427.94190000000003,
            "ap": 81837,
            "functional_unit": "m2",
            "uid": "Scope 3-Arch Option 1b (GFRP)-Fire Liner replacement",
            "eci_cost": 3356075.8093084213,
            "pocp": 78921,
            "odp": 0.02335780804806,
            "gwp_100": 55908900,
            "tetp": 275643,
            "adp_fossil": 714366000,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-216",
            "source": "node-67",
            "target": "node-91",
            "erelationship": "USES",
            "maetp": 9366840,
            "end_date": "",
            "quantity": 7938000000,
            "htp": 11986.38,
            "adp_elements": 0.06120198,
            "gwp_100_ebc": 528670.8,
            "ep": 558.0414,
            "faetp": 2802.114,
            "ap": 2190.888,
            "functional_unit": "kgkm",
            "uid": "Scope 3-Arch Option 1b (GFRP)-Fire Liner Transportation replacement",
            "eci_cost": 40934.41222330151,
            "pocp": -825.552,
            "odp": 0.000006247206,
            "gwp_100": 521526.6,
            "tetp": 1293.894,
            "adp_fossil": 7088634,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-217",
            "source": "node-67",
            "target": "node-93",
            "erelationship": "USES",
            "maetp": 679140000,
            "end_date": "",
            "quantity": 220500000,
            "htp": 165816,
            "adp_elements": 1.16424,
            "gwp_100_ebc": 3285450,
            "ep": 2163.105,
            "faetp": 13494.599999999999,
            "ap": 19051.2,
            "functional_unit": "kg",
            "uid": "Scope 3-Arch Option 1b (GFRP)-Construction concrete waste",
            "eci_cost": 349115.2940131437,
            "pocp": 1450.8899999999999,
            "odp": 1.72872e-8,
            "gwp_100": 3175200,
            "tetp": 87097.5,
            "adp_fossil": 42997500,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-218",
            "source": "node-67",
            "target": "node-94",
            "erelationship": "USES",
            "maetp": 62440000,
            "end_date": "",
            "quantity": 28000000,
            "htp": 110880,
            "adp_elements": 2.576,
            "gwp_100_ebc": 13580000,
            "ep": 2856,
            "faetp": 2940,
            "ap": 14616,
            "functional_unit": "kg",
            "uid": "Scope 3-Arch Option 1b (GFRP)-Waste disposal of GFRP",
            "eci_cost": 1882041.3084604626,
            "pocp": 854,
            "odp": 2.3408e-8,
            "gwp_100": 35560000,
            "tetp": 9968,
            "adp_fossil": 16295999.999999998,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-219",
            "source": "node-67",
            "target": "node-95",
            "erelationship": "USES",
            "maetp": 98343000,
            "end_date": "",
            "quantity": 44100000,
            "htp": 174636,
            "adp_elements": 4.0572,
            "gwp_100_ebc": 21388500,
            "ep": 4498.2,
            "faetp": 4630.5,
            "ap": 23020.2,
            "functional_unit": "kg",
            "uid": "Scope 3-Arch Option 1b (GFRP)-Waste disposal of Fire Liner",
            "eci_cost": 2964215.0608252287,
            "pocp": 1345.05,
            "odp": 3.68676e-8,
            "gwp_100": 56007000,
            "tetp": 15699.599999999999,
            "adp_fossil": 25666200,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-220",
            "source": "node-67",
            "target": "node-96",
            "erelationship": "USES",
            "maetp": -2298800000,
            "end_date": "",
            "quantity": 28000000,
            "htp": -1117200,
            "adp_elements": -7.335999999999999,
            "gwp_100_ebc": -38640000,
            "ep": -6188,
            "faetp": -46760,
            "ap": -45080,
            "functional_unit": "kg",
            "uid": "Scope 3-Arch Option 1b (GFRP)-Energy recovery from incineration of GFRP",
            "eci_cost": -2553390.0128419753,
            "pocp": -4116,
            "odp": -5.992e-7,
            "gwp_100": -38640000,
            "tetp": -43400,
            "adp_fossil": -554400000,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-221",
            "source": "node-67",
            "target": "node-97",
            "erelationship": "USES",
            "maetp": -3620609999.9999995,
            "end_date": "",
            "quantity": 44100000,
            "htp": -1759590,
            "adp_elements": -11.5542,
            "gwp_100_ebc": -60857999.99999999,
            "ep": -9746.1,
            "faetp": -73647,
            "ap": -71001,
            "functional_unit": "kg",
            "uid": "Scope 3-Arch Option 1b (GFRP)-Energy recovery from incineration of Liner",
            "eci_cost": -4021589.270226111,
            "pocp": -6482.7,
            "odp": -9.4374e-7,
            "gwp_100": -60857999.99999999,
            "tetp": -68355,
            "adp_fossil": -873180000,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-222",
            "source": "node-69",
            "target": "node-72",
            "erelationship": "USES",
            "maetp": 69443400,
            "end_date": "",
            "quantity": 3070000,
            "htp": 113743.5,
            "adp_elements": 0.3839342,
            "_updated": "1677771353122",
            "gwp_100_ebc": 4549740,
            "ep": 3675.7110000000002,
            "faetp": 23227.62,
            "ap": 15205.71,
            "functional_unit": "m3",
            "uid": "Scope 1-Arch Option 1c (GFRP)-Excavation",
            "eci_cost": 337503.63387860265,
            "pocp": 1544.517,
            "groupStrategy": "minimize_eci_cost",
            "odp": 7.54299e-10,
            "gwp_100": 4350190,
            "tetp": 9538.49,
            "adp_fossil": 59066799.99999999,
            "start_date": "2022-04-24",
            "ghg_scope": "Scope 1",
            "group": "Tunneling Process"
        },
        {
            "id": "edge-223",
            "source": "node-70",
            "target": "node-73",
            "erelationship": "USES",
            "maetp": 1441702700,
            "end_date": "",
            "quantity": 954770000000,
            "htp": 1842706.1,
            "adp_elements": 9.4044845,
            "gwp_100_ebc": 81250927,
            "ep": 86502.162,
            "faetp": 431556.04000000004,
            "ap": 339898.12,
            "functional_unit": "kgkm",
            "uid": "Scope 3-Arch Option 1c (GFRP)-Excavated Soil Transportation",
            "eci_cost": 6306222.6714589335,
            "pocp": -127939.18000000001,
            "odp": 0.000009643177,
            "gwp_100": 80105203,
            "tetp": 198592.16,
            "adp_fossil": 1088437800,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-224",
            "source": "node-70",
            "target": "node-92",
            "erelationship": "USES",
            "maetp": 961968000.0000001,
            "end_date": "",
            "quantity": 196000000,
            "htp": 3904320,
            "adp_elements": 47.275200000000005,
            "gwp_100_ebc": 29164799.999999996,
            "ep": 6491.52,
            "faetp": 36926.4,
            "ap": 42100.799999999996,
            "functional_unit": "kg",
            "uid": "Scope 3-Arch Option 1c (GFRP)-Base Concrete",
            "eci_cost": 2154689.7930626883,
            "pocp": 4398.24,
            "odp": 0.0000331632,
            "gwp_100": 29164799.999999996,
            "tetp": 68913.59999999999,
            "adp_fossil": 103723200,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-225",
            "source": "node-70",
            "target": "node-74",
            "erelationship": "USES",
            "maetp": 88788000,
            "end_date": "",
            "quantity": 39200000000,
            "htp": 113483.99999999999,
            "adp_elements": 0.5791799999999999,
            "gwp_100_ebc": 5003880.000000001,
            "ep": 5327.28,
            "faetp": 26577.600000000002,
            "ap": 20932.8,
            "functional_unit": "kgkm",
            "uid": "Scope 3-Arch Option 1c (GFRP)-Concrete Transportation",
            "eci_cost": 388371.97448112,
            "pocp": -7879.200000000001,
            "odp": 0.00059388,
            "gwp_100": 4933320,
            "tetp": 12230.4,
            "adp_fossil": 67032000,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-226",
            "source": "node-70",
            "target": "node-75",
            "erelationship": "USES",
            "maetp": 10795200,
            "end_date": "",
            "quantity": 62400000,
            "htp": 7176,
            "adp_elements": 0.0406224,
            "gwp_100_ebc": 144144,
            "ep": 71.13600000000001,
            "faetp": 575.328,
            "ap": 364.416,
            "functional_unit": "kg",
            "uid": "Scope 3-Arch Option 1c (GFRP)-Gravel",
            "eci_cost": 11109.270753547202,
            "pocp": -38.563199999999995,
            "odp": 0.0000013540800000000002,
            "gwp_100": 142896,
            "tetp": 1092,
            "adp_fossil": 1759680,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-227",
            "source": "node-70",
            "target": "node-76",
            "erelationship": "USES",
            "maetp": 18844800,
            "end_date": "",
            "quantity": 12480000000,
            "htp": 24086.4,
            "adp_elements": 0.12292800000000001,
            "gwp_100_ebc": 1062048,
            "ep": 1130.688,
            "faetp": 5640.96,
            "ap": 4442.88,
            "functional_unit": "kgkm",
            "uid": "Scope 3-Arch Option 1c (GFRP)-Gravel Transportation",
            "eci_cost": 82429.96631629344,
            "pocp": -1672.3200000000002,
            "odp": 1.26048e-7,
            "gwp_100": 1047072.0000000001,
            "tetp": 2595.84,
            "adp_fossil": 14227200,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-228",
            "source": "node-70",
            "target": "node-77",
            "erelationship": "USES",
            "maetp": 3077836800,
            "end_date": "",
            "quantity": 18640000,
            "htp": 3053791.2,
            "adp_elements": 824.7640799999999,
            "gwp_100_ebc": 74781816,
            "ep": 17048.330400000003,
            "faetp": 500148.48000000004,
            "ap": 177696.984,
            "functional_unit": "kg",
            "uid": "Scope 3-Arch Option 1c (GFRP)-GFRP Panels",
            "eci_cost": 5418999.856877016,
            "pocp": 29576.088,
            "odp": 0.0190681608,
            "gwp_100": 75022272,
            "tetp": 222902.712,
            "adp_fossil": 1731283200,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-229",
            "source": "node-70",
            "target": "node-78",
            "erelationship": "USES",
            "maetp": 4399040,
            "end_date": "",
            "quantity": 3728000000,
            "htp": 5629.28,
            "adp_elements": 0.028742880000000002,
            "gwp_100_ebc": 248284.80000000002,
            "ep": 262.0784,
            "faetp": 1315.984,
            "ap": 1028.9279999999999,
            "functional_unit": "kgkm",
            "uid": "Scope 3-Arch Option 1c (GFRP)-GFRP Panel Transportation",
            "eci_cost": 19224.425392853114,
            "pocp": -387.71200000000005,
            "odp": 0.0000029339359999999997,
            "gwp_100": 244929.6,
            "tetp": 607.664,
            "adp_fossil": 3329104,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-230",
            "source": "node-70",
            "target": "node-79",
            "erelationship": "USES",
            "maetp": 46574.341632,
            "end_date": "",
            "quantity": 282.0636,
            "htp": 46.210479588000005,
            "adp_elements": 0.0124804681092,
            "gwp_100_ebc": 1131.61095684,
            "ep": 0.257978189196,
            "faetp": 7.5683305152,
            "ap": 2.6889405051599997,
            "functional_unit": "kg",
            "uid": "Scope 3-Arch Option 1c (GFRP)-GFRP Tension Bars",
            "eci_cost": 82.0012128771575,
            "pocp": 0.44755031412,
            "odp": 2.8854260089200004e-7,
            "gwp_100": 1135.24957728,
            "tetp": 3.37300114788,
            "adp_fossil": 26198.067167999998,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-231",
            "source": "node-70",
            "target": "node-80",
            "erelationship": "USES",
            "maetp": 85.1832072,
            "end_date": "",
            "quantity": 56412.72,
            "htp": 0.10887654960000001,
            "adp_elements": 5.55665292e-7,
            "gwp_100_ebc": 4.8007224719999995,
            "ep": 0.005110992432,
            "faetp": 0.02549854944,
            "ap": 0.020082928320000002,
            "functional_unit": "kgkm",
            "uid": "Scope 3-Arch Option 1c (GFRP)-GFRP Tension Bars Transport",
            "eci_cost": 0.3726040552412254,
            "pocp": -0.007559304480000001,
            "odp": 5.69768472e-13,
            "gwp_100": 4.733027208,
            "tetp": 0.01173384576,
            "adp_fossil": 64.3105008,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-232",
            "source": "node-70",
            "target": "node-81",
            "erelationship": "USES",
            "maetp": 2900.768,
            "end_date": "",
            "quantity": 304000,
            "htp": 0.04337472,
            "adp_elements": 0.7408905600000001,
            "gwp_100_ebc": 6294928,
            "ep": 48.183088000000005,
            "faetp": 48.183088000000005,
            "ap": 9214.24,
            "functional_unit": "m2",
            "uid": "Scope 3-Arch Option 1c (GFRP)-Fire Liner",
            "eci_cost": 377869.27630731853,
            "pocp": 8885.92,
            "odp": 0.0026299161654112003,
            "gwp_100": 6294928,
            "tetp": 31035.36,
            "adp_fossil": 80432320,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-233",
            "source": "node-70",
            "target": "node-82",
            "erelationship": "USES",
            "maetp": 1054636.8,
            "end_date": "",
            "quantity": 893760000,
            "htp": 1349.5775999999998,
            "adp_elements": 0.0068908896,
            "gwp_100_ebc": 59524.416000000005,
            "ep": 62.831328,
            "faetp": 315.49728,
            "ap": 246.67775999999998,
            "functional_unit": "kgkm",
            "uid": "Scope 3-Arch Option 1c (GFRP)-Fire Liner Transportation",
            "eci_cost": 4608.911598475429,
            "pocp": -92.95104,
            "odp": 7.033891199999999e-7,
            "gwp_100": 58720.032,
            "tetp": 145.68287999999998,
            "adp_fossil": 798127.68,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-234",
            "source": "node-70",
            "target": "node-83",
            "erelationship": "USES",
            "maetp": 962700500,
            "end_date": "",
            "quantity": 637550000000,
            "htp": 1230471.5,
            "adp_elements": 6.2798675,
            "gwp_100_ebc": 54255505,
            "ep": 57762.030000000006,
            "faetp": 288172.60000000003,
            "ap": 226967.80000000002,
            "functional_unit": "kgkm",
            "uid": "Scope 3-Arch Option 1c (GFRP)-Backfill Soil Transportation",
            "eci_cost": 4210995.594948147,
            "pocp": -85431.70000000001,
            "odp": 0.000006439255,
            "gwp_100": 53490445.00000001,
            "tetp": 132610.4,
            "adp_fossil": 726807000,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-235",
            "source": "node-69",
            "target": "node-84",
            "erelationship": "USES",
            "maetp": 888290000,
            "end_date": "",
            "quantity": 130000,
            "htp": 1113164,
            "adp_elements": 0.20389200000000002,
            "gwp_100_ebc": 13620425,
            "ep": 5179.8825,
            "faetp": 22818.899999999998,
            "ap": 57220.8,
            "functional_unit": "m2",
            "uid": "Scope 1-Arch Option 1c (GFRP)-Average site impacts",
            "eci_cost": 1164848.6978275303,
            "pocp": 3047.785,
            "odp": 0.0000719162548,
            "gwp_100": 13618800,
            "tetp": 18911.75,
            "adp_fossil": 148989750,
            "ghg_scope": "Scope 1",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-236",
            "source": "node-71",
            "target": "node-85",
            "erelationship": "USES",
            "maetp": 359147776,
            "end_date": "",
            "quantity": 130000,
            "htp": 448820.216,
            "adp_elements": 0.6990094800000001,
            "gwp_100_ebc": 5247781.760000001,
            "ep": 2087.19472,
            "faetp": 22543.508,
            "ap": 21719.672000000002,
            "functional_unit": "m2",
            "uid": "Scope 2-Arch Option 1c (GFRP)-Average energy and fuel use in construction site",
            "eci_cost": 448559.11572869756,
            "pocp": 1345.30136,
            "odp": 0.00002597091536,
            "gwp_100": 5108985.44,
            "tetp": 12299.3,
            "adp_fossil": 91290004,
            "ghg_scope": "Scope 2",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-237",
            "source": "node-71",
            "target": "node-86",
            "erelationship": "USES",
            "maetp": 3147170000,
            "end_date": "",
            "quantity": 55900000,
            "htp": 3980080,
            "adp_elements": 4.309889999999999,
            "gwp_100_ebc": 48633000,
            "ep": 18447,
            "faetp": 81055,
            "ap": 204035,
            "functional_unit": "kWh",
            "uid": "Scope 2-Arch Option 1c (GFRP)-Energy use on the site (Electricity Saudi)",
            "eci_cost": 4155603.02625416,
            "pocp": 10844.6,
            "odp": 0.000257699,
            "gwp_100": 48633000,
            "tetp": 63166.99999999999,
            "adp_fossil": 531609000,
            "start_date": "2022-04-24",
            "ghg_scope": "Scope 2",
            "group": "Energy Mix"
        },
        {
            "id": "edge-238",
            "source": "node-71",
            "target": "node-125",
            "erelationship": "USES",
            "maetp": 1090050000,
            "end_date": "2022-06-30",
            "quantity": 55900000,
            "htp": 0,
            "adp_elements": 0,
            "_updated": "1677771353122",
            "gwp_100_ebc": 5590000,
            "ep": 0,
            "faetp": 0,
            "ap": 0,
            "functional_unit": "kWh",
            "uid": "Scope 2-Arch Option 1c (GFRP)-Alternative Renewable electricity (Solar)",
            "eci_cost": 391519.5090703001,
            "pocp": 0,
            "groupStrategy": "minimize_eci_cost",
            "odp": 0,
            "gwp_100": 5590000,
            "tetp": 0,
            "adp_fossil": 39130000,
            "start_date": "2022-04-24",
            "ghg_scope": "Scope 2",
            "group": "Energy Mix"
        },
        {
            "id": "edge-239",
            "source": "node-71",
            "target": "node-126",
            "erelationship": "USES",
            "maetp": 2118610000,
            "end_date": "2022-06-30",
            "quantity": 55900000,
            "htp": 0,
            "adp_elements": 0,
            "gwp_100_ebc": 27950000,
            "ep": 0,
            "faetp": 0,
            "ap": 0,
            "functional_unit": "kWh",
            "uid": "Scope 2-Arch Option 1c (GFRP)-Alternative Electricity (Saudi Grid +Solar)",
            "eci_cost": 1631323.8517979002,
            "pocp": 0,
            "odp": 0,
            "gwp_100": 27950000,
            "tetp": 0,
            "adp_fossil": 285090000,
            "start_date": "2022-04-24",
            "ghg_scope": "Scope 2",
            "group": "Energy Mix"
        },
        {
            "id": "edge-240",
            "source": "node-69",
            "target": "node-87",
            "erelationship": "USES",
            "maetp": 444307760,
            "end_date": "",
            "quantity": 6760000,
            "htp": 508122.16,
            "adp_elements": 2.6802048000000003,
            "gwp_100_ebc": 3844817.6,
            "ep": 2424.9472,
            "faetp": 144380.08,
            "ap": 13161.72,
            "functional_unit": "l",
            "uid": "Scope 1-Arch Option 1c (GFRP)-Diesel",
            "eci_cost": 329988.1310328149,
            "pocp": 2608.4136000000003,
            "odp": 0.0000020101536,
            "gwp_100": 2456854.4,
            "tetp": 59826,
            "adp_fossil": 381291040,
            "ghg_scope": "Scope 1",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-241",
            "source": "node-70",
            "target": "node-88",
            "erelationship": "USES",
            "maetp": 2002000,
            "end_date": "",
            "quantity": 650000,
            "htp": 488.79999999999995,
            "adp_elements": 0.003432,
            "gwp_100_ebc": 9685,
            "ep": 6.376499999999999,
            "faetp": 39.78,
            "ap": 56.16,
            "functional_unit": "kg",
            "uid": "Scope 3-Arch Option 1c (GFRP)-Waste",
            "eci_cost": 1029.138054914029,
            "pocp": 4.277,
            "odp": 5.0960000000000004e-11,
            "gwp_100": 9360,
            "tetp": 256.75,
            "adp_fossil": 126750,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-242",
            "source": "node-70",
            "target": "node-89",
            "erelationship": "USES",
            "maetp": 6518820,
            "end_date": "",
            "quantity": 332000000,
            "htp": 7204.4,
            "adp_elements": 0.042994,
            "gwp_100_ebc": 94238.2,
            "ep": 118.52399999999999,
            "faetp": 1533.84,
            "ap": 135.954,
            "functional_unit": "kg",
            "uid": "Scope 3-Arch Option 1c (GFRP)-Maintenance Water",
            "eci_cost": 8265.1853357122,
            "pocp": 12.5496,
            "odp": 4.8804e-7,
            "gwp_100": 102139.8,
            "tetp": 1371.16,
            "adp_fossil": 1220100,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-243",
            "source": "node-70",
            "target": "node-90",
            "erelationship": "USES",
            "maetp": 26106.912,
            "end_date": "",
            "quantity": 2736000,
            "htp": 0.39037248,
            "adp_elements": 6.66801504,
            "gwp_100_ebc": 56654352,
            "ep": 433.64779200000004,
            "faetp": 433.64779200000004,
            "ap": 82928.16,
            "functional_unit": "m2",
            "uid": "Scope 3-Arch Option 1c (GFRP)-Fire Liner replacement",
            "eci_cost": 3400823.486765867,
            "pocp": 79973.28,
            "odp": 0.0236692454887008,
            "gwp_100": 56654352,
            "tetp": 279318.24,
            "adp_fossil": 723890880,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-244",
            "source": "node-70",
            "target": "node-91",
            "erelationship": "USES",
            "maetp": 9491731.200000001,
            "end_date": "",
            "quantity": 8043840000,
            "htp": 12146.1984,
            "adp_elements": 0.0620180064,
            "gwp_100_ebc": 535719.7440000001,
            "ep": 565.481952,
            "faetp": 2839.47552,
            "ap": 2220.09984,
            "functional_unit": "kgkm",
            "uid": "Scope 3-Arch Option 1c (GFRP)-Fire Liner Transportation replacement",
            "eci_cost": 41480.20438627886,
            "pocp": -836.5593600000001,
            "odp": 0.00000633050208,
            "gwp_100": 528480.288,
            "tetp": 1311.14592,
            "adp_fossil": 7183149.12,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-245",
            "source": "node-70",
            "target": "node-93",
            "erelationship": "USES",
            "maetp": 603680000,
            "end_date": "",
            "quantity": 196000000,
            "htp": 147392,
            "adp_elements": 1.03488,
            "gwp_100_ebc": 2920400,
            "ep": 1922.7599999999998,
            "faetp": 11995.199999999999,
            "ap": 16934.4,
            "functional_unit": "kg",
            "uid": "Scope 3-Arch Option 1c (GFRP)-Construction concrete waste",
            "eci_cost": 310324.70578946103,
            "pocp": 1289.6799999999998,
            "odp": 1.5366400000000002e-8,
            "gwp_100": 2822400,
            "tetp": 77420,
            "adp_fossil": 38220000,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-246",
            "source": "node-70",
            "target": "node-94",
            "erelationship": "USES",
            "maetp": 41567200,
            "end_date": "",
            "quantity": 18640000,
            "htp": 73814.4,
            "adp_elements": 1.71488,
            "gwp_100_ebc": 9040400,
            "ep": 1901.28,
            "faetp": 1957.2,
            "ap": 9730.08,
            "functional_unit": "kg",
            "uid": "Scope 3-Arch Option 1c (GFRP)-Waste disposal of GFRP",
            "eci_cost": 1252901.7853465367,
            "pocp": 568.52,
            "odp": 1.558304e-8,
            "gwp_100": 23672800,
            "tetp": 6635.839999999999,
            "adp_fossil": 10848480,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-247",
            "source": "node-70",
            "target": "node-95",
            "erelationship": "USES",
            "maetp": 99654240,
            "end_date": "",
            "quantity": 44688000,
            "htp": 176964.48,
            "adp_elements": 4.111296,
            "gwp_100_ebc": 21673680,
            "ep": 4558.176,
            "faetp": 4692.24,
            "ap": 23327.136,
            "functional_unit": "kg",
            "uid": "Scope 3-Arch Option 1c (GFRP)-Waste disposal of Fire Liner",
            "eci_cost": 3003737.9283028985,
            "pocp": 1362.984,
            "odp": 3.7359168e-8,
            "gwp_100": 56753760,
            "tetp": 15908.928,
            "adp_fossil": 26008416,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-248",
            "source": "node-70",
            "target": "node-96",
            "erelationship": "USES",
            "maetp": -1530344000,
            "end_date": "",
            "quantity": 18640000,
            "htp": -743736,
            "adp_elements": -4.88368,
            "gwp_100_ebc": -25723199.999999996,
            "ep": -4119.4400000000005,
            "faetp": -31128.8,
            "ap": -30010.4,
            "functional_unit": "kg",
            "uid": "Scope 3-Arch Option 1c (GFRP)-Energy recovery from incineration of GFRP",
            "eci_cost": -1699828.2085490865,
            "pocp": -2740.08,
            "odp": -3.98896e-7,
            "gwp_100": -25723199.999999996,
            "tetp": -28892,
            "adp_fossil": -369072000,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-249",
            "source": "node-70",
            "target": "node-97",
            "erelationship": "USES",
            "maetp": -3668884799.9999995,
            "end_date": "",
            "quantity": 44688000,
            "htp": -1783051.2,
            "adp_elements": -11.708256,
            "gwp_100_ebc": -61669439.99999999,
            "ep": -9876.048,
            "faetp": -74628.96,
            "ap": -71947.68000000001,
            "functional_unit": "kg",
            "uid": "Scope 3-Arch Option 1c (GFRP)-Energy recovery from incineration of Liner",
            "eci_cost": -4075210.460495793,
            "pocp": -6569.1359999999995,
            "odp": -9.563232e-7,
            "gwp_100": -61669439.99999999,
            "tetp": -69266.4,
            "adp_fossil": -884822400,
            "ghg_scope": "Scope 3",
            "start_date": "",
            "group": ""
        },
        {
            "id": "edge-250",
            "source": "node-77",
            "target": "node-98",
            "erelationship": "HAS_PART",
            "functional_unit": "kg",
            "uid": "GFRP Panels-Glass fiber ",
            "quantity": 0.5085
        },
        {
            "id": "edge-251",
            "source": "node-77",
            "target": "node-99",
            "erelationship": "HAS_PART",
            "functional_unit": "kg",
            "uid": "GFRP Panels-UP Resin",
            "quantity": 0.5782
        },
        {
            "id": "edge-252",
            "source": "node-77",
            "target": "node-100",
            "erelationship": "HAS_PART",
            "functional_unit": "kg",
            "uid": "GFRP Panels-UPR Based Gelcoat",
            "quantity": 0.0575
        },
        {
            "id": "edge-253",
            "source": "node-77",
            "target": "node-101",
            "erelationship": "HAS_PART",
            "functional_unit": "kg",
            "uid": "GFRP Panels-Catalyst",
            "quantity": 0.01
        },
        {
            "id": "edge-254",
            "source": "node-77",
            "target": "node-102",
            "erelationship": "HAS_PART",
            "functional_unit": "kg",
            "uid": "GFRP Panels-Carbon Steel",
            "quantity": 0.01193634
        },
        {
            "id": "edge-255",
            "source": "node-77",
            "target": "node-103",
            "erelationship": "HAS_PART",
            "functional_unit": "kg",
            "uid": "GFRP Panels-Acetone",
            "quantity": 0.000066313
        },
        {
            "id": "edge-256",
            "source": "node-77",
            "target": "node-104",
            "erelationship": "HAS_PART",
            "functional_unit": "kgkm",
            "uid": "GFRP Panels-Ship transport",
            "quantity": 7447.295736
        },
        {
            "id": "edge-257",
            "source": "node-77",
            "target": "node-105",
            "erelationship": "HAS_PART",
            "functional_unit": "kgkm",
            "uid": "GFRP Panels-Truck transport",
            "quantity": 1644.256631
        },
        {
            "id": "edge-258",
            "source": "node-77",
            "target": "node-106",
            "erelationship": "HAS_PART",
            "functional_unit": "kWh",
            "uid": "GFRP Panels-Processing (Vaccum Infusion)",
            "quantity": 0.002262599
        },
        {
            "id": "edge-259",
            "source": "node-77",
            "target": "node-107",
            "erelationship": "HAS_PART",
            "functional_unit": "Nm3",
            "uid": "GFRP Panels-Compressed air",
            "quantity": 0.01201776
        },
        {
            "id": "edge-260",
            "source": "node-77",
            "target": "node-108",
            "erelationship": "HAS_PART",
            "functional_unit": "hour",
            "uid": "GFRP Panels-Crane operation",
            "quantity": 0.000176835
        },
        {
            "id": "edge-261",
            "source": "node-77",
            "target": "node-109",
            "erelationship": "HAS_PART",
            "functional_unit": "number",
            "uid": "GFRP Panels-Mould",
            "quantity": 3.97878e-7
        },
        {
            "id": "edge-262",
            "source": "node-99",
            "target": "node-127",
            "erelationship": "HAS_PART",
            "uid": "UP Resin-Cobalt Octate"
        },
        {
            "id": "edge-263",
            "source": "node-99",
            "target": "node-128",
            "erelationship": "HAS_PART",
            "uid": "UP Resin-Antimony trioxide"
        },
        {
            "id": "edge-264",
            "source": "node-99",
            "target": "node-129",
            "erelationship": "HAS_PART",
            "uid": "UP Resin-Styrene"
        },
        {
            "id": "edge-265",
            "source": "node-99",
            "target": "node-130",
            "erelationship": "HAS_PART",
            "uid": "UP Resin-Maleic anhydride"
        },
        {
            "id": "edge-266",
            "source": "node-99",
            "target": "node-131",
            "erelationship": "HAS_PART",
            "uid": "UP Resin-Neopentyl glycol"
        },
        {
            "id": "edge-267",
            "source": "node-100",
            "target": "node-129",
            "erelationship": "HAS_PART",
            "uid": "UPR Based Gelcoat-Styrene"
        },
        {
            "id": "edge-268",
            "source": "node-100",
            "target": "node-130",
            "erelationship": "HAS_PART",
            "uid": "UPR Based Gelcoat-Maleic anhydride"
        },
        {
            "id": "edge-269",
            "source": "node-100",
            "target": "node-132",
            "erelationship": "HAS_PART",
            "uid": "UPR Based Gelcoat-Cobalt(II) 2-ethylhexanoate"
        },
        {
            "id": "edge-270",
            "source": "node-100",
            "target": "node-133",
            "erelationship": "HAS_PART",
            "uid": "UPR Based Gelcoat-Fumed Silicia"
        },
        {
            "id": "edge-271",
            "source": "node-101",
            "target": "node-134",
            "erelationship": "HAS_PART",
            "uid": "Catalyst-Water"
        },
        {
            "id": "edge-272",
            "source": "node-101",
            "target": "node-135",
            "erelationship": "HAS_PART",
            "uid": "Catalyst-Methyl Ethyl Ketone"
        },
        {
            "id": "edge-273",
            "source": "node-101",
            "target": "node-136",
            "erelationship": "HAS_PART",
            "uid": "Catalyst-Ketone Peroxide"
        },
        {
            "id": "edge-274",
            "source": "node-101",
            "target": "node-137",
            "erelationship": "HAS_PART",
            "uid": "Catalyst-Dimethyl Phthalate"
        },
        {
            "id": "edge-275",
            "source": "node-101",
            "target": "node-138",
            "erelationship": "HAS_PART",
            "uid": "Catalyst-Hydrogen Peroxide"
        },
        {
            "id": "edge-276",
            "source": "node-101",
            "target": "node-139",
            "erelationship": "HAS_PART",
            "uid": "Catalyst-diisobutyrate"
        }
    ]
}