

import React, { Component } from "react";
import { Graph } from "react-d3-graph";
import dataInJson from "./../data1";
import FunctionBar from "./functionbar";
import SidePanelcomponent from "./Sidebar/sidePanel";
import NavbarCanvas from "./navbar_canvas";
import axios from 'axios';
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';


import {
  Backdrop,
  CircularProgress
} from '@mui/material'
import { Add, CenterFocusWeak, OpenInFull, Remove, ZoomIn, ZoomOut } from "@mui/icons-material";

import { on } from "fusioncharts";
import Sidebar from "./Sidebar/Sidebar";
const Base_URL = process.env.REACT_APP_BACKEND_URL;


class MainCanvas extends Component {
  previousState = false;
  graphRef = React.createRef();
  childRef = React.createRef();


  constructor(props) {
    super(props);
    this.state = {
      StartRender: false,
      graphData: dataInJson,
      canvasJson: {},
      canvasImages: {},
      canvasConfig: {},
      canvasCustom: {},
      dashboardTabConfigJson: {},
      apiGraphData: null,
      apiConfig: null,
      preprocessingFinished: false,
      selectedMessage: null,
      sidePanelVisible: false,
      functionBarData: null,
      hoveredNode: null
    };
    this.hoveredNode = null;
    this.preprocessor();
    this.handleNodePositionChange = this.handleNodePositionChange.bind(this);
    this.handdledragstart = this.handdledragstart.bind(this);
    this.handleRightClick = this.handleRightClick.bind(this);
    this.onClickNode = this.onClickNode.bind(this)
    this.onClickZoomIn = this.onClickZoomIn.bind(this)
    this.onClickZoomOut = this.onClickZoomOut.bind(this)
    this.onClickZoomAdjust = this.onClickZoomAdjust.bind(this)
    this.getChildNodes = this.getChildNodes.bind(this)
    this.getLink = this.getLink.bind(this)
    this.getRootNodesUid = this.getRootNodesUid.bind(this)
    this.handleDialogSubmit = this.handleDialogSubmit.bind(this)
    this.onMouseOverNode = this.onMouseOverNode.bind(this)
  }
  handleDialogSubmit = (selectedMessage) => {
    console.log("Selected Message from Alternative=>Maincanvas:", selectedMessage);
    this.setState({ selectedMessage });

  };
  componentDidMount() {
    this.setState({
      StartRender: true,
      // hoveredNode: null
    });
  }


  shouldComponentUpdate(nextProps, nextState) {
    // Check if specific state properties are different from their previous values
    const preprocessingChanged = this.state.preprocessingFinished !== nextState.preprocessingFinished;
    const selectedMessageChanged = this.state.selectedMessage !== nextState.selectedMessage;
    const previousStateChanged = this.previousState !== nextState.previousState;
    const expandedGraphChanged = this.state.apiConfig?.expandedGraph !== nextState.apiConfig?.expandedGraph;

    // Only update if any of the relevant properties have changed


    return preprocessingChanged || selectedMessageChanged || previousStateChanged || expandedGraphChanged;
  }

  componentDidUpdate() {
    if (this.state.preprocessingFinished) {
      this.graphRef.current.adjustPanAndZoom();

      if (
        this.state.selectedMessage ||
        (!this.state.selectedMessage && this.previousState === true) ||
        (this.state.selectedMessage && this.previousState === true)
      ) {
        this.adjustCanvas(true);
        this.previousState = true;
      } else {
        this.adjustCanvas(false);
        this.previousState = false;
      }
    }

    if (this.state.apiConfig?.expandedGraph) {
      const timer = setTimeout(() => {
        this.handleRightClick();
      }, 100);
    }
  }
  // componentDidUpdate() {
  //   if (this.state.preprocessingFinished) {
  //     this.graphRef.current.adjustPanAndZoom();



  //     if (this.state.selectedMessage || (!this.state.selectedMessage && this.previousState === true) || (this.state.selectedMessage && this.previousState === true)) {
  //       this.adjustCanvas(true)
  //       this.previousState = true
  //     }
  //     else {
  //       this.adjustCanvas(false)
  //       this.previousState = false
  //     }
  //   }

  //   if (this.state.apiConfig?.expandedGraph) {
  //     const timer = setTimeout(() => {
  //       this.handleRightClick()
  //     }, 100)
  //   }
  // }


  handleNodePositionChange(nodeId, x, y) {
    console.log(`Node ${nodeId} position changed to (${x}, ${y})`);

  }
  handdledragstart() {
    this.graphRef.current._ConvertRadialToTreeGraph();
  }

  handleRightClick() {
    this.graphRef.current._ConvertRadialToTreeGraph();

  }

  onClickZoomIn() {
    this.graphRef.current._ZoomIn();
  }

  onClickZoomOut() {
    this.graphRef.current._ZoomOut();
  }

  onClickZoomAdjust() {
    this.graphRef.current.adjustPanAndZoom();
  }

  onClickNode = async (nodeId, node) => {
    let childNodeArray = [];
    console.log("value", this.graphRef.current);
    var childNodes = this.graphRef.current._getSuccessorNodes(nodeId);
    const nodeDetails = await this.graphRef.current.calculateNodeInfo(nodeId);
    console.log("value1", childNodes);

    await Promise.all(childNodes.map(async (childNode) => {
      let deatilsFetched = await this.graphRef.current.calculateNodeInfo(childNode.id);
      console.log("deatilsFetched", deatilsFetched);
      childNodeArray.push(deatilsFetched);
      console.log("childNodeArray", childNodeArray);
    }

    ));


    console.log("nodeDetails", nodeDetails);
    this.childRef.current.changeState(nodeDetails, childNodeArray);
    // this.setState({ hoveredNode: this.state.apiData.nodes.find(node => node.id === nodeId) });
  };

  onMouseOverNode = (nodeId) => {
    // this.hoveredNode = this.state.apiData.nodes.find(node => node.id === nodeId);
    // this.setState({ hoveredNode: this.state.apiData.nodes.find(node => node.id === nodeId) });

    // console.log(this.hoveredNode);
    // You can pass this.hoveredNode as props to another component
  };

  // onMouseOverNode = (nodeId) => {
  //   this.setState({ hoveredNode: this.state.apiData.nodes.find(node => node.id === nodeId) });

  // };

  // onMouseOutNode = () => {
  //   this.setState({ hoveredNode: null });
  // };

  //  neo4jConfig = {
  //   username: "neo4j",
  //   serverUrl: "neo4j+s://55f36338.databases.neo4j.io",
  //   password: "qNtMrB0q5047e6GYsdcp29CyfnusuNWB4TtiEbftf58",
  // };

  //  hoveredNode = null;
  // tunnel-v2
  //  neo4jConfig = {
  //   username: "neo4j",
  //   serverUrl: "neo4j+s://173a394e.databases.neo4j.io",
  //   password: "zWDnuu5xT6OkeHPOZgRg4hgMebj0Z0xEu1oKkbruXbU",
  // };

  onClickLink = async (source, target) => {
    let linkInfo = this.graphRef.current._getlinkInfo(source, target);
    this.childRef.current.changeLinkState(linkInfo);
  }

  getRootNodesUid = async () => {
    let rootnodeNames = [];
    let rootNodes = this.graphRef.current._getRootNodes();
    for (const node of rootNodes) {
      let rootNodeDetails = await this.graphRef.current.calculateNodeInfo(node);
      rootnodeNames.push(rootNodeDetails);
    }
    return rootnodeNames;
  }

  getNodeDetailsForTab = (nodeId) => {
    return this.graphRef.current.calculateNodeInfo(nodeId);
  };

  adjustCanvas = (shouldDisplayDiv) => {
    let canvasConfig = this.state.apiConfig;
    if (!shouldDisplayDiv) {
      canvasConfig.width = window.innerWidth;
      this.graphRef.current.adjustCanvas(true)
      this.previousState = false
    }
    else {
      canvasConfig.width = window.innerWidth;
      this.graphRef.current.adjustCanvas(false)
      this.previousState = true
    }
  }
  getChildNodes = (nodeId) => {
    return (this.graphRef.current._getSuccessorNodes(nodeId));
  }

  getLink = (source, target) => {
    return (this.graphRef.current._getlinkInfo(source, target));
  }

  set_SelectedNode = (value) => {
    if (value?.source) {
      this.graphRef.current.onMouseOverLink(value.source, value.target);
    }
    else {
      this.graphRef.current.onMouseOverNode(value.id);
      this.graphRef.current.state.nodes[value.id].searchedNode = true;
      this.graphRef.current._stateRefresh();
    }
  };

  unset_SelectedNode = (value) => {
    if (value.source) {
      this.graphRef.current.onMouseOutLink(value.source, value.target);
    }
    else {
      this.graphRef.current.onMouseOutNode(value.id);
    }

  };



  preprocessor = async () => {
    const config = {
      headers: {
        'content-type': 'application/json',
        'authorization': 'bearer ' + sessionStorage.getItem('token')
      }
    };


    const response = await axios.get(Base_URL + '/projects/' + sessionStorage.getItem('tenanatId') + '/' + sessionStorage.getItem("project_id"), config);
    let apiConfig, apiData, apiImages, apiCustomJSON;
    apiData = response.data.data.D3Data;
    apiImages = response.data.data.node_image_json;
    apiConfig = response.data.data.graph_config_json;
    apiCustomJSON = response.data.data.custom_config_json;


    this.setState({
      canvasJson: apiData,
      canvasImages: apiImages,
      canvasConfig: apiConfig,
      canvasCustom: apiCustomJSON,
      dashboardTabConfigJson: response.data.data.dashboard_tab_config_json,
      functionBarData: response.data.data?.navbar_config || null
    })



    apiConfig.height = window.innerHeight - 110
    apiConfig.width = (window.innerWidth) - 50
    apiConfig.node = { ...apiConfig.node, ...apiCustomJSON.graph_config.nodes }
    apiConfig.link = { ...apiConfig.link, ...apiCustomJSON.graph_config.links }


    const rootNodesCount = apiData.nodes.reduce((count, node) => {
      if (node.rootNodeLabel === true) {
        count++;
      }
      return count;
    }, 0)

    let rootLableProcessCount = 1;
    const customConfigNodekeys = Object.keys(apiCustomJSON.node);
    apiData.nodes = apiData.nodes.map((node) => {

      if (node.rootNodeLabel === true) {
        node.fx = ((window.innerWidth * 3) / 4) * (rootLableProcessCount / (rootNodesCount + 1))
        node.fy = window.innerHeight / 2
        rootLableProcessCount = rootLableProcessCount + 1;
      }

      if (apiImages[node.uid]) {
        if (apiImages[node.uid].startsWith('http')) {
          node.svg = apiImages[node.uid];
        } else {
          node.svg = process.env.REACT_APP_BACKEND_URL + "/" + apiImages[node.uid];
        }
      }
      else {
        node.svg = ''
      }


      if (customConfigNodekeys.includes(node.labels)) {
        node = { ...node, ...apiCustomJSON.node[node.labels] }
      }
      return node;
    });


    this.setState({
      apiConfig: apiConfig,
      apiData: apiData,
      preprocessingFinished: true
    });

  };



  render() {
    // const { tab, selectedMessage } = this.state;
    const { hoveredNode, tab, selectedMessage } = this.state;
    // const { hoveredNode } = this;

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <NavbarCanvas></NavbarCanvas>
        {(this.state.StartRender && this.state.preprocessingFinished) && (
          <>
            <FunctionBar graphRef={this.graphRef} set_SelectedNode={this.set_SelectedNode} unset_SelectedNode={this.unset_SelectedNode} getChildNodes={this.getChildNodes} getLink={this.getLink} getRootNodesUid={this.getRootNodesUid} handleDialogSubmit={this.handleDialogSubmit} functionBarData={this.state.functionBarData} />
            <div style={{ display: 'flex', position: 'relative', }}>
              <div style={{ flex: '0 0 auto', padding: '5px', display: 'flex', flexDirection: 'column', gap: '8px', position: 'absolute', left: '10px', top: '10px' }}>
                <button id="zoomAdjust" onClick={this.onClickZoomAdjust} className={'graph-controller'}>
                  {/* <OpenInFull fontSize="small" /> */}
                  <CenterFocusWeak fontSize="small" />
                </button>
                <button id="zoomIn" onClick={this.onClickZoomIn} className={'graph-controller'}>
                  <Add fontSize="small" />
                </button>
                <button id="zoomOut" onClick={this.onClickZoomOut} className={'graph-controller'}>
                  <Remove fontSize="small" />
                </button>
              </div>
              <div style={{ flex: 1 }}>
                <Graph
                  id="graph"
                  ref={this.graphRef}
                  config={{ ...this.state.apiConfig, width: '100%' }}
                  data={this.state.apiData}
                  onNodePositionChange={this.handleNodePositionChange}
                  onClickNode={this.onClickNode}
                  onClickLink={this.onClicknodeDetailsLink}
                  onMouseOverNode={this.onMouseOverNode}
                  onMouseOutNode={this.onMouseOutNode}
                  graphRef={this.graphRef}
                  _onDragStart={this.handdledragstart}
                  style={{ height: '100%', width: '100%' }}
                />

              </div>
              <div style={{ display: 'flex', background: '#F9F9F9' }}>
                <SidePanelcomponent
                  ref={this.childRef}
                  tab={tab}
                  handleRightClick={this.handleRightClick}
                  dashboardTabConfigJson={this.state.dashboardTabConfigJson}
                  getRootNodesUid={this.getRootNodesUid}
                  getChildNodes={this.getChildNodes}
                  getLink={this.getLink}
                  getNodeDetailsForTab={this.getNodeDetailsForTab}
                  selectedMessage={selectedMessage}
                  adjustCanvas={this.adjustCanvas}
                  graphRefInstance={this.graphRef}
                />
              </div>

              {/* <div>{hoveredNode}</div> */}
              {/* {hoveredNode && (
  <div style={{ position: 'absolute', top: hoveredNode.y, left: hoveredNode.x }}>
  <div>Hover</div>
  {console.log(hoveredNode)}
  <NodeProperties2 properties={hoveredNode} neo4jConfig={this.neo4jConfig} />
          </div>
)} */}


            </div>
          </>
        )}

        {/* <Backdrop
          sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
          open={!(this.state.StartRender && this.state.preprocessingFinished)}
        >
          <CircularProgress color='inherit' />
        </Backdrop> */}
      </div>
    )
  }
}

export default MainCanvas;

// {hoveredNode && (
//   <div style={{ position: 'absolute', top: hoveredNode.y, left: hoveredNode.x }}>
//   <div>Hover</div>
//   {console.log(hoveredNode)}
//   {/* <NodeProperties2 properties={hoveredNode} neo4jConfig={this.neo4jConfig} /> */}
//           </div>
// )}