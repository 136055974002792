import React, { useEffect, useState } from 'react';
import neo4j from 'neo4j-driver';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import PowerCharts from 'fusioncharts/fusioncharts.powercharts';
import ReactFC from 'react-fusioncharts';
import Draggable from 'react-draggable';
import { KeyboardArrowDown } from '@mui/icons-material';
ReactFC.fcRoot(FusionCharts, PowerCharts, FusionTheme);

// Add necessary FusionCharts modules and theme
Charts(FusionCharts);
// FusionTheme(FusionCharts);
const Neo4jSpecificSankeyChart = ({ uid, neo4jConfig, width }) => {

  //   const neo4jConfig = {
  //     username: "neo4j",
  //     serverUrl: "neo4j+s://ac4ba3f7.databases.neo4j.io",
  //     password: "AWKEeX3JP4VQaD5ch-O6Z_2kuu6ksfJWCUCwdIFJHR0",
  //   };
  const hideTrialCreditsStyle = `
  svg > g[class^="raphael-group-"] > text {
    display: none !important;
  }
`;
  const chartStyle = {
    padding: '4px',
    // border: '2px solid #3f51b5',
    // borderRadius: '10px',
    // backgroundColor: '#f0f0f0',
    flex: 1,
    zIndex: 0,
    // height: width === '292%' ? "230px" : "300px",
    // width: width === '292%' ? "446px" : "510px",
  };
  const [selectedTheme, setSelectedTheme] = useState('candy');

  const [zIndex, setZIndex] = useState(1);
  const handleStart = () => {
    // Increment zIndex when dragging starts
    setZIndex((prevZIndex) => prevZIndex + 1);
  };

  useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.innerHTML = hideTrialCreditsStyle;
    document.head.appendChild(styleElement);
    // Destructure the neo4jConfig props
    const { serverUrl, username, password } = neo4jConfig;


    // Create a driver
    const driver = neo4j.driver(serverUrl, neo4j.auth.basic(username, password));

    // Fetch Nodes
    const sessionNodes = driver.session({ database: 'neo4j' });
    const queryNodes = `MATCH (n {uid: '${uid}'})-[t]->(c) RETURN DISTINCT n,t, c`;

    const nodes = [];

    sessionNodes.run(queryNodes)
      .then(result => {
        const records = result.records;
        const summary = result.summary;

        console.log(
          `>> Nodes query ${summary.query.text} ` +
          `returned ${records.length} records ` +
          `in ${summary.resultAvailableAfter} ms.`
        );
        console.log('>> Nodes Results');

        records.forEach(record => {
          const pnode = record.get('n');
          const cnode = record.get('c');
          nodes.push({ "label": pnode.properties.uid });
          nodes.push({ "label": cnode.properties.uid });
        });

        console.log(nodes);
        console.log('************************');

        const sessionLinks = driver.session({ database: 'neo4j' });
        const queryLinks = `MATCH (p {uid: '${uid}'})-[rel]->(r) RETURN p.uid,rel.quantity, r.uid,r.gwp_100`;
        const links = [['from', 'to', 'weight']];
        sessionLinks.run(queryLinks)
          .then(result => {
            const records = result.records;
            const summary = result.summary;

            console.log(
              `>> Links query ${summary.query.text} ` +
              `returned ${records.length} records ` +
              `in ${summary.resultAvailableAfter} ms.`
            );
            console.log('>> Links Results');

            records.forEach(record => {
              const fromUid = record.get('p.uid');
              const toUid = record.get('r.uid');
              let quantity = record.get('rel.quantity');
              console.log("^^^^^^^^^^^");
              console.log(record.get('r.gwp_100'));
              console.log(typeof (record.get('r.gwp_100')));
              //   quantity = (quantity != null || undefined) ? parseFloat(quantity) : 1;
              quantity = (quantity != null && quantity !== '' && !isNaN(quantity)) ? parseFloat(quantity) : 1;

              const l = parseFloat(record.get('r.gwp_100'))
              console.log("++++++++++++++++++++++++++");
              console.log(quantity);
              console.log(typeof (l));
              links.push({
                'from': fromUid,
                'to': toUid,
                'value': quantity * l
              });



            });

            console.log(links);


            // Create FusionCharts
            FusionCharts.ready(function () {
              const dataSource = {
                chart: {
                  caption: "",
                  subcaption: "",
                  theme: "fusion",
                  // theme: "fusion",
                  orientation: "horizontal",
                  linkalpha: 30,
                  linkhoveralpha: 60,
                  //   nodelabelposition: "end",
                  legendPosition: "bottom",
                  showlegend: 1,
                  linkcolor: 'blend',
                  // theme: "candy"
                  backgroundColor: "black",
                  nodelabelposition: 'end',
                },
                "nodes": nodes,
                "links": links
              };

              var myChart = new FusionCharts({
                type: "sankey",
                renderAt: document.getElementById("chart-container1"),
                width: width === '292%' ? "100%" : "100%",
                height: width === '292%' ? "70%" : "90%",
                dataFormat: "json",
                dataSource: dataSource
              }).render();









            });

          })
          .catch(error => {
            console.error('Error executing links query:', error);
          })
          .finally(() => {
            sessionLinks.close();
            driver.close();
          });

      })
      .catch(error => {
        console.error('Error executing nodes query:', error);
      })
      .finally(() => {
        sessionNodes.close();
      });
  }, [uid, width]); // Add neo4jConfig to dependency array to trigger effect when it changes


  const toogleCollapse = (id) => {
    const nodeProperty = document.getElementById(id);
    if (nodeProperty.classList.contains('hide')) {
      nodeProperty.classList.remove('hide');
    } else {
      nodeProperty.classList.add('hide');
    }
  };

  return (

    // <Draggable zIndex={zIndex}>
    <div className="node-property" id="np-4">

      <div className="node-header" onClick={() => toogleCollapse('np-4')}>
        <label>Critical Pathways</label>
        <div className="node-header-action" >
          <KeyboardArrowDown />
        </div>
      </div>
      <div className="node-property-content">
        <style>
          {`
          #chart-container1 {
            // width: 450px;
            height: 310px; /* Set the desired height */
            margin: 0px 0; /* Add some margin for spacing */
          }
        `}
        </style>

        <div id="chart-container1">
        </div>
      </div>
    </div>

    // {/* </Draggable> */}
  );
}

export default Neo4jSpecificSankeyChart;
