import React, { useState, useEffect } from 'react';
import { Card, CardActionArea, CardMedia, CardContent, Typography, CardActions,Divider, Button,Link } from '@mui/material';
import neo4j from 'neo4j-driver';

const NodeForm = ({ nodeId, onClose, neo4jConfig }) => {
  const [formData, setFormData] = useState('');
  const [relationshipInfo, setRelationshipInfo] = useState(null);
  const [gwp100, setGwp100] = useState(null);
  const [calGwp100, setCalGwp100] = useState(null);
  const [percentageContribution, setPercentageContribution] = useState(null);

  useEffect(() => {
    const fetchNodeInfo = async () => {
      const { serverUrl, username, password } = neo4jConfig;
      const driver = neo4j.driver(serverUrl, neo4j.auth.basic(username, password));
      const session = driver.session();

      try {
        // Fetch relationship info
        const relationshipQuery = `
          MATCH (parent)-[relationship]->(child {uid: '${nodeId}'})
          RETURN parent.name AS parentName, parent.gwp_100 AS parentGwp100, relationship.quantity AS quantity
        `;

        const relationshipResult = await session.run(relationshipQuery);

        if (relationshipResult.records.length > 0) {
          const parentName = relationshipResult.records[0].get('parentName');
          const parentGwp100 = parseFloat(relationshipResult.records[0].get('parentGwp100'));
          const quantity = parseFloat(relationshipResult.records[0].get('quantity'));

          setRelationshipInfo({ parentName, parentGwp100, quantity });
        }

        // Fetch GWP_100 info
        const gwp100Query = `
          MATCH (n {uid: '${nodeId}'})
          RETURN n.gwp_100 AS gwp100
        `;

        const gwp100Result = await session.run(gwp100Query);

        if (gwp100Result.records.length > 0) {
          const gwp100Value = parseFloat(gwp100Result.records[0].get('gwp100'));
          setGwp100(gwp100Value);

          const totalGwp100 = gwp100Value * relationshipInfo.quantity;
          setCalGwp100(totalGwp100);

          const percentage = (totalGwp100 / relationshipInfo.parentGwp100) * 100;
          setPercentageContribution(percentage);
        }
      } catch (error) {
        console.error('Error fetching node info:', error);
      } finally {
        session.close();
        driver.close();
      }
    };

    fetchNodeInfo();
  }, [neo4jConfig, nodeId]);

  const handleChange = (e) => {
    setFormData(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic, if needed
    alert(`Form submitted for Node ID: ${nodeId}, Form Data: ${formData}, Parent: ${relationshipInfo.parentName}, Quantity: ${relationshipInfo.quantity} Kg, GWP_100: ${gwp100}, Contribution Percentage: ${percentageContribution}%`);
    onClose(); // Close the form
  };
  const handlePreview = () => {
    // Replace the URLs with the actual links to your source files
    const file1Url = 'https://example.com/file1';
    const file2Url = 'https://example.com/file2';
    const file3Url = 'https://example.com/file3';

    // Open the links in new tabs
    window.open(file1Url, '_blank');
    window.open(file2Url, '_blank');
    window.open(file3Url, '_blank');
  };

  return (
    <Card sx={{ maxWidth: 500 }}>
    <CardActionArea>
      {/* <CardMedia
        component="img"
        height="130"
        image="https://external-preview.redd.it/sustain360-launches-ai-driven-sustainability-platform-to-v0-pL2uYL3i2nq8b6DQxkv6QYE_WpqqgwvSS6Wbbr6YK7o.jpg?width=1080&crop=smart&auto=webp&s=e4c1b68e6554746fe8b3b45f9fa1f0ca1d1ab69e"
        alt="Card Image"
      /> */}
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{ color: '#3f51b5', fontWeight: 'bold' }}>
        How was this calculated?
        </Typography>
        {/* <Typography variant="h6" sx={{ color: 'grey.600', fontStyle: 'italic' }}>
          How this is calculated?
        </Typography> */}
        <Typography variant="body2" sx={{ color: 'grey.700' }}>
          Total Emission" is calculated by multiplying the Global Warming Potential (GWP) per unit of the current material with the quantity of material required. This product represents the contribution of the current node to the parent node's emissions
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Typography variant="body1" sx={{ color: 'green' }}>Node ID: {nodeId}</Typography>
        {relationshipInfo && (
          <div>
            <Typography variant="body1" sx={{ color: 'purple' }}>Parent Node: {relationshipInfo.parentName}</Typography>
            <Typography variant="body1" sx={{ color: 'purple' }}>Input Quantity: {relationshipInfo.quantity} Kg</Typography>
          </div>
        )}
        {(
          <div>
            <Typography variant="body1" sx={{ color: 'purple' }}>Emission Factor: {gwp100} KgCo2 Eq.</Typography>
            <Typography variant="body1" sx={{ color: 'purple' }}>Total Emission: {calGwp100} KgCo2 Eq.</Typography>
            <Typography variant="body1" sx={{ color: 'purple' }}>
              Contribution Percentage: {percentageContribution}%
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Link href="https:example.com" target="_blank" rel="noopener" color="inherit"> Source File 1</Link>
              <Link href="https:example.com" target="_blank" rel="noopener" color="inherit"> Source File 2</Link>
              <Link href="https:example.com" target="_blank" rel="noopener" color="inherit"> Source File 3</Link>
            
          </div>
        )}
        <Divider sx={{ my: 2 }} />
      </CardContent>
    </CardActionArea>
  </Card>
  );
};

export default NodeForm;
