import React from 'react'
import { Button, Grid, Typography, Snackbar } from '@mui/material';

import datajson from './../template.json'

const ToolData = [
    {
        name: 'Graph Tools',

        buttons: [

            `${process.env.REACT_APP_BASE_URL}${datajson.images.MainCanvas.Tabs.toolbox.GraphTool.graph1}`,
            `${process.env.REACT_APP_BASE_URL}${datajson.images.MainCanvas.Tabs.toolbox.GraphTool.graph2}`,
            `${process.env.REACT_APP_BASE_URL}${datajson.images.MainCanvas.Tabs.toolbox.GraphTool.graph3}`,
            `${process.env.REACT_APP_BASE_URL}${datajson.images.MainCanvas.Tabs.toolbox.GraphTool.graph4}`,
            `${process.env.REACT_APP_BASE_URL}${datajson.images.MainCanvas.Tabs.toolbox.GraphTool.graph5}`,
        ]
    },
    {
        name: 'Selection Tools',

        buttons: [
            `${process.env.REACT_APP_BASE_URL}${datajson.images.MainCanvas.Tabs.toolbox.SelectionTools.Selection1}`,
            `${process.env.REACT_APP_BASE_URL}${datajson.images.MainCanvas.Tabs.toolbox.SelectionTools.Selection2}`,
            `${process.env.REACT_APP_BASE_URL}${datajson.images.MainCanvas.Tabs.toolbox.SelectionTools.Selection3}`,
            `${process.env.REACT_APP_BASE_URL}${datajson.images.MainCanvas.Tabs.toolbox.SelectionTools.Selection4}`,
            `${process.env.REACT_APP_BASE_URL}${datajson.images.MainCanvas.Tabs.toolbox.SelectionTools.Selection5}`,

        ]
    },
    {
        name: 'Layout Tools',

        buttons: [
            `${process.env.REACT_APP_BASE_URL}${datajson.images.MainCanvas.Tabs.toolbox.LayoutTools.Layout1}`,
            `${process.env.REACT_APP_BASE_URL}${datajson.images.MainCanvas.Tabs.toolbox.LayoutTools.Layout2}`,
            `${process.env.REACT_APP_BASE_URL}${datajson.images.MainCanvas.Tabs.toolbox.LayoutTools.Layout3}`,
            `${process.env.REACT_APP_BASE_URL}${datajson.images.MainCanvas.Tabs.toolbox.LayoutTools.Layout4}`,
            `${process.env.REACT_APP_BASE_URL}${datajson.images.MainCanvas.Tabs.toolbox.LayoutTools.Layout5}`,
            `${process.env.REACT_APP_BASE_URL}${datajson.images.MainCanvas.Tabs.toolbox.LayoutTools.Layout6}`,
            `${process.env.REACT_APP_BASE_URL}${datajson.images.MainCanvas.Tabs.toolbox.LayoutTools.Layout7}`,
        ]
    },
    {
        name: 'Relationship',

        buttons: [
            `${process.env.REACT_APP_BASE_URL}${datajson.images.MainCanvas.Tabs.toolbox.Relationships.Layout1}`,
            `${process.env.REACT_APP_BASE_URL}${datajson.images.MainCanvas.Tabs.toolbox.Relationships.Layout2}`,
            `${process.env.REACT_APP_BASE_URL}${datajson.images.MainCanvas.Tabs.toolbox.Relationships.Layout3}`
        ]
    },
];



function ToolboxNewComponent({ handleRightClick, newNode }) {
    const [open, setOpen] = React.useState(false);
 

    const handleClick = (button) => {
        if (button === `${process.env.REACT_APP_BASE_URL}${datajson.images.MainCanvas.Tabs.toolbox.LayoutTools.Layout7}`) {
            handleRightClick();
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
  
    return (
        <>
            {ToolData.map((tool, toolindex) => (
                <div key={toolindex} style={{ padding: '8' }}>
                    <Typography variant="subtitle1" style={{ color: '#2D323A', fontWeight: 'bold', marginBottom: '3' }}><b>{tool.name}</b></Typography>
                    <Grid container spacing={1.3}>
                        {tool.buttons.map((button, buttonindex) => (
                            <Grid item xs={2.4} key={buttonindex}>
                                <Button variant="contained" disabled={button !== `${process.env.REACT_APP_BASE_URL}${datajson.images.MainCanvas.Tabs.toolbox.LayoutTools.Layout7}`} style={{
                                    width: '100%',
                                    height: '100%',
                                    minWidth: '100%',
                                    minHeight: '100%',
                                    backgroundColor: '#EBEBEB',
                                    color: 'black',
                                    marginBottom: '5',
                                    boxShadow: 'none'
                                }}
                                    onClick={() => handleClick(button)}
                                >
                                    <img
                                        src={button}
                                        alt="buttonWallpaper"
                                        style={{ height: '25', width: '25' }}
                                    />
                                    <Snackbar
                                        open={open}
                                        autoHideDuration={2000}
                                        onClose={handleClose}
                                        message="Please Select Node First"
                                        style={
                                            { color: 'white' }
                                        }

                                    />
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            ))}
        </>

    )
}

export default ToolboxNewComponent
