import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { ConstructionOutlined, DescriptionOutlined, Settings, ViewComfyOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import './Sidebar.css';

const styles = {
  root: {
    flexGrow: 1,
  },
  button: {
    width: 97,
    height: 35,
    opacity: 1,
    rotate: '90deg',
    marginBottom: 38,
    marginTop: 30,
    // transform: 'rotate(90deg)',
    // transformOrigin: '0% 0%',
    borderRadius: 0,
  },
  activeButton: {
    fontWeight: 'bold',
    //font: 'normal normal normal 12px/10px Segoe UI',
    background: 'transparent linear-gradient(180deg, #0C6276 0%, #00A9A6 100%) 0% 0% no-repeat padding-box',
    color: '#000000',
  },
};

class VerticalButtonsGrid extends Component {

  constructor(props) {
    super(props);
    this.state = {
      buttons: [
        { label: 'Dashboard', active: false, icon: <ViewComfyOutlined style={{ fontSize: '20' }} /> },
        // { label: 'Charts', active: false, icon:<PieChartOutline style={{fontSize:'20'}}/> },
        { label: 'Details', active: false, icon: <DescriptionOutlined style={{ fontSize: '20' }} /> },
        { label: 'Toolbox', active: false, icon: <ConstructionOutlined style={{ fontSize: '20' }} /> },
        { label: 'Settings', active: false, icon: <Settings style={{ fontSize: '20' }} /> },
      ],
      sidebarVisible: true,
      activeButtonIndex: 0,
      previousIndex: null,

      selectedIndex: 0,
      sidePannelVisibility: true,
    };
  }
  handleClick = (index) => {
    this.setState({ selectedIndex: index })
    this.props.onSideClick(index);
    this.props.onTabChange(index);
  }

  render() {
    const { buttons, selectedIndex } = this.state;

    return (<>
      {/* <Grid container direction="column" justifyContent="flex-center" alignItems="center" width="100%" style={{ marginTop: '10px' }}>
        {buttons.map((button, index) => (
          // <Grid item key={index} >
          <Button
            variant="contained"
            style={{
              ...styles.button, background: selectedIndex === index ? 'transparent linear-gradient(180deg, #0C6276 0%, #00A9A6 100%) 0% 0% no-repeat padding-box' : '#E4E4E4 0% 0% no-repeat padding-box'
              ,
              color: selectedIndex === index ? '#FFFFFF' : '#2D323A',
              textTransform: 'none',
              display: 'flex',
              alignItems: 'center',
              padding: '0 12px'
            }}
            onClick={() => this.handleClick(index)}
          >
            {button.icon}<span style={{ fontSize: '10', marginLeft: '3px' }}>{button.label}</span>
          </Button>
          // </Grid>
        ))}
      </Grid> */}

      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', padding: '5px' }}>
        {/* Create icon buton with tooltip */}

        {buttons.map((button, index) => (
          <Tooltip title={button.label} key={index} placement="left-start">
            <Button
              variant="link"
              className={'sidear-button' + (selectedIndex === index ? ' active' : '')}

              onClick={() => this.handleClick(index)}
            >
              {button.icon}
            </Button>
          </Tooltip>
        ))}

      </div>
    </>
    );
  }
}
export default VerticalButtonsGrid;
