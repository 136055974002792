import * as React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import {
  Alert,
  Autocomplete,
  Avatar,
  Backdrop,
  Button,
  CircularProgress,
  Modal,
  Snackbar,
  Stack,
  TextField,
  Tooltip
} from '@mui/material'
import { Grid, Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import subscriptionImg from './../../assets/rank.png'
import SearchIcon from '@mui/icons-material/Search'
import InputBase from '@mui/material/InputBase'
import { Link, useNavigate } from 'react-router-dom'
import datajson from './../template.json'
import UserService from '../../services/UserService'
import Service from '../../services/Services'
import axios from 'axios'
const Base_URL = process.env.REACT_APP_BACKEND_URL

const drawerWidth = 240

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: 'white',

  '&:hover': {
    backgroundColor: 'white'
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: '100%'
  }
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),

    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    color: 'black',
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '20ch',
      '&:focus': {
        width: '20ch'
      }
    }
  }
}))

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar
}))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    fontSize: '1',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': {
      ...openedMixin(theme),
      '& .MuiTypography-root': {
        fontSize: '0.9rem'
      }
    }
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      ...closedMixin(theme),
      '& .MuiTypography-root': {
        fontSize: '0.9rem'
      }
    }
  })
}))

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#E9EFF2',
    padding: theme.spacing(2.5),
    textDecoration: 'none'
  },
  image: {
    width: '100%',
    height: '140',
    backgroundColor: '#ffffff',
    marginBottom: '13'
  },
  description: {
    marginTop: theme.spacing(2.5),
    color: 'black',
    textDecoration: 'none'
  }
}))

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  padding: '70px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
}

export default function MiniDrawer() {
  const classes = useStyles()
  let initials

  const navigate = useNavigate()

  const [open, setOpen] = React.useState(false)
  const [isSignoutPopupOpen, setSignoutPopupOpen] = React.useState(false)
  const [createNew, setCreateNew] = React.useState(false)
  const [selectedIndex, setSelectedIndex] = React.useState(null)
  const [active1, setActive1] = React.useState(true)
  const [active2, setActive2] = React.useState(false)
  const [active3, setActive3] = React.useState(false)
  const [active4, setActive4] = React.useState(false)
  const [active5, setActive5] = React.useState(false)
  const [active6, setActive6] = React.useState(false)

  const [allProject, setAllProject] = React.useState([])
  const [filteredProject, setFilteredProject] = React.useState([])

  const [projectName, setProjectName] = React.useState('')
  const [projectDescription, setProjectDescription] = React.useState('')

  const [template, setTemplete] = React.useState([])
  const [SelectedTemplate, setSelectedTemplate] = React.useState(0)

  const [databselist, setDatabaseList] = React.useState([])
  const [SelectedDatabase, setSelectedDatabase] = React.useState(0)

  const [addTeam, setAddTeam] = React.useState([])
  const [selectedTeam, setSelectedTeam] = React.useState([])

  const [handleEmptyField, setHandleEmptyField] = React.useState(false)

  const [open401, setOpen401] = React.useState(false)
  const [open422, setOpen422] = React.useState(false)

  const [showProgress, setShowProgress] = React.useState(true)

  try {
    const userName = sessionStorage.getItem('userName')
    if (userName !== null) {
      initials = userName
        .split(' ')
        .map(word => word.charAt(0))
        .slice(0, 2)
        .join('')
    }
  } catch (error) { }

  const handleAddTeamChange = (event, values) => {
    setSelectedTeam(values)
  }

  const handleDatabaseChange = (event, values) => {
    setSelectedDatabase(values)
  }

  const handleTemplateChange = (event, values) => {
    setSelectedTemplate(values)
  }

  const handleNameChange = event => {
    setProjectName(event.target.value)
  }

  const handleDescriptionChange = event => {
    setProjectDescription(event.target.value)
  }

  const onCreateNewClick = () => {
    setCreateNew(true)
    getTemplate()
    getdatabaseList()
    addTeams()
  }

  const onCancelNewClick = () => {
    setCreateNew(false)
    setProjectName('')
    setProjectDescription('')
    setHandleEmptyField('')
  }

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleActive1 = () => {
    setActive1(true)
    setActive2(false)
    setActive3(false)
    setActive4(false)
    setActive5(false)
    setActive6(false)
  }

  const handleActive2 = () => {
    setActive1(false)
    setActive2(true)
    setActive3(false)
    setActive4(false)
    setActive5(false)
    setActive6(false)
  }

  const handleActive3 = () => {
    setActive1(false)
    setActive2(false)
    setActive3(true)
    setActive4(false)
    setActive5(false)
    setActive6(false)
  }

  const handleActive4 = () => {
    setActive1(false)
    setActive2(false)
    setActive3(false)
    setActive4(true)
    setActive5(false)
    setActive6(false)
  }

  const handleActive5 = () => {
    setActive1(false)
    setActive2(false)
    setActive3(false)
    setActive4(false)
    setActive5(true)
    setActive6(false)
  }

  const handleActive6 = () => {
    setActive1(false)
    setActive2(false)
    setActive3(false)
    setActive4(false)
    setActive5(false)
    setActive6(true)
  }

  const items = [
    {
      index: '1',
      text: 'Home',
      img: `${process.env.REACT_APP_BASE_URL}${datajson.images.DashboardMenu.black.Home}`,
      selectedImg: `${process.env.REACT_APP_BASE_URL}${datajson.images.DashboardMenu.White.Home}`
    },
    {
      index: '2',
      text: 'My Projects',
      img: `${process.env.REACT_APP_BASE_URL}${datajson.images.DashboardMenu.black.MyProjects}`,
      selectedImg: `${process.env.REACT_APP_BASE_URL}${datajson.images.DashboardMenu.White.MyProjects}`
    },
    // {
    //   index: '3',
    //   text: 'Create New Project',
    //   img: `${process.env.REACT_APP_BASE_URL}${datajson.images.DashboardMenu.black.CreateNewProject}`,
    //   selectedImg: `${process.env.REACT_APP_BASE_URL}${datajson.images.DashboardMenu.White.CreateNewProject}`
    // },
    {
      index: '4',
      text: 'Template Management',
      img: `${process.env.REACT_APP_BASE_URL}${datajson.images.DashboardMenu.black.TemplateManagement}`,
      selectedImg: `${process.env.REACT_APP_BASE_URL}${datajson.images.DashboardMenu.White.TemplateManagement}`
    },
    {
      index: '5',
      text: 'User Management',
      img: `${process.env.REACT_APP_BASE_URL}${datajson.images.DashboardMenu.black.UserManagement}`,
      selectedImg: `${process.env.REACT_APP_BASE_URL}${datajson.images.DashboardMenu.White.UserManagement}`
    }
  ]
  const disabledIndicesForTab = ['6', '7', '8']

  const onClickHandle = async index => {
    setSelectedIndex(index)
    if (index === '3') {
      onCreateNewClick()
    }
    if (!disabledIndicesForTab.includes(index)) {
      setSelectedIndex(index)
    }
  }

  // React.useEffect(() => {
  //   const timer = setTimeout(() => {
  //     getAllProject()
  //     setShowProgress(false)
  //   }, 5000)

  //   return () => {
  //     clearTimeout(timer)
  //   }
  // }, [])

  const getToken = React.useCallback(async () => {
    return await UserService.getToken(); // Get the Keycloak token asynchronously
  }, []);


  React.useEffect(() => {
    let interval;
    const checkTokenAndCallApi = async () => {
      setShowProgress(true);
      const token = await getToken(); // Get the Keycloak token asynchronously
      if (token) {
        getAllProject();
        setShowProgress(false);
        clearInterval(interval);
      } else {
        console.error('No token available. User not authenticated.');
      }
    };

    interval = setInterval(() => {
      checkTokenAndCallApi();
    }, 500); // Check every 5 seconds
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [getToken]);

  const CustomListItem = ({
    text,
    img,
    index,
    selectedImg
  }) => {
    const isSelectedIndex = index === selectedIndex
    const isIndexOne = index === '1'
    const isDisabledIndex = disabledIndicesForTab.includes(index)

    return (
      <ListItem key={text} disablePadding sx={{ display: 'block' }}>
        <Tooltip
          title={text}
          disableHoverListener={open}
          placement='right'
          arrow
        >
          <ListItemButton
            onClick={() => {
              onClickHandle(index)
            }}
            disabled={isDisabledIndex}
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
              backgroundImage:
                isSelectedIndex || (selectedIndex === null && isIndexOne)
                  ? 'linear-gradient(#0C6276,#00A9A6)'
                  : 'inherit',
              color: isDisabledIndex ? 'gray' : 'inherit',
              '&:disabled': {
                color: 'gray'
              }
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center'
              }}
            >
              <img
                src={
                  isSelectedIndex || (selectedIndex === null && isIndexOne)
                    ? selectedImg
                    : img
                }
                alt={text}
                style={{
                  width: '21px',
                  height: '21px'
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary={text}
              sx={{
                opacity: open ? 1 : 0,
                color:
                  isSelectedIndex || (selectedIndex === null && isIndexOne)
                    ? 'white'
                    : 'inherit'
              }}
              secondary={text === 'Usage & Subscription' ? null : ''}
            />
            {text === 'Usage & Subscription' && open && (
              <img
                src={subscriptionImg}
                alt={text}
                style={{
                  width: '21px',
                  height: '21px',
                  position: 'absolute',
                  right: '8px',
                  top: '50%',
                  transform: 'translateY(-50%)'
                }}
              />
            )}
          </ListItemButton>
        </Tooltip>
      </ListItem>
    )
  }

  const getAllProject = async () => {
    const tenantidUser = await UserService.getTenantid()
    const token = await UserService.getToken()

    const config = {
      headers: {
        'content-type': 'application/json',
        authorization: token ? 'bearer ' + token : Service.token
      }
    }
    const tenantid = Service.tenant_id ? Service.tenant_id : tenantidUser
    axios
      .get(Base_URL + '/projects/' + tenantid, config)
      .then(response => {
        if (response.data.status) {
          setAllProject(response.data.data.projects_data)
          setFilteredProject(response.data.data.projects_data)
        }
      })
      .catch(function (error) {
        console.error(error)
      })
  }

  const handleApiError = (error) => {
    console.error(error);
    if (error.response && error.response.request) {
      const status = error.response.request.status;
      if (status === 401) {
        setOpen401(true);
      } else if (status === 422) {
        setOpen422(true);
      }
    }
  };

  const getTemplate = () => {
    const config = {
      headers: {
        'content-type': 'application/json',
        authorization: 'bearer ' + sessionStorage.getItem('token')
      }
    }
    axios
      .get(
        'local' + '/projecttemplates/' + sessionStorage.getItem('tenanatId'),
        config
      )
      .then(response => {
        if (response.data.status) {
          setTemplete(response.data.data.templates_data)
        }
      })
      .catch(function (error) {
        handleApiError(error);
      })
  }

  const getdatabaseList = () => {
    const config = {
      headers: {
        'content-type': 'application/json',
        authorization: 'bearer ' + sessionStorage.getItem('token')
      }
    }
    axios
      .get(
        Base_URL + '/neo4jdatabases/' + sessionStorage.getItem('tenanatId'),
        config
      )
      .then(response => {
        if (response.data.status) {
          setDatabaseList(response.data.data.neo4jDatabases_data)
        }
      })
      .catch(function (error) {
        handleApiError(error);
      })
  }

  const addTeams = () => {
    const config = {
      headers: {
        'content-type': 'application/json',
        tenantid: sessionStorage.getItem('tenanatId'),
        authorization: 'bearer ' + sessionStorage.getItem('token')
      }
    }
    axios
      .get(Base_URL + '/users/groups/', config)
      .then(response => {
        if (response.data.status) {
          setAddTeam(response.data.data)
        }
      })
      .catch(function (error) {
        handleApiError(error);
      })
  }
  const TeamArray = selectedTeam.map(item => item.name)

  const CreateProjectNew = () => {
    const config = {
      headers: {
        'content-type': 'application/json',
        authorization: 'bearer ' + sessionStorage.getItem('token')
      }
    }
    const requestBody = {
      name: projectName,
      template_category_id: SelectedTemplate.template_id,
      database_instance_id: SelectedDatabase.database_instance_id,
      project_description: projectDescription,
      teams: TeamArray
    }
    axios
      .post(
        Base_URL + '/projects/' + sessionStorage.getItem('tenanatId'),
        requestBody,
        config
      )
      .then(response => {
        if (response.data.status) {
          navigate('/mainCanvas')
          sessionStorage.setItem('project_id', response.data.data.project_id)
        }
      })
      .catch(function (error) {
        handleApiError(error);
      })
  }

  const handlingRequiredFeilds = () => {
    if (
      projectName === '' ||
      SelectedTemplate === 0 ||
      SelectedTemplate === null ||
      SelectedDatabase === 0 ||
      SelectedDatabase === null
    ) {
      setHandleEmptyField(true)
    } else {
      setHandleEmptyField(false)
      CreateProjectNew()
    }
  }

  const [searchQuery, setSearchQuery] = React.useState('');
  const [typingTimeout, setTypingTimeout] = React.useState(null);

  const handleSearchProjects = async (event) => {
    const tenantidUser = await UserService.getTenantid()
    const token = await UserService.getToken()

    const tenantid = Service.tenant_id ? Service.tenant_id : tenantidUser
    const userInput = event.target.value;
    setSearchQuery(userInput);

    if (userInput.length <= 2) {
      return;
    }
    const config = {
      headers: {
        'content-type': 'application/json',
        authorization: token ? 'bearer ' + token : Service.token,
        'searchstring': userInput
      }
    }

    // Clear the previous typing timeout
    clearTimeout(typingTimeout);

    // Set a new typing timeout
    const newTypingTimeout = setTimeout(async () => {
      axios
        .get(Base_URL + '/projects/projectsearch/' + tenantid, config)
        .then(response => {
          if (response.data.status) {
            setAllProject(response.data.data.projects_data)
          }
        })
        .catch(function (error) {
          console.error(error)
        })
    }, 200); // Adjust debounce delay as needed (e.g., 300ms)

    setTypingTimeout(newTypingTimeout);

    // const filteredResults = allProject.filter(
    //   (result) => result.name.toLowerCase().includes(userInput.toLowerCase())
    // );
    // setFilteredProject(filteredResults);
  };

  const renderMenuTab = () => {
    return (
      <Grid
        item
        xs={12}
        style={{
          backgroundImage: 'linear-gradient(#0C6276,#00A9A6)',
          height: '233px',
          padding: '50px',
          marginRight: '17px'
        }}
      >
        <Grid container justifyContent='center'>
          <Grid item xs={10}>
            <Search>
              <SearchIconWrapper>
                <SearchIcon style={{ color: '#029E9E' }} />
              </SearchIconWrapper>
              <StyledInputBase
                style={{ width: '100%' }}
                placeholder='Search your Project'
                inputProps={{ 'aria-label': 'search' }}
                // value={searchQuery}
                onChange={handleSearchProjects}
              />
            </Search>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent='center'
          style={{ padding: '25' }}
        >
          <div style={{ textAlign: 'center' }}>
            <Avatar
              sx={{
                bgcolor: active1 ? 'white' : '#0C6276',
                height: '60px',
                width: '60px',
                cursor: 'pointer',
                marginBottom: '10'
              }}
              onClick={handleActive1}
            >
              <img
                src={
                  active1
                    ? `${process.env.REACT_APP_BASE_URL}${datajson.images.homeScreen.Tabs.color.AllTab}`
                    : `${process.env.REACT_APP_BASE_URL}${datajson.images.homeScreen.Tabs.White.AllTab}`
                }
                alt={active1 ? 'Active' : 'All'}
                style={{
                  width: '31.62px',
                  height: '21.8px'
                }}
              />
            </Avatar>
            <Typography
              variant='caption'
              align='center'
              style={{ color: 'white' }}
            >
              ALL
            </Typography>
          </div>
          <div style={{ textAlign: 'center', paddingLeft: '70' }}>
            <Avatar
              sx={{
                bgcolor: active2 ? 'white' : '#0C6276',
                height: '60px',
                width: '60px',
                cursor: 'pointer',
                marginBottom: '10',
                marginLeft: '5'
              }}
              onClick={handleActive2}
            >
              <img
                src={
                  active2
                    ? `${process.env.REACT_APP_BASE_URL}${datajson.images.homeScreen.Tabs.color.MostRecent}`
                    : `${process.env.REACT_APP_BASE_URL}${datajson.images.homeScreen.Tabs.White.MostRecent}`
                }
                alt={active2 ? 'Active' : 'All'}
                style={{
                  width: '41.62px',
                  height: '40.8px'
                }}
              />
            </Avatar>
            <Typography
              variant='caption'
              align='center'
              style={{ color: 'white' }}
            >
              Most Recent
            </Typography>
          </div>
          <div style={{ textAlign: 'center', paddingLeft: '70' }}>
            <Avatar
              sx={{
                bgcolor: active3 ? 'white' : '#0C6276',
                height: '60px',
                width: '60px',
                cursor: 'pointer',
                marginBottom: '10',
                marginLeft: '16'
              }}
              onClick={handleActive3}
            >
              <img
                src={
                  active3
                    ? `${process.env.REACT_APP_BASE_URL}${datajson.images.homeScreen.Tabs.color.SharedWithMe}`
                    : `${process.env.REACT_APP_BASE_URL}${datajson.images.homeScreen.Tabs.White.SharedWithMe}`
                }
                alt={active3 ? 'Active' : 'All'}
                style={{
                  width: '35.62px',
                  height: '33.8px'
                }}
              />
            </Avatar>
            <Typography
              variant='caption'
              align='center'
              style={{ color: 'white' }}
            >
              Shared With Me
            </Typography>
          </div>
          <div style={{ textAlign: 'center', paddingLeft: '70' }}>
            <Avatar
              sx={{
                bgcolor: active4 ? 'white' : '#0C6276',
                height: '60px',
                width: '60px',
                cursor: 'pointer',
                marginBottom: '10'
              }}
              onClick={handleActive4}
            >
              <img
                src={
                  active4
                    ? `${process.env.REACT_APP_BASE_URL}${datajson.images.homeScreen.Tabs.color.MyRepo}`
                    : `${process.env.REACT_APP_BASE_URL}${datajson.images.homeScreen.Tabs.White.MyRepo}`
                }
                alt={active4 ? 'Active' : 'All'}
                style={{
                  width: '35.62px',
                  height: '33.8px'
                }}
              />
            </Avatar>
            <Typography
              variant='caption'
              align='center'
              style={{ color: 'white' }}
            >
              My Repo
            </Typography>
          </div>
          <div style={{ textAlign: 'center', paddingLeft: '70' }}>
            <Avatar
              sx={{
                bgcolor: active5 ? 'white' : '#0C6276',
                height: '60px',
                width: '60px',
                cursor: 'pointer',
                marginBottom: '10',
                marginLeft: '14'
              }}
              onClick={handleActive5}
            >
              <img
                src={
                  active5
                    ? `${process.env.REACT_APP_BASE_URL}${datajson.images.homeScreen.Tabs.color.GlobalRepo}`
                    : `${process.env.REACT_APP_BASE_URL}${datajson.images.homeScreen.Tabs.White.GlobalRepo}`
                }
                alt={active5 ? 'Active' : 'All'}
                style={{
                  width: '35.62px',
                  height: '33.8px'
                }}
              />
            </Avatar>
            <Typography
              variant='caption'
              align='center'
              style={{ color: 'white' }}
            >
              My Global Repo
            </Typography>
          </div>
          <div style={{ textAlign: 'center', paddingLeft: '70' }}>
            <Avatar
              sx={{
                bgcolor: active6 ? 'white' : '#0C6276',
                height: '60px',
                width: '60px',
                cursor: 'pointer',
                marginBottom: '10'
              }}
              onClick={handleActive6}
            >
              <img
                src={
                  active6
                    ? `${process.env.REACT_APP_BASE_URL}${datajson.images.homeScreen.Tabs.color.Forum}`
                    : `${process.env.REACT_APP_BASE_URL}${datajson.images.homeScreen.Tabs.White.Forum}`
                }
                alt={active6 ? 'Active' : 'All'}
                style={{
                  width: '35.62px',
                  height: '33.8px'
                }}
              />
            </Avatar>
            <Typography
              variant='caption'
              align='center'
              style={{ color: 'white' }}
            >
              Forum
            </Typography>
          </div>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      {sessionStorage.getItem('token') &&
        <div>
          <Box sx={{ display: 'flex' }}>
            <AppBar
              position='fixed'
              style={{ backgroundColor: 'white' }}
              open={open}
            >
              <Toolbar>
                <IconButton
                  color='inherit'
                  aria-label='open drawer'
                  onClick={open ? handleDrawerClose : handleDrawerOpen}
                  edge='start'
                  sx={{
                    ...(open && {})
                  }}
                >
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}${datajson.images.navbar.MainDashboard.Menu}`}
                    alt='logout'
                    style={{
                      width: '21px',
                      height: '21px'
                    }}
                  />
                </IconButton>

                <img
                  src={`${process.env.REACT_APP_BASE_URL}${datajson.images.logo.withName.black}`}
                  alt=''
                  style={{
                    width: '170px',
                    height: '20px'
                  }}
                />

                <Box sx={{ flexGrow: 1 }} />

                <Tooltip title='Settings' arrow>
                  <IconButton
                    size='large'
                    edge='end'
                    aria-label='Settings'
                    aria-haspopup='false'
                  >
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}${datajson.images.navbar.MainDashboard.Settings}`}
                      alt='settings'
                      style={{
                        width: '21px',
                        height: '21px'
                      }}
                    />
                  </IconButton>
                </Tooltip>

                {/* <Button
                  style={{
                    backgroundImage: 'linear-gradient(#0C6276,#00A9A6)',
                    color: 'white',
                    textTransform: 'none',
                    marginLeft: '12'
                  }}
                  onClick={() => onCreateNewClick()}
                >
                  <span>Create New Project</span>
                </Button> */}

                <Tooltip title='Account' arrow>
                  <IconButton
                    size='large'
                    edge='end'
                    aria-label='account of current user'
                    aria-haspopup='true'
                    onClick={() => {
                      setSignoutPopupOpen(!isSignoutPopupOpen)
                    }}
                  >
                    <Avatar
                      sx={{
                        bgcolor: '#029E9E',
                        color: 'white',
                        width: 28,
                        height: 28,
                        fontSize: '0.7rem'
                      }}
                    >
                      {initials}
                    </Avatar>
                  </IconButton>
                </Tooltip>

                <Tooltip title='Log out' arrow>
                  <IconButton
                    size='large'
                    edge='end'
                    aria-label='Logout'
                    aria-haspopup='true'
                    onClick={UserService.doLogout}
                  >
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}${datajson.images.navbar.MainDashboard.logout}`}
                      alt='logout'
                      style={{
                        width: '21px',
                        height: '21px'
                      }}
                    />
                  </IconButton>
                </Tooltip>

                {isSignoutPopupOpen && (
                  <div className='signout-popup'>
                    <div className='signout-popup-content'>
                      <div
                        className='user-info'
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Avatar
                          sx={{
                            bgcolor: '#029E9E',
                            width: 55,
                            height: 55,
                            fontSize: '1.5rem',
                            padding: '5px'
                          }}
                        >
                          {initials}
                        </Avatar>
                        <div style={{ marginLeft: '16px' }}>
                          <div className='user-name'>
                            {sessionStorage.getItem('userName')}
                          </div>
                          <div className='user-role'>
                            {sessionStorage.getItem('email')}
                          </div>
                          <div className='user-role'>
                            {sessionStorage.getItem('role')}
                          </div>
                        </div>
                      </div>
                      <Divider />
                    </div>
                  </div>
                )}
              </Toolbar>
            </AppBar>

            <Drawer variant='permanent' open={open}>
              <DrawerHeader></DrawerHeader>
              <Divider />
              <List>
                {items.map(({ text, img, index, selectedImg }) => (
                  <CustomListItem
                    key={text}
                    text={text}
                    img={img}
                    index={index}
                    selectedImg={selectedImg}
                    onClickHandle={onClickHandle}
                    selectedIndex={selectedIndex}
                    open={open}
                    disabledIndicesForTab={disabledIndicesForTab}
                  />
                ))}
              </List>

              <Divider />
              <List>
                {['Settings', 'Help'].map((text, index) => (
                  <ListItem key={text} disablePadding sx={{ display: 'block' }}>
                    <Tooltip
                      title={text}
                      disableHoverListener={open}
                      placement='right'
                      arrow
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? 'initial' : 'center',
                          px: 2.5
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center'
                          }}
                        >
                          {index % 2 === 0 ? (
                            <img
                              src={`${process.env.REACT_APP_BASE_URL}${datajson.images.DashboardMenu.black.Settings}`}
                              alt={text}
                              style={{
                                width: '21px',
                                height: '21px'
                              }}
                            />
                          ) : (
                            <img
                              src={`${process.env.REACT_APP_BASE_URL}${datajson.images.DashboardMenu.black.Help}`}
                              alt={text}
                              style={{
                                width: '21px',
                                height: '21px'
                              }}
                            />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={text}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </Tooltip>
                  </ListItem>
                ))}
              </List>
            </Drawer>

            <Box component='main' sx={{ flexGrow: 1, pt: 1, pb: 1, pl: 1 }}>
              <DrawerHeader />
              <div
                style={{
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  height: 'calc(95vh - 43px)',
                  boxSizing: 'border-box'
                }}
              >
                <Grid
                  container
                  spacing={3}
                  style={{
                    marginLeft: '5px',
                    maxWidth: '100%',
                    boxSizing: 'border-box'
                  }}
                >

                  {renderMenuTab()}

                  {active1 && (
                    <>
                      <Grid
                        key={`g1`}
                        item
                        xs={12}
                        style={{
                          marginTop: '20',
                          marginBottom: '10',
                          fontSize: '18',
                          marginRight: '17px'
                        }}
                      >
                        <b> All Projects </b>
                      </Grid>
                      <Grid key={`g2`} container style={{ marginRight: '17px' }} spacing={1}>
                        {allProject.map((item, index) => {
                          return (
                            <>
                              <Grid key={index} item xs={3}>
                                <Link
                                  to='/mainCanvas'
                                  style={{ textDecoration: 'none' }}
                                >
                                  <Container className={classes.container}>
                                    <img
                                      src={item.project_thumbnail_image?.includes("http") ? item.project_thumbnail_image :
                                        Base_URL +
                                        '/' +
                                        item.project_thumbnail_image
                                      }
                                      className={classes.image}
                                      alt='wallpaper'
                                      onClick={() =>
                                        sessionStorage.setItem(
                                          'project_id',
                                          item.project_id
                                        )
                                      }
                                    />
                                    <Typography
                                      variant=''
                                      component='span'
                                      className={classes.description}
                                    >
                                      <b>{item.name}</b>
                                    </Typography>
                                  </Container>
                                </Link>
                              </Grid>
                            </>
                          )
                        })}
                      </Grid>
                      <Grid
                        key={`g3`}
                        item
                        xs={12}
                        style={{
                          marginTop: '40',
                          fontSize: '18',
                          marginRight: '17px'
                        }}
                      >
                        <b> Choose A New Template </b>
                      </Grid>
                      <Grid
                        key={`g4`}
                        item
                        xs={12}
                        style={{
                          marginBottom: '10',
                          color: '#029E9E',
                          marginRight: '17px'
                        }}
                      >
                        {' '}
                        For Industry
                      </Grid>
                      <Grid key={`g5`} container style={{ marginRight: '17px' }} spacing={1}>
                        <Grid item xs={3}>
                          <Container className={classes.container}>
                            <img
                              className={classes.image}
                              src={`${process.env.REACT_APP_BASE_URL}/assets/application_specific/template1.png`}
                              alt=''
                            />
                            <Typography
                              variant=''
                              className={classes.description}
                            >
                              <b>Construction Template</b>
                              <br></br>Create an interactive construction design
                              in minutes. Use this to create a Modal or knowledge
                              base.
                            </Typography>
                          </Container>
                        </Grid>
                        <Grid item xs={3}>
                          <Container className={classes.container}>
                            <img
                              className={classes.image}
                              src={`${process.env.REACT_APP_BASE_URL}/assets/application_specific/template2.png`}
                              alt=''
                            />
                            <Typography
                              variant=''
                              className={classes.description}
                            >
                              <b>Manufacturing Template</b>
                              <br></br>Create an interactive Manufacture design in
                              minutes. Use this to create a Modal or knowledge
                              base.
                            </Typography>
                          </Container>
                        </Grid>
                        <Grid item xs={3}>
                          <Container className={classes.container}>
                            <img
                              className={classes.image}
                              src={`${process.env.REACT_APP_BASE_URL}/assets/application_specific/template3.png`}
                              alt=''
                            />
                            <Typography
                              variant=''
                              className={classes.description}
                            >
                              <b>Transportation Template</b>
                              <br></br>Create an interactive Automotive design in
                              minutes. Use this to create a Modal or knowledge
                              base.
                            </Typography>
                          </Container>
                        </Grid>
                        <Grid item xs={3}>
                          <Container className={classes.container}>
                            <img
                              className={classes.image}
                              src={`${process.env.REACT_APP_BASE_URL}/assets/application_specific/template4.png`}
                              alt=''
                            />
                            <Typography
                              variant=''
                              className={classes.description}
                            >
                              <b>Energy Template</b>
                              <br></br>Create an interactive 360 view design in
                              minutes. Use this to create a Modal or knowledge
                              base.
                            </Typography>
                          </Container>
                        </Grid>
                      </Grid>

                      <Grid
                        key={`g5`}
                        item
                        xs={12}
                        style={{
                          marginBottom: '10',
                          color: '#029E9E',
                          marginTop: '20',
                          marginRight: '17px'
                        }}
                      >
                        {' '}
                        For Functional
                      </Grid>
                      <Grid key={`g6`} container style={{ marginRight: '17px' }} spacing={1}>
                        <Grid key={`g11`} item xs={3}>
                          <Container className={classes.container}>
                            <img
                              className={classes.image}
                              src={`${process.env.REACT_APP_BASE_URL}/assets/application_specific/template1.png`}
                              alt=''
                            />
                            <Typography
                              variant=''
                              className={classes.description}
                            >
                              <b>Tunnel Template</b>
                              <br></br>Create an interactive construction design
                              in minutes. Use this to create a Modal or knowledge
                              base.
                            </Typography>
                          </Container>
                        </Grid>
                        <Grid key={`g12`} item xs={3}>
                          <Container className={classes.container}>
                            <img
                              className={classes.image}
                              src={`${process.env.REACT_APP_BASE_URL}/assets/application_specific/template2.png`}
                              alt=''
                            />
                            <Typography
                              variant=''
                              className={classes.description}
                            >
                              <b>Buildings Template</b>
                              <br></br>Create an interactive Manufacture design in
                              minutes. Use this to create a Modal or knowledge
                              base.
                            </Typography>
                          </Container>
                        </Grid>
                        <Grid key={`g13`} item xs={3}>
                          <Container className={classes.container}>
                            <img
                              className={classes.image}
                              src={`${process.env.REACT_APP_BASE_URL}/assets/application_specific/template3.png`}
                              alt=''
                            />
                            <Typography
                              variant=''
                              className={classes.description}
                            >
                              <b>Baems & Bridges Template</b>
                              <br></br>Create an interactive 360 view design in
                              minutes. Use this to create a Modal or knowledge
                              base.
                            </Typography>
                          </Container>
                        </Grid>
                        <Grid key={`g14`} item xs={3}>
                          <Container className={classes.container}>
                            <img
                              className={classes.image}
                              src={`${process.env.REACT_APP_BASE_URL}/assets/application_specific/template4.png`}
                              alt=''
                            />
                            <Typography
                              variant=''
                              className={classes.description}
                            >
                              <b>Transport Template</b>
                              <br></br>Create an interactive Automotive design in
                              minutes. Use this to create a Modal or knowledge
                              base.
                            </Typography>
                          </Container>
                        </Grid>
                      </Grid>

                      <Grid
                        key={`g7`}
                        item
                        xs={12}
                        style={{
                          marginBottom: '10',
                          color: '#029E9E',
                          marginTop: '20',
                          marginRight: '17px'
                        }}
                      >
                        {' '}
                        For Data Loading
                      </Grid>
                      <Grid
                        key={`g8`}
                        container
                        style={{ marginRight: '17px', marginBottom: '10px' }}
                        spacing={1}
                      >
                        <Grid item xs={3}>
                          <Container className={classes.container}>
                            <img
                              className={classes.image}
                              src={`${process.env.REACT_APP_BASE_URL}/assets/application_specific/template1.png`}
                              alt=''
                            />
                            <Typography
                              variant=''
                              className={classes.description}
                            >
                              <b>Industry Template</b>
                              <br></br>Create an interactive construction design
                              in minutes. Use this to create a Modal or knowledge
                              base.
                            </Typography>
                          </Container>
                        </Grid>
                        <Grid item xs={3}>
                          <Container className={classes.container}>
                            <img
                              className={classes.image}
                              src={`${process.env.REACT_APP_BASE_URL}/assets/application_specific/template2.png`}
                              alt=''
                            />
                            <Typography
                              variant=''
                              className={classes.description}
                            >
                              <b>PerIndustry Template</b>
                              <br></br>Create an interactive Manufacture design in
                              minutes. Use this to create a Modal or knowledge
                              base.
                            </Typography>
                          </Container>
                        </Grid>
                        <Grid item xs={3}>
                          <Container className={classes.container}>
                            <img
                              className={classes.image}
                              src={`${process.env.REACT_APP_BASE_URL}/assets/application_specific/template3.png`}
                              alt=''
                            />
                            <Typography
                              variant=''
                              className={classes.description}
                            >
                              <b>Master Template</b>
                              <br></br>Create an interactive Automotive design in
                              minutes. Use this to create a Modal or knowledge
                              base.
                            </Typography>
                          </Container>
                        </Grid>
                        <Grid item xs={3}>
                          <Container className={classes.container}>
                            <img
                              className={classes.image}
                              src={`${process.env.REACT_APP_BASE_URL}/assets/application_specific/template4.png`}
                              alt=''
                            />
                            <Typography
                              variant=''
                              className={classes.description}
                            >
                              <b>Functional Template</b>
                              <br></br>Create an interactive 360 view design in
                              minutes. Use this to create a Modal or knowledge
                              base.
                            </Typography>
                          </Container>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </div>
            </Box>
          </Box>

          <Modal
            open={createNew}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            height='778px'
          >
            <Box sx={style}>
              <div style={{ maxHeight: '80vh' }}>
                <Grid container padding={2} spacing={1}>
                  <Grid item xs={6}>
                    <Typography
                      id='modal-modal-title'
                      variant='h6'
                      component='h1'
                      style={{ color: '#0C6276', marginBottom: '10px' }}
                    >
                      <b>Create New Project</b>
                    </Typography>
                    <div style={{ height: '68.1px', marginBottom: '3.5' }}>
                      <div style={{ color: '#4A4A4A' }}>
                        Project Name <span style={{ color: 'red' }}>*</span>
                      </div>
                      <TextField
                        required
                        id='standard-required'
                        defaultValue='Project Name 1'
                        value={projectName}
                        onChange={handleNameChange}
                        fullWidth
                        size='small'
                        error={projectName === '' && handleEmptyField}
                        helperText={
                          projectName === '' && handleEmptyField
                            ? 'Required*'
                            : ''
                        }
                      />
                    </div>

                    <div style={{ height: '68.1px', marginBottom: '3.5' }}>
                      <div style={{ color: '#4A4A4A' }}>
                        Template Category<span style={{ color: 'red' }}>*</span>
                      </div>
                      <Autocomplete
                        disablePortal
                        id='size-small-standard'
                        size='small'
                        options={template}
                        getOptionLabel={option => option.template_name}
                        onChange={handleTemplateChange}
                        sx={{ width: '100%' }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label=''
                            required
                            error={
                              (SelectedTemplate === 0 ||
                                SelectedTemplate === null) &&
                              handleEmptyField
                            }
                            helperText={
                              (SelectedTemplate === 0 ||
                                SelectedTemplate === null) &&
                                handleEmptyField
                                ? 'Required*'
                                : ''
                            }
                            onClick={() => {
                              getTemplate()
                            }}
                          />
                        )}
                      />
                    </div>

                    <div style={{ height: '68.1px', marginBottom: '3.5' }}>
                      <div style={{ color: '#4A4A4A' }}>
                        Select Database<span style={{ color: 'red' }}>*</span>
                      </div>

                      <Autocomplete
                        disablePortal
                        id='size-small-standard'
                        size='small'
                        options={databselist}
                        getOptionLabel={option => option.database_instance_name}
                        onChange={handleDatabaseChange}
                        sx={{ width: '100%' }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label=''
                            required
                            error={
                              (SelectedDatabase === 0 ||
                                SelectedDatabase === null) &&
                              handleEmptyField
                            }
                            helperText={
                              (SelectedDatabase === 0 ||
                                SelectedDatabase === null) &&
                                handleEmptyField
                                ? 'Required*'
                                : ''
                            }
                            onClick={() => {
                              getdatabaseList()
                            }}
                          />
                        )}
                      />
                    </div>
                    <div style={{ height: '68.1px', marginBottom: '3.5' }}>
                      <div style={{ color: '#4A4A4A' }}>Add Team</div>

                      <Stack spacing={3} sx={{ width: '100%' }}>
                        <Autocomplete
                          multiple
                          id='tags-outlined'
                          size='small'
                          options={addTeam}
                          getOptionLabel={option => option.name}
                          onChange={handleAddTeamChange}
                          renderInput={params => (
                            <TextField {...params} label='' required />
                          )}
                        />
                      </Stack>
                    </div>

                    <div style={{ marginBottom: '15px' }}>
                      <TextField
                        id='filled-multiline-static'
                        multiline
                        label='Project Description'
                        rows={3}
                        defaultValue='Add Description here . . .'
                        value={projectDescription}
                        onChange={handleDescriptionChange}
                        variant='filled'
                        fullWidth
                      />
                    </div>
                    <Grid
                      item
                      xs={12}
                      style={{ float: 'right', marginBottom: '20' }}
                    >
                      <Button
                        style={{
                          backgroundColor: '#6F7173',
                          marginRight: '14px'
                        }}
                        variant='contained'
                        onClick={() => onCancelNewClick()}
                      >
                        Cancel
                      </Button>
                      <Button
                        style={{
                          backgroundImage: 'linear-gradient(#0C6276,#00A9A6)'
                        }}
                        variant='contained'
                        onClick={() => handlingRequiredFeilds()}
                      >
                        Create
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}${datajson.images.CreateNewProject}`}
                      width='100%'
                      height='482px'
                      className='bannerImage'
                      alt='wallpaper'
                    />
                  </Grid>
                </Grid>
              </div>
            </Box>
          </Modal>
          <Snackbar
            open={open401}
            autoHideDuration={4000}
            onClose={() => {
              setOpen401(false)
            }}
          >
            <Alert
              onClose={() => {
                setOpen401(false)
              }}
              severity='error'
              sx={{ width: '100%' }}
            >
              You do not permission to perform this Actvity
            </Alert>
          </Snackbar>
          <Snackbar
            open={open422}
            autoHideDuration={4000}
            onClose={() => {
              setOpen422(false)
            }}
          >
            <Alert
              onClose={() => {
                setOpen422(false)
              }}
              severity='error'
              sx={{ width: '100%' }}
            >
              Something went wrong while processing request
            </Alert>
          </Snackbar>
        </div>
      }
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
          open={showProgress}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      </div>

    </>
  )
}
