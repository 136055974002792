import { Grid, Typography } from '@mui/material';
import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const formatNumber = (num) => {
  if (typeof num === 'number') {
    const roundedNumber = parseFloat(num.toFixed(2));
    return (roundedNumber.toLocaleString('en-US'));
  }
  else {
    return num;
  }
}

const tableHeadStyle = {
  fontSize: '10',
  lineHeight: '1.3',
  // fontWeight: 'bold',
  color: 'white',
  padding: '10px'
}
const tableContentStyle = {
  fontSize: '10',
  lineHeight: '1.3',
  padding: '10px'
  // fontWeight: 'bold',
}

const propertyInfo = [
  { value: 'maetp', label: 'Marine Aquatic Ecotoxicity Pot.', unit: 'kg DCB eq.' },
  { value: 'htp', label: 'Human Toxicity Potential', unit: 'kg DCB eq.' },
  { value: 'adp_elements', label: 'Abiotic Depletion (ADP elements)', unit: 'kg Sb eq.' },
  { value: 'gwp_100_ebc', label: 'Global Warming Potential, excl', unit: 'kg CO2 eq.' },
  { value: 'ep', label: 'Eutrophication Potential', unit: 'kg Phosphet eq.' },
  { value: 'faetp', label: 'Freshwater Aquatic Ecotoxicity Pot.', unit: 'kg DCB eq.' },
  { value: 'ap', label: 'Acidification Potential', unit: 'kg SO2 eq.' },
  { value: 'eci_cost', label: 'ECI Cost', unit: '' },
  { value: 'pocp', label: 'Photochem. Ozone Creation Potential', unit: 'kg Ethene eq.' },
  { value: 'odp', label: 'Ozone Layer Depletion Potential', unit: 'kg R11 eq.' },
  { value: 'gwp_100', label: 'Global Warming Potential', unit: 'kg CO2 eq.' },
  { value: 'tetp', label: 'Terrestric Ecotoxicity Potential', unit: 'kg DCB eq.' },
  { value: 'adp_fossil', label: 'Abiotic Depletion (ADP fossil)', unit: 'MJ' }
];


function OptionSidePanelcomponent(props) {
  console.log(props.linkInfo);
  return (
    <>
      {props.linkInfo ?
        <Grid container spacing={1}>
          {/* <Grid item xs={12}><Typography style={{ fontSize: '14', color: '#2D323A' }}><b>DETAILS</b></Typography></Grid> */}
          <Grid item xs={12} >
            <TableContainer component={Paper}>
              <Table sx={{ width: '100%' }} aria-label="simple table">
                <TableHead style={{ backgroundColor: '#029E9E' }} >
                  <TableRow>
                    <TableCell component="th" scope="row" style={tableHeadStyle}>Properties</TableCell>
                    <TableCell align="left" style={tableHeadStyle}>Values</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row" style={tableContentStyle}>Name</TableCell>
                    <TableCell align="left" style={tableContentStyle}>{formatNumber(props?.linkInfo['uid'])}</TableCell>
                  </TableRow>

                  {props?.linkInfo['quantity'] &&
                    <>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row" style={tableContentStyle}>Quantity</TableCell>
                        <TableCell align="left" style={tableContentStyle}>{formatNumber(props?.linkInfo['quantity'])}</TableCell>
                      </TableRow>

                      {propertyInfo.map((property) => (
                        <TableRow key={property.value} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row" style={tableContentStyle}>{property.label}</TableCell>
                          <TableCell align="left" style={tableContentStyle}>
                            {formatNumber(props.linkInfo.target[property.value] * props.linkInfo.quantity)} {property.unit}
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        :
        <Grid container spacing={1}>
          {/* <Grid item xs={12}><Typography style={{ fontSize: '14', color: '#2D323A' }}><b>DETAILS</b></Typography></Grid> */}
          <Grid item xs={12} >
            <TableContainer component={Paper}>
              <Table sx={{ width: '100%' }} aria-label="simple table">
                <TableHead style={{ backgroundColor: '#029E9E' }} >
                  <TableRow>
                    <TableCell component="th" scope="row" style={tableHeadStyle}>Properties</TableCell>
                    <TableCell align="left" style={tableHeadStyle}>Values</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row" style={tableContentStyle}>Node type</TableCell>
                    <TableCell align="left" style={tableContentStyle}>{formatNumber(props?.newNode['labels'])}</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row" style={tableContentStyle}>Id</TableCell>
                    <TableCell align="left" style={tableContentStyle}>{formatNumber(props?.newNode['id'])}</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row" style={tableContentStyle}>Uid</TableCell>
                    <TableCell align="left" style={tableContentStyle}>{formatNumber(props?.newNode['uid'])}</TableCell>
                  </TableRow>
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row" style={tableContentStyle}>Name</TableCell>
                    <TableCell align="left" style={tableContentStyle}>{formatNumber(props?.newNode['name'])}</TableCell>
                  </TableRow>

                  {sessionStorage.getItem('project_id') == 16 ? (
                    <>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row" style={tableContentStyle}>Protocol</TableCell>
                        <TableCell align="left" style={tableContentStyle}>{props?.newNode['Protocol']}</TableCell>
                      </TableRow>
                      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row" style={tableContentStyle}>Standard</TableCell>
                        <TableCell align="left" style={tableContentStyle}>{props?.newNode['Standard']}</TableCell>
                      </TableRow>
                    </>

                  ) : (
                    <></>
                  )}

                  {propertyInfo.map((property) => (
                    <TableRow key={property.value} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row" style={tableContentStyle}>{property.label}</TableCell>
                      <TableCell align="left" style={tableContentStyle}>
                        {property.value === "eci_cost" ? '$' + formatNumber(props.newNode[property.value]) : formatNumber(props.newNode[property.value])} {property.unit}
                      </TableCell>
                    </TableRow>
                  ))}

                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      }
    </>
  )
}

export default OptionSidePanelcomponent
