import React, { Component } from 'react';
import { MapContainer as LeafletMap, TileLayer, Tooltip, Marker, Icon } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import TextPath from "react-leaflet-textpath";
import osm from '../../services/osm-provider';
import axios from 'axios';
import { Grid, Modal, Backdrop, CircularProgress, Box, Typography, Divider, FormControl, Select, InputLabel, MenuItem, SelectChangeEvent } from '@mui/material';
import { Close } from '@mui/icons-material'
import '../css/map.css'
import 'leaflet-routing-machine';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import RouteMachine from './../helpers/RoutingMachine'
import seaRouteCoords from './../helpers/sea_route_tempory_fix.json'
import SupplyChainStatsComponent from './supplyChainMainData';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: 'calc(100vh - 65.20px)',
    width: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
}

const Base_URL = process.env.REACT_APP_BACKEND_URL;
class Map extends Component {
    constructor(props) {
        super(props);
        this.mapRef = React.createRef();
        this.state = {
            project_id: sessionStorage.getItem('project_id'),
            supplyersData: [],
            senderToSenderPort: [],
            receiverToreceiverPort: [],
            senderPortToRecieverPort: [],
            materialName: [],
            materialIndex: '',
            material: '',
            senderPortName: "",
            receiversPortName: "",
            distance: 0,
            emission: 0,
            segOneDistance: 0,
            segThreeDistance: 0,
            segOneEmission: 0,
            segThreeEmission: 0,
            isMapFullScreen: true,
            showProgress: true,
            isMapOpen: false,
        };
    }

    componentDidMount() {
        if (sessionStorage.getItem('project_id') === '1') {
            this.getSeaRoutes();
        }
        this.getSuppyChainData();
    }
    getSeaRoutes = async () => {
        try {
            const requestBody = {
                latitude: "26.201000",
                longitude: "50.606998",
                countryname: "BHR",
                nodeUid: "UP Resin"
            };
            const config = {
                headers: {
                    'content-type': 'application/json',
                    'authorization': 'bearer ' + sessionStorage.getItem('token'),
                    'projectid': sessionStorage.getItem("project_id"),
                    'tenantid': sessionStorage.getItem('tenanatId'),
                },
            };
            await axios.post(Base_URL + '/supplychain/get-sea-routes/' +
                sessionStorage.getItem("tenanatId"),
                requestBody,
                config).then((response) => {

                    //Sender Data
                    const senderLocation = response.data.data.SourcePort.senderLocation;
                    const senderLatitude = senderLocation[0].latitude;
                    const senderLongitude = senderLocation[0].longitude;

                    const senderPortLocation = response.data.data.SourcePort.senderPortData;
                    const senderPortLatitude = senderPortLocation.latitude;
                    const senderPortLongitude = senderPortLocation.longitude;
                    const senderPortName = senderPortLocation.portName

                    //Receiver Data
                    const receiversLocation = response.data.data.DestinationPort.receiversLocation;
                    const receiverLatitude = receiversLocation.latitude;
                    const receiverLongitude = receiversLocation.longitude;

                    const receiversPortLocation = response.data.data.DestinationPort.receiverPortData;
                    const receiverPortLatitude = receiversPortLocation.latitude;
                    const receiverPortLongitude = receiversPortLocation.longitude;
                    const receiversPortName = receiversPortLocation.portName

                    const senderCoordinates = [senderLatitude, senderLongitude];
                    const senderPortCoordinates = [senderPortLatitude, senderPortLongitude]
                    const senderToSenderPort = [senderCoordinates, senderPortCoordinates]

                    const receiverCoordinates = [receiverLatitude, receiverLongitude];
                    const receiverPortCoordinates = [receiverPortLatitude, receiverPortLongitude]
                    const receiverToreceiverPort = [receiverCoordinates, receiverPortCoordinates]

                    const senderPortToRecieverPort = [senderPortCoordinates, receiverPortCoordinates]


                    const distance = response.data.data.Distance;
                    const emission = response.data.data.CarbonEmission;

                    this.setState({ receiversPortName, senderPortName, senderToSenderPort, receiverToreceiverPort, senderPortToRecieverPort, distance, emission });
                    this.setState({
                        showProgress: false,
                        isMapOpen: true,
                        segOneDistance: 200,
                        segThreeDistance: 200,
                        segOneEmission: 6.944832808,
                        segThreeEmission: 6.944832808,

                    })
                });


        } catch (error) {
            console.log(error);
        }
    }

    getSuppyChainData = async () => {
        try {
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${Base_URL}/supplychain/get-material-supply-chain/${sessionStorage.getItem('tenanatId')}`,
                headers: {
                    'tenantid': sessionStorage.getItem('tenanatId'),
                    'projectid': sessionStorage.getItem('project_id'),
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            };
            axios.request(config)
                .then((response) => {
                    console.log((response.data.data));
                    const supplyersData = response.data.data.newData
                    const materialName = supplyersData.map((item, index) => {
                        return item.name
                    })

                    this.setState({ supplyersData, materialName, showProgress: false })
                })

        } catch (error) {
            console.log(error);
        }
    }

    toggleMapFullScreen = (shouldDisplayMap) => {
        this.setState({
            isMapFullScreen: shouldDisplayMap,
        });
    };
    formatNumber = (num) => {
        if (typeof num === 'number') {
            return num.toFixed(2);
        }
        return num;
    }

    render() {

        const {
            receiversPortName,
            senderPortName,
            senderToSenderPort,
            receiverToreceiverPort,
            material,
            senderPortToRecieverPort,
            distance,
            emission,
            showProgress,
            segOneDistance,
            segThreeDistance,
            materialIndex,
            materialName,
            segOneEmission,
            segThreeEmission,
            project_id,
            supplyersData } = this.state;

        const polylineOptions = {
            color: ['blue', '#E72929', '#401F71', '#401F71'],
            weight: 3,
        };

        const senderToport = {
            location: "Zhejiang",
            portLocation: senderPortName + ' Port',
            distance: segOneDistance,
            emission: segOneEmission
        }
        const recieverToport = {
            location: receiversPortName + " Port",
            portLocation: "Manama",
            distance: segThreeDistance,
            emission: segThreeEmission
        }
        const handleChange = (event) => {
            const selectedMaterialName = event.target.value;
            const selectedMaterialIndex = materialName.indexOf(selectedMaterialName);
            this.setState({ materialIndex: selectedMaterialIndex });
            this.setState({ material: selectedMaterialName })
        };


        return (
            <Modal
                open={true}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box sx={style}>
                    <Grid container>
                        <Grid item xs={9}>
                            <Typography
                                id='modal-modal-title'
                                component='h1'
                                style={{
                                    color: '#2D323A',
                                    marginBottom: '10px',
                                    fontSize: '17'
                                }}
                            >
                                <b>Supply Chain Carbon Metrics</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end'
                                }}
                            >
                                <Close
                                    style={{
                                        color: '#029E9E',
                                        fontSize: 25,
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        this.props.handleSupplyChainCarbonMetricsModalClose()
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Divider
                        style={{
                            width: '100%',
                            color: 'darkgray',
                            marginBottom: '0.5rem'
                        }}
                    />
                    <Grid container style={{ overflow: 'auto', position: 'relative' }}>
                        <Grid item xs={8.3} >
                            <div>
                                <LeafletMap
                                    attributionControl={false}
                                    minZoom={2.1}
                                    center={['27.715622681215372', '85.3233807114001']}
                                    zoom={3.4}
                                    style={{
                                        height: '80vh', zIndex: 0
                                    }}
                                >
                                    <TileLayer url={osm.maptiler.url} attribution={osm.maptiler.attribution} />
                                    {senderPortToRecieverPort.length > 0 && project_id === '1' ? (
                                        <>
                                            <RouteMachine route={senderToSenderPort} locToPort={senderToport} />
                                            <RouteMachine route={receiverToreceiverPort} locToPort={recieverToport} />
                                            <TextPath positions={seaRouteCoords.geometry.coordinates}
                                                color={polylineOptions.color[0]}
                                                attributes={{
                                                    'font-size': 15,
                                                    'fill': "black",
                                                }}
                                                orientation="180"
                                                offset={14}
                                                center >
                                                <Tooltip opacity={0.7} sticky>
                                                    <div style={{ textAlign: 'center', padding: '3px' }}>
                                                        Zhejiang, China
                                                        <div style={{ textAlign: 'center', background: "#029E9E" }}>
                                                            Mode of transport : SHIP
                                                        </div>
                                                        <div>
                                                            Manama, Bahrain
                                                        </div>
                                                    </div>
                                                    <div style={{ padding: '3px' }}>
                                                        Distance: {this.formatNumber(distance)}
                                                        <br />
                                                        <strong>Global Warming Potential (GWP)</strong>: {this.emission} Kg CO2/km
                                                    </div>
                                                </Tooltip>
                                            </TextPath>

                                        </>
                                    ) : (
                                        <>
                                            {
                                                supplyersData.map((item, index) => (
                                                    <React.Fragment key={index}>
                                                        {materialIndex === index && item.source_port_coordinates && item.destination_port_coordinates && item.hop_details === 'truck_ship_truck' ? (
                                                            <>
                                                                <RouteMachine route={[item.source_coordinates, item.source_port_coordinates]} locToPort={{
                                                                    location: item.source,
                                                                    portLocation: item.source_port_name + ' Port',
                                                                    distance: item.sender_to_port_distance,
                                                                    emission: item.segOne_c02Emission
                                                                }} />
                                                                <RouteMachine route={[item.destination_coordinates, item.destination_port_coordinates]} locToPort={{
                                                                    location: item.destination_port_name + ' Port',
                                                                    portLocation: item.destination,
                                                                    distance: item.port_to_receiver_distance,
                                                                    emission: item.segthree_c02Emission
                                                                }} />
                                                                <TextPath
                                                                    positions={item.coordinate_path}
                                                                    color={polylineOptions.color[0]}
                                                                    attributes={{
                                                                        'font-size': 15,
                                                                        'fill': "black",
                                                                    }}
                                                                    orientation="180"
                                                                    offset={14}
                                                                    center
                                                                >
                                                                    <Tooltip opacity={0.7} sticky>
                                                                        <div style={{ textAlign: 'center', padding: '3px' }}>
                                                                            {item.source}
                                                                            <div style={{ textAlign: 'center', background: "#029E9E" }}>
                                                                                Mode of transport: {item.hop_details.toUpperCase()}
                                                                            </div>
                                                                            <div>
                                                                                {item.destination}
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ padding: '3px' }}>
                                                                            Distance: {this.formatNumber(item.port_to_port_distance)}
                                                                            <br />
                                                                            <strong>Global Warming Potential (GWP)</strong>: {item.emission} Kg CO2/km
                                                                        </div>
                                                                    </Tooltip>
                                                                </TextPath>
                                                            </>
                                                        ) : materialIndex === index ? (
                                                            <>
                                                                <RouteMachine route={[item.source_coordinates, item.destination_coordinates]} locToPort={{
                                                                    location: item.source,
                                                                    portLocation: item.destination,
                                                                    distance: item.source_to_destination_distance,
                                                                    emission: item.segOne_c02Emission
                                                                }} />
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </React.Fragment>
                                                ))
                                            }
                                        </>
                                    )}

                                </LeafletMap>
                            </div>
                        </Grid>
                        <Divider
                            orientation="vertical"
                            style={{
                                width: '1px',  // Set the desired width of the divider
                                backgroundColor: 'lightgray',
                                margin: '0 1rem',  // Set the desired margin
                                opacity: '0.3'
                            }}
                        />
                        <Grid item xs={3.3}>
                            <Typography style={{ fontSize: '14', color: '#2D323A', paddingBottom: '10px' }}><b>Supply Chain Carbon Report</b></Typography>

                            {project_id !== '1' && (
                                <Box style={{ paddingBottom: '10px' }}>
                                    <FormControl fullWidth size='small'>
                                        {material ? null : (
                                            <InputLabel
                                                shrink={false}
                                                focused={false}
                                                id='item_type_label'
                                            >
                                                Select Option
                                            </InputLabel>
                                        )}
                                        <Select
                                            labelId='demo-select-small-label'
                                            id='demo-select-small'
                                            value={material}
                                            onChange={handleChange}
                                        >
                                            {materialName.map((item, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={item}
                                                    className="menuitem-class"
                                                >
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            )}


                            {project_id === '1' ? (
                                <>
                                    <SupplyChainStatsComponent senderToport={senderToport} recieverToport={recieverToport} distance={distance} emission={emission}></SupplyChainStatsComponent>
                                </>
                            ) : (
                                <>
                                    {
                                        supplyersData.map((item, index) => (
                                            <React.Fragment key={index}>
                                                {(materialIndex === index && item.source_port_coordinates && item.destination_port_coordinates && item.hop_details === 'truck_ship_truck' && project_id === '42') || (materialIndex === index && item.source_port_coordinates && item.destination_port_coordinates && item.hop_details === 'truck_ship_truck' && project_id === '38') ? (
                                                    <>
                                                        <SupplyChainStatsComponent senderToport={{
                                                            location: item.source,
                                                            portLocation: item.source_port_name + ' Port',
                                                            distance: item.sender_to_port_distance,
                                                            emission: item.segOne_c02Emission,
                                                            transportPort: item.hop_details
                                                        }} recieverToport={{
                                                            location: item.destination_port_name + ' Port',
                                                            portLocation: item.destination,
                                                            distance: item.port_to_receiver_distance,
                                                            emission: item.segthree_c02Emission
                                                        }} distance={item.port_to_port_distance}
                                                            transportPort={item.hop_details} emission={item.emission}></SupplyChainStatsComponent>
                                                    </>
                                                ) : (materialIndex === index && project_id === '42') || (materialIndex === index && project_id === '38') ? (
                                                    <>
                                                        <SupplyChainStatsComponent senderToport={{
                                                            location: item.source,
                                                            portLocation: item.destination,
                                                            distance: item.source_to_destination_distance,
                                                            emission: item.segOne_c02Emission
                                                        }} recieverToport={{
                                                            location: item.destination_port_name + ' Port',
                                                            portLocation: item.destination,
                                                            distance: item.port_to_receiver_distance,
                                                            emission: item.segthree_c02Emission
                                                        }} distance={item.port_to_port_distance} transportPort={item.hop_details} emission={item.emission}></SupplyChainStatsComponent>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </React.Fragment>
                                        ))

                                    }
                                </>
                            )
                            }



                        </Grid>
                        <div>
                            <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={showProgress}>
                                <CircularProgress color="inherit" />
                            </Backdrop>
                        </div>
                    </Grid >
                </Box>

            </Modal >
        );
    }
}

export default Map;
