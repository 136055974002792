import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { Tooltip, Avatar, Divider } from '@mui/material';
import datajson from './../template.json';
import UserService from '../../services/UserService';


export default function NavbarCanvas() {

    let initials;

    const [isSignoutPopupOpen, setSignoutPopupOpen] = React.useState(false);

    try {
        const userName = sessionStorage.getItem('userName');
        if (userName !== null) {
            initials = userName
                .split(' ')
                .map(word => word.charAt(0))
                .slice(0, 2)
                .join('');
        }
    } catch (error) {

    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar
                position="relative"
                style={{ backgroundImage: "linear-gradient(#0C6276,#00A9A6)", height: '55px', zIndex: '3' }}>
                <Toolbar style={{ height: '55px' }}>
                    <img
                        src={`${process.env.REACT_APP_BASE_URL}${datajson.images.logo.withName.white}`}
                        alt=''
                        style={{
                            width: '170px',
                            height: '19px'
                        }}
                    />

                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <Tooltip title="Settings" arrow>
                            <IconButton
                                size="large"
                                // edge="end"
                                aria-label="Settings"
                                aria-haspopup="false"
                                style={{ padding: "4px" }}
                            >
                                <img
                                    src={`${process.env.REACT_APP_BASE_URL}${datajson.images.navbar.Canvas.Settings}`}
                                    alt='settings'
                                    style={{
                                        width: '21px',
                                        height: '21px',
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                        <IconButton
                            size="large"
                            // edge="end"
                            aria-label="account of current user"
                            aria-haspopup="true"
                            style={{ padding: "4px" }}
                            onClick={() => { setSignoutPopupOpen(!isSignoutPopupOpen) }}
                        >
                            <Avatar sx={{ bgcolor: '#029E9E', color: 'white', width: 28, height: 28, fontSize: '0.7rem' }}>{initials}</Avatar>
                        </IconButton>
                        <Tooltip title="Logout" arrow>
                            <IconButton
                                size="large"
                                // edge="end"
                                aria-label="account of current user"
                                aria-haspopup="true"
                                style={{ padding: "4px" }}
                                onClick={UserService.doLogout}
                            //   color="inherit"
                            >
                                <img
                                    src={`${process.env.REACT_APP_BASE_URL}${datajson.images.navbar.Canvas.logout}`}
                                    alt='logout'
                                    style={{
                                        width: '21px',
                                        height: '21px',
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                    </Box>

                    {isSignoutPopupOpen && (
                        <div className="signout-popup">
                            <div className="signout-popup-content">
                                <div className="user-info" style={{ display: 'flex', alignItems: 'center' }}>
                                    <Avatar sx={{ bgcolor: '#029E9E', width: 55, height: 55, fontSize: '1.5rem', padding: "5px" }}>{initials}</Avatar>
                                    <div style={{ marginLeft: '16px' }}>
                                        <div className="user-name">{sessionStorage.getItem('userName')}</div>
                                        <div className="user-role">{sessionStorage.getItem('email')}</div>
                                        <div className="user-role">{sessionStorage.getItem('role')}</div>
                                    </div>
                                </div>
                                <Divider />
                            </div>
                        </div>
                    )}

                </Toolbar>
            </AppBar>

        </Box>
    );
}