import * as React from 'react'
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import { MenuItem, Grid, Paper } from '@material-ui/core'
import {
  Button,
  Checkbox,
  FormControl,
  Modal,
  Select,
  Table,
  InputLabel,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'

import { Close } from '@mui/icons-material'
import OutlinedInput from '@mui/material/OutlinedInput'
import ListItemText from '@mui/material/ListItemText'
import axios from 'axios'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
const Base_URL = process.env.REACT_APP_BACKEND_URL
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: 'calc(100vh - 65.20px)',
  width: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  overflowY: 'scroll',
  '.MuiOutlinedInput-input': {
    height: "20px",
    padding: 1
  },
}
const tableContentStyle = {
  fontSize: '10.2',
  lineHeight: '1',
  fontWeight: 'bold'
}
const ITEM_HEIGHT = 40
const ITEM_PADDING_TOP = 4
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}
function Substitutes(props) {
  const [showContent, setShowContent] = useState(false)
  const [rootNodeSelected, setRootNodeSelected] = useState(null)
  const [mainData_, setMainData] = useState({})
  const [customCategory, setCustomCategory] = useState('')
  const [gwp100, setGWP100] = useState(0)
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const [substitute_Data, set_substituteData] = useState([])
  const [chartTitle, setChartTitle] = useState('Compare Substitutes');
  const [rows, setRows] = useState([])

  const handleMenuOpen = event => {
    setDropdownOpen(true)
    const selectedValue = event.target.value
    handleAlternativeSelect(selectedOption, selectedValue)
    if (selectedValue === event.target.value) {
      if (mainData_.substitute.length > 0) {
        setCustomCategory(event.target.value)
      }
    }

  }

  const formatNumber = (num) => {
    if (typeof num === 'number') {
      const roundedNumber = parseFloat(num.toFixed(2));
      return (roundedNumber.toLocaleString('en-US'));
    }
    else {
      return num;
    }
  }
  const handleCheckboxChange = (rowId) => {
    const updatedRows = rows.map(row => {
      if (row.id === rowId) {
        return {
          ...row,
          checked: !row.checked
        };
      }
      return row;
    });
    setRows(updatedRows);
  };


  const [rootDetails, setRootDetails] = useState([])
  const [checkboxSelect, setcheckbox] = useState([
    'Global Warming Potential'
  ])

  const handleVariableChange = (event) => {
    const {
      target: { value },
    } = event;

    const updatedSelectedValues = Array.isArray(value) ? value : [];

    let title = 'Compare Substitutes';
    setChartTitle(title);
    setcheckbox(updatedSelectedValues);
  };
  const handleMenuClose = () => {
    setDropdownOpen(false)

  }

  const getRootNodeDetails = async () => {
    let rootNDetails = await props.getRootNodesUid()
    setRootDetails(rootNDetails)
  }
  const [selectedOption, setselectedOption] = useState([])
  const handleRootNodeSelect = async event => {
    try {
      const selectedValue = event.target.value
      setRootNodeSelected(selectedValue)
      setselectedOption(selectedValue)

      set_substituteData([]);
      const config = {
        headers: {
          'content-type': 'application/json',
          authorization: 'bearer ' + sessionStorage.getItem('token'),
          projectid: sessionStorage.getItem('project_id'),
          tenantid: sessionStorage.getItem('tenanatId'),
          option: selectedValue
        }
      }
      const response = await axios.get(
        Base_URL +
        '/substitutes/get-substitutes/' +
        sessionStorage.getItem('tenanatId'),
        config
      )
      const mainData = response.data.data.Nodes.mainData[0]
      if (mainData.substitute.length > 0) {
        const substituteUids = mainData.substitute.map(
          substitute => substitute.properties.uid
        )
        const gwp_100 = mainData.substitute.map(gwp => gwp.properties.gwp_100)
        setGWP100(gwp_100)
        set_substituteData(substituteUids)
      }
      setMainData(mainData)
      return response
    } catch (error) {
      console.error(error)
      throw new Error('Error occurred while handling root node selection.')
    }
  }
  const handleAlternativeSelect = async (selectedOptionFromRoot, uid) => {

    setShowContent(true)
    try {
      const config = {
        headers: {
          'content-type': 'application/json',
          authorization: 'bearer ' + sessionStorage.getItem('token'),
          projectid: sessionStorage.getItem('project_id'),
          tenantid: sessionStorage.getItem('tenanatId'),
          option: selectedOptionFromRoot,
          substituteof: uid
        }
      }
      const response = await axios.get(
        Base_URL +
        '/substitutes/get-substituteNodes/' +
        sessionStorage.getItem('tenanatId'),
        config
      )
      const substitutes = response.data.data.Nodes.mainData[0].substitutes
      const updatedRows = substitutes.map((substitute, index) => ({
        id: index + 1,
        alternatives: substitute.properties.name,
        global_warming_potential: substitute.properties.gwp_100,
        eci_cost: substitute.properties.eci_cost,
        htp: substitute.properties.htp,
        density_variation: 'Similar',
        melting_point: 'Substitute',
        Conductivity: 'conductivity',
        checked: false
      }))
      setRows(updatedRows)

      handleMenuClose()
      return response
    } catch (error) {
      console.error(error)
      throw new Error('Error occurred while handling substitute selection.')
    }
  }

  const ColumnChart = ({ title }) => {
    if (!showContent) return null;
    const chartOptions = {
      chart: {
        type: 'column',
        height: 'calc(100vh - 400.20px)',
      },
      title: {
        text: title,
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: rows.map((rowinfo) => rowinfo.alternatives),
        crosshair: true,
      },
      yAxis: {
        title: {
          text: 'Values',
          rotation: -90,
        },
      },

      tooltip: {
        shared: false,
        formatter: function () {


          let row = rows[this.point.index]

          let tooltipContent = `<b>${row.alternatives}</b><br>`
          tooltipContent += `GWP: ${formatNumber(row.global_warmming_potential)}<br>`
          if (checkboxSelect.includes('Human Toxicity Potential')) {
            tooltipContent += `HTP: ${formatNumber(row.htp)}<br>`
          }

          if (checkboxSelect.includes('ECI Costs')) {
            tooltipContent += `ECI Costs: ${formatNumber(row.eci_cost)}`
          }

          if (this.series.name === 'Global Warming Potential') {
            tooltipContent = `GWP: ${formatNumber(row.global_warming_potential)}<br>`
          } else if (this.series.name === 'ECI Cost') {
            tooltipContent = `ECI Cost: ${formatNumber(row.eci_cost)}<br>`
          } else if (this.series.name === 'HTP') {
            tooltipContent = `HTP: ${formatNumber(row.htp)}<br>`
          }

          return tooltipContent
        }
      },
      plotOptions: {
        column: {
          pointWidth: 25,
          dataLabels: {
            enabled: true,
          },
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
      series: [
        checkboxSelect.includes('Global Warming Potential')
          ? {
            name: 'Global Warming Potential',
            data: rows.map((rowInfo) => parseFloat(rowInfo.global_warming_potential.toFixed(2))),
            color: {
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [
                [0, '#8BD3DD'],
                [1, '#6EAFC7'],
              ],
            },
          } : null,
        checkboxSelect.includes('ECI Costs')
          ? {
            name: 'ECI Cost',
            data: rows.map((rowInfo) => parseFloat(rowInfo.eci_cost.toFixed(2))),
            color: {
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [
                [0, '#FEDF87'],
                [1, '#FEB557'],
              ],
            },
          }
          : null,
        checkboxSelect.includes('Human Toxicity Potential')
          ? {
            name: 'HTP',
            data: rows.map((rowInfo) => parseFloat(rowInfo.htp.toFixed(2))),
            color: {
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [
                [0, '#A3E57D'],
                [1, '#71CC66'],
              ],
            },
          }
          : null,
      ].filter((series) => series !== null),
    };
    return (
      <div id="chart-container" style={{ height: 'calc(80vh - 100.20px)' }}>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </div>
    );
  };
  useEffect(() => {
    getRootNodeDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      open={true}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      overflowY={'auto'}
    >
      <Box sx={style}>
        <div>
          <div style={{ maxHeight: 'calc(100vh - 12.20px)' }}>
            <Grid container>
              <Grid item xs={9}>
                <Typography
                  id='modal-modal-title'
                  component='h1'
                  style={{
                    color: '#2D323A',
                    marginBottom: '10px',
                    fontSize: '17'
                  }}
                >
                  <b>Substitutes</b>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end'
                  }}
                >

                  <Close
                    style={{
                      color: '#029E9E',
                      fontSize: 25,
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      props.handleSubstituteClose()
                    }}
                  />
                </div>
              </Grid>
            </Grid>
            <Divider
              style={{
                width: '100%',
                color: 'darkgray',
                marginBottom: '0.5rem'
              }}
            />
            <Grid container>
              <Grid xs={3}>
                <div style={{ color: '#0C6276', fontSize: '14px', marginBottom: '10px' }}>
                  <b> Options </b>
                </div>
                <div style={{ paddingRight: '20px' }}>
                  <FormControl sx={{ width: '100%' }} size='small'>
                    {rootNodeSelected ? null : (
                      <InputLabel
                        shrink={false}
                        focused={false}
                        id='item_type_label'
                      >
                        Select Option
                      </InputLabel>
                    )}
                    <Select
                      labelId='demo-select-small-label'
                      id='demo-select-small'
                      value={rootNodeSelected}
                      onChange={handleRootNodeSelect}
                    // autoWidth={false}
                    >
                      {rootDetails.map((item, index) => (
                        <MenuItem key={item.id} value={item.uid}>
                          {item.uid}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              <Grid xs={3}>
                <div style={{ color: '#0C6276', fontSize: '14px', marginBottom: '10px' }}>
                  <b> Category </b>
                </div>
                <div style={{ paddingRight: '20px' }}>
                  <FormControl sx={{ width: '100%' }} size='small'>
                    {substitute_Data.length === 0 ? (
                      <InputLabel
                        shrink={false}
                        focused={false}
                        id='item_type_label'
                      >
                        Select Category
                      </InputLabel>
                    ) : null}
                    <Select
                      labelId='demo-select-small-label'
                      id='demo-select-small'
                      onChange={handleMenuOpen}
                      value={substitute_Data}
                      open={isDropdownOpen}
                      onClose={handleMenuClose}
                      onOpen={() => setDropdownOpen(true)}
                    >
                      {substitute_Data.length === 0
                        ? null
                        : substitute_Data.map(substituteUid => (
                          <MenuItem key={substituteUid} value={substituteUid}>
                            {substituteUid}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              <Grid xs={3}>
                <div style={{ color: '#0C6276', fontSize: '14px', marginBottom: '10px' }}>
                  <b> Select Variable </b>
                </div>
                <div>
                  <FormControl sx={{ width: '100%' }} size='small'>
                    {checkboxSelect.length === 0 ? (
                      <InputLabel
                        shrink={false}
                        focused={false}
                        id='item_type_label'
                      >
                        Select Variable
                      </InputLabel>
                    ) : null}
                    <Select
                      labelId='demo-select-small-label'
                      id='demo-select-small'
                      multiple
                      value={checkboxSelect}
                      onChange={handleVariableChange}
                      input={<OutlinedInput label='Tag' />}
                      renderValue={selected => {
                        if (selected.length === 0) {
                          return 'Global Warming Potential';
                        }
                        return selected.join(', ');
                      }}
                      MenuProps={MenuProps}
                    >
                      <MenuItem value='Global Warming Potential'>
                        <Checkbox checked={checkboxSelect.includes(
                          'Global Warming Potential'
                        )} />
                        <ListItemText primary='Global Warming Potential' />
                      </MenuItem>
                      <MenuItem value='Human Toxicity Potential'>
                        <Checkbox
                          checked={checkboxSelect.includes(
                            'Human Toxicity Potential'
                          )}
                        />
                        <ListItemText primary='Human Toxicity Potential' />
                      </MenuItem>
                      <MenuItem value='ECI Costs'>
                        <Checkbox
                          checked={checkboxSelect.includes('ECI Costs')}
                        />
                        <ListItemText primary='ECI Costs' />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Grid>
            </Grid>
            {showContent && (
              <Typography
                id='modal-modal-title'
                component='h1'
                style={{ color: '#0C6276', marginTop: '15px', fontSize: '16' }}
              >
                <b>
                  {customCategory} - Global_warming_potential(kg CO2): {formatNumber(gwp100)}
                </b>
              </Typography>
            )}
            {showContent && (
              <Divider
                style={{
                  width: '100%',
                  color: 'darkgray',
                  margin: '0.4rem 0rem'
                }}
              />
            )}
            {showContent && (
              <div>
                <TableContainer component={Paper} sx={{ width: '100%' }}>
                  <Table
                    size='small'
                    aria-label='a dense table'
                    sx={{ border: 'none' }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align='center'
                          style={tableContentStyle}
                        ></TableCell>
                        <TableCell align='center' style={tableContentStyle}>
                          Substitutes
                        </TableCell>
                        {checkboxSelect.includes(
                          'Global Warming Potential'
                        ) && (
                            <TableCell align='center' style={tableContentStyle}>
                              Global_warming_potential(kg CO2)
                            </TableCell>
                          )}
                        {checkboxSelect.includes(
                          'Human Toxicity Potential'
                        ) && (
                            <TableCell align='center' style={tableContentStyle}>
                              Human Toxicity Potential(kg DCB eq.)
                            </TableCell>
                          )}
                        {checkboxSelect.includes('ECI Costs') && (
                          <TableCell align='center' style={tableContentStyle}>
                            ECI Costs(USD)
                          </TableCell>
                        )}
                        <TableCell align='center' style={tableContentStyle}>
                          density_variation
                        </TableCell>
                        <TableCell align='center' style={tableContentStyle}>
                          melting_point
                        </TableCell>
                        <TableCell align='center' style={tableContentStyle}>
                          Conductivity
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map(row => (
                        <TableRow key={row.id}>
                          <TableCell padding='checkbox'>
                            <Checkbox
                              checked={row.checked}
                              onChange={() => handleCheckboxChange(row.id)}
                            />
                          </TableCell>
                          <TableCell align='center'>
                            {row.alternatives}
                          </TableCell>
                          {checkboxSelect.includes(
                            'Global Warming Potential'
                          ) && (
                              <TableCell align='center'>
                                {formatNumber(row.global_warming_potential)}
                              </TableCell>
                            )}
                          {checkboxSelect.includes(
                            'Human Toxicity Potential'
                          ) && <TableCell align='center'>{formatNumber(row.htp)}</TableCell>}
                          {checkboxSelect.includes('ECI Costs') && (
                            <TableCell align='center'> ${formatNumber(row.eci_cost)}</TableCell>
                          )}
                          <TableCell align='center'>
                            {row.density_variation}
                          </TableCell>
                          <TableCell align='center'>
                            {row.melting_point}
                          </TableCell>
                          <TableCell align='center'>
                            {row.Conductivity}
                          </TableCell>
                          <TableCell align='center'>
                            {row.selectedOptions}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
          </div>
        </div>
        {showContent && (
          <div id="chart-container" style={{ border: '1px solid gray', height: '10vh-700.20px', marginTop: '15px' }}>
            <ColumnChart showContent={true} rows={rows} title={chartTitle} checkboxSelect={checkboxSelect} />
          </div>
        )}
        {showContent && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: 'flex-end',

            }}
          >
            <div style={{ flex: 1 }} />
            <Button
              variant="contained"
              color="secondary"
              style={{
                fontSize: "14px",
                marginRight: "10px",
                background: "#6f7173",
                border: "1px solid #dfdfdf",
                opacity: 1,
                color: "white",
                width: "150px",
                borderRadius: "2px",
                boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)",
              }}
              size="small"
              onClick={() => {
                props.handleSubstituteClose();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{
                fontSize: "14px",
                marginRight: "10px",
                background:
                  "transparent linear-gradient(180deg, #0C6276 0%, #00A9A6 100%) 0% 0% no-repeat padding-box",
                color: "white",
                width: "150px",
                border: "1px solid #dfdfdf",
                opacity: 1,
                borderRadius: "2px",
                boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)",
              }}
              size="small"
            >
              Submit
            </Button>
          </Box>
        )}

      </Box>
    </Modal>
  )
}
export default Substitutes