import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

import { Modal } from '@mui/material'
import { Grid } from '@material-ui/core'

import { Close } from '@mui/icons-material'



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: 'calc(100vh - 65.20px)',
    width: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflowY: 'scroll',
}





function ESgreport(props) {

    return (
        <Modal
            open={true}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            overflowY={'auto'}
        >

            <Box sx={style}>
                <div>
                    <div style={{ maxHeight: 'calc(100vh - 12.20px)' }}>
                        <Grid container>
                            <Grid item xs={9}>
                                <Typography
                                    id='modal-modal-title'
                                    component='h1'
                                    style={{
                                        color: '#2D323A',
                                        marginBottom: '10px',
                                        fontSize: '17'
                                    }}
                                >
                                    <b>ESG Reports</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end',

                                    }}
                                >
                                    <Close
                                        style={{
                                            color: '#029E9E',
                                            fontSize: 25,
                                            cursor: 'pointer',
                                            top: "50"
                                        }}
                                        onClick={() => {
                                            props.handleESGModalClose()
                                        }}
                                    />
                                </div>
                            </Grid>
                        </Grid>

                        <Divider
                            style={{
                                width: '100%',
                                color: 'darkgray',
                                marginBottom: '0.5rem'
                            }}
                        />

                        <Grid container style={{
                            position: 'absolute',
                            height: '680',
                            width: '100%',
                            boxShadow: 24,
                            display: 'flex',
                        }}>


                            <iframe
                                width="100%"
                                height="680"
                                title='Esgreport'
                                src={props?.esgReportLink || ""}
                                frameborder="0"
                                style={{ border: 0 }}
                                allowfullscreen
                            />
                        </Grid>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

export default ESgreport