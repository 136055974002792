import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Container,
} from "@mui/material";
import { Bar } from "react-chartjs-2";
import neo4j from "neo4j-driver";
import NodeChildProperties from "./NodeChildProperties";
import Neo4jSpecificSankeyChart from "./Neo4jSpecificSankeyChart";
import { Chart, registerables } from 'chart.js';

import Draggable from 'react-draggable';
import { Key, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
Chart.register(...registerables);
const NodeProperties = ({ properties, neo4jConfig, width, envImpacts, selectedEnvProperty, setSelectedEnvProperty, setChildNodeCount }) => {
  const containerStyle = {
    // display: "flex",
    // display:"initial",
    // overflowY: 'hidden',
    display: width === '292%' ? "flex" : "initial",
    flexDirection: "row",
    gap: "5px",
    // height: width === '292%' ? "240px" : "350px",

  };

  const chartStyle = {
    // padding: "20px",
    // border: "2px solid #3f51b5",
    // borderRadius: "10px",
    // backgroundColor: "#f0f0f0",
    // flex: 1,
    // width: width === '292%' ? "290px" : "480px",
    // height: width === '292%' ? "200px" : "230px",
    zIndex: 0,
  };

  const tableStyle = {
    // padding: "20px",
    // border: "2px solid #3f51b5",
    // borderRadius: "10px",
    // backgroundColor: "#f0f0f0",
    flex: 1,
    zIndex: 0,
    // width: '260px'
  };

  const [maximized, setMaximized] = useState(false); // State variable to track maximize state
  const [selectedProperty, setSelectedProperty] = useState(selectedEnvProperty || "gwp_100");
  const [chartDataValues, setChartDataValues] = useState([]);
  const [childNodes, setChildNodes] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [barUid, setbarUid] = useState(null);
  const [alternativeNodes, setAlternativeNodes] = useState([]);
  const [alternativeChartData, setAlternativeChartData] = useState(null);
  const [noChildNodes, setNoChildNodes] = useState(true); // New state to track nodes with no children
  const [allNodesData, setAllNodesData] = useState([]);

  useEffect(() => {
    setSelectedProperty(selectedEnvProperty);
  }, [selectedEnvProperty]);

  useEffect(() => {
    setChildNodeCount(childNodes.length);
  }, [childNodes]);

  useEffect(() => {
    const fetchChildNodes = async () => {
      const { serverUrl, username, password } = neo4jConfig;
      const driver = neo4j.driver(serverUrl, neo4j.auth.basic(username, password));
      const session = driver.session();

      const nodeId = properties.uid;

      try {
        const query = `MATCH (n {uid: '${nodeId}'})-[r]->(m) RETURN m, r;`;
        // const query = `MATCH (n)-[r]->(m) RETURN m, r;`;
        const result = await session.run(query);
        const newChildNodes = result.records.map((record) => {
          const node = record.get("m");
          console.log(node);
          // console.log("+++++++++++++++");
          const relationship = record.get("r");
          //   setbarUid(node.properties.uid);
          return {
            id: node.identity.toString(),
            label: truncateLabel(node.properties.name),
            gwp_100: node.properties.gwp_100,
            uid: node.properties.uid,
            relationshipType: relationship.type,
            ap: node.properties.ap,
            faetp: node.properties.faetp,
            maetp: node.properties.maetp,
            tetp: node.properties.tetp,
            adp_fossil: node.properties.adp_fossil,
            ep: node.properties.ep,
            htp: node.properties.htp,
            adp_elements: node.properties.adp_elements,
            odp: node.properties.odp,
            pocp: node.properties.pocp,
            category: node.properties.category,
          };
        });

        setChildNodes(newChildNodes);

        // if (newChildNodes.length > 0) {
        //   const chartLabels = newChildNodes.map(node => node.label);
        //   const chartDataValues = newChildNodes.map(node => node.gwp_100);
        //   setNoChildNodes(false);
        //   setChartData({
        //     labels: chartLabels,
        //     datasets: [
        //       {
        //         label: 'Child nodes-GWP Values(KgCo2 Eq)',
        //         data: chartDataValues,
        //         backgroundColor: '#84B4E0',
        //       },
        //     ],
        //   });
        // }

        const capitalizeFirstLetter = (string) => {
          return string.charAt(0).toUpperCase() + string.slice(1);
        };
        if (newChildNodes.length > 0) {
          // const  chartDataValues = newChildNodes.map((node) => node.gwp_100);
          setChartDataValues(newChildNodes.map((node) => node.gwp_100));
          const chartLabels = newChildNodes.map((node) => node.label);
          if (selectedProperty === "htp") {
            setChartDataValues(newChildNodes.map((node) => node.htp));
          } else if (selectedProperty === "adp_fossil") {
            setChartDataValues(newChildNodes.map((node) => node.adp_fossil));
          } else if (selectedProperty === "ep") {
            setChartDataValues(newChildNodes.map((node) => node.ep));
          } else if (selectedProperty === "adp_elements") {
            setChartDataValues(newChildNodes.map((node) => node.adp_elements));
          } else if (selectedProperty === "odp") {
            setChartDataValues(newChildNodes.map((node) => node.odp));
          } else if (selectedProperty === "pocp") {
            setChartDataValues(newChildNodes.map((node) => node.pocp));
          } else if (selectedProperty === "ap") {
            setChartDataValues(newChildNodes.map((node) => node.ap));
          } else if (selectedProperty === "gwp_100") {
            setChartDataValues(newChildNodes.map((node) => node.gwp_100));
          } else if (selectedProperty === "faetp") {
            setChartDataValues(newChildNodes.map((node) => node.faetp));
          } else if (selectedProperty === "maetp") {
            setChartDataValues(newChildNodes.map((node) => node.maetp));
          } else if (selectedProperty === "tetp") {
            setChartDataValues(newChildNodes.map((node) => node.tetp));
          }
          setNoChildNodes(false);

          setChartData({
            labels: chartLabels,
            datasets: [
              {
                label: `Child nodes-${capitalizeFirstLetter(
                  selectedProperty
                )} Values(KgCo2 Eq)`,
                data: chartDataValues,
                backgroundColor: "#84B4E0",
              },
            ],
          });
        }

        session.close();
      } catch (error) {
        console.error("Error fetching child nodes:", error);
        session.close();
      }

      try {
        const secondQuery = `
          MATCH (p)-[]->(n {uid: '${nodeId}'})
          MATCH (p)-[]->(a) WHERE 'Alternative' IN labels(a)
          RETURN a AS ab ;
        `;
        const secondSession = driver.session();

        const secondResult = await secondSession.run(secondQuery);
        const newAlternativeNodes = secondResult.records.map((record) => {
          const node = record.get("ab");
          return {
            id: node.identity.toString(),
            label: truncateLabelFromEnd(node.properties.name),
            gwp_100: node.properties.gwp_100,
          };
        });

        setAlternativeNodes(newAlternativeNodes);

        if (newAlternativeNodes.length > 0) {
          const alternativeChartLabels = [
            ...new Set(newAlternativeNodes.map((node) => node.label)),
          ];
          const alternativeChartDataValues = newAlternativeNodes.map(
            (node) => node.gwp_100
          );

          if (alternativeNodes.length > 0) {
            setAlternativeChartData({
              labels: alternativeChartLabels,
              datasets: [
                {
                  label: "Alternative Nodes",
                  data: alternativeChartDataValues,
                  backgroundColor: "rgba(255,99,132,0.6)",
                },
              ],
            });
          }
        }

        secondSession.close();
      } catch (error) {
        console.error("Error fetching Alternative nodes:", error);
      } finally {
        driver.close();
      }
    };

    fetchChildNodes();
  }, [properties.id, selectedProperty, chartData, chartDataValues, childNodes, width]);

  const truncateLabel = (label) => {
    const maxLength = 9;
    return label.length > maxLength ? `${label.slice(0, maxLength)}...` : label;
  };

  const truncateLabelFromEnd = (label) => {
    const match = label.match(/\((.*?)\)/);
    return match ? match[1] : label;
  };

  const handleMaximizeToggle = () => {
    setMaximized(!maximized);
  };


  const renderChart = () => {
    if (chartData) {
      return (

        // <div style={{ ...chartStyle, backgroundColor: "white" }}>
        <div className="node-property" id="np-2">

          <div className="node-header" onClick={() => toogleCollapse('np-2')}>
            <label>Environmental Impacts</label>
            <div className="node-header-action">
              <KeyboardArrowDown />
            </div>
          </div>
          <div className="node-property-content">
            {/* <h3 style={{ borderBottom: '2px solid #3f51b5',  textAlign: 'center',paddingBottom: '10px', margin: '0 auto', marginBottom: '15px' }}>Properties of Node</h3> */}
            {/* <Container  > */}
            <div>
              {/* Dropdown for property selection */}
              {/* <label>
                <strong>{properties.name} </strong>
              </label> */}
              {/* <label>Select Environmental Metrics: </label> */}

              {/* <select
                value={selectedProperty}
                onChange={(e) => setSelectedProperty(e.target.value)}
                className="select-property"
              >
                {EnvImpacts.map((impact) => (
                  <option key={impact.value} value={impact.value}>
                    {impact.label}
                  </option>
                ))}
              </select> */}

              {/* ... (your other UI elements) */}
            </div>
            <div height="200px" width="160px" >
              <Bar height="180px" width='150px'

                // height="440px"
                // width='390px'
                data={chartData}
                options={{
                  scales: {
                    y: {
                      beginAtZero: true,
                    },
                  },
                  maintainAspectRatio: false,
                  responsive: true,
                  onClick: handleBarClick,
                }}
              />
            </div>
          </div>
        </div>

      );
    }
    return null;
  };


  const handleBarClick = (event, elements) => {

    // Check if any bars are clicked
    if (elements.length > 0) {
      const clickedIndex = elements[0].index;
      const clickedNode = childNodes[clickedIndex];

      console.log("___________________________");

      console.log("___________________________");
      console.log("Clicked Node:", clickedNode);
      console.log(clickedNode.uid);
      setbarUid(clickedNode.uid);
    }
  };

  function getRating(ecoScore) {
    let rating = '';
    let textColor = '';
    let bgColor = '';
    let circleSize = '14px';
    let containerStyle = {
      display: 'flex',

    };
    let circleStyle = {
      width: circleSize,
      height: circleSize,
      borderRadius: '30%',
      textAlign: 'center',
      lineHeight: circleSize,
      marginRight: '3px',
    };


    if (ecoScore >= 0 && ecoScore <= 0.7142857) {
      rating = 'AAA';
      textColor = 'black';
      bgColor = 'darkgreengreen';
    } else if (ecoScore >= 0.7142 && ecoScore <= 1.42857) {
      rating = 'AA';
      textColor = 'black';
      bgColor = 'lightgreen';
    }
    else if (ecoScore >= 1.42857 && ecoScore <= 2.142857) {
      rating = 'A';
      textColor = 'black';
      bgColor = 'yellow';
    }
    else if (ecoScore >= 2.142857 && ecoScore <= 2.857) {
      rating = 'BBB';
      textColor = 'black';
      bgColor = 'orange';
    } else if (ecoScore >= 2.857 && ecoScore <= 3.571) {
      rating = 'BB';
      textColor = 'black';
      bgColor = 'orange';
    }
    else if (ecoScore >= 3.571 && ecoScore <= 4.286) {
      rating = 'B';
      textColor = 'black';
      bgColor = 'darkorange';
    }
    else if (ecoScore >= 4.286 && ecoScore <= 5.00) {
      rating = 'CCC';
      textColor = 'black';
      bgColor = 'red';
    }


    return (
      <div style={{ ...containerStyle, lineHeight: "0.75" }}>
        Rating:
        <div
          style={{
            ...circleStyle,
            color: textColor,
            backgroundColor: bgColor,
            verticalAlign: "middle",
            display: "inline-block",
            marginLeft: "5px"
          }}
        >

        </div>
        <span style={{ color: textColor, marginLeft: "3px", verticalAlign: "middle" }}>{rating}</span>
      </div>
    );
  }
  const renderAlternativeChart = () => {
    if (alternativeChartData) {
      return (
        <div style={{ ...chartStyle, backgroundColor: "white" }}>
          <Bar
            data={alternativeChartData}
            options={{
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
              maintainAspectRatio: false,
              responsive: true,
            }}
          />
        </div>
      );
    }
    return null;
  };

  const toogleCollapse = (id) => {
    const nodeProperty = document.getElementById(id);
    if (nodeProperty.classList.contains('hide')) {
      nodeProperty.classList.remove('hide');
    } else {
      nodeProperty.classList.add('hide');
    }
  };

  return (
    <>      {/* <Neo4jSpecificSankeyChart uid={barUid} neo4jConfig={neo4jConfig} /> */}
      <div className="node-property-wrapper" style={containerStyle} >

        {/* <div width='350px' height="310px"> */}
        <div className={"node-property"} id='np-1'>
          <div className="node-header" onClick={() => toogleCollapse('np-1')}>
            <label>Node Properties {properties?.name && '- ' + properties?.name} </label>
            <div className="node-header-action">
              <KeyboardArrowDown />
            </div>
          </div>
          <div className="node-property-content"
            style={{
              ...tableStyle,

              // backgroundColor: "white",
              textAlign: "center",
              // padding: "20px",
              borderRadius: "10px",
              //   height:'300px',
              // height: width === '292%' ? "200px" : "300px",
              //   width:'350px'
              // width: width === '292%' ? "270px" : "480px",

              //   width:'350px'
            }}
          >
            <ul className="node-property-list"
              style={{ listStyleType: "none", padding: "0", textAlign: "left" }}
            >
              <li><label>Node:</label> {properties.name}</li>
              {!isNaN(properties.gwp_100) && (
                <li><label>GWP:</label> {Number(properties.gwp_100).toFixed(2)} KgCO<sub>2</sub> Eq.</li>
              )}
              {properties.chemical_formula &&
                <li><label>Chemical Formula:</label> {properties.chemical_formula}</li>
              }
              {properties.smiles &&
                <li><label>SMILES:</label> {properties.smiles}</li>
              }
              {properties.eco_score &&
                <li><label>Eco Score:</label> {properties.eco_score}</li>
              }
              {properties.eco_score &&
                <li>{getRating(properties.eco_score)}</li>
              }



              <div style={{ display: "none", flexDirection: "row" }}>
                {/* Left side */}
                <div style={{ marginRight: "20px" }}>
                  <ul>
                    {/* Properties on the left side */}
                    <li style={{ marginBottom: "10px" }}>
                      <strong>Node:</strong> {properties.name}
                    </li>

                    {!isNaN(properties.gwp_100) && (
                      <li style={{ marginBottom: "8px" }}>
                        <strong>GWP:</strong>{" "}
                        {Number(properties.gwp_100).toFixed(2)} KgCO<sub>2</sub> Eq.
                      </li>
                    )}
                    {properties.chemical_formula && (
                      <li style={{ marginBottom: "8px" }}>
                        <strong>Chemical Formula:</strong>{" "}
                        {properties.chemical_formula}
                      </li>
                    )}
                    {properties.smiles && (
                      <li style={{ marginBottom: "8px" }}>
                        <strong>SMILES:</strong> {properties.smiles}
                      </li>
                    )}

                  </ul>
                  {properties.smiles && (
                    <li style={{ textAlign: "center" }}>
                      <img
                        src={`https://pubchem.ncbi.nlm.nih.gov/rest/pug/compound/name/${properties.name}/PNG?record_type=3d`}
                        alt={`3D structure of ${properties.name}`}
                        style={{
                          width: width === '292%' ? "0px" : "220px", // Adjust the width as needed
                          height: width === '292%' ? "0px" : "200px", // Adjust the height as needed
                          maxWidth: "100%",
                        }}
                      />
                    </li>
                  )}

                </div>

                {/* Right side */}
                <div>
                  <ul>
                    {properties.eco_score && (
                      <li style={{ marginBottom: "8px" }}>
                        <strong>Eco Score:</strong> {properties.eco_score}
                      </li>
                    )}

                    {properties.eco_score && (
                      <li >
                        <strong style={{ marginRight: "5px" }}>{getRating(properties.eco_score)}</strong>

                      </li>
                    )}
                  </ul>
                </div>
              </div>

            </ul>

            {/* "Standards" heading */}

            {!properties.smiles && (
              <div>
                <h3
                  style={{
                    borderBottom: "1px solid #3f51b5",
                    margin: "0 auto",
                    fontSize: "1em",
                    textAlign: "left",
                    paddingBottom: "5px",
                    marginBottom: "10px",
                  }}
                >
                  Standards
                </h3>

                <div
                  style={{
                    display: "flex",
                    gap: "14px",
                    marginBottom: "4px",
                    // alignItems: "center",
                  }}
                >
                  {/* First small image in a box */}
                  <div
                    style={{
                      border: "1px solid #ccc",
                      padding: "5px",
                      borderRadius: "5px",
                    }}
                  >
                    <img
                      src="https://scottishlandandestates.co.uk/sites/default/files/inline-images/IPCC.jpg"
                      alt="Small Image 1"
                      style={{ maxWidth: width === '292%' ? "25px" : "440px", maxHeight: width === '292%' ? "25px" : "40px" }}
                    />
                  </div>
                  {/* Second small image in a box */}
                  <div
                    style={{
                      border: "1px solid #ccc",
                      padding: "5px",
                      borderRadius: "5px",
                    }}
                  >
                    <img
                      src="https://th.bing.com/th/id/OIP.Y393PU8lUIo4BJX2ateH-wAAAA?pid=ImgDet&w=202&h=188&c=7"
                      alt="Small Image 2"
                      style={{ maxWidth: width === '292%' ? "25px" : "40px", maxHeight: width === '292%' ? "25px" : "40px" }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

        </div>

        {/* Common env matric selection for all graphs */}
        {childNodes.length > 0 && <>
          <select
            value={selectedProperty}
            onChange={(e) => setSelectedEnvProperty(e.target.value)}
            className="select-property hide-horizontally"
          >
            {envImpacts.map((impact) => (
              <option key={impact.value} value={impact.value}>
                {impact.label}
              </option>
            ))}
            {/* Add more options for other properties */}
          </select>
          <Neo4jSpecificSankeyChart uid={properties.uid} neo4jConfig={neo4jConfig} width={width} />
          {renderChart()}
          <NodeChildProperties
            selectedProperty={selectedProperty}
            uid={barUid}
            neo4jConfig={neo4jConfig} width={width}
          />
        </>
        }

      </div>
    </ >
  );
};

export default NodeProperties;