import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import {
    Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, Button, Backdrop,
    CircularProgress
} from '@mui/material';
import { Grid } from '@material-ui/core';
import { Close } from '@mui/icons-material';
import axios from 'axios'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: 'calc(100vh - 65.20px)',
    width: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
};

const formatNumber = (num) => {
    if (typeof num === 'number') {
        const roundedNumber = parseFloat(num.toFixed(2));
        return (roundedNumber.toLocaleString('en-US'));
    }
    else {
        return num;
    }
}

const strategies = ['Minimize GWP 100y', 'Minimize Construction Cost', 'Minimize Construction Time'];
const root_nodes = [
    'Baseline (Concrete Design)',
    'Arch Option 1a (GFRP)',
    'Arch Option 1b (GFRP)',
    'Arch Option 1c (GFRP)',
];

// const propertyStaticData = {
//     'Global warming potential(GWP 100 years)[kg CO2 eq.]': [634_631_453.28, 372_886_735.94, 393_992_499.53, 341_438_801.24],
//     'Construction Time [Weeks]': [75.6, 18.9, 19.2, 20.5],
//     "Construction Cost [USD]": [720_628_000.00, 675_134_000.00, 715_642_040.00, 722_393_380.00],
// };

const costRootMapping = {
    'node-7': 720_628_000.00,
    'node-8': 675_134_000.00,
    'node-13': 715_642_040.00,
    'node-14': 722_393_380.00,

}

// Create a mapping between strategies and propertyData keys
const strategyToKeyMap = {
    'Minimize GWP 100y': 'Global warming potential(GWP 100 years)[kg CO2 eq.]',
    'Minimize Construction Cost': 'Construction Cost [USD]',
    'Minimize Construction Time': 'Construction Time [Weeks]',
};



const Optimizer = (props) => {
    const [selectedMetrics, setSelectedMetrics] = useState(['Minimize GWP 100y']);
    const [nodeDataReceived, setNodeDataReceived] = useState(false);
    const [propertyData, setPropertyData] = useState({
        'Global warming potential(GWP 100 years)[kg CO2 eq.]': [],
        'Construction Time [Weeks]': [],
        "Construction Cost [USD]": [],
    })
    const Base_URL = process.env.REACT_APP_BACKEND_URL;
    const [tableData, setTableData] = useState({
    });
    // const [nodeIds, setNodeIds] = useState([]);
    let nodeIds = []

    const calculateTime = (response) => {

        const processData = response.data
        // let time = 0
        const totalWeeksArray = [];

        // Threshold for considering values as zero
        const threshold = 1e-10;

        // Iterate through each option
        for (const optionName in processData.data.Process_data) {
            if (processData.data.Process_data.hasOwnProperty(optionName)) {
                // Calculate total weeks for the current option
                const totalWeeks = processData.data.Process_data[optionName]
                    .reduce((sum, item) => sum + (Math.abs(item.weeks) < threshold ? 0 : item.weeks), 0);

                // Push the result to the array
                totalWeeksArray.push(totalWeeks);
            }
        }
        setPropertyData(prevState => ({
            ...prevState,
            'Construction Time [Weeks]': totalWeeksArray,
        }));
    }

    const calculateCarbon = async () => {

        const totalCarbon = []

        const rootNodesData = await props.getRootNodesUid()
        rootNodesData.forEach(element => {
            totalCarbon.push(element.gwp_100)
        });
        setPropertyData(prevState => ({
            ...prevState,
            'Global warming potential(GWP 100 years)[kg CO2 eq.]': totalCarbon,
        }))
    }

    const calculateCost = async () => {
        const root_ids = ['node-7', 'node-8', 'node-13', 'node-14'];
        const totalCostOfRoots = [];
        let index = 0


        for (const root_node of root_ids) {
            // Clear the nodeIds array for each root node
            nodeIds = [];
            const gwpDictionary = {};
            const parentChildMapping = {};
            let totalCost = 0;
            let totalCostWithKey = {};

            await getAllNodes(root_node, gwpDictionary, parentChildMapping);
            const quantityDictionary = {};


            await getRelationData().then(response => {
                const links = response.data.data.D3Data.links;
                links.forEach(element => {
                    if ('source' in element && 'target' in element) {
                        const sourceId = element.source;
                        const targetId = element.target;

                        if (nodeIds.includes(sourceId) && nodeIds.includes(targetId)) {
                            const quantity = element.quantity;
                            quantityDictionary[`${targetId}`] = quantity;
                        }
                    }
                });
            });

            for (const key in quantityDictionary) {
                if (quantityDictionary.hasOwnProperty(key)) {
                    const quantity = quantityDictionary[key] === "" ? 0 : parseFloat(quantityDictionary[key]);
                    const gwpValue = gwpDictionary[key] || 0;

                    const cost = ((quantity * gwpValue) / gwpDictionary[root_ids[index]]) * costRootMapping[root_node];
                    totalCostWithKey[key] = cost;
                    totalCost += cost;
                }
            }
            index += 1
            totalCostOfRoots.push(totalCost);
        }

        setPropertyData(prevState => ({
            ...prevState,
            "Construction Cost [USD]": totalCostOfRoots,
        }))
    };

    const getAllNodes = async (nodeId, gwpDictionary, parentChildMapping) => {
        const successorNodes = await props._getSuccessorNodes(nodeId);
        // setNodeIds(prevNodeIds => [...prevNodeIds, nodeId]);  // Update nodeIds state
        nodeIds.push(nodeId)
        const nodeInfo = await props.calculateInfo(nodeId, props.nodes);
        const gwpValue = nodeInfo.gwp_100;

        // Store the gwp_100 value in the dictionary
        gwpDictionary[nodeId] = gwpValue;

        // Check if the node has no successor (leaf node)
        if (successorNodes.length > 0) {
            // Iterate through successor nodes
            for (const successorNode of successorNodes) {
                const childNodeId = successorNode.id;

                // Add the child node to the parent's list
                parentChildMapping[nodeId] = parentChildMapping[nodeId] || [];
                parentChildMapping[nodeId].push(childNodeId);

                // Recursively call getAllNodes for each successor
                await getAllNodes(childNodeId, gwpDictionary, parentChildMapping);
            }
        }
    };

    const getNodeData = async () => {
        try {
            const config = {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'bearer ' + sessionStorage.getItem('token'),
                }
            }
            return (await axios.get(
                Base_URL + `/optimizer/${sessionStorage.getItem('tenanatId')}/${sessionStorage.getItem('project_id')}`,
                config
            ))
        } catch (error) {
            console.log(error)
        }
    }

    const getRelationData = async () => {
        try {
            const config = {
                headers: {
                    'content-type': 'application/json',
                    authorization: 'bearer ' + sessionStorage.getItem('token'),
                }
            }
            return (await axios.get(
                Base_URL + `/projects/${sessionStorage.getItem('tenanatId')}/${sessionStorage.getItem('project_id')}`,
                config
            ))
        } catch (error) {
            console.log(error)
        }
    }

    const handleCheckboxChange = (strategy) => {
        const isSelected = selectedMetrics.includes(strategy);
        let updatedTableData = { ...tableData };

        const key = strategyToKeyMap[strategy];

        if (isSelected) {
            // Remove the row if the checkbox is deselected
            delete updatedTableData[key];
        } else {
            // Add the row if the checkbox is selected
            updatedTableData[key] = propertyData[key];
        }

        setSelectedMetrics(isSelected ? selectedMetrics.filter((item) => item !== strategy) : [...selectedMetrics, strategy]);
        setTableData(updatedTableData);
    };

    const getColorForValue = (value, min) => {
        const colorRange = ['#f4f0ec', '#c0c0c0'];
        if (value === min) {
            return colorRange[1]
        }
        else {
            return colorRange[0]
        }
    };

    const findMinMaxValues = (data) => {
        const min = Math.min(...data);
        return min;
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getNodeData();
                await Promise.all([
                    calculateTime(response),
                    calculateCarbon(),
                    calculateCost()
                ]);

                setNodeDataReceived(true);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);
    useEffect(() => {
        // Set the default selected strategy and data when the component mounts
        setSelectedMetrics(['Minimize GWP 100y']);
        setTableData({
            'Global warming potential(GWP 100 years)[kg CO2 eq.]': propertyData['Global warming potential(GWP 100 years)[kg CO2 eq.]']
        });
    }, [propertyData]); // Empty dependency array to run the effect only once when the component mounts


    const renderTableHeader = () => {
        const headerCells = ['Metrices', ...root_nodes];
        return (
            <TableRow>
                {headerCells.map((cell, index) => (
                    <TableCell style={{
                        position: 'sticky', top: 0, zIndex: 1,
                        background:
                            "transparent linear-gradient(180deg, #0C6276 0%, #00A9A6 100%) 0% 0% no-repeat padding-box",
                        color: "white",
                        padding: "8px",
                        border: "1px solid #fdfdfd",
                        height: "60px",
                        cursor: "pointer",
                    }}
                        key={index}>{cell}</TableCell>
                ))}
            </TableRow>
        );
    };

    const renderTableRows = () => {
        return Object.keys(tableData).map((metric, rowIndex) => {
            const min = findMinMaxValues(propertyData[metric]);
            return (
                <TableRow key={rowIndex}>
                    <TableCell style={{ fontWeight: 'bold' }}>{metric}</TableCell>
                    {root_nodes.map((node, colIndex) => (
                        <TableCell
                            key={colIndex}
                            style={{
                                backgroundColor: getColorForValue(tableData[metric][colIndex], min),
                            }}
                        >
                            {formatNumber(tableData[metric][colIndex])}
                        </TableCell>
                    ))}
                </TableRow>
            );
        });
    };

    const renderCheckedBox = (strategy, index) => {
        if (strategy === 'Minimize GWP 100y') {
            return (
                <Checkbox key={index} defaultChecked onClick={() => handleCheckboxChange(strategy)} />
            )
        } else {

            return (
                <Checkbox key={index} onClick={() => handleCheckboxChange(strategy)} />
            );
        }
    };


    return (
        <>
            <Modal open={true} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
                <Box sx={style}>
                    <div style={{ position: 'relative' }}>
                        <div style={{ maxHeight: 'calc(100vh - 12.20px)' }}>
                            <Grid container>
                                <Grid item xs={9}>
                                    <Typography id='modal-modal-title' component='h1' style={{ color: '#2D323A', marginBottom: '10px', fontSize: '17' }}>
                                        <b>Optimizer</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                        <Close
                                            style={{ color: '#029E9E', fontSize: 25, cursor: 'pointer' }}
                                            onClick={() => {
                                                props.handleOptimizerModalClose();
                                            }}
                                        />
                                    </div>
                                </Grid>
                            </Grid>

                            <Divider style={{ width: '100%', color: 'darkgray', marginBottom: '0.5rem' }} />

                            <Grid container>
                                <Grid item xs={12} md={9}>
                                    <div
                                        style={{
                                            fontSize: '14px',
                                            marginBottom: '10px',
                                            textAlign: 'left',
                                            font: 'normal normal 600 14px/19px Segoe UI',
                                            letterSpacing: '0px',
                                            color: '#0C6276',
                                            opacity: 1,
                                        }}
                                    >
                                        <b>Strategies</b>
                                    </div>
                                    <div style={{ paddingRight: '5px', display: 'flex', alignItems: 'center' }}>
                                        {strategies.map((strategy, index) => (
                                            <>
                                                {renderCheckedBox(strategy, index)}
                                                <Typography
                                                    id='modal-modal-title'
                                                    component='h1'
                                                    style={{
                                                        color: '#2D323A',
                                                        fontSize: '17',
                                                        padding: '4px',
                                                        fontWeight: selectedMetrics.includes(strategy) ? 'bold' : 'normal',
                                                    }}
                                                >
                                                    {strategy}
                                                </Typography>
                                            </>
                                        ))}
                                    </div>
                                </Grid>
                            </Grid>

                            <Divider style={{ width: '100%', color: 'darkgray', marginTop: '20px' }} />

                            <Grid container spacing={3}>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={8} style={{ paddingTop: '4%' }}>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>{renderTableHeader()}</TableHead>
                                            <TableBody>{renderTableRows()}</TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                        <Button
                                            variant='contained'
                                            color='secondary'
                                            style={{
                                                fontSize: '14px',
                                                marginRight: '10px',
                                                background: '#6f7173',
                                                border: '1px solid #dfdfdf',
                                                opacity: 1,
                                                color: 'white',
                                                width: '150px',
                                                borderRadius: '2px',
                                                boxShadow: '0 .125rem .25rem rgba(0,0,0,.075)'
                                            }}
                                            size='small'
                                            onClick={() => {
                                                props.handleOptimizerModalClose();
                                            }}
                                        >
                                            Cancel
                                        </Button>

                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
                        open={!nodeDataReceived}
                    >
                        <CircularProgress color='inherit' />
                    </Backdrop>
                </Box>
            </Modal>
        </>
    );
};

export default Optimizer;
