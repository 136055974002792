import React, { Component } from 'react';
import { Divider, Grid, Tooltip } from '@mui/material';
import CloseIcon from '@material-ui/icons/Close';
import { DragIndicator, PushPin, Fullscreen, FullscreenExit, ScreenRotationAlt } from '@mui/icons-material';

import CropRotateIcon from '@mui/icons-material/CropRotate';
import DashboardSidePanelComponent from '../dashboardTab';
import Draggable from 'react-draggable';
import OptionSidePanelcomponent from '../Option_sidePanel'
import TabsComponent from "./Tabscomponent"
import SettingsSidePanelComponent from '../settingsTab';
import ToolboxNewComponent from '../toolboxNew';

import NodeProperties from '../NodeProperties';
import axios from 'axios';
// import { Divider, Grid, Tooltip, Slider } from '@mui/material';



export default class SidePanelcomponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      neo4jConfig: {
        username: "",
        serverUrl: "",
        password: "",
      },
      newNode: '',
      linkInfo: null,
      childNode: null,
      value: 0,
      activeTab: 0,
      panelComponentVisible: false,
      isFullScreen: false,
      isDock: false,
      isPinned: false,
      shouldDisplayDiv: false,
      previousIndex: 0,
      tab: null,
      size: { width: 200, height: 200 },
      sliderValue: 100,

      divHeight: 'calc(100vh - 120px)',
      divWidth: '100%',
      // divHeight:'calc(100vh - 620px)',
      // divWidth:'272%'
      unlockDrag: false,
      controlledPosition: { x: 0, y: 0 },
      orientationClass: '',
    };
    this.changeState = this.changeState.bind(this)
    this.changeLinkState = this.changeLinkState.bind(this)
  }
  sideClickicked = false;


  handleSliderChange = (event, newValue) => {
    this.setState({
      sliderValue: newValue,
    });
  };
  changeState(node, childNodes) {
    this.setState({
      newNode: node,
      childNode: childNodes,
      linkInfo: null
    });
  }

  changeLinkState(linkInfo) {
    this.setState({
      linkInfo: linkInfo
    });
  }



  async fetchNeo4jConfig() {
    try {
      const Base_URL = process.env.REACT_APP_BACKEND_URL;
      const config = {
        headers: {
          'content-type': 'application/json',
          authorization: 'bearer ' + sessionStorage.getItem('token'),
        },
      };

      const response = await axios.get(
        Base_URL +
        '/neo4jdatabases/' +
        sessionStorage.getItem('tenanatId') +
        '/' +
        sessionStorage.getItem('project_id'),
        config
      );

      const neo4jConfigFromAPI = response.data.data.neo4jDatabases_data[0].neo4j_factory_config;

      // Update the state with the dynamic Neo4j configuration
      this.setState({
        neo4jConfig: {
          username: neo4jConfigFromAPI.neo4j_username,
          serverUrl: neo4jConfigFromAPI.neo4j_uri,
          password: neo4jConfigFromAPI.neo4j_password,
        },
      });

      // ... other code
    } catch (error) {
      console.error('Error fetching Neo4j configuration', error);
    }
  }


  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const isDock = urlParams.get('isDock') === 'true';
    this.setState({ isDock });
    this.fetchNeo4jConfig();

  }
  componentDidUpdate(prevProps, prevState) {
    // Check if the unlockDrag state has changed
    if (prevState.unlockDrag !== this.state.unlockDrag) {
      this.props.adjustCanvas(!this.state.unlockDrag);
    }

    if (this.props.selectedMessage && this.state.shouldDisplayDiv === false && this.sideClickicked === false && !this.divHeight && this.divWidth) {
      this.setState({
        shouldDisplayDiv: true,
        tab: 0
      })
    }
    this.sideClickicked = false;

  }

  toggleFullScreen = () => {
    const { isFullScreen } = this.state;
    if (!isFullScreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
    this.setState(prevState => ({
      isFullScreen: !prevState.isFullScreen,
    }));
  };



  DockUndockcomponent = () => {
    const { isDock } = this.state;
    if (isDock) {
      window.close();
    } else {
      const params = new URLSearchParams();
      params.set('isDock', true);
      const newWindow = window.open(window.location.href + '?' + params.toString(), 'width=1000,height=800');
      newWindow.addEventListener('beforeunload', () => {
        this.setState({ isDock: false });
      });
      this.setState({ isDock: true, panelComponentVisible: true });
    }
  }

  onSideClick = (index) => {
    const { shouldDisplayDiv, previousIndex } = this.state;
    const newShouldDisplayDiv = previousIndex !== index ? true : !shouldDisplayDiv;
    this.setState({
      shouldDisplayDiv: newShouldDisplayDiv,
      previousIndex: index,
    });
    this?.props?.adjustCanvas(newShouldDisplayDiv);
    this.sideClickicked = true;
  }



  changeTabSize = () => {
    this.setState((prevState) => {
      // Check the current state and toggle between two different sets of values
      const newHeight = prevState.divHeight === 'calc(100vh - 428px)' ? 'calc(100vh - 120px)' : 'calc(100vh - 428px)';
      const newWidth = prevState.divWidth === '292%' ? '111%' : '292%';

      // Set the new state
      return {
        divHeight: newHeight,
        divWidth: newWidth,
      };
    });
  };


  onClose = () => {
    this.setState({ shouldDisplayDiv: false, });

    this?.props?.adjustCanvas(false);
    this.sideClickicked = this.state.unlockDrag ? false : true;

    if (this.state.unlockDrag) {
      this.setState({ controlledPosition: { x: 0, y: 0 }, unlockDrag: false, orientationClass: '' })
    };

  };

  handleTabChange = (newTab) => {
    this.setState({ tab: newTab });
  };

  renderComponentToRender() {
    const { newNode, linkInfo } = this.state;
    const { childNode, tab } = this.state;
    const { selectedMessage } = this.props;

    if (tab === 0) {
      this.divHeight = 'calc(100vh - 120px)';
      // this.divWidth = '102%'
      return <DashboardSidePanelComponent newNode={newNode} neo4jConfig={this.state.neo4jConfig}
        // width={this.state.divWidth}
        graphRefInstance={this.props.graphRefInstance} childNode={childNode} getRootNodesUid={this.props.getRootNodesUid} getChildNodes={this.props.getChildNodes} getLink={this.props.getLink} selectedMessage={selectedMessage} getNodeDetailsForTab={this.props.getNodeDetailsForTab} dashboardTabConfigJson={this.props.dashboardTabConfigJson} />;
    } else if (tab === 2) {
      this.divHeight = 'calc(100vh - 120px)';
      this.divWidth = '102%'
      return <ToolboxNewComponent handleRightClick={this.props.handleRightClick} newNode={newNode} />;
    } else if (tab === 1) {
      this.divHeight = 'calc(100vh - 120px)';
      this.divWidth = '102%'
      console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~")
      console.log(linkInfo)
      return <OptionSidePanelcomponent newNode={newNode} linkInfo={linkInfo} />;
    } else if (tab === 3) {
      return <SettingsSidePanelComponent />;

      // return  <NodeProperties properties={newNode} neo4jConfig={this.state.neo4jConfig} width={this.state.divWidth}/>
    }
    else if (tab === 4) {
      return <SettingsSidePanelComponent />;
    }
  }

  onControlledDrag = (e, position) => {
    const { x, y } = position;
    this.setState({ controlledPosition: { x, y } });
  };

  onControlledDragStop = (e, position) => {
    this.onControlledDrag(e, position);

  };


  tootleVerticalHorizontal = () => {

    const xpos = window.innerWidth - 95;
    const oClass = this.state.orientationClass === '' ? 'horizantal' : '';
    const uDrag = this.state.orientationClass === '' ? true : false;
    const cPos = uDrag ? { x: -xpos, y: 12 } : { x: 0, y: 0 };

    this.setState({
      orientationClass: oClass,
      unlockDrag: uDrag,
      controlledPosition: cPos,
    });

  }

  render() {
    const { shouldDisplayDiv, tab, unlockDrag, orientationClass } = this.state;

    return (
      <>
        {/* {"unlockDrag" + unlockDrag} */}
        {shouldDisplayDiv &&
          <Draggable
            bounds="body"
            position={this.state.controlledPosition}
            onStart={() => unlockDrag}
            onStop={this.onControlledDragStop}
            handle='.drag-header'
          >
            {/* <div style={{ height: 'calc(100vh - 120px)', width: '102%', backgroundColor: '#F9F9F9', display: shouldDisplayDiv ? 'block' : 'none', marginTop: '105', overflow: 'auto', padding: '2' }}> */}
            <div style={{
              // height: this.state.divHeight,
              // width: this.state.divWidth,
              display: shouldDisplayDiv ? 'flex' : 'none',
              // transform: orientationClass ? 'translate(calc(-100vw + 105px), 0px)' : 'translate(-500px, 0px)',
              position: unlockDrag ? 'absolute' : '',
            }}
              className={'draggable-content ' + orientationClass + ' ' + (unlockDrag ? 'draggable-content-unlocked' : '')}
            >

              <div style={this.divStyle} className='drag-header'>
                <div className='drag-header-title'>
                  {
                    tab === 0 ? 'Dashboard' : tab === 1 ? 'Details' : tab === 2 ? 'Toolbox' : tab === 3 ? 'Settings' : ''
                  }
                </div>
                <div className='drag-header-actions'>
                  <Tooltip title="Rotate" arrow>
                    <ScreenRotationAlt fontSize='small' onClick={this.tootleVerticalHorizontal} style={{ cursor: 'pointer' }} />
                  </Tooltip>

                  <Tooltip title="Drag Panel" arrow>
                    <DragIndicator fontSize='small' className="drag-handle" style={{ cursor: 'pointer' }}
                    />
                  </Tooltip>
                  <Tooltip title="Toggle Fullscreen" arrow>
                    {this.isFullScreen ? (
                      <FullscreenExit fontSize='small' onClick={this.toggleFullScreen} style={{ cursor: 'pointer' }} />
                    ) : (
                      <Fullscreen fontSize='small' onClick={this.toggleFullScreen} style={{ cursor: 'pointer' }} />
                    )}
                  </Tooltip>

                  <Tooltip title={"Unlock seperate"} arrow>
                    <PushPin fontSize='small' style={{ cursor: 'pointer' }}
                      color={unlockDrag ? '' : 'red'}
                      onClick={() =>
                        this.setState(prevState => ({
                          unlockDrag: !prevState.unlockDrag,
                          controlledPosition: !prevState.unlockDrag ? { x: -500, y: 0 } : { x: 0, y: 0 },
                          orientationClass: '',
                        }))
                      } />
                  </Tooltip>

                  <Tooltip title="Close" arrow>
                    <CloseIcon fontSize='small' style={{ cursor: 'pointer' }} onClick={this.onClose} />
                  </Tooltip>
                </div>
              </div>
              <Divider style={{ color: '#707070', }} />
              <div className='drag-conent'>
                {this.renderComponentToRender()}
              </div>
            </div>
          </Draggable >
        }
        <TabsComponent onSideClick={this.onSideClick} tab={tab} onTabChange={this.handleTabChange} />
      </>
    );
  }
}
