import React from 'react';
import ReactDOM from "react-dom/client";
import axios from "axios";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import UserService from "./services/UserService";

// HTTP

const _axios = axios.create();
_axios.interceptors.request.use((config) => {
    if (UserService.isLoggedIn()) {
        const cb = () => {
            config.headers.Authorization = `Bearer ${UserService.getToken()}`;
            return Promise.resolve(config);
        };
        return UserService.updateToken(cb);
    }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
const renderApp = root.render(
    <App />
);
UserService.initKeycloak(renderApp);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
