import * as React from 'react'
import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import { MenuItem, Popover, Grid, Paper } from '@material-ui/core'
import {
  Button,
  Checkbox,
  FormControl,
  Modal,
  Select,
  Table,
  InputLabel,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import { Close } from '@mui/icons-material'
import OutlinedInput from '@mui/material/OutlinedInput'
import ListItemText from '@mui/material/ListItemText'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import axios from 'axios'

const Base_URL = process.env.REACT_APP_BACKEND_URL
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: 'calc(100vh - 65.20px)',
  width: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  overflow: 'auto',
  '.MuiOutlinedInput-input': {
    height: " 20px",
    padding: 1
  },
}
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

function Alternatives(props) {
  const {
    handleDialogSubmit,
    getRootNodesUid
  } = props;
  const [variableName, setVariableName] = useState([])
  let [rows, setRows] = useState([])
  let [showContent, setShowContent] = useState(false)
  const [uidArray, setUidArray] = useState([''])
  const [rootNodeSelected, setRootNodeSelected] = useState(null)
  const [openPopover, setOpenPopover] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [mainData_, setMainData] = useState({})
  const [anchorPosition, setAnchorPosition] = useState({
    bottom: 0,
    right: 0
  })
  const [customCategory, setCustomCategory] = useState('')
  const [SelectedUid, setSelectedUid] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('')
  const [gwp100, setGWP100] = useState(0)
  const [energyGwp100, setEnergyGWP100] = useState(0)
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const [selectedMessage, setSelectedMessage] = useState('')
  const [chartTitle, setChartTitle] = useState('Compare Alternatives')
  const [rootDetails, setRootDetails] = useState([])
  const [checkboxSelect, setcheckbox] = useState(['Global Warming Potential'])
  let [selectedOption, setselectedOption] = useState([])
  const [isEnergy, setIsEnergy] = useState(false)

  useEffect(() => {
    getRootNodeDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {
    console.log('Selected Message:', selectedMessage);
    handleDialogSubmit(selectedMessage)
    props.handleAlternativeClose()
  }

  const formatNumber = (num) => {
    if (typeof num === 'number') {
      const roundedNumber = parseFloat(num.toFixed(2));
      return (roundedNumber.toLocaleString('en-US'));
    }
    else {
      return num;
    }
  }
  const handleMenuOpen = event => {
    setDropdownOpen(true)
    const selectedValue = event.target.value
    if (selectedValue === 'energy') {
      if (mainData_.energy.length > 0) {
        const energyUids = mainData_.energy.map(energy => energy.properties.uid)
        setUidArray(energyUids)
        setCustomCategory(event.target.value)
      }
    } else if (selectedValue === 'material') {
      if (mainData_.material.length > 0) {
        setVariableName(prevVariableName => [
          ...prevVariableName,
          'material'
        ])

        const materialUids = mainData_.material.map(
          material => material.properties.uid
        )
        setUidArray(materialUids)
        setCustomCategory(event.target.value)
      }
    }
    setAnchorEl(event.currentTarget)

    const optionPosition = event.currentTarget.getBoundingClientRect()
    setAnchorPosition({
      top: optionPosition.bottom,
      left: optionPosition.right
    })
    setOpenPopover(true)
  }
  const handleMenuClose = () => {
    setOpenPopover(false)
    setDropdownOpen(false)
    setSelectedCategory('')
    setAnchorEl(null)
    setSelectedCategory(customCategory)
  }

  const handleVariableChange = event => {
    const {
      target: { value }
    } = event

    const updatedSelectedValues = Array.isArray(value) ? value : []

    let title = 'Compare Alternatives'

    setChartTitle(title)
    setcheckbox(updatedSelectedValues)
  }


  const getRootNodeDetails = async () => {
    let rootNDetails = await getRootNodesUid()
    setRootDetails(rootNDetails)
  }

  const handleRootNodeSelect = async event => {
    try {
      const selectedValue = event.target.value
      setRootNodeSelected(selectedValue)
      setselectedOption(selectedValue)
      setVariableName([])
      setUidArray([])
      const config = {
        headers: {
          'content-type': 'application/json',
          authorization: 'bearer ' + sessionStorage.getItem('token'),
          projectid: sessionStorage.getItem('project_id'),
          tenantid: sessionStorage.getItem('tenanatId'),
          option: selectedValue
        }
      }
      const response = await axios.get(
        Base_URL +
        '/alternatives/get-categories/' +
        sessionStorage.getItem('tenanatId'),
        config
      )
      const mainData = response.data.data.Nodes.mainData[0]
      if (mainData.energy.length > 0) {
        setVariableName(prevVariableName => [...prevVariableName, 'energy'])

        const gwp_100 = mainData.energy.map(gwp => gwp.properties.gwp_100)
        const updatedCheckboxSelect = checkboxSelect.filter(
          option => option !== 'Human Toxicity Potential'
        );
        setcheckbox(updatedCheckboxSelect);
        setEnergyGWP100(gwp_100)
      }
      if (mainData.material.length > 0) {
        setVariableName(prevVariableName => [...prevVariableName, 'material'])

        const gwp_100 = mainData.material.map(gwp => gwp.properties.gwp_100)
        setGWP100(gwp_100)
      }
      setMainData(mainData)

      setSelectedCategory('')
      return response
    } catch (error) {
      console.error(error)
      throw new Error('Error occurred while handling root node selection.')
    }
  }

  const handleAlternativeSelect = async (uid) => {
    setSelectedUid(uid)
    setShowContent(true)
    setSelectedCategory('')
    try {

      const config = {
        headers: {
          'content-type': 'application/json',
          authorization: 'bearer ' + sessionStorage.getItem('token'),
          projectid: sessionStorage.getItem('project_id'),
          tenantid: sessionStorage.getItem('tenanatId'),
          option: selectedOption,
          alternativeof: uid
        }
      }

      const response = await axios.get(
        Base_URL +
        '/alternatives/get-alternativeNodes/' +
        sessionStorage.getItem('tenanatId'),
        config
      )

      const alternativeNodes =
        response.data.data.Nodes.mainData[0].alternativeNodes
      const updatedRows = alternativeNodes.map((alternative, index) => ({
        id: index + 1,
        alternatives: alternative.name,
        global_warmming_potential: alternative.gwp_100_ebc,
        eci_cost: alternative.eci_cost,
        htp: alternative.htp,
        density_variation: 'Similar',
        melting_point: 'alternative',
        Conductivity: 'conductivity',
        checked: false
      }))
      setIsEnergy(selectedOption === 'energy')
      setRows(updatedRows)

      handleMenuClose()
      return response
    } catch (error) {
      console.error(error)
      throw new Error('Error occurred while handling alternative selection.')
    }
  }
  const handleCheckboxChange = rowId => {
    setRows(prevRows =>
      prevRows.map(row => {
        if (row.id === rowId) {
          const updatedRow = updateRowWithCheckbox(row);
          setSelectedMessage(generateSelectedMessage(updatedRow));
          return updatedRow;
        }
        return row;
      })
    );
  }


  const updateRowWithCheckbox = row => {
    const updatedRow = { ...row, checked: !row.checked }
    const selectedOptions = checkboxSelect.filter(option =>
      updatedRow.alternatives.includes(option)
    )
    updatedRow.selectedOptions = selectedOptions.join(', ')

    return updatedRow
  }

  const generateSelectedMessage = updatedRow => {
    const { global_warmming_potential, htp, eci_cost } = updatedRow

    const minGWPValue = Math.min(
      ...rows.map(row => row.global_warmming_potential)
    )
    const minHtpValue = Math.min(...rows.map(row => row.htp))
    const minECIValue = Math.min(...rows.map(row => row.eci_cost))

    const selectedMessageParts = []

    if (
      checkboxSelect.includes('Global Warming Potential') &&
      global_warmming_potential === minGWPValue
    ) {
      selectedMessageParts.push(`GWP value: ${formatNumber(minGWPValue)}`)
    }
    if (
      checkboxSelect.includes('Human Toxicity Potential') &&
      htp === minHtpValue
    ) {
      selectedMessageParts.push(`HTP value: ${formatNumber(minHtpValue)}`)
    }
    if (checkboxSelect.includes('ECI Costs') && eci_cost === minECIValue) {
      selectedMessageParts.push(`ECI Cost value: ${formatNumber(minECIValue)}`)
    }

    if (selectedMessageParts.length > 0) {
      return `Alternative: ${updatedRow.alternatives
        } with ${selectedMessageParts.join(', ')} has been selected.`
    }
    return ''
  }
  const createSeries = () => {
    const series = [];
    const gwpData = rows.map(row => parseFloat(row.global_warmming_potential.toFixed(2)));

    if (!showContent) return null

    if (checkboxSelect.includes('Global Warming Potential')) {
      series.push({
        name: 'Global Warming Potential',
        data: gwpData,
        color: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, '#8BD3DD'],
            [1, '#6EAFC7']
          ]
        }
      });
    }

    if (checkboxSelect.includes('ECI Costs')) {
      series.push({
        name: 'ECI Cost',
        data: rows.map(row => parseFloat(row.eci_cost.toFixed(2))),
        color: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, '#FEDF87'],
            [1, '#FEB557']
          ]
        }
      });
    }

    if (selectedCategory !== 'energy' && checkboxSelect.includes('Human Toxicity Potential')) {
      series.push({
        name: 'HTP',
        data: rows.map(row => parseFloat(row.htp.toFixed(2))),
        color: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, '#A3E57D'],
            [1, '#71CC66']
          ]
        }
      });
    }

    return series;
  };

  const ColumnChart = ({ title }) => {
    if (!showContent) return null;
    const chartOptions = {
      credits: {
        enabled: false
      },
      chart: {
        type: 'column',
        height: 'calc(100vh - 400.20px)'
      },
      title: {
        text: title
      },
      xAxis: {
        categories: rows.map(row => row.alternatives),
        crosshair: true,

      },
      yAxis: {
        title: {
          text: 'Values',
          rotation: -90,

        }
      },

      tooltip: {
        shared: false,
        formatter: function () {
          const row = rows[this.point.index]
          let tooltipContent = `<b>${row.alternatives}</b><br>`
          tooltipContent += `GWP: ${formatNumber(row.global_warmming_potential)}<br>`

          if (checkboxSelect.includes('Human Toxicity Potential')) {
            tooltipContent += `HTP: ${formatNumber(row.htp)}<br>`
          }

          if (checkboxSelect.includes('ECI Costs')) {
            tooltipContent += `ECI Cost: ${formatNumber(row.eci_cost)}`
          }


          if (this.series.name === 'Global Warming Potential') {
            tooltipContent = `GWP: ${formatNumber(row.global_warmming_potential)}<br>`
          } else if (this.series.name === 'ECI Cost') {
            tooltipContent = `ECI Cost: ${formatNumber(row.eci_cost)}<br>`
          } else if (this.series.name === 'HTP') {
            tooltipContent = `HTP: ${formatNumber(row.htp)}<br>`
          }

          return tooltipContent
        }
      },
      plotOptions: {
        column: {
          pointWidth: 25,
          dataLabels: {
            enabled: true
          },
          states: {
            hover: {
              enabled: true
            }
          }
        }
      },
      series: createSeries(),
    }
    return (
      <div id='chart-container' style={{ height: 'calc(80vh - 100.20px)' }}>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </div>
    )
  }

  const generateMenuItem = (value, label, selectedCheckboxes) => (
    <MenuItem value={value}>
      <Checkbox checked={selectedCheckboxes.includes(value)} />
      <ListItemText primary={label} />
    </MenuItem>
  );


  return (
    <Modal
      open={true}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      overflowY={'auto'}
    >
      <Box sx={style}>
        <div>
          <div style={{ maxHeight: 'calc(100vh - 12.20px)' }}>
            <Grid container>
              <Grid item xs={9}>
                <Typography
                  id='modal-modal-title'
                  component='h1'
                  style={{
                    color: '#2D323A',
                    marginBottom: '10px',
                    fontSize: '17'
                  }}
                >
                  <b>Alternatives</b>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end'
                  }}
                >
                  <Close
                    style={{
                      color: '#029E9E',
                      fontSize: 25,
                      cursor: 'pointer'
                    }}
                    onClick={() => {
                      props.handleAlternativeClose()
                    }}
                  />
                </div>
              </Grid>
            </Grid>

            <Divider
              style={{
                width: '100%',
                color: 'darkgray',
                marginBottom: '0.5rem'
              }}
            />

            <Grid container>
              <Grid xs={3}>
                <div style={{ color: '#0C6276', fontSize: '14px', marginBottom: '10px', fontWeight: 'bold' }}>
                  Options
                </div>
                <div style={{ paddingRight: '20px' }} sx={style}>
                  <FormControl sx={{ width: '100%' }} size='small'>
                    {!rootNodeSelected && (
                      <InputLabel shrink={false} focused={false} id='item_type_label'>
                        Select Option
                      </InputLabel>
                    )}
                    <Select

                      labelId='demo-select-small-label'
                      id='demo-select-small'
                      value={rootNodeSelected}
                      onChange={handleRootNodeSelect}
                      autoWidth={false}
                    >
                      {rootDetails.map((item) => (
                        <MenuItem key={item.id} value={item.uid}>
                          {item.uid}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>

              <Grid xs={3}>
                <div
                  style={{
                    color: '#0C6276',
                    fontSize: '14px',
                    marginBottom: '10px'
                  }}
                >
                  <b> Category </b>
                </div>
                <div style={{ paddingRight: '20px' }}>
                  <FormControl sx={{ width: '100%' }} size='small'>
                    {selectedCategory ? null : (
                      <InputLabel
                        shrink={false}
                        focused={false}
                        id='item_type_label'
                      >
                        Select Category
                      </InputLabel>
                    )}
                    <Select
                      labelId='demo-select-small-label'
                      id='demo-select-small'
                      onChange={handleMenuOpen}
                      value={selectedCategory}
                      open={isDropdownOpen}
                      onClose={handleMenuClose}
                      onOpen={() => setDropdownOpen(true)}
                    >
                      {['energy', 'material', 'process'].map(category => (
                        variableName.includes(category) && (
                          <MenuItem
                            key={category}
                            value={category}
                            onClick={handleMenuOpen}
                            disabled={selectedCategory === category}
                          >
                            {category.charAt(0).toUpperCase() + category.slice(1)}
                          </MenuItem>
                        )
                      ))}
                    </Select>
                  </FormControl>
                  <Popover
                    open={openPopover}
                    anchorEl={anchorEl}
                    onClose={() => {
                      handleMenuClose()
                      setSelectedCategory(selectedOption)
                    }}
                    anchorReference='anchorPosition'
                    anchorPosition={{
                      top: anchorPosition.top,
                      left: anchorPosition.left
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left'
                    }}
                  >
                    {uidArray.map(uid => (
                      <MenuItem
                        key={uid}
                        onClick={() => handleAlternativeSelect(uid)}
                      >
                        {uid}
                      </MenuItem>
                    ))}
                  </Popover>
                </div>
              </Grid>

              <Grid xs={3}>
                <div
                  style={{
                    color: '#0C6276',
                    fontSize: '14px',
                    marginBottom: '10px'
                  }}
                >
                  <b> Select Variable </b>
                </div>

                <div>
                  <FormControl sx={{ width: '100%' }} size='small'>
                    {checkboxSelect.length === 0 ? (
                      <InputLabel
                        shrink={false}
                        focused={false}
                        id='item_type_label'
                      >
                        Select Variable
                      </InputLabel>
                    ) : null}
                    <Select
                      labelId='demo-select-small-label'
                      id='demo-select-small'
                      multiple
                      value={checkboxSelect}
                      onChange={handleVariableChange}
                      input={<OutlinedInput label='Tag' />}
                      renderValue={selected => {
                        if (selected.length === 0) {
                          return 'Global Warming Potential';
                        }
                        return selected.join(', ');
                      }}
                      MenuProps={MenuProps}
                    >
                      {generateMenuItem('Global Warming Potential', 'Global Warming Potential', checkboxSelect)}

                      {selectedCategory !== 'energy' && generateMenuItem('Human Toxicity Potential', 'Human Toxicity Potential', checkboxSelect)}

                      {generateMenuItem('ECI Costs', 'ECI Costs', checkboxSelect)}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
            </Grid>
            {showContent && (
              <Typography
                id='modal-modal-title'
                component='h1'
                style={{ color: '#0C6276', marginTop: '15px', fontSize: '16' }}
              >
                <b>
                  {SelectedUid} - Global_warming_potential(kg CO2): {selectedCategory === 'energy' ? formatNumber(energyGwp100) : formatNumber(gwp100)}
                </b>
              </Typography>
            )}
            {showContent && (
              <Divider
                style={{
                  width: '100%',
                  color: 'darkgray',
                  margin: '0.4rem 0rem'
                }}
              />
            )}
            {showContent && (
              <div>
                <TableContainer component={Paper} sx={{ width: '100%' }}>
                  <Table
                    size='small'
                    aria-label='a dense table'
                    sx={{ border: 'none' }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell padding='checkbox'></TableCell>
                        <TableCell align='center'>Alternatives</TableCell>
                        {checkboxSelect.includes(
                          'Global Warming Potential'
                        ) && (
                            <TableCell align='center'>
                              Global_warming_potential(kg CO2)
                            </TableCell>
                          )}
                        {selectedCategory === 'material' &&
                          checkboxSelect.includes('Human Toxicity Potential') && (
                            <TableCell align='center'>Human Toxicity Potential(kg DCB eq.)</TableCell>
                          )}
                        {checkboxSelect.includes('ECI Costs') && (
                          <TableCell align='center'>ECI Costs(USD)</TableCell>
                        )}
                        {!isEnergy && selectedCategory !== 'energy' && (
                          <>
                            <TableCell align='center'>
                              density_variation
                            </TableCell>
                            <TableCell align='center'>melting_point</TableCell>
                            <TableCell align='center'>Conductivity</TableCell>
                          </>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map(row => (
                        <TableRow key={row.id}>
                          <TableCell padding='checkbox'>
                            <Checkbox
                              checked={row.checked}
                              onChange={() => handleCheckboxChange(row.id)}
                            />
                          </TableCell>
                          <TableCell align='center'>
                            {row.alternatives}
                          </TableCell>
                          {checkboxSelect.includes(
                            'Global Warming Potential'
                          ) && (
                              <TableCell align='center'>
                                {formatNumber(row.global_warmming_potential)}
                              </TableCell>
                            )}
                          {selectedCategory === 'material' && checkboxSelect.includes('Human Toxicity Potential') && (
                            <TableCell align='center'>
                              {formatNumber(row.htp)}
                            </TableCell>
                          )}
                          {checkboxSelect.includes('ECI Costs') && (
                            <TableCell align='center'>
                              ${formatNumber(row.eci_cost)}
                            </TableCell>
                          )}
                          {!isEnergy && selectedCategory !== 'energy' && (
                            <>
                              <TableCell align='center'>
                                {row.density_variation}
                              </TableCell>
                              <TableCell align='center'>
                                {row.melting_point}
                              </TableCell>
                              <TableCell align='center'>
                                {row.Conductivity}
                              </TableCell>
                            </>
                          )}
                          <TableCell align='center'>
                            {row.selectedOptions}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
          </div>
        </div>
        {showContent && (
          <div
            id='chart-container'
            style={{
              border: '1px solid gray',
              height: '10vh-700.20px',
              marginTop: '15px'
            }}
          >
            <ColumnChart
              showContent={true}
              rows={rows}
              title={chartTitle}
              checkboxSelect={checkboxSelect}

            />
          </div>
        )}
        {showContent && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Button
              variant='contained'
              color='secondary'
              style={{
                fontSize: '14px',
                marginRight: '20px',
                background: '#6f7173',
                border: '1px solid #dfdfdf',
                opacity: 1,
                color: 'white',
                width: '150px',
                borderRadius: '2px',
                boxShadow: '0 .125rem .25rem rgba(0,0,0,.075)'
              }}
              size='small'
              onClick={() => {
                props.handleAlternativeClose()
              }}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              color='primary'
              style={{
                fontSize: '14px',
                marginRight: '10px',
                background:
                  'transparent linear-gradient(180deg, #0C6276 0%, #00A9A6 100%) 0% 0% no-repeat padding-box',
                color: 'white',
                width: '150px',
                border: '1px solid #dfdfdf',
                opacity: 1,
                borderRadius: '2px',
                boxShadow: '0 .125rem .25rem rgba(0,0,0,.075)'
              }}
              size='small'
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  )
}
export default Alternatives