import React, { useEffect, useState } from 'react';
import neo4j from 'neo4j-driver';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.candy';
import PowerCharts from 'fusioncharts/fusioncharts.powercharts';
import ReactFC from 'react-fusioncharts';
import axios from 'axios';
import { Modal } from '@mui/material';
import { Grid } from '@material-ui/core';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Close } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Tooltip } from '@mui/material'
ReactFC.fcRoot(FusionCharts, PowerCharts, FusionTheme);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '98%',
  width: '98%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

Charts(FusionCharts);
FusionTheme(FusionCharts);

const Neo4jSankeyChart = (props) => {
  const [neo4jConfig, setNeo4jConfig] = useState(null);
  const [isLightTheme, setIsLightTheme] = useState(true);
  const [check, setCheck] = React.useState(false);

  const handleCheck = () => {
    setCheck(!check);
  };

  useEffect(() => {
    const getNeo4JData = async () => {
      try {
        const Base_URL = process.env.REACT_APP_BACKEND_URL;
        const config = {
          headers: {
            'content-type': 'application/json',
            authorization: 'bearer ' + sessionStorage.getItem('token'),
          },
        };


        const response = await axios.get(
          Base_URL +
          '/neo4jdatabases/' +
          sessionStorage.getItem('tenanatId') +
          '/' +
          sessionStorage.getItem('project_id'),
          config
        );


        setNeo4jConfig(response.data.data.neo4jDatabases_data[0].neo4j_factory_config);

        const URI = response.data.data.neo4jDatabases_data[0].neo4j_factory_config.neo4j_uri;
        const USER = response.data.data.neo4jDatabases_data[0].neo4j_factory_config.neo4j_username;
        const PASSWORD =
          response.data.data.neo4jDatabases_data[0].neo4j_factory_config.neo4j_password;

        const driver = neo4j.driver(URI, neo4j.auth.basic(USER, PASSWORD));

        const sessionNodes = driver.session({ database: 'neo4j' });
        const queryNodes = 'MATCH (n) RETURN n.uid';

        const nodes = [];

        sessionNodes
          .run(queryNodes)
          .then((result) => {
            const records = result.records;
            const summary = result.summary;

            records.forEach((record) => {
              const label = record.get('n.uid');
              nodes.push({ label: label });
            });

            const sessionLinks = driver.session({ database: 'neo4j' });
            const queryLinks = 'MATCH (p)-[q]->(r) RETURN p.uid, q.quantity, r.uid, r.gwp_100';

            const links = [['from', 'to', 'weight']];

            sessionLinks
              .run(queryLinks)
              .then((result) => {
                const records = result.records;
                const summary = result.summary;

                records.forEach(record => {
                  let quantity = record.get('q.quantity');
                  quantity = (quantity != null || undefined || NaN) ? parseFloat(quantity) : 1;
                  if (isNaN(quantity)) {
                    quantity = 1;
                  }
                  const t = parseFloat(record.get('r.gwp_100'));
                  const v = (quantity) * (t);

                  links.push({
                    'from': record.get('p.uid'),
                    'to': record.get('r.uid'),
                    'value': v
                  });
                });

                FusionCharts.ready(function () {
                  const dataSource = {
                    chart: {
                      caption: '',
                      subcaption: 'A Horizontal Sankey with options to configure the flow representation',
                      theme: isLightTheme ? 'candy' : 'gammel',
                      orientation: 'horizontal',
                      linkalpha: 30,
                      linkhoveralpha: 60,
                      nodelabelposition: 'end',
                    },
                    nodes: nodes,
                    links: links,
                  };

                  var myChart = new FusionCharts({
                    type: 'sankey',
                    renderAt: 'chart-container',
                    width: '100%',
                    height: '69%',
                    dataFormat: 'json',
                    dataSource: dataSource,
                  });
                  myChart.render();
                });
              })
              .catch((error) => {
                console.error('Error executing links query:', error);
              })
              .finally(() => {
                sessionLinks.close();
                driver.close();
              });
          })
          .catch((error) => {
            console.error('Error executing nodes query:', error);
          })
          .finally(() => {
            sessionNodes.close();
          });
      } catch (error) {
        console.error('Error fetching Neo4J data:', error);
      }
    };


    getNeo4JData();
  }, [isLightTheme]);
  const handleThemeSwitch = () => {
    setCheck(!check)
    setIsLightTheme((prevTheme) => !prevTheme);
  };
  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& .MuiSwitch-thumb:before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            '#fff',
          )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
      width: 32,
      height: 32,
      '&::before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      borderRadius: 20 / 2,
    },
  }));
  return (
    <Modal open={true} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description' overflowY={'auto'}>
      <Box sx={style}>
        <div>
          <div style={{ maxHeight: 'calc(100vh - 12.20px)' }}>
            <Grid container>
              <Grid item xs={9}>
                <Typography
                  id='modal-modal-title'
                  component='h1'
                  style={{
                    color: '#2D323A',
                    marginBottom: '10px',
                    fontSize: '17',
                  }}
                >
                  <b>Carbon Pathways</b>
                  <Tooltip title={check ? "light" : "dark"}>
                    <FormControlLabel onChange={handleCheck}
                      control={<MaterialUISwitch sx={{ m: 1 }} defaultChecked={isLightTheme} onChange={handleThemeSwitch} />}
                    />
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Close
                    style={{
                      color: '#029E9E',
                      fontSize: 25,
                      cursor: 'pointer',
                      top: '50',
                    }}
                    onClick={() => {
                      props.handleCarbonPathwaysClose();
                    }}
                  />
                </div>
              </Grid>
            </Grid>

            <Divider
              style={{
                width: '100%',
                color: 'darkgray',
                marginBottom: '0.5rem',
              }}
            />

            <Grid
              container
              style={{
                position: 'absolute',
                height: '680',
                width: '100%',
                boxShadow: 24,
                display: 'flex',
              }}
            >
              <style>
                {`
                  #chart-container {
                    width: 98%;
                    height: 120vh; 
                    margin: 20px 0;
                  }
                `}
              </style>
              <div id='chart-container'></div>
            </Grid>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default Neo4jSankeyChart;