import React, { useState } from 'react'
import Divider from '@mui/material/Divider'
import { Modal, Paper ,Typography, Box,TextField, IconButton} from '@mui/material'
import { Grid } from '@material-ui/core'
import { Close } from '@mui/icons-material'
import SendIcon from '@mui/icons-material/Send'


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: 'calc(100vh - 65.20px)',
  width: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2
}

function EnvironmentalGptModal(props) {
  console.log('Props=onenv======', props)
  const [messages, setMessages] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [isSendIconClicked, setIsSendIconClicked] = useState(false)
  const handleInputChange = event => {
    setInputValue(event.target.value)
  }

  const handleInputKeyPress = event => {
    if (event.key === 'Enter') {
      setIsSendIconClicked(true)
      handleSendMessage()
    }
  }

  const handleSendMessage = () => {
    if (inputValue.trim() !== '') {
      const newMessage = { text: inputValue, sender: 'user' }
      newMessage.image = require('./../../assets/UserName.jpg')
      setMessages([...messages, newMessage])
      setInputValue('')
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    handleSendMessage()
  }

  const handleSendIconClick = () => {
    setIsSendIconClicked(true)
    handleSendMessage()
  }

  return (
    <Modal
      open={true}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      disablePortal
    >
      <Box sx={style}>
        <div>
          <Grid container>
            <Grid item xs={9}>
              <Typography
                id='modal-modal-title'
                component='h1'
                style={{
                  color: '#2D323A',
                  marginBottom: '10px',
                  fontSize: '17'
                }}
              >
                <b>Enviornmetal GPT</b>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end'
                }}
              >
                {/* <ShareOutlined
                  style={{ color: '#029E9E', fontSize: 25, cursor: 'pointer' }}
                />
                <Typography style={{ marginLeft: '6px', fontSize: '15px' }}>
                  Share
                </Typography>
                <Divider
                  orientation='vertical'
                  flexItem
                  style={{ margin: '0 10px' }}
                />
                <img
                  src={`${process.env.REACT_APP_BASE_URL}${datajson.images.MainCanvas.Menubar.upload}`}
                  alt='settings'
                  style={{
                    width: '25px',
                    height: '25px'
                  }}
                />
                <Typography
                  style={{
                    marginLeft: '5px',
                    fontSize: '15px',
                    marginRight: '12px'
                  }}
                > 
                  Export
                </Typography>*/}
                <Close
                  style={{ color: '#029E9E', fontSize: 25, cursor: 'pointer' }}
                  onClick={() => {
                    props.handleclose();

                  }}
                />

              </div>
            </Grid>
          </Grid>

          <Divider style={{ width: '100%', color: 'darkgray' }} />
          <div style={{ height: '70%', overflow: 'auto', padding: '40px' }}>
            {!isSendIconClicked && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '55px',
                  marginBottom: '30px'
                }}
              >
                <img
                  src={require('./../../assets/ChatImageIcon.png')}
                  alt=''
                  style={{
                    width: '100px',
                    height: '100px'
                  }}
                />
              </div>
            )}
            {!isSendIconClicked && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Typography
                  id='modal-modal-title'
                  component='h1'
                  style={{ fontSize: '25px' }}
                >
                  <b>Bahari Standards Compliance Assistant</b>
                </Typography>

                <Typography id='modal-modal-title' style={{ fontSize: '12px' }}>
                  Ask anything about Standards Compliance
                </Typography>
              </div>
            )}

            {messages.map((message, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  justifyContent:
                    message.sender === 'user' ? 'flex-start' : 'flex-end',
                  padding: '20px'
                }}
              >
                {message.sender === 'user' && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      background: '#EEEEEE',
                      padding: '10px',
                      borderRadius: '5px',
                      marginRight: '10px'
                    }}
                  >
                    {message.image && (
                      <img
                        src={message.image}
                        alt='User'
                        style={{
                          width: '8%',
                          height: '8%',
                          padding: '10px',
                          borderRadius: '10px'
                        }}
                      />
                    )}
                    <Typography
                      id='modal-modal-title'
                      component='h1'
                      style={{ fontSize: '16px' }}
                    >
                      {message.text}
                    </Typography>
                  </div>
                )}
                {message.sender !== 'user' && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      background: '#FFFFFF',
                      padding: '10px',
                      borderRadius: '5px',
                      marginLeft: '10px'
                    }}
                  >
                    <img
                      src={require('./../../assets/Gpt.png')}
                      alt='Answer'
                      style={{ width: '1%', height: '1%', marginRight: '7px' }}
                    />
                    <Typography
                      id='modal-modal-title'
                      style={{ fontSize: '2px' }}
                    >
                      {message.text}
                    </Typography>
                  </div>
                )}
              </div>
            ))}

            {isSendIconClicked && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  marginBottom: '10px'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    background: '#FFFFFF',
                    padding: '10px',
                    marginLeft: '14px',
                    borderRadius: '5px'
                  }}
                >
                  <img
                    src={require('./../../assets/Gpt.png')}
                    alt='Answer'
                    style={{
                      width: '4%',
                      height: '4%',
                      marginRight: '5px',
                      borderRadius: '10px'
                    }}
                  />
                  <Typography
                    id='modal-modal-title'
                    style={{ fontSize: '16px' }}
                  >
                    <ul>
                      <li>
                        GHG Scope 1, Scope 2, and Scope 3 are different
                        categories that help classify and measure greenhouse gas
                        emissions within an organization or activity. Each scope
                        represents a different level of emissions, with
                        increasing scope number encompassing a broader range of
                        emissions sources. Here's a detailed explanation of each
                        scope:
                      </li>
                      <ol>
                        <br></br>
                        <li>
                          GHG Scope 1: Scope 1 emissions refer to direct
                          greenhouse gas emissions that occur from sources that
                          are owned or controlled by the organization. These
                          emissions are produced from activities that are
                          directly under the organization's operational control,
                          such as combustion of fossil fuels, on-site emissions
                          from manufacturing processes, and transportation of
                          owned or controlled vehicles. Scope 1 emissions are
                          considered the most easily measurable and
                          controllable.
                        </li>
                        <li>
                          GHG Scope 2: Scope 2 emissions encompass indirect
                          greenhouse gas emissions associated with the
                          consumption of purchased electricity, heat, or steam.
                          These emissions are produced off-site but are a result
                          of the organization's activities. Scope 2 emissions
                          are typically generated from the generation of
                          electricity at power plants or other sources that
                          supply energy to the organization. This category
                          allows organizations to account for the environmental
                          impact of their energy consumption.
                        </li>
                        <li>
                          GHG Scope 3: Scope 3 emissions represent a broader
                          category of indirect greenhouse gas emissions that
                          occur as a consequence of the organization's
                          activities but are not owned or controlled by the
                          organization. These emissions can be attributed to the
                          entire value chain, including both upstream and
                          downstream activities. Scope 3 emissions can include
                          emissions from sources such as purchased goods and
                          services, business travel, employee commuting, waste
                          disposal, and transportation and distribution of
                          products. These emissions are often the most
                          challenging to measure and control as they involve a
                          wide range of activities outside the organization's
                          direct influence.
                        </li>
                      </ol>
                      <br></br>
                      <li>
                        Scope 3 emissions are considered to have the most
                        significant impact on a company's overall carbon
                        footprint, as they account for emissions associated with
                        the entire life cycle of a product or service. These
                        emissions extend beyond the organization's immediate
                        operations and require collaboration and engagement with
                        suppliers, customers, and other stakeholders to
                        effectively reduce and manage.
                      </li>
                      <br></br>
                      <li>
                        It is important for organizations to consider all three
                        scopes when evaluating and addressing their greenhouse
                        gas emissions. By accounting for emissions in each
                        scope, organizations can gain a comprehensive
                        understanding of their environmental impact and identify
                        opportunities for emission reductions and sustainability
                        improvements throughout their value chain. This holistic
                        approach is crucial for achieving meaningful progress in
                        addressing climate change and promoting sustainable
                        practices.
                      </li>
                      <br></br>
                    </ul>
                  </Typography>
                </div>
              </div>
            )}
          </div>
        </div>

        <Paper component='form' onSubmit={handleSubmit}>
          <TextField
            id='outlined-basic'
            label='Message'
            autoComplete='off'
            value={inputValue}
            onChange={handleInputChange}
            onKeyPress={handleInputKeyPress}
            fullWidth
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleSendIconClick}>
                  <SendIcon />
                </IconButton>
              )
            }}
          />
        </Paper>
      </Box>
    </Modal>
  )
}

export default EnvironmentalGptModal
