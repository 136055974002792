import './App.css';
import * as React from 'react'
import { Route, Routes, HashRouter } from "react-router-dom";
import Dashboard from './utilities/components/dashboard';
import MainCanvas from './utilities/components/mainCanvas';
import UserService from './services/UserService';



function App() {
  const [showProgress, setShowProgress] = React.useState(true)
  const getToken = React.useCallback(async () => {
    return await UserService.getToken(); // Get the Keycloak token asynchronously
  }, []);

  React.useEffect(() => {
    let interval;
    const checkTokenAndCallApi = async () => {
      const token = await getToken(); // Get the Keycloak token asynchronously
      if (token) {
        setShowProgress(false);
        clearInterval(interval);
      } else {
        console.error('No token available. User not authenticated.');
      }
    };
    interval = setInterval(() => {
      checkTokenAndCallApi();
    }, 500); // Check every 5 seconds
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [getToken]);

  return (
    <>
      {!showProgress && <div>
        <HashRouter>
          <Routes>
            <Route exact path="/" element={<Dashboard />} />
            <Route exact path="/:orgnization/:suborgnization" element={<Dashboard />} />
            <Route exact path="/mainCanvas" element={<MainCanvas />} />
          </Routes>
        </HashRouter>
      </div>
      }
    </>
  );
}

export default App;
