import React, { useEffect, useState } from 'react';
import { Paper, IconButton, Divider } from '@mui/material';
import { HeatMapGrid } from 'react-grid-heatmap';
import neo4j from 'neo4j-driver';
// import DonutChart from './DonutChart';
// import NodeForm from './NodeForm';
import Draggable from 'react-draggable';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import NodeForm from './NodeForm';
import { KeyboardArrowDown } from '@mui/icons-material';

const SortIcon = ({ ascending, onClick }) => (
  <IconButton onClick={onClick} size="small">
    {ascending ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
  </IconButton>
);
const SortIcon2 = ({ ascending, onClick }) => (
  <IconButton onClick={onClick} size="small">
    <ArrowDownwardIcon />
  </IconButton>
);

const NodeChildProperties = ({ selectedProperty, uid, neo4jConfig, width }) => {
  const chartStyle = {
    padding: '20px',
    border: '2px solid #3f51b5',
    borderRadius: '10px',
    backgroundColor: '#f0f0f0',
    flex: 1,
    zIndex: 999,
    overflowX: 'auto',
    // height: '300px',
    // width: '525px',
    width: width === '292%' ? "308px" : "480px",
    height: width === '292%' ? "300px" : "630px",
    overflowY: 'hidden'
  };
  const [containerHeight, setContainerHeight] = useState('200px');
  const [childNodes, setChildNodes] = useState([]);
  const [heatmapData, setHeatmapData] = useState([]);
  const [heatmapDataValues, setHeatmapDataValues] = useState([]);
  const [mainNode, setMainNode] = useState([null]);
  const [showForm, setShowForm] = useState(false);
  const [formNodeId, setFormNodeId] = useState(null);
  const [yLabels, setYLabels] = useState([]);
  const xLabels = [selectedProperty];
  const [ascending, setAscending] = useState(true);
  const [mark, setMark] = useState(1);
  const [zIndex, setZIndex] = useState(5);

  useEffect(() => {
    const fetchChildNodes = async () => {
      const { serverUrl, username, password } = neo4jConfig;
      const driver = neo4j.driver(serverUrl, neo4j.auth.basic(username, password));
      const session = driver.session();

      const nodeId = uid;

      try {
        const query = `MATCH (n {uid: '${nodeId}'})-[r]->(m) RETURN n,m, r;`;
        const result = await session.run(query);
        const newChildNodes = result.records.map((record) => {
          const node = record.get('m');
          const mainnode = record.get('n');
          console.log('+++++++++++++++++++++++');
          setMainNode(mainnode.properties.name);

          const relationship = record.get('r');
          return {
            id: node.identity.toString(),
            label: node.properties.name,
            // gwp_100: isNaN( parseFloat(node.properties.gwp_100) * parseFloat(relationship.properties.quantity))  ? 0: parseFloat(node.properties.gwp_100) * parseFloat(relationship.properties.quantity),
            // relationshipType: relationship.type,
            // htp: parseFloat(node.properties.htp) * parseFloat(relationship.properties.quantity),
            // ap: parseFloat(node.properties.ap) * parseFloat(relationship.properties.quantity),
            // faetp: parseFloat(node.properties.faetp) * parseFloat(relationship.properties.quantity),
            // maetp: parseFloat(node.properties.maetp) * parseFloat(relationship.properties.quantity),
            // tetp: parseFloat(node.properties.tetp) * parseFloat(relationship.properties.quantity),
            // adp_fossil: parseFloat(node.properties.adp_fossil) * parseFloat(relationship.properties.quantity),
            // ep: parseFloat(node.properties.ep) * parseFloat(relationship.properties.quantity),
            // adp_elements: parseFloat(node.properties.adp_elements) * parseFloat(relationship.properties.quantity),
            // odp: parseFloat(node.properties.odp) * parseFloat(relationship.properties.quantity),
            // pocp: parseFloat(node.properties.pocp) * parseFloat(relationship.properties.quantity),
            // quantity: parseFloat(relationship.properties.quantity),
            gwp_100: isNaN(parseFloat(relationship.properties.quantity)) ? parseFloat(node.properties.gwp_100) * 1 : parseFloat(node.properties.gwp_100) * parseFloat(relationship.properties.quantity),
            htp: isNaN(parseFloat(relationship.properties.quantity)) ? parseFloat(node.properties.htp) : parseFloat(node.properties.htp) * parseFloat(relationship.properties.quantity),
            ap: isNaN(parseFloat(relationship.properties.quantity)) ? parseFloat(node.properties.ap) : parseFloat(node.properties.ap) * parseFloat(relationship.properties.quantity),
            faetp: isNaN(parseFloat(relationship.properties.quantity)) ? parseFloat(node.properties.faetp) : parseFloat(node.properties.faetp) * parseFloat(relationship.properties.quantity),
            maetp: isNaN(parseFloat(relationship.properties.quantity)) ? parseFloat(node.properties.maetp) : parseFloat(node.properties.maetp) * parseFloat(relationship.properties.quantity),
            tetp: isNaN(parseFloat(relationship.properties.quantity)) ? parseFloat(node.properties.tetp) : parseFloat(node.properties.tetp) * parseFloat(relationship.properties.quantity),
            adp_fossil: isNaN(parseFloat(relationship.properties.quantity)) ? parseFloat(node.properties.adp_fossil) : parseFloat(node.properties.adp_fossil) * parseFloat(relationship.properties.quantity),
            ep: isNaN(parseFloat(relationship.properties.quantity)) ? parseFloat(node.properties.ep) : parseFloat(node.properties.ep) * parseFloat(relationship.properties.quantity),
            adp_elements: isNaN(parseFloat(relationship.properties.quantity)) ? parseFloat(node.properties.adp_elements) : parseFloat(node.properties.adp_elements) * parseFloat(relationship.properties.quantity),
            odp: isNaN(parseFloat(relationship.properties.quantity)) ? parseFloat(node.properties.odp) : parseFloat(node.properties.odp) * parseFloat(relationship.properties.quantity),
            pocp: isNaN(parseFloat(relationship.properties.quantity)) ? parseFloat(node.properties.pocp) : parseFloat(node.properties.pocp) * parseFloat(relationship.properties.quantity),
            quantity: parseFloat(relationship.properties.quantity),
          };
        });

        const sortedNodes = newChildNodes.sort((a, b) => {
          const sortOrder = mark ? 1 : -1;
          return sortOrder * (b[selectedProperty] - a[selectedProperty]);
        });

        setChildNodes(sortedNodes);

        setYLabels(sortedNodes.map((node) => node.label));

        const heatmapDataValues = sortedNodes.map((node) => ({
          x: [selectedProperty],
          y: node.label,
          value: [parseFloat(node[selectedProperty])],
          // value: isNaN(parseFloat(node[selectedProperty])) ? 0 : [parseFloat(node[selectedProperty])]
        }));

        const transformedData = heatmapDataValues.reduce((acc, currentValue) => {
          const rowIndex = yLabels.indexOf(currentValue.y);

          if (!acc[rowIndex]) {
            acc[rowIndex] = new Array(xLabels.length).fill(0);
          }

          currentValue.value.forEach((val, index) => {
            acc[rowIndex][index] = val;
          });

          return acc;
        }, []);

        setHeatmapData(transformedData);
        setHeatmapDataValues(heatmapDataValues);
      } catch (error) {
        console.error('Error fetching child nodes:', error);
      } finally {
        session.close();
        driver.close();
      }
    };

    fetchChildNodes();
  }, [neo4jConfig, uid, selectedProperty, ascending, heatmapDataValues, heatmapData, mark]);


  const handleSort = () => {
    if (mark == 1) {

      setMark(0);
    }

    else if (mark == 0) {

      setMark(1);
    }
  };


  const toogleCollapse = (id) => {
    const nodeProperty = document.getElementById(id);
    if (nodeProperty.classList.contains('hide')) {
      nodeProperty.classList.remove('hide');
    } else {
      nodeProperty.classList.add('hide');
    }
  };

  return (
    // <Draggable zIndex={zIndex}>


    <div className="node-property" id="np-3">

      <div className="node-header" onClick={() => toogleCollapse('np-3')}>
        <label>Detailed Env. Impact</label>
        <div className="node-header-action">
          <KeyboardArrowDown />
        </div>
      </div>
      {/* {heatmapData.length > 0 && ( */}

      {/* <Paper
           style={{ backgroundColor: 'white', textAlign: 'center', height: '100%' }}
         > */}
      <div className="node-property-content">
        <label style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <strong>{mainNode}</strong>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ArrowUpwardIcon style={{ cursor: 'pointer', color: 'rgb(255, 130, 128)' }} onClick={() => handleSort(true)} />
            <ArrowDownwardIcon style={{ cursor: 'pointer', color: 'rgb(255, 130, 128)' }} onClick={() => handleSort(false)} />
          </div>
        </label>
        <Divider sx={{ my: .8 }} />

        {!uid && <span>Please select a bar chart</span>}
        <div style={{ overflowX: 'auto', width: '100%', overflowY: 'auto', minHeight: '100px' }}>

          <HeatMapGrid
            overflowX="auto"
            data={heatmapData}
            // xLabels={xLabels}
            yLabels={yLabels}
            cellRender={(x, y, value) => (
              <div
                title={`Pos(${x}, ${y}) = ${value.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}`}
              >
                {value.toLocaleString('en-US', { maximumFractionDigits: 2 })}
              </div>
            )}
            xLabelsStyle={(index) => ({
              backgroundColor: '',
            })}
            yLabelsStyle={() => ({
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontSize: '.6rem',
              textTransform: 'uppercase',
              color: 'black',
              backgroundColor: '',
            })}
            cellHeight="1rem"
            cellWidth="2rem"
            xLabelsPos="top"
            onClick={(x, y) => {
              console.log('~~~~~~~~~~~~~~~~~');
              console.log(heatmapDataValues);
              setFormNodeId(heatmapDataValues[x].y);
              setShowForm(true);
              setContainerHeight('685px');
            }}
            yLabelsPos="left"
            rectangle="false"
            backgroundColor=" #d2e7ff"
            cellStyle={(x, y, ratio) => ({
              background: `rgb(255,130, 128, ${ratio})`,
              fontSize: '.6rem',
              color: `rgb(0, 0, 0, ${ratio / 2 + 0.4})`,
            })}
          />
        </div>
        {showForm && (
          <NodeForm neo4jConfig={neo4jConfig} nodeId={formNodeId} onClose={() => setShowForm(false)} />
        )}
      </div>
      {/* </Paper> */}

    </div >


    // </Draggable>
  );
};

export default NodeChildProperties;
