import L from "leaflet";
import { createControlComponent } from "@react-leaflet/core";
import "leaflet-routing-machine";
import markerIcon from '../../assets/marker-icon-2.png'
import './../css/map.css'

const createRoutineMachineLayer = (props) => {

    const formatNumber = (num) => {
        if (typeof num === 'number') {
            return num.toFixed(2);
        }
        return num;
    }
    const marker = L.icon({
        iconUrl: markerIcon,
        iconSize: [24, 32],
        iconAnchor: [12, 32],
    });
    const startPoint = props.route[0]
    const endPoint = props.route[1]
    const supplyChainData = props?.locToPort
    const tooltipContent = `
                <div style="text-align: center; padding: 3px;">
                    ${supplyChainData?.location}
                    <div style="text-align: center; background: #029E9E;">
                    Mode of transport : Truck-Trailer
                    </div>
                    <div>
                        ${supplyChainData?.portLocation}
                    </div>
                </div>
                <div style="padding: 3px;">
                    Distance: ${formatNumber(supplyChainData?.distance)} Km
                    <br />
                    <strong>Global Warming Potential (GWP)</strong>: ${supplyChainData?.emission} Kg CO2/Km
                </div>
            `;

    const instance = L.Routing.control({
        waypoints: [
            L.latLng(startPoint[0], startPoint[1]),
            L.latLng(endPoint[0], endPoint[1]),
        ],
        lineOptions: {
            styles: [{ color: "#029E9E", weight: 3 }]
        },
        show: false,
        showInstructions: false,
        fitSelectedRoutes: false,
        createMarker: (i, waypoint, n) => {
            if (i === 0 || i === n - 1) {
                const markerOptions = { icon: marker };
                return L.marker(waypoint.latLng, markerOptions)
                    .bindTooltip(tooltipContent, {
                        permanent: false,
                        direction: "sticky",
                        opacity: 0.7
                    })
                    .openTooltip();

            }
            return null;
        },
    });

    if (instance?._routes) {

        const tooltip = L.tooltip({
            permanent: false,
            direction: "bottom",
            opacity: 0.7,
        }).addTo(instance._map);

        const routeLayer = instance?._routes[0]?.coordinates;

        routeLayer.on("mouseover", (e) => {
            tooltip.setContent("This is the route!");
            tooltip.setLatLng(e.latlng).openTooltip();
        });

        routeLayer.on("mouseout", () => {
            tooltip.removeFrom(instance._map);
        });
    }

    return instance;
};

const RoutingMachine = createControlComponent(createRoutineMachineLayer);

export default RoutingMachine;
