import Keycloak from "keycloak-js";
import axios from 'axios';
let _kc;
/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = async () => {
  const url = window.location.pathname;
  const match = url.match(/\/([^\/]+)\/([^\/]+)/); // Match the pattern of "/{segment}/{segment}"
  let firstSegment = ''; // TATA
  let secondSegment = '';
  if (match) {
    firstSegment = match[1]; // TATA
    secondSegment = match[2]; // tata-motors

    console.log(firstSegment); // Output: TATA
    console.log(secondSegment); // Output: tata-motors
  }
  //const response = await axios.get(process.env.REACT_APP_BACKEND_URL + `/applicationtemplates/${firstSegment}/${secondSegment}`);
  const response = await axios.get(process.env.REACT_APP_BACKEND_URL + '/applicationtemplates');
  const keycloak_config = response.data.data.keycloak_config;
  _kc = new Keycloak(keycloak_config);

  return new Promise((resolve, reject) => {
    _kc.init(keycloak_config)
      .then(authenticated => {
        if (!authenticated) {
          sessionStorage.setItem("failed", "yes");
          console.log("user is not authenticated..!");
          reject(new Error("Keycloak initialization failed"));
        } else {
          // debugger;
          sessionStorage.setItem("token", getToken());
          sessionStorage.setItem("userName", getUserFullname());
          sessionStorage.setItem("email", getEmail());
          sessionStorage.setItem("role", getRole());
          const tenanatId = getTenantid();
          sessionStorage.setItem("tenanatId", tenanatId);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

const validateKeycloakInstance = () => {
  if (!_kc) {
    console.error("Keycloak instance is not initialized yet.");
    throw new Error("Keycloak instance is not initialized.");
  }
};


const doLogin = () => {
  validateKeycloakInstance()
  _kc.login();
};

const doLogout = () => {
  validateKeycloakInstance()
  return _kc.logout({
    redirectUri: process.env.REACT_APP_BASE_URL
  });
};

const getToken = () => {
  validateKeycloakInstance()
  return _kc.token;
};

const isLoggedIn = () => !!getToken();

const updateToken = (successCallback) => {
  validateKeycloakInstance()
  _kc.updateToken(5).then(successCallback).catch(doLogin);
};

const getUsername = () => {
  validateKeycloakInstance()
  return _kc.tokenParsed?.preferred_username;
};

const getUserFullname = () => {
  validateKeycloakInstance()
  return _kc.tokenParsed?.name;
}

const getEmail = () => {
  validateKeycloakInstance()
  return _kc.tokenParsed?.email
};

const getRole = () => {
  validateKeycloakInstance()
  let roles = _kc.tokenParsed?.realm_access.roles;

  const rolesToRemove = [
    "offline_access",
    "default-roles-bahari",
    "uma_authorization"
  ];

  const remainingRoles = roles.filter(role => !rolesToRemove.includes(role));
  return remainingRoles[0];
}

const hasRole = (roles) => {
  validateKeycloakInstance()
  return roles.some((role) => _kc.hasRealmRole(role));
};

const getTenantid = () => {
  validateKeycloakInstance()
  return _kc.tokenParsed ? _kc.tokenParsed["Group Id"] : null;
};

const getProfile = () => {
  validateKeycloakInstance()
  return _kc.loadUserProfile()
    .then((profile) => {
      console.log(profile);
      return profile;
    })
    .catch((error) => {
      console.error("Error loading user profile:", error);
      return null;
    });
};

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  getProfile,
  getTenantid,
  getEmail,
  getUserFullname,
  getRole
};

export default UserService;
