import React from 'react'
import {
    Divider,
    Grid,
    MenuItem,
    Select,
    Slider,
    Typography
} from '@mui/material'
import { TextField, Radio, FormControlLabel } from '@material-ui/core'
import Switch from '@mui/material/Switch'

const label = { inputProps: { 'aria-label': 'Switch demo' } };


function SettingsSidePanelComponent({ customColorSetting }) {
    const [value, setValue] = React.useState('off');
    const [value1, setValue1] = React.useState('off');


    const handleChange = () => {
        setValue(value === 'off' ? 'on' : 'off');
    };

    const handleChange1 = () => {
        setValue1(value1 === 'off' ? 'on' : 'off');
    };

    return (
        <>
            <Grid item xs={12}><Typography color={'#4A4A4A'} style={{ fontSize: '14' }}><b>TITLES</b></Typography></Grid>
            <Grid style={{ display: "block", backgroundColor: 'white', border: 'none', marginLeft: 0, width: '100%' }} container marginTop={1.1} spacing={0.5} paddingRight={1}>
                <Grid item container alignItems={'center'}>
                    <Grid item xs={3.7}>
                        <div style={{ color: '#4A4A4A', fontSize: '13', fontWeight: '500' }}>Nodes</div>
                    </Grid>
                    <Grid item xs={8.3}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value='10'
                            label=""
                            fullWidth
                            style={{ height: '37', fontSize: '13px', border: '1px solid white' }}
                        // onChange={handleChange}
                        >
                            <MenuItem value={10}>name</MenuItem>
                            <MenuItem value={20}>adp_elements</MenuItem>
                            <MenuItem value={30}>ep</MenuItem>
                        </Select>
                    </Grid>
                </Grid>

                <Grid item container alignItems={'center'} padding={0} spacing={0}>
                    <Grid item xs={3.7}>
                        <div style={{ color: '#4A4A4A', fontSize: '13', fontWeight: '500' }}>Relationships</div>
                    </Grid>
                    <Grid item xs={8.3}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value='10'
                            label=""
                            fullWidth
                            style={{ height: '37', fontSize: '13px', border: '1px solid white' }}
                        // onChange={handleChange}
                        >
                            <MenuItem value={10}>_dbRelType</MenuItem>
                            <MenuItem value={20}>adp_elements</MenuItem>
                            <MenuItem value={30}>ep</MenuItem>
                        </Select>
                    </Grid>
                </Grid>

                <Grid item container alignItems={'center'} padding={0} spacing={0}>
                    <Grid item xs={3.7}>
                        <div style={{ color: '#4A4A4A', fontSize: '13', fontWeight: '500' }}>Title Size</div>
                    </Grid>
                    <Grid item xs={8.3}>
                        <Slider
                            defaultValue={50}
                            aria-label="Default"
                            valueLabelDisplay="auto"
                            style={{ color: '#029E9E' }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Divider style={{ width: '100%', color: 'black', marginTop: '0.5rem', marginBottom: '0.5rem' }} />



            <Grid item container alignItems={'center'}>
                <Grid item xs={10}><Typography color={'#4A4A4A'} style={{ fontSize: '14' }}><b>TOOLTIPS</b></Typography></Grid>
                <Grid item xs={2}>
                    <Switch
                        color='default'
                        defaultChecked
                        style={{ color: '#029E9E' }}
                    />
                </Grid>
            </Grid>

            <Grid style={{ display: "block", backgroundColor: 'white', border: 'none', marginLeft: 0, width: '100%' }} container marginTop={1.1} spacing={0.8}>
                <Grid item xs={12}>
                    <div style={{ color: '#4A4A4A', fontSize: '13', fontWeight: '500', marginBottom: '3' }}>Node Tooltip Properties</div>
                    <TextField defaultValue='' size='small' style={{ backgroundColor: 'white', width: '95%', fontSize: '13' }} id="outlined-basic" variant="outlined" />
                </Grid>
                <Grid item xs={12}>
                    <div style={{ color: '#4A4A4A', fontSize: '13', fontWeight: '500', marginBottom: '3', marginTop: '5' }}>Relationships Tooltip Properties</div>
                    <TextField defaultValue='' size='small' style={{ backgroundColor: 'white', width: '95%', fontSize: '13', marginBottom: '10' }} id="outlined-basic" fullWidth variant="outlined" />
                </Grid>
            </Grid>

            <Divider style={{ width: '100%', color: 'black', marginTop: '0.5rem', marginBottom: '0.5rem' }} />

            <Grid item container alignItems={'center'}>
                <Grid item xs={10}><Typography color={'#4A4A4A'} style={{ fontSize: '14' }}><b>SNIPPETS</b></Typography></Grid>
                <Grid item xs={2}>
                    <Switch
                        color='default'
                        defaultChecked
                        style={{ color: '#029E9E' }}
                    />
                </Grid>
            </Grid>
            <Grid style={{ display: "block", backgroundColor: 'white', border: 'none', marginLeft: 0, width: '100%' }} container marginTop={1.1} spacing={0.8} >
                <Grid item xs={12}>
                    <div style={{ color: '#4A4A4A', fontSize: '13', fontWeight: '500', marginBottom: '3' }}>Node Snippet Properties</div>
                    <TextField defaultValue='' size='small' style={{ backgroundColor: 'white', width: '95%', fontSize: '13', marginBottom: '10' }} id="outlined-basic" variant="outlined" />
                </Grid>
            </Grid>

            <Divider style={{ width: '100%', color: 'black', marginTop: '0.5rem', marginBottom: '0.5rem' }} />

            <Grid item xs={12}><Typography color={'#4A4A4A'} style={{ fontSize: '14' }}><b>STYLE</b></Typography></Grid>
            <Grid item container alignItems={'center'}>
                <Grid item xs={10}><Typography color={'#029E9E'} style={{ fontSize: '14', marginTop: '15' }}><b>Nodes</b></Typography></Grid>
                <Grid item xs={2}>
                    <Switch
                        color='default'
                        defaultChecked
                        style={{ color: '#029E9E' }}
                    />
                </Grid>
            </Grid>
            <Grid style={{ display: "block", backgroundColor: 'white', border: 'none', marginLeft: 0, width: '100%' }} container marginTop={1.1} spacing={0.5} paddingRight={0.8} paddingBottom={0.8}>
                <Grid item container alignItems={'center'}>
                    <Grid item xs={3.7}>
                        <div style={{ color: '#4A4A4A', fontSize: '13', alignItems: 'center', fontWeight: '500' }}>Background image</div>
                    </Grid>
                    <Grid item xs={8.3}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value='10'
                            label=""
                            fullWidth
                            style={{ height: '37', fontSize: '13px', border: '1px solid white' }}
                        // onChange={handleChange}
                        >
                            <MenuItem value={10}>Custom Rule</MenuItem>
                            <MenuItem value={20}>adp_elements</MenuItem>
                            <MenuItem value={30}>ep</MenuItem>
                        </Select>
                    </Grid>
                </Grid>

                <Grid item container alignItems={'center'} padding={0} spacing={0}>
                    <Grid item xs={3.7}>
                        <div style={{ color: '#4A4A4A', fontSize: '13', fontWeight: '500' }}>Background color</div>
                    </Grid>
                    <Grid item xs={8.3}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value='10'
                            label=""
                            fullWidth
                            style={{ height: '37', fontSize: '13px', border: '1px solid white' }}
                        // onChange={handleChange}
                        >
                            <MenuItem value={10}>Custom Rule</MenuItem>
                            <MenuItem value={20}>adp_elements</MenuItem>
                            <MenuItem value={30}>ep</MenuItem>
                        </Select>
                    </Grid>
                </Grid>

                <Grid item container alignItems={'center'} padding={0} spacing={0}>
                    <Grid item xs={3.7}>
                        <div style={{ color: '#4A4A4A', fontSize: '13', fontWeight: '500' }}>Shape</div>
                    </Grid>
                    <Grid item xs={8.3}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value='10'
                            label=""
                            fullWidth
                            style={{ height: '37', fontSize: '13px', border: '1px solid white' }}
                        // onChange={handleChange}
                        >
                            <MenuItem value={10}>Custom Rule</MenuItem>
                            <MenuItem value={20}>adp_elements</MenuItem>
                            <MenuItem value={30}>ep</MenuItem>
                        </Select>
                    </Grid>
                </Grid>

                <Grid item container alignItems={'center'} padding={0} spacing={0}>
                    <Grid item xs={3.7}>
                        <div style={{ color: '#4A4A4A', fontSize: '13', fontWeight: '500' }}>Border width</div>
                    </Grid>
                    <Grid item xs={8.3}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value='10'
                            label=""
                            fullWidth
                            style={{ height: '37', fontSize: '13px', border: '1px solid white' }}
                        // onChange={handleChange}
                        >
                            <MenuItem value={10}>Custom Rule</MenuItem>
                            <MenuItem value={20}>adp_elements</MenuItem>
                            <MenuItem value={30}>ep</MenuItem>
                        </Select>
                    </Grid>
                </Grid>


                <Grid item container alignItems={'center'} padding={0} spacing={0}>
                    <Grid item xs={3.7}>
                        <div style={{ color: '#4A4A4A', fontSize: '13', fontWeight: '500' }}>Border color</div>
                    </Grid>
                    <Grid item xs={8.3}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value='10'
                            label=""
                            fullWidth
                            style={{ height: '37', fontSize: '13px', border: '1px solid white' }}
                        // onChange={handleChange}
                        >
                            <MenuItem value={10}>Custom Rule</MenuItem>
                            <MenuItem value={20}>adp_elements</MenuItem>
                            <MenuItem value={30}>ep</MenuItem>
                        </Select>
                    </Grid>
                </Grid>

                <Grid item container alignItems={'center'} padding={0} spacing={0}>
                    <Grid item xs={3.7}>
                        <div style={{ color: '#4A4A4A', fontSize: '13', fontWeight: '500' }}>Border size</div>
                    </Grid>
                    <Grid item xs={8.3}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value='10'
                            label=""
                            fullWidth
                            style={{ height: '37', fontSize: '13px', border: '1px solid white' }}
                        // onChange={handleChange}
                        >
                            <MenuItem value={10}>Custom Rule</MenuItem>
                            <MenuItem value={20}>adp_elements</MenuItem>
                            <MenuItem value={30}>ep</MenuItem>
                        </Select>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item container alignItems={'center'}>
                <Grid item xs={10}><Typography color={'#029E9E'} style={{ fontSize: '14', marginTop: '15' }}><b>Relationships</b></Typography></Grid>
                <Grid item xs={2}>
                    <Switch
                        color='default'
                        defaultChecked
                        style={{ color: '#029E9E' }}
                    />
                </Grid>
            </Grid>
            <Grid style={{ display: "block", backgroundColor: 'white', border: 'none', marginLeft: 0, width: '100%' }} container marginTop={1.1} spacing={0.5} paddingRight={0.8}>
                <Grid item container alignItems={'center'}>
                    <Grid item xs={3.7}>
                        <div style={{ color: '#4A4A4A', fontSize: '13', alignItems: 'center', fontWeight: '500' }}>Color</div>
                    </Grid>
                    <Grid item xs={8.3}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            // value={linkColor}
                            label=""
                            fullWidth
                            style={{ height: '37', fontSize: '13px', border: '1px solid white' }}
                        // onChange={handleLinkColorChange}
                        >
                            <MenuItem value={"blue"}>Blue</MenuItem>
                            <MenuItem value={"red"}>Red</MenuItem>
                            <MenuItem value={"green"}>Green</MenuItem>
                        </Select>
                    </Grid>
                </Grid>

                <Grid item container alignItems={'center'} padding={0} spacing={0}>
                    <Grid item xs={3.7}>
                        <div style={{ color: '#4A4A4A', fontSize: '13', fontWeight: '500' }}>Width</div>
                    </Grid>
                    <Grid item xs={8.3}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value='10'
                            label=""
                            fullWidth
                            style={{ height: '37', fontSize: '13px', border: '1px solid white' }}
                        // onChange={handleLinkWidthChange}
                        >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                        </Select>
                    </Grid>
                </Grid>

                <Grid item container alignItems={'center'} padding={0} spacing={0}>
                    <Grid item xs={3.7}>
                        <div style={{ color: '#4A4A4A', fontSize: '13', fontWeight: '500' }}>Style</div>
                    </Grid>
                    <Grid item xs={8.3}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value='10'
                            label=""
                            fullWidth
                            style={{ height: '37', fontSize: '13px', border: '1px solid white' }}
                        // onChange={handleChange}
                        >
                            <MenuItem value={10}>-</MenuItem>
                            <MenuItem value={20}>adp_elements</MenuItem>
                            <MenuItem value={30}>ep</MenuItem>
                        </Select>
                    </Grid>
                </Grid>

                <Grid item container alignItems={'center'} padding={0} spacing={0}>
                    <Grid item xs={3.7}>
                        <div style={{ color: '#4A4A4A', fontSize: '13', fontWeight: '500' }}>Curve style</div>
                    </Grid>
                    <Grid item xs={8.3}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value='10'
                            label=""
                            fullWidth
                            style={{ height: '37', fontSize: '13px', border: '1px solid white' }}
                        // onChange={handleChange}
                        >
                            <MenuItem value={10}>-</MenuItem>
                            <MenuItem value={20}>adp_elements</MenuItem>
                            <MenuItem value={30}>ep</MenuItem>
                        </Select>
                    </Grid>
                </Grid>


                <Grid item container alignItems={'center'} padding={0} spacing={0}>
                    <Grid item xs={3.7}>
                        <div style={{ color: '#4A4A4A', fontSize: '13', fontWeight: '500' }}>Arrow shape</div>
                    </Grid>
                    <Grid item xs={8.3}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value='10'
                            label=""
                            fullWidth
                            style={{ height: '37', fontSize: '13px', border: '1px solid white', marginBottom: '10' }}
                        // onChange={handleChange}
                        >
                            <MenuItem value={10}>-</MenuItem>
                            <MenuItem value={20}>adp_elements</MenuItem>
                            <MenuItem value={30}>ep</MenuItem>
                        </Select>
                    </Grid>
                </Grid>
            </Grid>

            <Divider style={{ width: '100%', color: 'black', marginTop: '0.5rem', marginBottom: '0.5rem' }} />

            <Grid item xs={12}>
                <Typography color={'#4A4A4A'} style={{ fontSize: '14' }}>
                    <b>ADDITIONAL SETTINGS</b>
                </Typography>
            </Grid>
            <Grid item xs={10}>
                <Typography
                    color={'#029E9E'}
                    style={{ fontSize: '14', marginTop: '15' }}
                >
                    <b>Loading Mode</b>
                </Typography>
            </Grid>
            <Grid
                style={{
                    display: 'block',
                    backgroundColor: 'white',
                    border: 'none',
                    marginLeft: 0,
                    width: '100%'
                }}
                container
                marginTop={1.1}
                spacing={0.5}
                padding={0.9}
                marginBottom={1}
            >
                <Grid item container alignItems="center">
                    <Grid item xs={12} sm={3.5} >
                        <Switch {...label} defaultChecked color='default' style={{ size: '2px', color: '#029E9E' }} />
                    </Grid>
                    <Grid item xs={12} sm={8.3}>
                        <div style={{ color: '#4A4A4A', fontSize: '13', fontWeight: '500' }}>Ask when visualization is being opened</div>
                    </Grid>
                </Grid>

                <Grid item container alignItems="center">
                    <Grid item xs={12} sm={3.5} >
                        <FormControlLabel
                            control={<Radio style={{ color: '#029E9E' }} />}
                            checked={value1 === 'on'}
                            onClick={handleChange1}
                            style={{ marginLeft: '15' }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8.3}>
                        <div style={{ color: '#4A4A4A', fontSize: '13', fontWeight: '500' }}>Snapshot - Load the graph exactly as it was stored</div>
                    </Grid>
                </Grid>

                <Grid item container alignItems="center">
                    <Grid item xs={12} sm={3.5} >
                        <FormControlLabel
                            control={<Radio style={{ color: '#029E9E' }} />}
                            checked={value === 'on'}
                            onClick={handleChange}
                            style={{ marginLeft: '15' }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8.3}>
                        <div style={{ color: '#4A4A4A', fontSize: '13', fontWeight: '500' }}>Refresh - show me what has changed since last save</div>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default SettingsSidePanelComponent
