import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import {
    Checkbox,
    FormControl,
    MenuItem,
    Modal,
    Select,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material'
import { Grid, Paper } from '@material-ui/core'
import datajson from '../template.json'
import { Close } from '@mui/icons-material'
import HeatMapTable_CarbonReports from './HeatMapTable_CarbonReports'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import ListItemText from '@mui/material/ListItemText'
import { useState } from 'react'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: 'calc(100vh - 65.20px)',
    width: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2
}

const tableContentStyle1 = {
    fontSize: '11.3',
    lineHeight: '1.3',
    fontWeight: 'bold',
    color: 'white',
    padding: '10px'
}
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
}

const label = { inputProps: { 'aria-label': 'Checkbox demo' } }

function CarbonReports(props) {
    let content = []

    const [selectedOptions, setSelectedOptions] = useState([''])
    const [checkedScope1, setCheckedScope1] = React.useState(true)
    const [checkedScope2, setCheckedScope2] = React.useState(true)
    const [checkedScope3, setCheckedScope3] = React.useState(true)
    const [rootNodeSelected, setRootNodeSelected] = React.useState("")
    const [rootDetails, setRootDetails] = React.useState([])

    const handleRootNodeSelected = value => {
        setRootNodeSelected(value.target.value)
    }

    const handleScope1Change = () => {
        setCheckedScope1(!checkedScope1)
    }

    const handleScope2Change = () => {
        setCheckedScope2(!checkedScope2)
    }

    const handleScope3Change = () => {
        setCheckedScope3(!checkedScope3)
    }

    const handleVariableChange = event => {
        const {
            target: { value }
        } = event

        setSelectedOptions(value)
    }

    const getNodeMaterialDetails = (nodeID) => {
        let ScopeDetails = props.getChildNodes(nodeID)
        let ScopeNodeId = []
        ScopeDetails.forEach(node => {
            ScopeNodeId.push(node.id)
        })
        ScopeNodeId.forEach(scopeNode => {
            let materialDetails = props.getChildNodes(scopeNode)
            materialDetails.forEach(material => {
                let linkInfo = props.getLink(scopeNode, material.id)
                let contentTemp = {
                    "Scope": linkInfo.source.uid.split('-')[0],
                    "Material": material.uid,
                    "eci_cost": linkInfo.target.eci_cost * linkInfo.quantity,
                    "adp_elements": linkInfo.target.adp_elements * linkInfo.quantity,
                    "adp_fossil": linkInfo.target.adp_fossil * linkInfo.quantity,
                    "ap": linkInfo.target.ap * linkInfo.quantity,
                    "ep": linkInfo.target.ep * linkInfo.quantity,
                    "faetp": linkInfo.target.faetp * linkInfo.quantity,
                    "gwp_100": linkInfo.target.gwp_100 * linkInfo.quantity,
                    "gwp_100_ebc": linkInfo.target.gwp_100_ebc * linkInfo.quantity,
                    "htp": linkInfo.target.htp * linkInfo.quantity,
                    "maetp": linkInfo.target.maetp * linkInfo.quantity,
                    "odp": linkInfo.target.odp * linkInfo.quantity,
                    "pocp": linkInfo.target.pocp * linkInfo.quantity,
                    "tetp": linkInfo.target.tetp * linkInfo.quantity
                };
                if (checkedScope1 && contentTemp.Scope === 'Scope 1') {
                    content.push(contentTemp);
                }
                if (checkedScope2 && contentTemp.Scope === 'Scope 2') {
                    content.push(contentTemp);
                }
                if (checkedScope3 && contentTemp.Scope === 'Scope 3') {
                    content.push(contentTemp);
                }
            });
        });
    }

    getNodeMaterialDetails(rootNodeSelected);
    const columns = [
        { label: 'Scope', property: 'Scope' },
        { label: 'Material', property: 'Material' },
        { label: 'Global Warming Potential (GWP 100 years) [kg CO2 eq.]', property: 'gwp_100' },
        { label: 'ECI Cost (USD)', property: 'eci_cost' },
        { label: 'Abiotic Depletion (ADP Elements) [kg Sb eq.]', property: 'adp_elements' },
        { label: 'Abiotic Depletion (ADP Fossils) [MJ]', property: 'adp_fossil' },
        { label: 'Acidification Potential (AP) [kg SO2 eq.]', property: 'ap' },
        { label: 'Eutrophication Potential (EP) [kg Phosphet eq.]', property: 'ep' },
        { label: 'Freshwater Aquatic Ecotoxicity Pot. (FAETP inf.) [kg DCB eq.]', property: 'faetp' },
        { label: 'Global Warming Potential (GWP 100 years), excl biogenic carbon [kg CO2 eq.]', property: 'gwp_100_ebc' },
        { label: 'Human Toxicity Potential (HTP inf.) [kg DCB eq.]', property: 'htp' },
        { label: 'Marine Aquatic Ecotoxicity Pot. (MAETP inf) [kg DCB eq.]', property: 'maetp' },
        { label: 'Ozone Layer Depletion Potential (ODP steaady state) [kg R11 eq.]', property: 'odp' },
        { label: 'Photochem. Ozone Creation Potential (POCP) [kg Ethene eq.]', property: 'pocp' },
        { label: 'Terrestric Ecotoxicity Potential (TETP inf.) [kg DCB eq.]', property: 'tetp' }
    ];

    const filteredCarbon12 = content.filter(entry => entry.Scope !== "Scope 3");
    const filteredCarbon3 = content.filter(entry => entry.Scope == "Scope 3");
    const filteredCarbon123 = filteredCarbon12.concat(filteredCarbon3);

    const exportPDF = () => {
        if (rootNodeSelected != "") {
            const doc = new jsPDF('p', 'pt', 'a3')
            const table = document.getElementById('Table_Id')

            const title = 'Neom Tunnel Carbon Reports';
            const titleX = doc.internal.pageSize.getWidth() / 2;
            const titleY = 25;

            const margins = {
                top: 50,
                bottom: 10,
                left: 20,
                width: 800
            }

            doc.setFontSize(15);
            doc.setTextColor(8, 116, 130);
            doc.text(title, titleX, titleY, { align: 'center' });


            html2canvas(table).then(canvas => {

                doc.addImage(require('./../../assets/sustain360_dark.png'), 'PNG', margins.left, 11, 80, 12);
                doc.addImage(require('./../../assets/guardtime.png'), 'PNG', doc.internal.pageSize.width - 90, 10, 70, 20);

                const imgData = canvas.toDataURL('image/png')

                doc.addImage(imgData, 'PNG', margins.left, margins.top, margins.width, 0)

                doc.save('CarbonReports.pdf')

            })
        }
    }

    const getRootNodeDetails = async () => {
        let rootNDetails = await props.getRootNodesUid()
        setRootDetails(rootNDetails)
    }

    const displayedColumns = columns.filter((column, index) => {
        if (index < 3) {
            return true
        }
        return selectedOptions.includes(column.label)
    })

    React.useEffect(() => {
        getRootNodeDetails()
    }, [])


    return (
        <Modal
            open={true}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
        >
            <Box sx={style}>
                <div style={{ maxHeight: 'calc(100vh - 12.20px)' }}>


                    <Grid container>
                        <Grid item xs={9}>
                            <Typography
                                id='modal-modal-title'
                                component='h1'
                                style={{
                                    color: '#2D323A',
                                    marginBottom: '10px',
                                    fontSize: '17'
                                }}
                            >
                                <b>Carbon Reports</b>
                            </Typography>
                        </Grid>

                        <Grid item xs={3}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end'
                                }}
                            >

                                <img
                                    src={`${process.env.REACT_APP_BASE_URL}${datajson.images.MainCanvas.Menubar.upload}`}
                                    alt='settings'
                                    style={{
                                        width: '25px',
                                        height: '25px',
                                    }}
                                />
                                <Typography
                                    style={{
                                        marginLeft: '5px',
                                        fontSize: '15px',
                                        marginRight: '12px',
                                        cursor: rootNodeSelected ? 'pointer' : 'not-allowed',
                                    }}
                                    onClick={exportPDF}
                                >
                                    {' '}
                                    Export
                                </Typography>

                                <Close
                                    style={{ color: '#029E9E', fontSize: 25, cursor: 'pointer' }}
                                    onClick={() => {
                                        props.handleCarbonReportsClose()
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid>


                    <Divider
                        style={{ width: '100%', color: 'darkgray', marginBottom: '0.5rem' }}
                    />



                    <Grid container spacing={2}>
                        <Grid item>
                            <Typography
                                id='modal-modal-title'
                                component='h1'
                                style={{ color: '#0C6276', fontSize: '14' }}
                            >
                                <b>Options</b>
                            </Typography>

                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '2px' }}>
                                <FormControl sx={{ width: '300px' }} size='small'>
                                    {rootNodeSelected ? (
                                        ''
                                    ) : (
                                        <InputLabel
                                            id='demo-select-small-label'
                                            shrink={false}
                                            focused={false}
                                            sx={{ margin: '-1% 0 auto 0' }}
                                        >
                                            Select an Option
                                        </InputLabel>
                                    )}
                                    <Select
                                        labelId='demo-select-small-label'
                                        id='demo-select-small'
                                        value={rootNodeSelected}
                                        onChange={handleRootNodeSelected}
                                        style={{
                                            width: '300px',
                                            height: '34px',
                                            boxShadow: '0 .125rem .25rem rgba(0,0,0,.075)'
                                        }}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: '200px',
                                                    width: '250px'
                                                }
                                            }
                                        }}
                                    >
                                        {rootDetails.map((item, index) => {
                                            return (
                                                <MenuItem key={item.id} value={item.id} style={{ fontSize: '14px', paddingLeft: '15px' }}>
                                                    {item.uid}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </div>

                        </Grid>
                        <Grid item>
                            <div
                                style={{
                                    fontSize: '14px',
                                    textAlign: 'left',
                                    font: 'normal normal 600 14px/19px Segoe UI',
                                    letterSpacing: '0px',
                                    color: '#0C6276',
                                    opacity: 1,
                                    marginBottom: '8px'
                                }}
                            >
                                <b>Environmental Metrics</b>
                            </div>


                            <Paper style={{ width: '250', marginRight: '30' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>

                                    <FormControl sx={{ width: '100%' }} size='small'>

                                        <Select
                                            labelId='demo-select-small-label'
                                            id='demo-select-small'
                                            multiple
                                            value={selectedOptions}
                                            onChange={handleVariableChange}
                                            input={<OutlinedInput label='Tag' />}
                                            style={{ height: '34px', boxShadow: '0 .125rem .25rem rgba(0,0,0,.075)' }}
                                            renderValue={(selected) => {
                                                if (selected.length === 0) {
                                                    return <em>Global Warming Potential</em>;
                                                }
                                                return <> Global Warming Potential {selected.join(', ')}</>;
                                            }}
                                            MenuProps={{
                                                style: { maxHeight: '300px', maxWidth: '400px' },
                                            }}
                                        >
                                            {columns.map((column, index) => {
                                                if (index < 2) {
                                                    return null;
                                                }

                                                return (
                                                    <MenuItem key={column.label} value={column.label} style={{ fontSize: '14px', padding: '5px', marginTop: '5px' }} disabled={column.property === "gwp_100"}>
                                                        <Checkbox checked={selectedOptions.includes(column.label)} />
                                                        <ListItemText primary={column.label} />
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>

                                </div>

                            </Paper>
                        </Grid>
                        <Grid item>
                            <Typography
                                id='modal-modal-title'
                                component='h1'
                                style={{ color: '#0C6276', fontSize: '14' }}
                            >
                                <b>Scopes</b>
                            </Typography>
                            <Grid item container direction='row' alignItems='center'>

                                <Paper style={{ height: '34px', width: '120px', marginRight: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Checkbox
                                            {...label}
                                            checked={checkedScope1}
                                            onChange={handleScope1Change}
                                            sx={{
                                                color: '#029E9E',
                                                '&.Mui-checked': {
                                                    color: '#029E9E'
                                                }
                                            }}
                                        />
                                        <Typography style={{ marginLeft: '5px', fontSize: '17px' }}>
                                            Scope 1
                                        </Typography>
                                    </div>
                                </Paper>

                                <Paper style={{ height: '34px', width: '120px', marginRight: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Checkbox
                                            {...label}
                                            checked={checkedScope2}
                                            onChange={handleScope2Change}
                                            sx={{
                                                color: '#029E9E',
                                                '&.Mui-checked': {
                                                    color: '#029E9E'
                                                }
                                            }}
                                        />
                                        <Typography style={{ marginLeft: '5px', fontSize: '17px' }}>
                                            Scope 2
                                        </Typography>
                                    </div>
                                </Paper>

                                <Paper style={{ height: '34px', width: '120px', marginRight: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Checkbox
                                            {...label}
                                            checked={checkedScope3}
                                            onChange={handleScope3Change}
                                            sx={{
                                                color: '#029E9E',
                                                '&.Mui-checked': {
                                                    color: '#029E9E'
                                                }
                                            }}
                                        />
                                        <Typography style={{ marginLeft: '5px', fontSize: '17px' }}>
                                            Scope 3
                                        </Typography>
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>


                    </Grid>

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '10'
                        }}
                    >
                    </div>

                    <Divider
                        style={{ width: '100%', color: '#707070', marginBottom: '0.5rem' }}
                    />
                </div>

                <div>
                    <TableContainer
                        component={Paper}
                        style={{ height: 'calc(100vh - 200.20px)', overflow: 'scroll' }}
                    >
                        <Table
                            sx={{ width: '100%' }}
                            aria-label='simple table'
                            id='Table_Id'
                        >
                            <TableHead
                                style={{
                                    background:
                                        'transparent linear-gradient(180deg, #0C6276 0%, #00A9A6 100%) 0% 0% no-repeat padding-box'
                                }}
                            >
                                <TableRow>
                                    {displayedColumns.map(column => (
                                        <TableCell
                                            key={column.label}
                                            component='th'
                                            scope='row'
                                            style={tableContentStyle1}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <HeatMapTable_CarbonReports
                                data={filteredCarbon123}
                                columns={displayedColumns}
                            />
                        </Table>
                    </TableContainer>
                </div>

            </Box>
        </Modal>
    )
}

export default CarbonReports