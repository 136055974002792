import React from 'react';
import { Button, Modal  } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Grid } from '@material-ui/core';
import { Close, ArrowUpward, ArrowDownward } from '@mui/icons-material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: 'calc(100vh - 65.20px)',
    width: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
};

function ClimateRisk(props) {
    return (
        <Modal
            open={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div style={{ maxHeight: 'calc(100vh - 12.20px)' }}>
                    <Grid container>
                        <Grid item xs={9}>
                            <Typography
                                id="modal-modal-title"
                                component="h1"
                                style={{ color: '#2D323A', marginBottom: '10px', fontSize: '17' }}
                            >
                                <b>Climate Risk</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                
                                <Close
                                    style={{ color: '#029E9E', fontSize: 25, cursor: 'pointer' }}
                                    onClick={() => {
                                        props.handleClimateModalClose();
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid>

                    <Divider style={{ width: '100%', color: 'darkgray', marginBottom: '0.5rem' }} />

                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '20px', marginTop: '8' }}>
                        <img
                            src={require('./../../assets/Table.png')}
                            alt=""
                            style={{
                                width: '50%',
                                marginRight: '15px',
                            }}
                        />

                        <table style={{ width: '50%', marginRight: '12px', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    <th style={{ background: 'transparent linear-gradient(180deg, #0C6276 0%, #00A9A6 100%) 0% 0% no-repeat padding-box', padding: '8px', color: 'white', border: "1px solid black", height: '50px' }}></th>
                                    <th style={{ background: 'transparent linear-gradient(180deg, #0C6276 0%, #00A9A6 100%) 0% 0% no-repeat padding-box', padding: '8px', color: 'white', border: "1px solid black", height: '50px' }}>Concrete Baseline</th>
                                    <th style={{ background: 'transparent linear-gradient(180deg, #0C6276 0%, #00A9A6 100%) 0% 0% no-repeat padding-box', padding: '8px', color: 'white', border: "1px solid black", height: '50px' }}>Arch Option 1a</th>
                                    <th style={{ background: 'transparent linear-gradient(180deg, #0C6276 0%, #00A9A6 100%) 0% 0% no-repeat padding-box', padding: '8px', color: 'white', border: "1px solid black", height: '50px' }}>Arch Option 1b</th>
                                    <th style={{ background: 'transparent linear-gradient(180deg, #0C6276 0%, #00A9A6 100%) 0% 0% no-repeat padding-box', padding: '8px', color: 'white', border: "1px solid black", height: '50px' }}>Arch Option 1c</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ border: '1px solid #000' }}><b>Health Risk</b></td>
                                    <td style={{ border: '1px solid #000' }}>
                                        High
                                        < ArrowDownward style={{ color: 'red', fontSize: '30px', verticalAlign: 'middle' }} />
                                    </td>
                                    <td style={{ border: '1px solid #000' }}>
                                        Low
                                        <ArrowUpward style={{ color: 'green', fontSize: '30px', verticalAlign: 'middle' }} />
                                    </td>
                                    <td style={{ border: '1px solid #000' }}>
                                        Low
                                        <ArrowUpward style={{ color: 'green', fontSize: '30px', verticalAlign: 'middle' }} />
                                    </td>
                                    <td style={{ border: '1px solid #000' }}>
                                        Low
                                        <ArrowUpward style={{ color: 'green', fontSize: '30px', verticalAlign: 'middle' }} />
                                    </td>
                                </tr>

                                <tr>
                                    <td style={{ border: '1px solid #000' }}><b>Water pollution</b></td>
                                    <td style={{ border: '1px solid #000' }}>
                                        High
                                        <ArrowDownward style={{ color: 'red', fontSize: '30px', verticalAlign: 'middle' }} />
                                    </td>
                                    <td style={{ border: '1px solid #000' }}>
                                        Moderate
                                        <ArrowForwardIcon style={{ color: 'orange', fontSize: '30px', verticalAlign: 'middle' }} />
                                    </td>
                                    <td style={{ border: '1px solid #000' }}>
                                        Low
                                        <ArrowUpward style={{ color: 'green', fontSize: '30px', verticalAlign: 'middle' }} />
                                    </td>
                                    <td style={{ border: '1px solid #000' }}>
                                        Low
                                        <ArrowUpward style={{ color: 'green', fontSize: '30px', verticalAlign: 'middle' }} />
                                    </td>
                                </tr>


                                <tr>
                                    <td style={{ border: '1px solid #000' }}><b>Marine Pollution</b></td>
                                    <td style={{ border: '1px solid #000' }}>
                                        Moderate
                                        <ArrowForwardIcon style={{ color: 'orange', fontSize: '30px', verticalAlign: 'middle' }} />
                                    </td>
                                    <td style={{ border: '1px solid #000' }}>
                                        High
                                        <ArrowDownward style={{ color: 'red', fontSize: '30px', verticalAlign: 'middle' }} />
                                    </td>
                                    <td style={{ border: '1px solid #000' }}>
                                        Low
                                        <ArrowUpward style={{ color: 'green', fontSize: '30px', verticalAlign: 'middle' }} />
                                    </td>
                                    <td style={{ border: '1px solid #000' }}>
                                        Low
                                        <ArrowUpward style={{ color: 'green', fontSize: '30px', verticalAlign: 'middle' }} />
                                    </td>
                                </tr>

                                <tr>
                                    <td style={{ border: '1px solid #000' }}><b>Water stress</b></td>
                                    <td style={{ border: '1px solid #000' }}>
                                        High
                                        <ArrowDownward style={{ color: 'red', fontSize: '30px', verticalAlign: 'middle' }} />
                                    </td>
                                    <td style={{ border: '1px solid #000' }}>
                                        Moderate
                                        <ArrowForwardIcon style={{ color: 'orange', fontSize: '30px', verticalAlign: 'middle' }} />
                                    </td>
                                    <td style={{ border: '1px solid #000' }}>
                                        Low
                                        <ArrowUpward style={{ color: 'green', fontSize: '30px', verticalAlign: 'middle' }} />
                                    </td>
                                    <td style={{ border: '1px solid #000' }}>
                                        Low
                                        <ArrowUpward style={{ color: 'green', fontSize: '30px', verticalAlign: 'middle' }} />
                                    </td>
                                </tr>


                                <tr>
                                    <td style={{ border: '1px solid #000' }}><b>Ecosystem Disruption </b></td>
                                    <td style={{ border: '1px solid #000' }}>
                                        High
                                        <ArrowDownward style={{ color: 'red', fontSize: '30px', verticalAlign: 'middle' }} />
                                    </td>
                                    <td style={{ border: '1px solid #000' }}>
                                        Moderate
                                        <ArrowForwardIcon style={{ color: 'orange', fontSize: '30px', verticalAlign: 'middle' }} />
                                    </td>
                                    <td style={{ border: '1px solid #000' }}>
                                        Low
                                        <ArrowUpward style={{ color: 'green', fontSize: '30px', verticalAlign: 'middle' }} />
                                    </td>
                                    <td style={{ border: '1px solid #000' }}>
                                        Low
                                        <ArrowUpward style={{ color: 'green', fontSize: '30px', verticalAlign: 'middle' }} />
                                    </td>
                                </tr>

                                <tr>
                                    <td style={{ border: '1px solid #000' }}><b>Economic Losses</b></td>
                                    <td style={{ border: '1px solid #000' }}>
                                        High
                                        <ArrowDownward style={{ color: 'red', fontSize: '30px', verticalAlign: 'middle' }} />
                                    </td>
                                    <td style={{ border: '1px solid #000' }}>
                                        Low
                                        <ArrowUpward style={{ color: 'green', fontSize: '30px', verticalAlign: 'middle' }} />
                                    </td>
                                    <td style={{ border: '1px solid #000' }}>
                                        Low
                                        <ArrowUpward style={{ color: 'green', fontSize: '30px', verticalAlign: 'middle' }} />
                                    </td>
                                    <td style={{ border: '1px solid #000' }}>
                                        Low
                                        <ArrowUpward style={{ color: 'green', fontSize: '30px', verticalAlign: 'middle' }} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                    <Button
                        variant='contained'
                        color='primary'
                        style={{
                            fontSize: '14px',
                            marginRight: '10px',
                            background: '#6f7173',
                            border: '1px solid #dfdfdf',
                            opacity: 1,
                            color: 'white',
                            height: '36px',
                            width: '150px',
                            borderRadius: '2px',
                            boxShadow: '0 .125rem .25rem rgba(0,0,0,.075)'
                        }} size='small'
                        onClick={() => {
                            props.handleClimateModalClose();
                        }}
                    >CANCEL</Button>
                    <Button
                        variant='contained'
                        color='primary'
                        style={{
                            fontSize: '14px',
                            marginRight: '10px',
                            background: 'transparent linear-gradient(180deg, #0C6276 0%, #00A9A6 100%) 0% 0% no-repeat padding-box',
                            color: 'white',
                            width: '150px',
                            border: '1px solid #dfdfdf',
                            opacity: 1,
                            borderRadius: '2px',
                            boxShadow: '0 .125rem .25rem rgba(0,0,0,.075)'
                        }}
                        size='small'>SUBMIT</Button>
                </div>
            </Box>
        </Modal>
    );
}

export default ClimateRisk;
