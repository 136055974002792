import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import { ArrowBack, History, SettingsOutlined } from '@mui/icons-material';
import { Alert, Divider, InputAdornment, Snackbar, Toolbar, TextField, Autocomplete } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import AnalyticsBalancedScorecard from './AnalyticsBalancedScorecard'
import DonutLargeOutlinedIcon from '@mui/icons-material/DonutLargeOutlined';

import EnvironmentalGptModal from './EnvironmentalGptModal';
import Optimizer from './Optimizer2';
import ClimateRisk from './ClimateRisk';
import Alternatives from './alternatives';
import Substitute from './substitute';
import CarbonReports from './CarbonReports';
import Neo4jSankeyChart from './Neo4jSankeyChart'
import ESgreport from './esgReports';
import Map from './supplyChainCarbonMetrics';

const Base_URL = process.env.REACT_APP_BACKEND_URL;
const styles = {
    whiteButton: {
        backgroundColor: 'white',
        border: '1px solid #ddd',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '10px',
        height: '30px',
        cursor: 'pointer'
    },
    disabledButton: {
        backgroundColor: '#dddddd',
        border: '1px solid #ccc',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '10px',
        height: '30px',
        cursor: 'not-allowed'
    },
    icon: {
        color: 'black',
    },
    text: {
        color: 'black',
        marginLeft: '5px',
    },
};


export default function FunctionBar(props) {


    const [ESGReportMenuAnchorEl, setESGReportMenuAnchorEl] = useState(null);
    const [supplyChainCarbonMetrics, setSupplyChainCarbonMetrics] = useState(null);
    const [ReportsMenu, setReportsMenu] = useState(null);
    const [advanceSettingsMenuAnchorEl, setadvanceSettingsMenuAnchorEl] = useState(null);
    const [advanceAnalyticsAnchorEl, setadvanceAnalyticsAnchorEl] = useState(null);

    const [substituteOpen, setsubstituteOpen] = useState(false);

    const { graphRef } = props;
    const { handleDialogSubmit } = props;
    const [searchString, setSearchString] = useState('');
    const [nodeList, setNodeList] = useState([]);
    const [relationList, setRelationList] = useState([]);
    const [searchSelect, setSearchSelect] = useState(null);
    const [stateNodes, setStateNodes] = useState();

    const [balancedModalOpen, setBalanceModalOpen] = useState(false);
    const [CarbonReportsOpen, setCarbonReportsOpen] = useState(false);
    const [OptimizerModalOpen, setOptimizerModalOpen] = useState(false);
    const [CarbonPathwaysOpen, setCarbonPathwaysOpen] = useState(false);
    const [EsgReportOpen, setEsgReportOpen] = useState(false);


    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [ClimateModalOpen, setClimateModalOpen] = React.useState(false);
    const [alternativesOpen, setAlternativesOpen] = useState(false);

    const [open401, setOpen401] = React.useState(false);
    const [open422, setOpen422] = React.useState(false);


    useEffect(() => {
        if (props?.graphRef?.current?.state?.nodes) {
            const tempNodeData = JSON.parse(JSON.stringify(props?.graphRef?.current?.state?.nodes));
            setStateNodes(tempNodeData);
        }
        searchNode();
        searchRelation()

    }, [])

    const handleReportsMenuClick = (event) => {
        setReportsMenu(event.currentTarget);

    };

    const handleadvanceSettingsMenuClick = (event) => {
        setadvanceSettingsMenuAnchorEl(event.currentTarget);

    };

    const handleRequestError = (error) => {
        console.error(error);
        if (error.response.request.status === 401) {
            console.log("You do not have permission to perform this activity");
            setOpen401(true);
        }
        if (error.response.request.status === 422) {
            console.log("Something went wrong while processing the request");
            setOpen422(true);
        }
    };



    const handleadvanceAnalyticsClick = (event) => {
        setadvanceAnalyticsAnchorEl(event.currentTarget);
    };




    const handleClose = () => {
        setReportsMenu(null);
        setadvanceSettingsMenuAnchorEl(null);
        setadvanceAnalyticsAnchorEl(null);
        setESGReportMenuAnchorEl(null);
    };

    const handleOpenModal = () => {
        setReportsMenu(null);
        setadvanceSettingsMenuAnchorEl(null);
        setadvanceAnalyticsAnchorEl(null);
        setIsModalOpen(true);
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };



    const handleBalancedModalOpen = () => {
        setReportsMenu(null);
        setadvanceSettingsMenuAnchorEl(null);
        setadvanceAnalyticsAnchorEl(null);
        setBalanceModalOpen(true);
    };

    const handleCarbonReportsOpen = () => {
        setReportsMenu(null);
        setadvanceSettingsMenuAnchorEl(null);
        setadvanceAnalyticsAnchorEl(null);
        setCarbonReportsOpen(true);
    };

    const handleOptimizerModalOpen = () => {
        setReportsMenu(null);
        setadvanceSettingsMenuAnchorEl(null);
        setadvanceAnalyticsAnchorEl(null);
        setOptimizerModalOpen(true);
    };

    const handleCarbonPathwaysModalOpen = () => {
        setadvanceSettingsMenuAnchorEl(null);
        setadvanceAnalyticsAnchorEl(null);
        setEsgReportOpen(null);
        setCarbonPathwaysOpen(true);
        setReportsMenu(null);
    };

    const handleEsgModalOpen = () => {
        setadvanceSettingsMenuAnchorEl(null);
        setadvanceAnalyticsAnchorEl(null);
        setEsgReportOpen(true);
        setCarbonPathwaysOpen(null);
        setReportsMenu(null);
    };


    const handleClimateModalOpen = () => {
        setReportsMenu(null);
        setadvanceSettingsMenuAnchorEl(null);
        setadvanceAnalyticsAnchorEl(null);
        setClimateModalOpen(true);
    };
    const handleSupplyChainCarbonReportModalOpen = () => {
        setReportsMenu(null);
        setadvanceSettingsMenuAnchorEl(null);
        setadvanceAnalyticsAnchorEl(null);
        setSupplyChainCarbonMetrics(true);
    };

    const handleAlternativeOpen = () => {
        setReportsMenu(null);
        setadvanceSettingsMenuAnchorEl(null);
        setadvanceAnalyticsAnchorEl(null);
        setAlternativesOpen(true);
    };

    const handlesubstituteOpen = () => {
        setReportsMenu(null);
        setadvanceSettingsMenuAnchorEl(null);
        setadvanceAnalyticsAnchorEl(null);
        setsubstituteOpen(true);
    };
    const searchNode = () => {
        const config = {
            headers: {
                'content-type': 'application/json',
                'authorization': 'bearer ' + sessionStorage.getItem('token'),
                'searchstring': searchString === '' ? 'a' : searchString
            }
        };
        axios.get(Base_URL + '/projects/nodes/' + sessionStorage.getItem('tenanatId') + '/' + sessionStorage.getItem('project_id'), config)
            .then(response => {
                if (response.data.status) {
                    setNodeList(response.data.data.nodes);
                }
            })
            .catch(handleRequestError);
    }
    const searchRelation = () => {
        const config = {
            headers: {
                'content-type': 'application/json',
                'authorization': 'bearer ' + sessionStorage.getItem('token'),
                'searchstring': searchString === '' ? 'a' : searchString
            }
        };
        axios.get(Base_URL + '/projects/relations/' + sessionStorage.getItem('tenanatId') + '/' + sessionStorage.getItem('project_id'), config)
            .then(response => {
                if (response.data.status) {
                    setRelationList(response.data.data.edges);
                }
            })
            .catch(function (error) {
                console.error(error);
                if (error.response.request.status === 401) {
                    console.log("You do not permission to perform this Actvity");
                    setOpen401(true);

                }
                if (error.response.request.status === 422) {
                    console.log("Something went wrong while processing request");
                    setOpen422(true);
                }
            });
    }

    const handleInputChange = (event, value) => {
        setSearchString(value);
        searchNode();
        searchRelation();
    };

    const handleButtonClick = () => {
        setSearchSelect(null);
        setSearchString('');
        searchNode();
        searchRelation();
    };


    useEffect(() => {
        // Take the Reference of Close Button
        const close = document?.getElementsByClassName(
            "MuiAutocomplete-clearIndicator"
        )[0];

        // Add a Click Event Listener to the button
        close?.addEventListener("click", handleButtonClick);

        // Cleanup function to remove the event listener when the component unmounts
        return () => {
            close?.removeEventListener("click", handleButtonClick);
        };
    }, [searchSelect]);

    const onSearchClick = () => {
        searchNode();
        searchRelation();
        props.unset_SelectedNode(searchSelect);
    };

    const handleSearchSelect = (event, value) => {
        setSearchSelect(value);
        props.set_SelectedNode(value);
    };

    const handleBalancedModalClose = () => {
        setBalanceModalOpen(false)
    };

    const handleCarbonReportsClose = () => {
        setCarbonReportsOpen(false)
    };

    const handleClimateModalClose = () => {
        setClimateModalOpen(false)
    };

    const handleOptimizerModalClose = () => {
        setOptimizerModalOpen(false)
    };
    const handleCarbonPathwaysModalClose = () => {
        setCarbonPathwaysOpen(false)
    };
    const handleESGModalClose = () => {
        setEsgReportOpen(false)
    };
    const handleSupplyChainCarbonMetricsModalClose = () => {
        setSupplyChainCarbonMetrics(false)
    };



    const navigate = useNavigate();
    const location = useLocation();

    const handleBackButtonClick = () => {
        if (location.pathname === '/map') {
            navigate('/mainCanvas');
        } else if (location.pathname === '/mainCanvas') {
            navigate('/');
        }
    };


    const handleAlternativeClose = () => {
        setAlternativesOpen(false)
    };

    const handleSubstituteClose = () => {
        setsubstituteOpen(false)
    };

    const renderAutocompleteOption = (renderprops, option) => {
        let imgSrc;
        if (option.svg) {
            if (option.svg.startsWith('https')) {
                imgSrc = option.svg;
            } else {
                imgSrc = process.env.REACT_APP_BACKEND_URL + "/" + option.svg;
            }
        } else {
            imgSrc = process.env.REACT_APP_BASE_URL + "/assets/application_specific/relationshipicon(color).png";
        }

        let displayText;
        if (option?.erelationship) {
            const uidParts = option?.uid?.split("-");
            const lastTwoParts = uidParts?.slice(-2).join(` - ${option.erelationship} - `);
            displayText = `${lastTwoParts}`;
        } else {
            displayText = option.uid;
        }

        let nodeType = option.erelationship ? "Relationship" : "Node";

        return (
            <>
                <Divider style={{ width: '95%', color: '#707070', marginLeft: '10' }} />
                <div style={{ marginTop: '0.5rem' }}>
                    <li {...renderprops} style={{ marginBottom: '10' }}>
                        <img
                            src={imgSrc}
                            alt={option.id}
                            style={{ borderRadius: '10px', width: 40, height: 40, marginRight: 10 }}
                        />
                        <div>
                            <b><span style={{ fontSize: 16, color: '#666666' }}>{displayText}</span></b>
                            <br />
                            <span style={{ fontSize: 11, color: 'grey' }}>{nodeType}</span>
                        </div>
                    </li>
                </div>
            </>
        );
    };

    return (
        <>
            <AppBar
                position="realative"
                style={{
                    backgroundColor: 'white',
                    border: '1px', height: '55px',
                    // marginTop: '55px',
                    zIndex: '2'
                }}>
                {/* <Toolbar style={{ justifyContent: 'flex-start' }}> */}
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 24px', height: '55px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', }}>
                        <button onClick={handleBackButtonClick} style={styles.whiteButton}><ArrowBack style={{ color: '#029E9E' }} /></button>
                        <button aria-controls="ESGReport" onClick={handleReportsMenuClick} keepMountedaria-haspopup="true" style={props?.functionBarData?.esg_reports?.enabled === true ? styles.whiteButton : styles.disabledButton} disabled={props?.functionBarData?.esg_reports?.enabled === true ? false : true}><DonutLargeOutlinedIcon style={styles.icon} /> <span style={styles.text}>Reports</span></button>
                        <Menu
                            id="ESGReport"
                            anchorEl={ReportsMenu}
                            // keepMounted
                            open={Boolean(ReportsMenu)}
                            onClose={handleClose}
                            style={{ top: '48px', left: '0' }}>
                            <MenuItem onClick={handleEsgModalOpen}>ESG Report</MenuItem>
                            <MenuItem onClick={handleCarbonPathwaysModalOpen}>Critical Pathways</MenuItem>
                        </Menu>

                        <button aria-controls="advanceSettingsMenu" aria-haspopup="true" onClick={handleadvanceSettingsMenuClick} style={props?.functionBarData?.decarbonization?.enabled === true ? styles.whiteButton : styles.disabledButton} disabled={props?.functionBarData?.decarbonization?.enabled === true ? false : true}><SettingsOutlined style={styles.icon} /> <span style={styles.text}>Decarbonization</span></button>
                        <Menu id="advanceSettingsMenu"
                            anchorEl={advanceSettingsMenuAnchorEl}
                            keepMounted
                            open={Boolean(advanceSettingsMenuAnchorEl)}
                            onClose={handleClose}
                            style={{ top: '48px', left: '0' }}>
                            <MenuItem onClick={handleAlternativeOpen}>Alternatives</MenuItem>
                            <MenuItem onClick={handlesubstituteOpen}>Substitutes</MenuItem>
                            <MenuItem onClick={handleCarbonReportsOpen}>Carbon Report</MenuItem>
                            <MenuItem onClick={handleOpenModal}>Environmental GPT</MenuItem>
                        </Menu>

                        <button aria-controls="advanceAnalytics" aria-haspopup="true" onClick={handleadvanceAnalyticsClick} style={props?.functionBarData?.advance_analytics?.enabled === true ? styles.whiteButton : styles.disabledButton} disabled={props?.functionBarData?.advance_analytics?.enabled === true ? false : true}><History style={styles.icon} /> <span style={styles.text}>Advanced Analytics</span></button>
                        <Menu
                            id="advanceAnalytics"
                            anchorEl={advanceAnalyticsAnchorEl}
                            keepMounted
                            open={Boolean(advanceAnalyticsAnchorEl)}
                            onClose={handleClose}
                            style={{ top: '48px', left: '0' }}>

                            <MenuItem onClick={handleOptimizerModalOpen}>Optimizer</MenuItem>
                            <MenuItem onClick={handleClose}>Net Zero (Timeline)</MenuItem>
                            <MenuItem onClick={handleBalancedModalOpen}>Balanced Scorecard</MenuItem>
                            <MenuItem onClick={handleClimateModalOpen}>Climate Risk</MenuItem>
                            <MenuItem onClick={handleSupplyChainCarbonReportModalOpen}>Supply Chain Carbon Metrics</MenuItem>
                        </Menu>

                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', }}>

                        <Autocomplete
                            id="controlled-demo"
                            options={nodeList && relationList ? nodeList.concat(relationList) : ''}
                            getOptionLabel={(option) => option.uid}
                            inputValue={searchString}
                            onInputChange={handleInputChange}
                            value={searchSelect}
                            onChange={handleSearchSelect}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    placeholder='Search...'
                                    style={{ width: '400' }}
                                    onClick={onSearchClick}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon style={{ color: '#029E9E' }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                            renderOption={renderAutocompleteOption}

                        />
                    </div>
                </div>
                {/* </Toolbar> */}
            </AppBar>
            <Snackbar open={open401} autoHideDuration={4000} onClose={() => { setOpen401(false) }}>
                <Alert onClose={() => { setOpen401(false) }} severity="error" sx={{ width: '100%' }}>
                    You do not permission to perform this Actvity
                </Alert>
            </Snackbar>
            <Snackbar open={open422} autoHideDuration={4000} onClose={() => { setOpen422(false) }}>
                <Alert onClose={() => { setOpen422(false) }} severity="error" sx={{ width: '100%' }}>
                    Something went wrong while processing request
                </Alert>
            </Snackbar>

            {balancedModalOpen && (<AnalyticsBalancedScorecard handleBalancedModalClose={handleBalancedModalClose} getChildNodes={props.getChildNodes} getLink={props.getLink} getRootNodesUid={props.getRootNodesUid} />)
            }

            {CarbonReportsOpen && (<CarbonReports handleCarbonReportsClose={handleCarbonReportsClose} getChildNodes={props.getChildNodes} getLink={props.getLink} getRootNodesUid={props.getRootNodesUid} />)
            }

            {isModalOpen && (
                <EnvironmentalGptModal handleclose={handleCloseModal} getChildNodes={props.getChildNodes} getLink={props.getLink} getRootNodesUid={props.getRootNodesUid} />
            )}

            {ClimateModalOpen && (
                <ClimateRisk
                    handleClimateModalClose={handleClimateModalClose}
                    getChildNodes={props.getChildNodes} getLink={props.getLink} getRootNodesUid={props.getRootNodesUid}
                />
            )}

            {OptimizerModalOpen && (
                <Optimizer
                    nodes={stateNodes}
                    calculateInfo={graphRef.current.calculateInfo}
                    _getSuccessorNodes={graphRef.current._getSuccessorNodes}
                    getRootNodesUid={props.getRootNodesUid}
                    handleOptimizerModalClose={handleOptimizerModalClose}
                />
            )}

            {CarbonPathwaysOpen && (
                <Neo4jSankeyChart
                    handleCarbonPathwaysClose={handleCarbonPathwaysModalClose}
                // esgReportLink = {props?.functionBarData?.esg_reports?.link || ""}
                />
            )}

            {EsgReportOpen && (
                <ESgreport
                    handleESGModalClose={handleESGModalClose}
                    esgReportLink={props?.functionBarData?.esg_reports?.link || ""}
                />
            )}
            {supplyChainCarbonMetrics && (
                <Map handleSupplyChainCarbonMetricsModalClose={handleSupplyChainCarbonMetricsModalClose} />
            )}


            {alternativesOpen && (<Alternatives handleAlternativeClose={handleAlternativeClose} getChildNodes={props.getChildNodes} getLink={props.getLink} getRootNodesUid={props.getRootNodesUid} handleDialogSubmit={handleDialogSubmit} />)
            }
            {
                substituteOpen && (
                    <Substitute
                        handleSubstituteClose={handleSubstituteClose}
                        getChildNodes={props.getChildNodes}
                        getLink={props.getLink}
                        getRootNodesUid={props.getRootNodesUid}
                    />
                )
            }
        </>
    );
}
