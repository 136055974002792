import React, { Component } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import NodeProperties from "./NodeProperties";

let final_content = [];

const useStyles = (theme) => ({
  // Define your styles here
  root: {
    backgroundColor: "lightblue",
    padding: theme.spacing(2),
  },
  button: {
    backgroundColor: "orange",
    color: "white",
  },
});

const formatNumber = (num) => {
  if (typeof num === "number") {
    const roundedNumber = parseFloat(num.toFixed(2));
    return roundedNumber.toLocaleString("en-US");
  } else {
    return num;
  }
};

const tableHeadStyle = {
  fontSize: "10",
  lineHeight: "1.3",
  color: "white",
  padding: "10px",
};

const tableContentStyle = {
  fontSize: "10",
  lineHeight: "1.3",
  padding: "10px",
};

const unitOfMeasureMapping = {
  eci_cost: "",
  adp_elements: "kg Sb eq.",
  adp_fossil: "MJ",
  ap: "kg SO2 eq.",
  ep: "kg Phosphet eq.",
  faetp: "kg DCB eq.",
  gwp_100: "kg CO2 eq.",
  gwp_100_ebc: "kg CO2 eq.",
  htp: "kg DCB eq.",
  maetp: "kg DCB eq.",
  odp: "kg R11 eq.",
  pocp: "kg Ethene eq.",
  tetp: "kg DCB eq.",
};

const GraphLabelRendering = {
  eci_cost: "ECI Cost in USD",
  adp_elements: "Abiotic Depletion (ADP elements) in kg Sb eq.",
  adp_fossil: "Abiotic Depletion (ADP fossil) in MJ",
  ap: "Acidification Potential (AP) in kg SO2 eq.",
  ep: "Eutrophication Potential (EP) in kg Phosphate eq.",
  faetp: "Freshwater Aquatic Ecotoxicity Pot. (FAETP inf.) in kg DCB eq.",
  gwp_100: "Global Warming Potential (GWP 100 years) in kg CO2 eq.",
  gwp_100_ebc:
    "Global Warming Potential (GWP 100 years), excl biogenic carbon in kg CO2 eq.",
  htp: "Human Toxicity Potential (HTP inf.) in kg DCB eq.",
  maetp: "Marine Aquatic Ecotoxicity Pot. (MAETP inf.) in kg DCB eq.",
  odp: "Ozone Layer Depletion Potential (ODP, steady state) in kg R11 eq.",
  pocp: "Photochem. Ozone Creation Potential (POCP) in kg Ethene eq.",
  tetp: "Terrestric Ecotoxicity Potential (TETP inf.) in kg DCB eq.",
};

const EnvImpacts = [{ "label": "Abiotic Depletion (ADP elements) [kg Sb eq.]", "value": "adp_elements" },
{ "label": "Abiotic Depletion (ADP fossil) [MJ]", "value": "adp_fossil" },
{ "label": "Acidification Potential [kg SO2 eq.]", "value": "ap" },
{ "label": "ECI Cost [USD]", "value": "eci_cost" },
{ "label": "Eutrophication Potential [kg Phosphet eq.]", "value": "ep" },
{ "label": "Freshwater Aquatic Ecotoxicity Pot. [kg DCB eq.]", "value": "faetp" },
{ "label": "Global Warming Potential [kg CO2 eq.]", "value": "gwp_100" },
{ "label": "Global Warming Potential, excl [kg CO2 eq.]", "value": "gwp_100_ebc" },
{ "label": "Human Toxicity Potential [kg DCB eq.]", "value": "htp" },
{ "label": "Marine Aquatic Ecotoxicity Pot. [kg DCB eq.]", "value": "maetp" },
{ "label": "Ozone Layer Depletion Potential [kg R11 eq.]", "value": "odp" },
{ "label": "Photochem. Ozone Creation Potential [kg Ethene eq.]", "value": "pocp" },
{ "label": "Terrestric Ecotoxicity Potential [kg DCB eq.]", "value": "tetp" }];

class DashboardSidePanelcomponent extends Component {
  constructor(props) {
    super(props);
    final_content = []
    this.state = {
      propertySelected: props?.dashboardTabConfigJson
        ? props?.dashboardTabConfigJson["enviromental-variable-dropdown"]
          ?.length > 0
          ? props?.dashboardTabConfigJson["enviromental-variable-dropdown"][0]
            ?.value
          : ""
        : "",
      rootDetails: [],
      ScopeDetails: [],
      data: [],
      subTab: "comparison",
      final_data: [],
      mainCanvasGraphRef: this.props?.graphRefInstance,
      dashboardDetailsTableColumns: [],
      dashboardDetailsTableRows: [],
      barChartRootNodeUids: [],
      scopeNodesName: [],
      colorCodesScopeNodes: {},
      final_content: [],
      selectedProperty: "gwp_100",
      childNodeCount: 0,
    };
  }

  handleselectedPropertyChange = (value) => {
    this.setState({ selectedProperty: value });
  };
  setChildNodeCount = (count) => {
    this.setState({ childNodeCount: count });
  };

  getNodeMaterialDetails = (nodeID) => {
    const { getChildNodes, getLink } = this.props;
    const ScopeDetails = getChildNodes(nodeID);
    const ScopeNodeId = ScopeDetails.map((node) => node.id);
    const content = [];
    ScopeNodeId.forEach((scopeNode) => {
      const materialDetails = getChildNodes(scopeNode);
      materialDetails.forEach((material) => {
        const linkInfo = getLink(scopeNode, material.id);
        if (material.uid === "GFRP Panels") {
          const contentTemp = {
            Node_name: this.state.barChartRootNodeUids.find(str => linkInfo?.source?.parent_uids?.includes(str)),
            Scope: this.state.scopeNodesName.find(str => linkInfo?.source?.parent_names?.includes(str)) || 'scope',
            Material: material.uid,
            eci_cost: 0.29425608759882493 * (linkInfo.quantity || 1),
            adp_elements: 0.00003255342316163526 * (linkInfo.quantity || 1),
            adp_fossil: 23.15001252217553 * (linkInfo.quantity || 1),
            ap: 0.00009857302148574831 * (linkInfo.quantity || 1),
            ep: 1.990004963647467 * (linkInfo.quantity || 1),
            faetp: 2.6156248298898346 * (linkInfo.quantity || 1),
            gwp_100: 2.7283101784485098 * (linkInfo.quantity || 1),
            gwp_100_ebc: 2.5800602004874236 * (linkInfo.quantity || 1),
            htp: 0.016685509370688578 * (linkInfo.quantity || 1),
            maetp: 248.10732773268137 * (linkInfo.quantity || 1),
            odp: 1.6694216999384292e-13 * (linkInfo.quantity || 1),
            pocp: 0.0004300726460283455 * (linkInfo.quantity || 1),
            tetp: 0.0065042997305395645 * (linkInfo.quantity || 1),
          };
          content.push(contentTemp);
        } else {
          const contentTemp = {
            Node_name: this.state.barChartRootNodeUids.find(str => linkInfo?.source?.parent_uids?.includes(str)),
            Scope: this.state.scopeNodesName.find(str => linkInfo?.source?.parent_names?.includes(str)) || 'scope',
            Material: material.uid,
            eci_cost: (linkInfo.target.eci_cost || 0) * (linkInfo.quantity || 1),
            adp_elements: (linkInfo.target.adp_elements || 0) * (linkInfo.quantity || 1),
            adp_fossil: (linkInfo.target.adp_fossil || 0) * (linkInfo.quantity || 1),
            ap: (linkInfo.target.ap || 0) * (linkInfo.quantity || 1),
            ep: (linkInfo.target.ep || 0) * (linkInfo.quantity || 1),
            faetp: (linkInfo.target.faetp || 0) * (linkInfo.quantity || 1),
            gwp_100: (linkInfo.target.gwp_100 || 0) * (linkInfo.quantity || 1),
            gwp_100_ebc: (linkInfo.target.gwp_100_ebc || 0) * (linkInfo.quantity || 1),
            htp: (linkInfo.target.htp || 0) * (linkInfo.quantity || 1),
            maetp: (linkInfo.target.maetp || 0) * (linkInfo.quantity || 1),
            odp: (linkInfo.target.odp || 0) * (linkInfo.quantity || 1),
            pocp: (linkInfo.target.pocp || 0) * (linkInfo.quantity || 1),
            tetp: (linkInfo.target.tetp || 0) * (linkInfo.quantity || 1),
          };
          content.push(contentTemp);
        }
      });
    });
    // this.setState({
    //   final_content : [...final_content,content]
    // })
    final_content.push(content);
  };

  getindividualdata = (value) => {
    const newData = [];
    final_content.forEach((individual_nodes) => {
      individual_nodes.forEach((dictionaries) => {
        const contentTemp1 = {
          name: dictionaries.Node_name,
          Scope: dictionaries.Scope,
          Material: dictionaries.Material,
          value: dictionaries[value],
        };
        newData.push(contentTemp1);
      });
      this.setState({ final_data: newData });
    });
  };

  handleSubTabChange = (event, newValue) => {
    this.setState({ subTab: newValue });
  };

  handlePropertyChange = (event) => {
    const { rootDetails } = this.state;
    const value = event.target.value;
    this.setState({ propertySelected: value, final_data: [] });
    // const data = [
    //   { name: rootDetails[0].uid, value: rootDetails[0][value] },
    //   { name: rootDetails[1].uid, value: rootDetails[1][value] },
    //   { name: rootDetails[2].uid, value: rootDetails[2][value] },
    //   { name: rootDetails[3].uid, value: rootDetails[3][value] },
    // ];
    // this.setState({ data });
    this.getindividualdata(value);
  };

  async componentDidMount() {
    try {
      const materialDetailPromises = [];
      const dashboardDetailsTable = this.props?.dashboardTabConfigJson
        ? this.props?.dashboardTabConfigJson["dashboard-details-table"]
        : {};
      const modifiedRootNodeIds = await this.getDetailsById(
        dashboardDetailsTable["column-header-ids"]
      );
      const modifiedRows = await this.getDetailsTableRows(
        dashboardDetailsTable["rows"]
      );
      const barChartRootNodeUidsDetails = await this.getDetailsById(this.props?.dashboardTabConfigJson?.dashboardBarchartConfig?.barChartRoots)
      this.setState({
        dashboardDetailsTableColumns: modifiedRootNodeIds,
        dashboardDetailsTableRows: modifiedRows
      });
      const BarChartRootNodeUids = barChartRootNodeUidsDetails.map((node) => { return node.uid })
      this.setState({
        rootDetails: modifiedRootNodeIds,
        barChartRootNodeUids: BarChartRootNodeUids,
        scopeNodesName: this.props?.dashboardTabConfigJson?.dashboardBarchartConfig?.scopeNodesName || [],
        colorCodesScopeNodes: this.props?.dashboardTabConfigJson?.dashboardBarchartConfig?.colorCodesScopeNodes || {}
      }, async () => {
        if (
          this.props?.dashboardTabConfigJson?.dashboardBarchartConfig?.barChartRoots &&
          this.props?.dashboardTabConfigJson?.dashboardBarchartConfig?.barChartRoots?.length > 0
        ) {
          for (
            var i = 0;
            i < this.props?.dashboardTabConfigJson?.dashboardBarchartConfig?.barChartRoots?.length;
            i++
          ) {
            materialDetailPromises.push(
              this.getNodeMaterialDetails(
                this.props?.dashboardTabConfigJson?.dashboardBarchartConfig?.barChartRoots[i]
              )
            );
          }
        }
        this.getindividualdata(this.state.propertySelected);
        await Promise.all(materialDetailPromises);
      });

      // await this.getRootNodeDetails();
    } catch (err) {
      console.log("Error while data display ");
      console.log(err);
    }
  }

  getDetailsById = async (nodeIdsArr) => {
    var modifiedNodeIds = [];
    if (nodeIdsArr?.length > 0) {
      for (var i = 0; i < nodeIdsArr.length; i++) {
        var nodeDetails;
        if (nodeIdsArr[i] !== "-") {
          nodeDetails = await this.props.getNodeDetailsForTab(nodeIdsArr[i]);
        } else {
          nodeDetails = ""
        }
        modifiedNodeIds.push(nodeDetails || "");
      }
    }
    return modifiedNodeIds;
  };

  getDetailsTableRows = async (rows) => {
    const modifiedRows = [];
    if (rows?.length > 0) {
      for (var i = 0; i < rows.length; i++) {
        var nodeDetails = await this.getDetailsById(rows[i]["row-ids"]);
        modifiedRows.push({
          "row-header-name": rows[i]["row-header-name"],
          "row-ids": nodeDetails,
        });
      }
    }
    return modifiedRows;
  };

  getRootNodeDetails = async () => {
    const { getRootNodesUid, getChildNodes } = this.props;
    let rootNDetails = await getRootNodesUid();
    let ScopeNodeId = [];
    let ScopeDetailsArr = [];
    this.setState({ rootDetails: rootNDetails });
    this.setState({
      data: [
        {
          name: rootNDetails[0].uid,
          value: rootNDetails[0][this.state.propertySelected],
        },
        {
          name: rootNDetails[1].uid,
          value: rootNDetails[1][this.state.propertySelected],
        },
        {
          name: rootNDetails[2].uid,
          value: rootNDetails[2][this.state.propertySelected],
        },
        {
          name: rootNDetails[3].uid,
          value: rootNDetails[3][this.state.propertySelected],
        },
      ],
    });

    rootNDetails.forEach((node) => {
      ScopeNodeId.push(node.id);
    });

    ScopeNodeId.forEach((scopeNode) => {
      let materialDetails = getChildNodes(scopeNode);
      ScopeDetailsArr.push(materialDetails);
    });

    ScopeDetailsArr.forEach((scopeNode) => {
      scopeNode.forEach(async (childNodes, index) => {
        if (childNodes["eci_cost"] === "") {
          let deatilsFetched = await this.props.getNodeDetailsForTab(
            childNodes.id
          );
          scopeNode[index] = deatilsFetched;
        }
      });
    });

    this.setState({ ScopeDetails: ScopeDetailsArr });
  };
  ChartComponent = (data) => {
    const rootNodeNames = [...new Set(data.map((item) => item.name))];
    const materialNames = [...new Set(data.map((item) => item.Material))];

    const getScopeColor = (material) => {
      return this.state.colorCodesScopeNodes[material.Scope] || this.state.colorCodesScopeNodes["default"];
    };

    const seriesData = materialNames.map((materialName) => {
      const materialData = data.filter(
        (item) => item.Material === materialName
      );
      const materialValues = rootNodeNames.map((rootName) => {
        const matchingData = materialData.find(
          (item) => item.name === rootName
        );
        return matchingData ? matchingData.value : 0;
      });

      return {
        name: materialName,
        data: materialValues,
        color: getScopeColor(materialData[0]),
      };
    });

    const options = {
      credits: {
        enabled: false,
      },
      chart: {
        type: "column",
      },
      title: {
        text: "",
      },
      xAxis: {
        categories: rootNodeNames,
      },
      yAxis: {
        title: {
          text:
            unitOfMeasureMapping[this.state.propertySelected] === ""
              ? "USD"
              : unitOfMeasureMapping[this.state.propertySelected],
        },
        stackLabels: {
          enabled: false,
          style: {
            fontWeight: "normal",
            color: "black",
          },
        },
        labels: {
          formatter: function () {
            let value = this.value;
            let absValue = Math.abs(value);
            if (absValue >= 1000000) {
              return (value / 1000000).toFixed(1) + "M";
            } else if (absValue >= 1000) {
              return (value / 1000).toFixed(1) + "K";
            } else {
              return value.toString();
            }
          },
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        column: {
          stacking: "normal",
          minPointLength: 8,
        },
      },
      series: seriesData,
      tooltip: {
        formatter: function () {
          let tooltip = `<b>${this.x}</b><br/>`;
          this.points.forEach((point) => {
            const formattedValue =
              point.y >= 0 ? point.y : `-${Math.abs(point.y)}`;
            tooltip += `<span style="color:${point.series.color}">\u25CF</span> ${point.series.name}: ${formattedValue}<br/>`;
          });

          return tooltip;
        },
        shared: true,
        style: {
          fontSize: "10px",
          width: "300px",
          height: "100px",
        },
      },
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
  };

  childNodeRender() {
    const { selectedProperty } = this.state;
    const { childNode } = this.props;
    const { newNode } = this.props;
    if (!newNode) {
      return <span>Please select a node</span>;
    }

    // if (childNode.length === 0) {
    //   return <span>No Child Node Present for this node</span>;
    // }

    return (
      // <TableContainer component={Paper}>
      //   <Table sx={{ width: "100%" }} aria-label="simple table">
      //     <TableHead style={{ backgroundColor: "#029E9E" }}>
      //       <TableRow>
      //         <TableCell component="th" scope="row" style={tableHeadStyle}>
      //           Contains/Uses
      //         </TableCell>
      //         <TableCell align="Left" style={tableHeadStyle}>
      //           Value
      //         </TableCell>
      //       </TableRow>
      //     </TableHead>
      //     <TableBody>
      //       {this.props.childNode.map((child, index) => (
      //         <TableRow
      //           key={index}
      //           sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      //         >
      //           <TableCell component="th" scope="row" style={tableContentStyle}>
      //             {child.name}
      //           </TableCell>
      //           <TableCell align="Left" style={tableContentStyle}>
      //             {propertySelected === "eci_cost"
      //               ? "$" + formatNumber(child[propertySelected])
      //               : formatNumber(child[propertySelected])}{" "}
      //             {unitOfMeasureMapping[propertySelected]}
      //           </TableCell>
      //         </TableRow>
      //       ))}
      //     </TableBody>
      //   </Table>
      // </TableContainer>
      <NodeProperties
        properties={this.props.newNode}
        neo4jConfig={this.props.neo4jConfig}
        width={this.props.width}
        envImpacts={EnvImpacts}
        selectedEnvProperty={selectedProperty}
        setSelectedEnvProperty={this.handleselectedPropertyChange}
        setChildNodeCount={this.setChildNodeCount}
      />
    );
  }

  EnvironmentalMatrix() {
    const { propertySelected } = this.state;

    return (
      <FormControl
        variant="outlined"
        style={{ width: "100%", marginLeft: "5px" }}
      >
        <InputLabel id="demo-select-small-label"></InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={propertySelected}
          label=""
          style={{
            height: "35px",
            fontSize: "12px",
            display: "flex",
            alignItems: "center",
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: "250px",
                width: "250px",
              },
            },
          }}
          onChange={this.handlePropertyChange}
        >
          {this.props?.dashboardTabConfigJson &&
            this.props?.dashboardTabConfigJson["enviromental-variable-dropdown"]
              ?.length > 0 &&
            this.props?.dashboardTabConfigJson[
              "enviromental-variable-dropdown"
            ].map((menuItem, index) => (
              <MenuItem
                value={menuItem.value}
                key={index}
                className="menuitem-class"
              >
                {menuItem.label}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  }

  render() {
    const { propertySelected, rootDetails, ScopeDetails, subTab, final_data, selectedProperty, childNodeCount } =
      this.state;
    const { selectedMessage } = this.props;
    return (
      <>
        {/* <Grid item xs={12}>
          <Typography style={{ fontSize: "14", color: "#2D323A" }}>
            <b>DASHBOARD</b>
          </Typography>
        </Grid> */}
        <div style={{ display: 'grid', alignItems: 'center', gridTemplateColumns: 'auto 1fr', gap: '15px', marginBottom: '15px' }}>
          <Tabs
            value={subTab}
            onChange={this.handleSubTabChange}
            textColor="black"
            TabIndicatorProps={{ style: { backgroundColor: "#029E9E" } }}
            aria-label="wrapped label tabs example"
          >
            <Tab
              value="comparison"
              label="Comparison"
              sx={{ fontSize: 12, fontWeight: "bold", textTransform: "none" }}
            />
            <Tab
              value="explore"
              label="Explore"
              sx={{ fontSize: 12, fontWeight: "bold", textTransform: "none" }}
            />
          </Tabs>
          {subTab === "explore" && childNodeCount > 0 && < select
            value={selectedProperty}
            onChange={(e) => this.setState({ selectedProperty: e.target.value })}
            className="select-property hide-vertical"
          >
            {EnvImpacts.map((impact) => (
              <option key={impact.value} value={impact.value}>
                {impact.label}
              </option>
            ))}
            {/* Add more options for other properties */}
          </select>}
        </div >
        {subTab === "comparison" && (
          <>
            <Grid item xs={12} style={{ paddingTop: "10px" }}>
              <Typography style={{ fontSize: "12", color: "#2D323A" }}>
                Environmental Metrics
              </Typography>
            </Grid>
            {this.EnvironmentalMatrix()}

            <Grid item xs={12} style={{ paddingTop: "20px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ width: "100%" }} aria-label="simple table">
                  <TableHead
                    style={{ backgroundColor: "#029E9E", padding: "0px" }}
                  >
                    <TableRow style={{ padding: "0px" }}>
                      <TableCell style={tableHeadStyle}>Options</TableCell>
                      {this.state.dashboardDetailsTableColumns.map(
                        (root, index) => (
                          <TableCell align="center" style={tableHeadStyle}>
                            {root?.name || "-"}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {this.state.dashboardDetailsTableRows.map((row, index) => (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            fontSize: "10",
                            lineHeight: "1.3",
                            padding: "0px",
                            paddingLeft: "10px",
                          }}
                        >
                          {row["row-header-name"]}
                        </TableCell>
                        {row["row-ids"]?.length > 0 &&
                          row["row-ids"].map((rowElement, index) => (
                            <TableCell align="center" style={tableContentStyle}>
                              {rowElement !== "" ? propertySelected === "eci_cost"
                                ? "$" +
                                formatNumber(rowElement[propertySelected])
                                : formatNumber(rowElement[propertySelected]) : "-"}
                            </TableCell>
                          ))}
                      </TableRow>
                    ))}
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          fontSize: "10",
                          lineHeight: "1.3",
                          padding: "0px",
                          paddingLeft: "10px",
                        }}
                      >
                        Total
                      </TableCell>
                      {this.state.dashboardDetailsTableColumns.map(
                        (rootNode, index) => (
                          <TableCell align="center" style={tableContentStyle}>
                            {rootNode !== "" ? propertySelected === "eci_cost"
                              ? "$" + formatNumber(rootNode[propertySelected])
                              : formatNumber(rootNode[propertySelected]) : "-"}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid item xs={12}>
                <Typography
                  style={{
                    fontSize: "14",
                    color: "#2D323A",
                    marginTop: "15px",
                  }}
                >
                  {selectedMessage}
                </Typography>
              </Grid>

              <div
                style={{ overflow: "auto", marginTop: "30px", width: "100%" }}
              >
                <Typography style={{ color: "#2D323A", paddingBottom: "10px" }}>
                  {GraphLabelRendering[propertySelected]}
                </Typography>
                {this.ChartComponent(final_data)}
              </div>
            </Grid>
          </>
        )
        }
        {
          subTab === "explore" && (
            <>
              {/* <Grid item xs={12} style={{ paddingTop: "20px" }}>
              <Typography style={{ fontSize: "12", color: "#2D323A" }}>
                Environmental Metrics
              </Typography>
            </Grid>
            {this.EnvironmentalMatrix()} */}
              {/* <Grid item xs={12} style={{ paddingTop: "10px" }}> */}
              {this.childNodeRender()}
              {/* </Grid> */}
            </>
          )
        }
      </>
    );
  }
}

export default DashboardSidePanelcomponent;
